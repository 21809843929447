import React, { useState, useEffect } from 'react';
import { TextField, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { RoundedButton, RoundedInput, LoadingButton } from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import StudentModal from '../../components/StudentModal';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '1em',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '2em 0em',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    maxWidth: '600px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  inputDate: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    maxWidth: '200px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
  customButton: {
    borderRadius: '5px !important',
  },
}));

export default ({
  titulo = '',
  codigoSaidaAntecipadaParam = null,
  responsavelParam = null,
  estudantesParam = null,
  dataParam = null,
  motivoParam = null,
  dataInicio = null,
  horaSaida = null,
  observacaoParam = null,
  registroTabSec = null,
}) => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const history = useHistory();

  const porPaginaEstudantes = 10;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [isLoadingStudent, setIsLoadingStudent] = useState(false);
  const [responsavel, setResponsavel] = useState(null);
  const [estudantes, setEstudantes] = useState([]);
  const [estudantesFiltrados, setEstudantesFiltrados] = useState([]);
  const [buscaEstudantes, setBuscaEstudantes] = useState('');
  const [paginaEstudantes, setPaginaEstudantes] = useState(1);
  const [data, setData] = useState(
    dataParam
      ? moment(dataParam).format('YYYY-MM-DD')
      : moment(dataInicio).format('YYYY-MM-DD')
  );
  const [hora, setHora] = useState(
    dataParam
      ? moment(dataParam).format('HH:mm')
      : moment(horaSaida).format('HH:mm')
  );
  const [nome, setNome] = useState('');
  const [openStudents, setOpenStudents] = useState(false);
  const [estudantesConfirmados, setEstudantesConfirmados] = useState([]);
  const [motivo, setMotivo] = useState(motivoParam ?? '');
  const [observacao, setObservacao] = useState(observacaoParam ?? '');
  const loading = open && isLoading;

  useEffect(() => {
    if (responsavelParam) handleSetResponsavel(responsavelParam);

    if (estudantesParam) setEstudantesConfirmados([...estudantesParam]);
    // eslint-disable-next-line
  }, []);

  const handleRequestError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);

    const params = { nome };

    api
      .get('earlyExit/getResponsaveis', {
        params,
      })
      .then((response) => {
        const { data } = response;

        if (data.sucesso) {
          setOptions(
            data.dados.map((x) => ({
              id: x.codigoPessoa,
              name: x.nome,
            }))
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        handleRequestError();
      });
  }, [nome]);

  const handleSetNome = debounce((text) => setNome(text));

  const handleFilterText = (e) => {
    if (!e) return;

    if (typeof e === 'string' || e instanceof String) handleSetNome(e);
  };

  const handleChangeEstudantes = (estudantesParam = []) => {
    setEstudantesFiltrados(estudantesParam);
    setEstudantes(estudantesParam);
  };

  const handleSetResponsavel = (resp) => {
    setResponsavel(resp);
    handleChangeEstudantes([]);
    setEstudantesConfirmados([]);

    if (resp) {
      setIsLoadingStudent(true);
      const params = { codigoResponsavel: resp.id };

      api
        .get('earlyExit/getEstudantes', {
          params,
        })
        .then((response) => {
          const { data } = response;

          if (data) {
            handleChangeEstudantes(
              data.dados.map((x) => ({
                id: x.codigoEstudante,
                name: x.nomeEstudante,
                codigoEmpresa: x.codigoEmpresa,
                codigoUnidade: x.codigoUnidade,
                checked: false,
                confirmed: false,
              }))
            );
          }

          setIsLoadingStudent(false);
        })
        .catch(() => setIsLoadingStudent(true));
    }
  };

  const handleConfirmar = () => {
    const estudantesTemp = [
      ...estudantesConfirmados,
      ...estudantes.filter((x) => x.checked),
    ];
    const estudantesDistinct = [
      ...new Map(estudantesTemp.map((item) => [item.id, item])).values(),
    ];

    if (estudantesTemp) setEstudantesConfirmados([...estudantesDistinct]);

    setOpenStudents(false);
  };

  const handleChangeCheck = (value, idEstudante) => {
    const estudantesTemp = estudantesFiltrados;
    const index = estudantesTemp.findIndex((x) => x.id === idEstudante);

    if (index > -1) {
      estudantesTemp[index].checked = value;
      setEstudantesFiltrados([...estudantesTemp]);
    }
  };

  const handleOpenStudents = () => {
    const estuTemp = estudantes.map((x) => {
      x.checked = false;
      x.confirmed = estudantesConfirmados.some((e) => e.id === x.id);
      return x;
    });

    handleChangeEstudantes(estuTemp);
    setBuscaEstudantes('');
    setOpenStudents(true);
  };

  const handleChangePageEstudantes = (event, nextPage) =>
    setPaginaEstudantes(nextPage + 1);

  const handleChangeSearch = (value) => {
    setBuscaEstudantes(value);

    const estudantesTemp = estudantes.filter((e) =>
      e.name.toLowerCase().includes(value.toLowerCase())
    );

    setPaginaEstudantes(1);
    setEstudantesFiltrados(estudantesTemp);
  };

  const successAlert = (message) => {
    setAlert({
      isOpen: true,
      type: 'success',
      message,
    });
  };

  const warningAlert = (message) => {
    setAlert({
      isOpen: true,
      type: 'warning',
      message,
    });
  };

  const errorAlert = (message) => {
    setAlert({
      isOpen: true,
      type: 'error',
      message,
    });
  };

  const invalidSubmit = (message) => {
    warningAlert(message);
    setIsLoadingButton(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingButton(true);

    if (!responsavel) {
      invalidSubmit('Necessário informar um responsável.');
      return;
    }

    if (!estudantesConfirmados || estudantesConfirmados.length === 0) {
      invalidSubmit('Necessário informar pelo menos um estudante.');
      return;
    }

    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      invalidSubmit(
        'Não foi possível realizar o cadastro, entre em contato com a instituição.'
      );
      return;
    }

    const dataTemp = moment(data + hora, 'YYYY-MM-DD HH:mm').format();

    const estudantesTemp = estudantesConfirmados.map((x) => ({
      codigo: x.id,
      idAluno: x.idAluno,
      descricao: x.name,
      idEmpresa: x.codigoEmpresa,
      idUnidade: x.codigoUnidade,
    }));

    const registerData = {
      idPessoa: responsavel.id,
      alunos: estudantesTemp,
      dataSaida: dataTemp,
      dataInicio: moment(dataTemp).format('YYYY-MM-DD'),
      dataTermino: moment(dataTemp).format('YYYY-MM-DD'),
      horaSaida: moment(dataTemp).format('HH:mm:ss'),
      motivo,
      observacao,
      tokenJWT: tokenLs,
    };

    const functionSubmit = {
      CADASTRAR: () => api.post('earlyExit/createExit', registerData),
      ATUALIZAR: () =>
        api.put('earlyExit/editExit', {
          codigoSaidaAntecipada: codigoSaidaAntecipadaParam,
          ...registerData,
        }),
      ATUALIZARNOVO: () =>
        api.post('earlyExit/createExit', {
          codigoSaidaAntecipada: codigoSaidaAntecipadaParam,
          ...registerData,
        }),
    };
    let funcName = codigoSaidaAntecipadaParam ? 'ATUALIZAR' : 'CADASTRAR';

    if (!registroTabSec && funcName === 'ATUALIZAR') funcName = 'ATUALIZARNOVO';

    functionSubmit[funcName]()
      .then((res) => {
        const { data } = res;

        if (res.status === 200) {
          successAlert(data.mensagem);
          setIsLoadingButton(false);
          history.push(`/early-exit/`);
        } else {
          setIsLoadingButton(false);
          errorAlert(data.mensagem);
        }
      })
      .catch((err) => {
        errorAlert(err.response.data.error);
        setIsLoadingButton(false);
      });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.formInputs}>
        <Typography className={classes.title} variant="h5" color="primary">
          {titulo}
        </Typography>
        <div className={classes.formBody}>
          <Autocomplete
            id="asynchronous"
            className={classes.input}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onInputChange={(e) => handleFilterText(e?.target?.value)}
            onChange={(event, value) => handleSetResponsavel(value)}
            value={responsavel}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => option.name}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Responsável"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <Autocomplete
            multiple
            id="tags-filled"
            className={classes.input}
            open={false}
            inputValue=""
            value={estudantesConfirmados}
            onOpen={handleOpenStudents}
            options={estudantes}
            getOptionLabel={(op) => op.name}
            onChange={(event, value) => setEstudantesConfirmados(value)}
            filterOptions={(options, state) => options}
            popupIcon={<Search />}
            forcePopupIcon
            freeSolo
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Estudantes" />
            )}
          />
          <div>
            <TextField
              id="start-date"
              label="Data"
              className={classes.inputDate}
              variant="outlined"
              type="date"
              style={{ marginRight: '8px' }}
              defaultValue={data}
              onChange={(e) => setData(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="start-date"
              label="Hora"
              className={classes.inputDate}
              variant="outlined"
              type="time"
              defaultValue={hora}
              onChange={(e) => setHora(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <RoundedInput
            className={classes.input}
            label="Motivo"
            size="small"
            multiline
            inputProps={{ maxLength: '200' }}
            rows={4}
            variant="outlined"
            value={motivo}
            onChange={(e) => setMotivo(e.target.value)}
          />
          <RoundedInput
            className={classes.input}
            label="Observação"
            size="small"
            multiline
            inputProps={{ maxLength: '1000' }}
            rows={4}
            variant="outlined"
            value={observacao}
            onChange={(e) => setObservacao(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.buttons}>
        <RoundedButton
          variant="contained"
          className={classes.customButton}
          onClick={() => history.push(`/early-exit/`)}
        >
          Cancelar
        </RoundedButton>
        <LoadingButton
          className={classes.loadingButton}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isLoadingButton}
        >
          Salvar
        </LoadingButton>
      </div>
      <StudentModal
        open={openStudents}
        onChange={handleChangeCheck}
        handleClose={() => setOpenStudents(false)}
        confirm={handleConfirmar}
        onChangePage={handleChangePageEstudantes}
        searchInputValue={buscaEstudantes}
        page={paginaEstudantes}
        perPage={porPaginaEstudantes}
        total={estudantesFiltrados.length}
        onChangeSearch={handleChangeSearch}
        students={estudantesFiltrados.slice(
          (paginaEstudantes - 1) * porPaginaEstudantes,
          porPaginaEstudantes * paginaEstudantes
        )}
        loading={isLoadingStudent}
      />
    </form>
  );
};
