import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import XLSX from 'xlsx';
import moment from 'moment';

const trackPDF = (data) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportData = data?.map((d) => {
    return [
      '\n',
      {
        columns: [
          { text: `Criado por: ${d.quemAutorizou}` },
          { text: `Data: ${d.dataSaida}` },
        ],
      },
      {
        columns: [
          { text: `Responsável: ${d.nomeResponsavel}` },
          { text: `Hora: ${d.horaSaida}` },
        ],
      },
      {
        columns: [
          { text: `Estudante(s): ${d.estudantes}` },
          { text: `Situação: ${d.situacao}` },
        ],
      },
      {
        columns: [
          { text: `Curso(s): ${d.cursos}` },
          { text: `Motivo: ${d.motivo}` },
        ],
      },
      {
        columns: [{ text: `Observação: ${d.observacao}` }],
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 535,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      },
    ];
  });

  const header = [
    { text: 'Relatório "Saídas antecipadas"', style: 'header' },
    {
      text: `Data: ${moment().format('HH:MM - DD/MM/YYYY')}`,
      style: 'subHeader',
    },
  ];
  const details = [header, ...reportData];

  const doc = {
    pageSize: 'A4',
    pageMargins: [15, 25, 15, 25],

    header: [],
    content: [details],
    footer: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      subHeader: {
        fontSize: 12,
      },
    },
    defaultStyle: {
      fontSize: 10,
      columnGap: 20,
      lineHeight: 1.5,
    },
  };

  const fileName = `relatorio_saidasantecipadas_${+new Date()}.pdf`;
  pdfMake.createPdf(doc).download(fileName);
};

const trackExcel = (data) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, 'Relatorio_Saidas_Antecipadas');

  XLSX.writeFile(wb, `relatorio_saidasantecipadas_${+new Date()}.xlsx`);
};

export default trackPDF;
export { trackPDF, trackExcel };
