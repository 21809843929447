export const setSketch = (idChat, texto) => {
  const currentSketch = JSON.parse(localStorage.getItem('chatSketch'));

  if (currentSketch != null) {
    if (currentSketch.findIndex((s) => s.idChat === idChat) !== -1) {
      if (texto !== '') {
        localStorage.setItem(
          'chatSketch',
          JSON.stringify(
            currentSketch.map((s) => {
              if (s.idChat === idChat) {
                s.texto = texto;
              }
              return s;
            })
          )
        );
      } else {
        localStorage.setItem(
          'chatSketch',
          JSON.stringify(currentSketch.filter((s) => s.idChat !== idChat))
        );
      }
    } else {
      localStorage.setItem(
        'chatSketch',
        JSON.stringify([...currentSketch, { idChat, texto }])
      );
    }
  } else {
    localStorage.setItem('chatSketch', JSON.stringify([{ idChat, texto }]));
  }
};

export const getSketch = (idChat) => {
  const currentSketch = JSON.parse(localStorage.getItem('chatSketch'));

  if (idChat && currentSketch) {
    if (currentSketch.findIndex((s) => s.idChat === idChat) !== -1) {
      return currentSketch[currentSketch.findIndex((s) => s.idChat === idChat)]
        .texto;
    }
  }

  return '';
};
