import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import XLSX from 'xlsx';
import moment from 'moment';

const trackPDF = (data) => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const reportData = data?.map((d) => {
    return ([
      '\n',
      {
        columns: [
          {text: `Estudante: ${d.NomeAluno}`}
        ]         
      },
      {
        columns: [
          {text: `Data do envio da mensagem de autorização: ${d.DataEnvioMensagemPrincipal}`},
          
        ]         
      },
      {
        columns: [
          {text: `Responsável: ${d.NomeResponsavel}`}
        ]         
      },
      {
        columns: [
          {text: `Data de visualização: ${d.DataVisualizacao}`},
        ]         
      },
      {
        columns: [
          {text: `Autorizado: ${d.Autorizado}`},
        ]         
      },
      {
        columns: [
          {text: `Data de Autorização: ${d.DataAutorizacao}`},
        ]         
      },
      {
        columns: [
          {text: `Autorizado por: ${d.AutorizadoPor}`},
        ]         
      },
      {
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 535,
            y2: 5,
            lineWidth: 0.5
          }
        ]
      },
    ]);
  });

  const header = [
    { text: 'Relatório do Evento: ' + data[0].DescricaoEvento , style: 'header' },
    { text: `Data: ${moment().format('HH:MM - DD/MM/YYYY')}`, style: 'subHeader' },
  ]
  const details = [ header, ...reportData];

  const doc = {
    pageSize: 'A4',
    pageMargins: [15, 25, 15, 25],

    header: [],
    content: [details],
    footer: [],
    styles: {
      header: {
        fontSize: 18,
        bold: true
      },
      subHeader: {
        fontSize: 12,
      },
    },
    defaultStyle: {
      fontSize: 10,
      columnGap: 20,
      lineHeight: 1.5,
    }
  }

  const fileName = `relatorio_evento_${+ new Date()}.pdf`
  pdfMake.createPdf(doc).download(fileName);
};

const trackExcel = (data) => {
  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, ws, "Relatorio_Evento");

  XLSX.writeFile(wb, `relatorio_evento_${+ new Date()}.xlsx`);
}

export default trackPDF;
export {trackPDF, trackExcel};