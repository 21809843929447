import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Typography, Checkbox } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import {
  RoundedInput,
  RoundedButton,
  LoadingButton,
  MultipleValueInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';
import ViewConfigurationFileModal from './ViewConfigurationFileModal';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '1em',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '2em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  divTargetAudience: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5em',
    marginBottom: '0.5em',
  },
  fieldTargetAudience: {
    flexGrow: 1,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1em',
  },
}));

export default ({
  isEdit = false,
  configurationFileInfo = {},
  configurationFileId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState(
    configurationFileInfo.publicosAlvo ?? []
  );

  const [configurationName, setConfigurationName] = useState(
    configurationFileInfo.descricao ?? ''
  );
  const [releaseRealTime, setReleaseRealTime] = useState(
    configurationFileInfo.liberaEmTempoReal ?? false
  );
  const [restrictTeacher, setRestrictTeacher] = useState(
    configurationFileInfo.restringeSomenteProfTitular ?? false
  );

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [settingSelected, setSettingSelected] = useState();

  const handleCancel = () => history.push('/configuration-files');
  const handleHandleSuccess = () => history.push(`/configuration-files`);

  const handleRegisterError = (errorMessage) => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível cadastrar a configuração',
    });
  };

  const handleOpenDetailsItem = () => {
    const setting = {
      descricao: configurationName,
      liberaEmTempoReal: releaseRealTime,
      restringeSomenteProfTitular: restrictTeacher,
      publicosAlvo: selectedTargetAudiences,
    };
    setSettingSelected(setting);
    setOpenDetailsDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const registerData = {
      Descricao: configurationName,
      LiberaEmTempoReal: releaseRealTime,
      RestringeSomenteProfTitular: restrictTeacher,
      idsPublicoAlvo: selectedTargetAudiences.map((ta) => ta.id),
    };

    if (isEdit) {
      api
        .put(`configuration-file/${configurationFileId}`, registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Configuração editada com sucesso!',
          });
          history.push(`/configuration-files`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao tentar editar a configuração.',
          });
        });
    } else {
      api
        .post('configuration-files', registerData)
        .then((res) => {
          if (res.statusText != 'OK') handleRegisterError(res.data.Mensagem);
          else {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'success',
              message: 'Configuração cadastrada com sucesso!',
            });
            handleHandleSuccess();
          }
        })
        .catch(() => {
          handleRegisterError(
            'Ocorreu um erro inesperado ao tentar cadastrar a configuração.'
          );
        });
    }
  };

  useEffect(() => {
    api
      .get('target-audience', {
        params: {
          limit: 1000,
          appRole: ['coordination', 'admin'],
        },
      })
      .then((response) => {
        setTargetAudiences(response.data.data);
      });
  }, []);

  return (
    <>
      <ViewConfigurationFileModal
        dialogConfigurationFile={settingSelected}
        open={openDetailsDialog}
        title="Resumo"
        onClose={handleCloseDialog}
      />
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.formInputs}>
          <Typography className={classes.title} variant="h5" color="primary">
            {isEdit ? 'Edição' : 'Cadastro'}
          </Typography>
          <div className={classes.formBody}>
            <RoundedInput
              className={classes.input}
              label="Descrição"
              size="small"
              inputProps={{ maxLength: '40', minLength: '2' }}
              variant="outlined"
              value={configurationName}
              onChange={(e) => setConfigurationName(e.target.value)}
              required
            />
          </div>
          <Typography
            className={classes.subTitle}
            variant="subtitle1"
            color="primary"
          >
            Público-alvo
          </Typography>
          <div className={classes.divTargetAudience}>
            <MultipleValueInput
              customClasses={classes.fieldTargetAudience}
              label="Procurar..."
              variant="outlined"
              labelProperty="descricao"
              value={selectedTargetAudiences}
              options={targetAudiences}
              onChange={(event, value) => setSelectedTargetAudiences(value)}
            />
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={restrictTeacher}
              onChange={() => setRestrictTeacher(!restrictTeacher)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Restringe somente professor titular
            </p>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={releaseRealTime}
              onChange={() => setReleaseRealTime(!releaseRealTime)}
              color="primary"
            />
            <p className={classes.pUseLink}>Liberar em tempo real</p>
          </div>
        </div>
        <div className={classes.buttons}>
          <RoundedButton
            style={{ marginRight: '4em' }}
            color="primary"
            variant="contained"
            onClick={() => handleOpenDetailsItem()}
          >
            <ListAlt />
          </RoundedButton>
          <RoundedButton
            variant="contained"
            className={classes.customButton}
            onClick={handleCancel}
          >
            Cancelar
          </RoundedButton>
          <LoadingButton
            className={classes.loadingButton}
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isLoading}
          >
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </LoadingButton>
        </div>
      </form>
    </>
  );
};
