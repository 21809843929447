import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  Avatar,
  Paper,
  Typography,
  IconButton,
  CircularProgress,
  TableCell,
  MenuItem,
  Checkbox,
  Tooltip,
  Select,
  InputLabel,
  ListItemText,
  Dialog,
} from '@material-ui/core';
import {
  Refresh,
  CameraAltSharp,
  FullscreenExit,
  Fullscreen,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { io } from 'socket.io-client';
import { Link } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import { Container, Clock } from '../../components';
import { nameInitials } from '../../helpers';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';

const tracksToCards = (tracks) => {
  return tracks.reduce((cards, track) => {
    return {
      ...cards,
      [track.IdTrajeto]: track,
    };
  }, {});
};

const cardsToTracks = (cards) => {
  return Object.keys(cards).reduce((tracks, key) => {
    return [...tracks, cards[key]];
  }, []);
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    overflow: 'auto',
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#fff',
  },
  clock: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '0.5rem',
    justifyContent: 'space-around',
    placeItems: 'center',
  },
  card: {
    padding: '4px 16px 4px 16px',
    marginBottom: theme.spacing(1),
    transition: 'background-color 0.2s ease',
    color: '#858585',
    '& p': {
      fontSize: '0.8em',
    },
  },
  cardInfos: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },
  draggingCard: {
    backgroundColor: theme.palette.background.default,
  },
  checkboxCustom: {
    marginLeft: 'auto',
    padding: '0 0 0 5px',
  },
  columns: {
    display: 'flex',
    flexFlow: 'row wrap',
    flexGrow: '1',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    minWidth: '250px',
    minHeight: '600px',
    margin: theme.spacing(1),
  },
  columnTitle: {
    padding: theme.spacing(1),
  },
  cardList: {
    flexGrow: '1',
    padding: theme.spacing(1),
    transition: 'background-color 0.5s ease',
    border: `2px solid ${theme.palette.grey[100]}`,
    borderRadius: theme.spacing(1),
    height: '600px',
    backgroundColor: theme.palette.grey[100],
    overflow: 'auto',
  },
  draggingOverColumn: {
    backgroundColor: theme.palette.grey[300],
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  avatarAndText: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  customButtonDefault: {
    height: '30px',
    borderRadius: '5px !important',
    marginLeft: '5px',
    marginTop: '5px',
  },
  listIconCustom: {
    minWidth: '0',
    marginRight: '5px',
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '20px',
  },
  menuPaper: {
    maxHeight: '300px',
  },
}));

const initialData = {
  columns: {
    first: {
      id: 'first',
      title: 'Está próximo',
      cardIds: [],
    },
    second: {
      id: 'second',
      title: 'Chegou',
      cardIds: [],
    },
    third: {
      id: 'third',
      title: 'Estudante já entregue',
      cardIds: [],
    },
  },
  columnOrder: ['first', 'second', 'third'],
};

export default () => {
  const availableSituations = {
    description: 'Todos',
  };

  const classes = useStyles();
  const [socket, setSocket] = useState(null);
  const application = useSelector((state) => state.profile?.application);
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [filterClass, setFilterClasses] = useState([
    availableSituations.description,
  ]);
  const [filterShift, setFilterShifts] = useState([
    availableSituations.description,
  ]);
  const [filterGate, setFilterGate] = useState([
    availableSituations.description,
  ]);
  const [allClasses, setAllClasses] = useState([availableSituations]);
  const [allShifts, setAllShifts] = useState([availableSituations]);
  const [allGates, setAllGates] = useState([availableSituations]);
  const [cards, setCards] = useState({});
  const [open, setOpen] = useState(false);
  const setAlert = useAlert();
  const startSocket = () => {
    setSocket(
      io(`${process.env.REACT_APP_API_WEBSOCKET}/portal`, {
        auth: {
          token: application.app_key,
        },
      })
    );
  };

  const organizeCards = useCallback((cardList) => {
    if (cardList)
      setCards((oldCards) => {
        const newCards = { ...oldCards, ...cardList };
        setData((oldData) => {
          const tracks = cardsToTracks(newCards);
          const newFirstColumn = {
            ...oldData.columns.first,
            cardIds: [
              ...new Set(
                tracks
                  .filter(
                    (track) =>
                      track.EstaProximo && !track.Chegou && !track.Concluido
                  )
                  .sort((aTrack, bTrack) => {
                    if (bTrack.Distancia && aTrack.Distancia) {
                      return aTrack.Distancia - bTrack.Distancia;
                    }
                    if (bTrack.Distancia) return 1;
                    if (aTrack.Distancia) return -1;
                    return (
                      new Date(bTrack.DataAtualizacao) -
                      new Date(aTrack.DataAtualizacao)
                    );
                  })
                  .map((track) => track.id)
              ),
            ],
          };
          const newSecondColumn = {
            ...oldData.columns.second,
            cardIds: [
              ...new Set(
                tracks
                  .filter((track) => track.Chegou && !track.Concluido)
                  .sort(
                    (aTrack, bTrack) =>
                      new Date(bTrack.DataAtualizacao) -
                      new Date(aTrack.DataAtualizacao)
                  )
                  .map((track) => track.id)
              ),
            ],
          };
          const newThirdColumn = {
            ...oldData.columns.third,
            cardIds: [
              ...new Set(
                tracks
                  .filter((track) => track.Concluido)
                  .sort(
                    (aTrack, bTrack) =>
                      new Date(bTrack.DataAtualizacao) -
                      new Date(aTrack.DataAtualizacao)
                  )
                  .map((track) => track.id)
              ),
            ],
          };

          const newData = {
            ...oldData,
            columns: {
              ...oldData.columns,
              [newFirstColumn.id]: newFirstColumn,
              [newSecondColumn.id]: newSecondColumn,
              [newThirdColumn.id]: newThirdColumn,
            },
          };
          return newData;
        });

        return newCards;
      });
  }, []);

  const getTracks = useCallback(() => {
    setIsLoading(true);
    api
      .get('getTracks')
      .then((response) => {
        if (response.data.trajetos) {
          const tracks = response.data.trajetos.map((track) => ({
            ...track,
            id: `${track.IdTrajeto}`,
          }));
          organizeCards(tracksToCards(tracks));
        }
        setIsLoading(false);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao obter trajetos.',
        });
        setIsLoading(false);
      });
  }, [organizeCards]);

  const getTrack = useCallback(
    (trackID) => {
      api
        .get('getTracks', { params: { id: trackID } })
        .then((response) => {
          const tracks = response.data.trajetos.map((track) => ({
            ...track,
            id: `${track.IdTrajeto}`,
          }));
          organizeCards(tracksToCards(tracks));
        })
        .catch((err) => console.log(err));
    },
    [organizeCards]
  );

  const handleCloseSituation = (event) => {
    const {
      target: { value },
    } = event;
    setFilterClasses(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCloseShift = (event) => {
    const {
      target: { value },
    } = event;
    setFilterShifts(typeof value === 'string' ? value.split(',') : value);
  };

  const handleCloseGate = (event) => {
    const {
      target: { value },
    } = event;
    setFilterGate(typeof value === 'string' ? value.split(',') : value);
  };

  useEffect(() => {
    if (application) {
      startSocket();
    }
  }, [application]);

  useEffect(() => {
    if (socket !== null) {
      socket.on('control-panel:track-status', (status) => {
        setCards((oldCards) => {
          // TODO: Manter os status separados dos cards
          const oldCard = oldCards[status.idTrajeto];
          if (!oldCard) return oldCards;
          const newCards = {
            ...oldCards,
            [status.idTrajeto]: {
              ...oldCard,
              DataAtualizacao: new Date().toISOString(),
              EstaProximo: oldCard.EstaProximo
                ? true
                : status.status === 'proximo',
              Chegou: oldCard.Chegou ? true : status.status === 'chegou',
              Concluido: oldCard.Concluido
                ? true
                : status.status === 'embarcaram',
              Hora: status.hora,
              Distancia: status.distancia,
            },
          };
          organizeCards(newCards);
          return newCards;
        });
      });
      socket.on('control-panel:new-track', (trackId) => {
        getTrack(trackId);
      });
      socket.on('control-panel:cancel-track', (trackId) => {
        setCards((oldCards) => {
          const newCards = {
            ...oldCards,
          };
          delete newCards[trackId];
          organizeCards(newCards);
          return newCards;
        });
      });
      socket.on('connect_error', (err) => {
        console.log('connect_error', err.message);
        socket.disconnect();
      });
    }

    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket, organizeCards, getTrack]);

  useEffect(() => {
    getTracks();
  }, [getTracks]);

  const handleOnDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    const startColumn = data.columns[source.droppableId];
    const finishColumn = data.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      const newCardsIds = Array.from(startColumn.cardIds);
      newCardsIds.splice(source.index, 1);
      newCardsIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...startColumn,
        cardIds: newCardsIds,
      };

      const newData = {
        ...data,
        columns: {
          ...data.columns,
          [newColumn.id]: newColumn,
        },
      };

      setData(newData);
      return;
    }

    const startCardIds = Array.from(startColumn.cardIds);
    startCardIds.splice(source.index, 1);
    const newStartColumn = {
      ...startColumn,
      cardIds: startCardIds,
    };

    const finishCardIds = Array.from(finishColumn.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId);
    const newFinishColumn = {
      ...finishColumn,
      cardIds: finishCardIds,
    };

    const newData = {
      ...data,
      columns: {
        ...data.columns,
        [newStartColumn.id]: newStartColumn,
        [newFinishColumn.id]: newFinishColumn,
      },
    };

    setData(newData);
  };

  const availableClasses = (student) => {
    const classesTemp = allClasses;
    student.map((s) => {
      const available = {
        description: s.TurmaAluno,
      };
      updateFilters(classesTemp, available);
    });
    setAllClasses(classesTemp);
  };

  const availableShift = (student) => {
    const shiftsTemp = allShifts;
    student.map((s) => {
      const available = {
        description: s.TurnoAluno,
      };
      updateFilters(shiftsTemp, available);
    });
    setAllShifts(shiftsTemp);
  };

  const availableGate = (gate) => {
    const gatesTemp = allGates;
    const available = {
      description: gate,
    };
    updateFilters(gatesTemp, available);
    setAllGates(gatesTemp);
  };

  const updateFilters = (array, element) => {
    if (
      array.findIndex((item) => item.description === element.description) === -1
    ) {
      array.push(element);
    }
  };

  const confirmFinishCard = (id, socketId) => {
    const data = { id };
    api
      .put('olaCheguei/finishTrack', data)
      .then((response) => {
        const { data } = response;

        if (!data.Sucesso)
          setAlert({
            isOpen: true,
            type: 'error',
            message:
              data.Mensagem ??
              'Ocorreu um erro inesperado ao finalizar trajeto.',
          });
        else {
          if (socket != null) {
            socket.emit('buscaConcluidaPortal', socketId);
          }
          setCards((oldCards) => {
            const oldCard = oldCards[id];
            if (!oldCard) return oldCards;

            const newCards = {
              ...oldCards,
              [id]: {
                ...oldCard,
                DataAtualizacao: new Date().toISOString(),
                Concluido: true,
              },
            };

            organizeCards(newCards);
            return newCards;
          });
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao finalizar trajeto.',
        });
      });
  };

  const Students = ({ students, card }) => {
    availableClasses(students);
    availableGate(card.DescricaoPortao);
    availableShift(students);
    const [classesFilter, setClassesFilter] = useState(
      students.filter(
        (st) =>
          filterClass == 'Todos' ||
          filterClass.toString().includes(st.TurmaAluno)
      )
    );

    const shiftsFilter = students.filter(
      (st) =>
        filterShift == 'Todos' || filterShift.toString().includes(st.TurnoAluno)
    );

    const SaveReleasedStudent = (idAluno, idTrajeto) => {
      const data = { idAluno, idTrajeto };
      api
        .put('olaCheguei/releaseStudent', data)
        .then((response) => {
          if (response.data.Sucesso) {
            setClassesFilter(
              classesFilter.map((std) => {
                if (std.IdAluno === idAluno) {
                  std.Liberado = true;
                  return std;
                }
                return std;
              })
            );

            const studentLength = students.length;
            let count = 0;
            students.forEach((s) => {
              if (s.Liberado) count += 1;
              if (count === studentLength)
                confirmFinishCard(card.IdTrajeto, card.SocketId);
            });
          } else {
            setAlert({
              isOpen: true,
              type: 'error',
              message: response.data.Mensagem,
            });
          }
        })
        .catch(() => {
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro inesperado ao finalizar trajeto.',
          });
        });
    };

    const classes = useStyles();
    const studentInfos = (std) =>
      `${std.CursoAluno} - ${std.CicloAluno} - ${std.TurmaAluno}`;
    return (
      shiftsFilter.length &&
      classesFilter.length && (
        <>
          <Typography>Estudante:</Typography>

          {shiftsFilter.map((s) =>
            classesFilter.map(
              (student) =>
                s === student && (
                  <div key={student.IdAluno} className={classes.avatarAndText}>
                    <Avatar className={classes.avatar} src={student.FotoAluno}>
                      {nameInitials(student.NomeAluno)}
                    </Avatar>
                    <div>
                      <Typography>{student.NomeAluno}</Typography>
                      <Typography>{studentInfos(student)}</Typography>
                    </div>
                    <div
                      hidden={students.length === 1 || card.Concluido === true}
                    >
                      <Checkbox
                        className={classes.checkboxCustom}
                        disabled={student.Liberado}
                        checked={student.Liberado}
                        onClick={() => {
                          SaveReleasedStudent(student.IdAluno, card.IdTrajeto);
                        }}
                        color="primary"
                        size="small"
                      />
                    </div>
                  </div>
                )
            )
          )}
        </>
      )
    );
  };

  const Card = ({ card, index }) => {
    const classes = useStyles();
    const students = card.Estudantes;

    const classesFilter = students.filter(
      (st) =>
        filterClass == 'Todos' || filterClass.toString().includes(st.TurmaAluno)
    );

    const shiftsFilter = students.filter(
      (st) =>
        filterShift == 'Todos' || filterShift.toString().includes(st.TurnoAluno)
    );

    return shiftsFilter.length && classesFilter.length ? (
      (filterGate == 'Todos' ||
        filterGate.toString().includes(card.DescricaoPortao)) && (
        <Draggable draggableId={card.id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Paper
                className={`${classes.card} ${
                  snapshot.isDragging && classes.draggingCard
                }`}
              >
                <div className={classes.avatarAndText}>
                  <Avatar className={classes.avatar} src={card.FotoResponsavel}>
                    {nameInitials(card.NomeCompletoResponsavel)}
                  </Avatar>
                  <div>
                    <Typography>Responsável</Typography>
                    <Typography>
                      <strong>{card.NomeCompletoResponsavel}</strong>
                    </Typography>
                  </div>
                  <Tooltip
                    title="Concluir cartão"
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <Checkbox
                      className={classes.checkboxCustom}
                      disabled={card.Concluido || students.length > 1}
                      checked={card.Concluido}
                      onClick={() =>
                        confirmFinishCard(card.IdTrajeto, card.SocketId)
                      }
                      color="primary"
                      size="small"
                    />
                  </Tooltip>
                </div>
                <div className={classes.cardInfos}>
                  <div>
                    <Typography>Local:</Typography>
                    <Typography color="primary">
                      {card.DescricaoPortao}
                    </Typography>
                  </div>
                  {card.Hora && card.Distancia && card.EstaProximo && (
                    <>
                      <div>
                        <Typography>Distância: </Typography>
                        <Typography color="primary">
                          {card.Distancia} metros
                        </Typography>
                      </div>
                      <div>
                        <Typography>Estimativa: </Typography>
                        <Typography color="primary">{card.Hora}</Typography>
                      </div>
                    </>
                  )}
                </div>
                <Students students={card.Estudantes} card={card} />
              </Paper>
            </div>
          )}
        </Draggable>
      )
    ) : (
      <> </>
    );
  };

  return (
    <DefaultPage title="Painel de controle">
      <Container className={classes.container}>
        <div className={classes.clock}>
          <div>
            <TableCell>
              <InputLabel id="turnosLabel">Turnos</InputLabel>
              <Select
                labelId="turnosLabel"
                id="turnosCheckbox"
                multiple
                value={filterShift}
                onChange={handleCloseShift}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                }}
              >
                {allShifts.map((shift) => (
                  <MenuItem key={shift.description} value={shift.description}>
                    <Checkbox
                      checked={filterShift.indexOf(shift.description) > -1}
                      style={{
                        color: '#00796B',
                      }}
                    />
                    <ListItemText primary={shift.description} />
                  </MenuItem>
                ))}
              </Select>
            </TableCell>

            <TableCell>
              <InputLabel id="turmasLabel">Turmas</InputLabel>
              <Select
                labelId="turmasLabel"
                id="turmasCheckbox"
                multiple
                value={filterClass}
                onChange={handleCloseSituation}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                }}
              >
                {allClasses.map((classe) => (
                  <MenuItem key={classe.description} value={classe.description}>
                    <Checkbox
                      checked={filterClass.indexOf(classe.description) > -1}
                      style={{
                        color: '#00796B',
                      }}
                    />
                    <ListItemText primary={classe.description} />
                  </MenuItem>
                ))}
              </Select>
            </TableCell>

            <TableCell>
              <InputLabel id="portoesLabel">Portões</InputLabel>
              <Select
                labelId="portoesCheckbox"
                multiple
                value={filterGate}
                onChange={handleCloseGate}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                }}
              >
                {allGates.map((gate) => (
                  <MenuItem key={gate.description} value={gate.description}>
                    <Checkbox
                      checked={filterGate.indexOf(gate.description) > -1}
                      className={classes.checkbox}
                      style={{
                        color: '#00796B',
                      }}
                    />
                    <ListItemText primary={gate.description} />
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
          </div>
          <CameraButton />
          <Clock />
          <ForceReloadButton isLoading={isLoading} handleClick={getTracks} />
          <IconButton onClick={() => setOpen(!open)}>
            {open ? <FullscreenExit size={24} /> : <Fullscreen size={24} />}
          </IconButton>
        </div>

        <div className={classes.root}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className={classes.columns}>
              {data.columnOrder.map((columnId) => {
                const column = data.columns[columnId];
                const columncards = column.cardIds.map(
                  (cardId) => cards[cardId]
                );

                return (
                  <div className={classes.column}>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.columnTitle}
                    >
                      {column.title}
                    </Typography>
                    <Droppable droppableId={column.id} type={column.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`${classes.cardList} ${
                            snapshot.isDraggingOver &&
                            classes.draggingOverColumn
                          }`}
                        >
                          {columncards.map((card, index) => (
                            <Card key={card.id} card={card} index={index} />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
            </div>
          </DragDropContext>
        </div>
        <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
          <div className={classes.clock}>
            <div>
              <TableCell>
                <InputLabel id="turnosLabel">Turnos</InputLabel>
                <Select
                  labelId="turnosLabel"
                  id="turnosCheckbox"
                  multiple
                  value={filterShift}
                  onChange={handleCloseShift}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menuPaper },
                  }}
                >
                  {allShifts.map((shift) => (
                    <MenuItem key={shift.description} value={shift.description}>
                      <Checkbox
                        checked={filterShift.indexOf(shift.description) > -1}
                        style={{
                          color: '#00796B',
                        }}
                      />
                      <ListItemText primary={shift.description} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell>
                <InputLabel id="turmasLabel">Turmas</InputLabel>
                <Select
                  labelId="turmasLabel"
                  id="turmasCheckbox"
                  multiple
                  value={filterClass}
                  onChange={handleCloseSituation}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menuPaper },
                  }}
                >
                  {allClasses.map((classe) => (
                    <MenuItem
                      key={classe.description}
                      value={classe.description}
                    >
                      <Checkbox
                        checked={filterClass.indexOf(classe.description) > -1}
                        style={{
                          color: '#00796B',
                        }}
                      />
                      <ListItemText primary={classe.description} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>

              <TableCell>
                <InputLabel id="portoesLabel">Portões</InputLabel>
                <Select
                  labelId="portoesCheckbox"
                  multiple
                  value={filterGate}
                  onChange={handleCloseGate}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menuPaper },
                  }}
                >
                  {allGates.map((gate) => (
                    <MenuItem key={gate.description} value={gate.description}>
                      <Checkbox
                        checked={filterGate.indexOf(gate.description) > -1}
                        className={classes.checkbox}
                        style={{
                          color: '#00796B',
                        }}
                      />
                      <ListItemText primary={gate.description} />
                    </MenuItem>
                  ))}
                </Select>
              </TableCell>
            </div>
            <CameraButton />
            <Clock />
            <ForceReloadButton isLoading={isLoading} handleClick={getTracks} />
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <FullscreenExit size={24} /> : <Fullscreen size={24} />}
            </IconButton>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className={classes.columns}>
              {data.columnOrder.map((columnId) => {
                const column = data.columns[columnId];
                const columncards = column.cardIds.map(
                  (cardId) => cards[cardId]
                );

                return (
                  <div className={classes.column}>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={classes.columnTitle}
                    >
                      {column.title}
                    </Typography>
                    <Droppable droppableId={column.id} type={column.id}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`${classes.cardList} ${
                            snapshot.isDraggingOver &&
                            classes.draggingOverColumn
                          }`}
                        >
                          {columncards.map((card, index) => (
                            <Card key={card.id} card={card} index={index} />
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
            </div>
          </DragDropContext>{' '}
        </Dialog>
      </Container>
    </DefaultPage>
  );
};

const ForceReloadButton = ({ isLoading, handleClick }) => {
  return (
    <IconButton disabled={isLoading} onClick={handleClick}>
      {isLoading ? <CircularProgress size={24} /> : <Refresh />}
    </IconButton>
  );
};

const CameraButton = () => {
  return (
    <Link to="/qrscanner">
      <IconButton>
        <CameraAltSharp size={24} />
      </IconButton>
    </Link>
  );
};
