import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: (props) => (props.primary ? theme.palette.primary.main : '#fafafa'),
  },
}));

export default ({ to = '', primary, ...rest }) => {
  const classes = useStyles({ primary });
  return (
    <IconButton component={Link} to={to} aria-label="back button" {...rest}>
      <ArrowBackRoundedIcon className={classes.icon} />
    </IconButton>
  );
};
