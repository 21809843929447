import React, { useState, useEffect } from "react";

import { Container, RoundedButton, TableRowFocusable } from '../../components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore, Edit } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';

import DefaultPage from '../../templates/DefaultPage';

const useStyles = makeStyles(() => ({
  containerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarPadding: {
    paddingLeft: '16px',
  },
  registerButton: {
    padding: 'auto 20px',
  },
  tableCellIdentity: {
    width: '25%',
  },
  tableCellLink: {
    width: '55%',
  },
  tableCellOrder: {
    width: '18%',
    textAlign: 'center',
  },
  tableCellIcons: {
    display: 'flex',
    justifyContent: 'center',
  },
  divCheckBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const perPage = 10;

  const [total, setTotal] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [page, setPage] = useState(1);
  const [links, setLinks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState();
  const [textButtonOrderMenu, setTextButtonOrderMenu] = useState("Ordenar por");
  const [lastTextButtonOrderMenu, setLastTextButtonOrderMenu] = useState("Ordenar por");
  const optionsOrderMenu = [
    {
      id: 1,
      description: "Mais recente",
    },
    {
      id: 2,
      description: "Ordem alfabética",
    }
  ];

  useEffect(() => {
    getLinks();
  }, [page, orderId]);

  const getLinks = () => {
    var params = {
      page,
      limit: perPage,
      order: orderId
    };

    setIsLoading(true);
    api
      .get('getLinks', { params })
      .then((response) => {
        console.log('response', response);
        setTotal(response.data.total);
        setLinks(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setPage(1);
    setTotal(0);
  };

  const handleOpenOrderMenu = (e) => {
    setAnchorElMenu(e.currentTarget);
    setTextButtonOrderMenu("Ordenar por");
  };

  const handleCloseOrderMenu = () => {
    setTextButtonOrderMenu(lastTextButtonOrderMenu);
    setAnchorElMenu(null);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  const handleSelectOrderMenu = (opt) => {
    setAnchorElMenu(null);
    setTextButtonOrderMenu(opt.description);
    setLastTextButtonOrderMenu(opt.description);
    setOrderId(opt.id);
  };

  const handleEditLink = (id) => {
    history.push(`/links/editLink/${id}`);
  }

  return (
    <DefaultPage title="Links">
      <Container>        
        <Toolbar className={classes.toolbarPadding}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.6em' }}
            color="primary"
          >
            Gerenciar links
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellIdentity}>IDENTIFICADOR</TableCell>
                <TableCell className={classes.tableCellLink}>LINK</TableCell>
                <TableCell className={classes.tableCellOrder}>
                  <RoundedButton
                    endIcon={<ExpandMore />}
                    aria-controls="simple-menu-situation" 
                    aria-haspopup="true" 
                    onClick={handleOpenOrderMenu}
                  >
                    {textButtonOrderMenu}
                  </RoundedButton>
                  <Menu
                    anchorEl={anchorElMenu}
                    open={Boolean(anchorElMenu)}
                    onClose={() => handleCloseOrderMenu(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {optionsOrderMenu.map((opt) => (
                      <MenuItem key={opt.id} onClick={() => handleSelectOrderMenu(opt)}>
                        <Typography key={opt.description} variant="inherit">
                          {opt.description}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {isLoading ?
              new Array(10).fill().map((e, i) => i).map((e) => (
                <TableRowFocusable key={e} onClick={() => {}} hover>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" />
                  </TableCell>
                </TableRowFocusable>
              )) : links?.length === 0 ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" color="textSecondary">
                      Nenhum registro encontrado.
                    </Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              ) : (
                links?.map((link) => (
                  <TableRowFocusable key={link.id} onClick={() => {}} hover>
                    <TableCell className={classes.tableCellIdentity}>
                      <div>{link.chave}</div>
                    </TableCell>
                    <TableCell className={classes.tableCellLink}>
                      <div>{link.valor}</div>                    
                    </TableCell>
                    <TableCell className={classes.tableCellOrder}>
                      <div className={classes.tableCellIcons}>
                        <Tooltip title="Editar">
                          <span>
                            <Edit
                              color="primary"
                              onClick={() => handleEditLink(link.id)}
                            />     
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRowFocusable>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};