import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import api from '../../services/api';
import NewsForm from './NewsForm';
import { Container, LoadingForm, NotFound } from '../../components';

export default () => {
  const { idNoticia } = useParams();

  const [newsInfo, setNewsInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get('news/getUpdateNews', { params: { idNoticia } })
      .then((response) => {
        setNewsInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [idNoticia]);

  return (
    <DefaultPage title="Notícias">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <NewsForm isEdit newsInfo={newsInfo} newsId={idNoticia} />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
