import { useSelector } from 'react-redux';

export default ({ children, allowedRoles }) => {
  const userRole = useSelector((state) => state.user?.user?.role);

  if (!allowedRoles) {
    return children;
  }

  if (!userRole) {
    return null;
  }

  const userIsAllowed = allowedRoles.includes(userRole);

  if (!userIsAllowed) {
    return null;
  }

  return children;
};
