import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core';
import { Creators as ProfileActions } from '../../store/ducks/profile';

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default ({ onClose, open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const applications = useSelector((state) => state.profile?.applications);

  const handleClick = (application) => {
    dispatch(ProfileActions.setSelectedApplication(application));
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Selecionar aplicação</DialogTitle>
      <List>
        {applications.map((application) => (
          <ListItem
            button
            onClick={() => handleClick(application)}
            key={application.id}
          >
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{application.initials}</Avatar>
            </ListItemAvatar>
            <ListItemText>{application.name} </ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
