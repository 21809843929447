import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit } from '@material-ui/icons';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import {
  SearchInput,
  RoundedButton,
  ConfirmationDialog,
  Container,
  TableRowFocusable,
} from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  tableRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr) 80px',
  },
  tableCell: {
    wordBreak: 'break-word',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
}));

export default () => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const history = useHistory();

  const [idUserToDelete, setIdUserToDelete] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  const handleOpenRegisterPage = () => history.push(`/users/register`);

  const handleOpenEditPage = (userId) => history.push(`/users/${userId}`);

  const handleOpenDeleteDialog = (idUser) => {
    setIdUserToDelete(idUser);
    setIsDialogOpen(true);
  };

  const handleDeleteError = () => {
    setIsDialogLoading(false);
    setIsDialogOpen(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível excluir o usuário!',
    });
  };

  const handleDelete = () => {
    setIsDialogLoading(true);
    api
      .delete(`users/${idUserToDelete}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Usuário exluído com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogOpen(false);
      })
      .catch((err) => {
        handleDeleteError(err);
      });
  };

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setUsers([]);
    setPage(1);
    setTotal(0);
  };

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };
  useEffect(() => {
    const params = {
      page,
    };
    if (queryText) {
      params.query = queryText;
    }

    setIsLoading(true);
    api
      .get('users', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setUsers(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
    // eslint-disable-next-line
  }, [page, queryText]);

  return (
    <DefaultPage title="Usuários">
      <ConfirmationDialog
        title="Excluir este usuário?"
        open={isDialogOpen}
        isConfirmLoading={isDialogLoading}
        handleConfirm={handleDelete}
        handleClose={() => setIsDialogOpen(false)}
      />
      <Container>
        <Toolbar>
          <SearchInput onChange={debounce(handleChangeQueryText)} />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCell}>Nome</TableCell>
                <TableCell className={classes.tableCell}>Login</TableCell>
                <TableCell className={classes.tableCell}>Email</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable
                        key={e}
                        className={classes.tableRow}
                        onClick={() => {}}
                        hover
                      >
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : users?.map((user) => (
                    <TableRowFocusable
                      key={user.id}
                      className={classes.tableRow}
                      onClick={() => {}}
                      hover
                    >
                      <TableCell className={classes.tableCell}>
                        {user.name}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.login}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.email}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Edit
                          color="primary"
                          onClick={() => handleOpenEditPage(user.id)}
                        />
                        <DeleteForever
                          color="primary"
                          onClick={() => handleOpenDeleteDialog(user.id)}
                        />
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};
