/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  TextField,
  CircularProgress,
  Box,
  Tab,
  Tabs,
  AppBar,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import {
  AddCircleOutline,
  DeleteForever,
  SubjectRounded,
} from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';

import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import useDebounce from '../../hooks/useDebounce';
import { RoundedButton, ConfirmationDialog } from '../../components';
import useAlert from '../../hooks/useAlert';
import ResumeConfirmationDialog from './ResumeConfirmationDialog';

import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  descriptionText: {
    width: '100%',
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: '17px',
    fontWeight: 'bold',
    marginBottom: '0px',
    marginRight: '0.5em',
  },
  inputDate: {
    borderRadius: '5px !important',
    maxWidth: '200px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  button: {
    width: 'fit-content',
  },
  leftButtons: {
    display: 'inline-flex',
    justifyContent: 'flex-start',
    margin: '1em 1em',
    width: '50%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  thash: {
    marginLeft: 'auto',
    placeSelf: 'self-end',
  },
  loadingButton: {
    marginLeft: '1em',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
  },
  infoIcon: {
    margin: theme.spacing(1),
  },
  divDesc: {
    color: '#414141',
    fontSize: '17px',
    width: '45%',
    minWidth: '250px',
    marginRight: '10px',
  },
  divDescEPubFields: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    flexFlow: 'row wrap',
    width: '100%',
  },
  root: {
    width: '100%',
    flexGrow: '1',
    marginTop: '10px',
    backgroundColor: 'transparent',
    boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.2)',
  },
  addMealRegister: {
    width: '5%',
  },
  addMealIcon: {
    color: '#00796B',
  },
  appBar: {
    backgroundColor: 'transparent',
    color: 'black',
    fontSize: '17px',
    boxShadow: 'none',
  },
  mealDescription: {
    width: '60%',
  },
  textPublish: {
    color: theme.palette.common.black,
    fontSize: '15px',
  },
  deleteMeal: {
    placeSelf: 'end',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ({ isEdit = false, menuInfo = {}, menuId }) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const debounce = useDebounce();
  const handleCancel = () => history.push('/menu');
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [isRegisterDialog, setIsRegisterDialog] = useState(false);
  const [valueTab, setValueTab] = useState(0);
  const [idMealToDelete, setIdMealToDelete] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [confirmEdit, setConfirmEdit] = useState(false);
  const tipSetDate =
    'Esta data refere-se a exibição do cardápio no aplicativo.';
  const [loadingTarget, setLoadingTarget] = useState(false);
  const meal = {
    id: 0,
    descricao: null,
    hora: null,
    cardapioRefeicaoItens: [],
  };

  const [description, setDescription] = useState(menuInfo.descricao ?? '');
  const [userName, setUserName] = useState(null);

  const [date, setDate] = useState(
    moment(new Date(menuInfo.dataExibicao)).format('YYYY-MM-DD') ?? ''
  );

  const [targetAudiences, setTargetAudiences] = useState(
    menuInfo?.publicoAlvo ?? []
  );
  const [queryTargetAudiences, setQueryTargetAudiences] = useState('');
  const [selectedTargets, setSelectedTargets] = useState(
    menuInfo?.publicosAlvo ?? []
  );
  const [meals, setMeals] = useState(menuInfo?.cardapioRefeicoes ?? [meal]);
  const [published, setPublished] = useState(menuInfo?.publicado ?? false);
  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };
  const getUserById = () => {
    if (menuInfo.idUsuarioPortal != null) {
      const params = {
        page: 1,
        ids: [menuInfo.idUsuarioPortal],
        limit: 1,
      };

      api.get('users/getUsersByIds', { params }).then((response) => {
        setUserName(
          response.data.data.length > 0 ? response.data.data[0].name : null
        );
      });
    }
    return userName;
  };

  const handleMapDescriptions = () => {
    const dateCopy = new Date(new Date(date).getTime());
    dateCopy.setDate(dateCopy.getDate() + 1);
    setDescriptions({
      descricao: description,
      dataExibicao: new Date(dateCopy).toLocaleDateString(),
      cadastradoPor: userName,
      publicosAlvo: selectedTargets
        .map((target) => target.descricao)
        .join(', '),
      cardapioRefeicoes: meals.map((m) => ({
        id: m.id,
        descricao: m.descricao,
        hora: m.hora,
        cardapioRefeicaoItens: m.cardapioRefeicaoItens.map((i) => ({
          descricao: i.descricao ?? i,
        })),
      })),
      publicado: published,
    });
  };

  const handleOpenDialog = () => {
    handleMapDescriptions();
    setDialogIsOpen(true);
  };

  const handleOpenResume = () => {
    setIsRegisterDialog(false);
    handleOpenDialog();
  };

  const handleOpenRegister = (e) => {
    e.preventDefault();
    setIsRegisterDialog(true);
    handleOpenDialog();
  };
  const handleSelectTargets = (targets) => {
    const limitTarget = 10;

    if (targets.length > limitTarget) {
      setAlert({
        isOpen: true,
        type: 'warning',
        message: `Limite máximo de (${limitTarget}) cárdapios atingido.`,
      });
      return;
    }

    setSelectedTargets(targets);
  };

  const mapRegisterPayload = () => {
    const payload = {
      descricao: description,
      dataExibicao: date,
      idsPublicoAlvo: selectedTargets.map((t) => parseInt(t.id)),
      cardapioRefeicoes: meals.map((m) => ({
        id: m.id,
        descricao: m.descricao,
        hora: m.hora,
        cardapioRefeicaoItens: m.cardapioRefeicaoItens.map((i) => ({
          descricao: i.descricao ?? i,
        })),
      })),
      publicado: published,
    };
    return payload;
  };

  const handleSubmit = async () => {
    let isValid = true;
    meals.forEach((e) => {
      if (e.cardapioRefeicaoItens.length === 0) {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Preencha os itens do cardápio!',
        });
        isValid = false;
      }
    });

    if (isValid) {
      setIsLoading(true);
      const registerData = mapRegisterPayload();

      if (isEdit) {
        if (registerData.publicado == 1) {
          setConfirmEdit(true);
        } else {
          handleConfirmEdit();
        }
      } else {
        api
          .post('menu', registerData)
          .then(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'success',
              message: 'Cardápio cadastrado com sucesso!',
            });
            history.push(`/menu`);
          })
          .catch(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'error',
              message: 'Já existe cardápio cadastrado.',
            });
          });
      }
    }
  };

  const handleSetTextAudience = debounce((text) =>
    setQueryTargetAudiences(text)
  );

  const handleFilterText = (e) => {
    if (e == null) return;

    if (typeof e === 'string' || e instanceof String) handleSetTextAudience(e);
  };

  const searchTargetAudiences = (queryText) => {
    setLoadingTarget(true);

    const params = {
      page: 1,
      limit: 1000,
      appRole: ['coordination', 'admin'],
    };

    if (queryText && queryText.length > 0) params.query = queryText;

    api
      .get('target-audience', {
        params,
      })
      .then((response) => {
        const dataTemp = [...response.data.data];
        setTargetAudiences(dataTemp);
        setLoadingTarget(false);
      })
      .catch(() => {
        setTargetAudiences([]);
        setLoadingTarget(false);
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao buscar público alvo',
        });
      });
  };

  useEffect(() => {
    searchTargetAudiences(queryTargetAudiences);
    getUserById();
    // eslint-disable-next-line
  }, [queryTargetAudiences]);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleOpenDeleteDialog = (idMeal) => {
    setIdMealToDelete(idMeal);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setIsDeleteLoading(false);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmEdit(false);
    setIsLoading(false);
  };

  const handleConfirmEdit = () => {
    const registerData = mapRegisterPayload();
    api
      .put(`menu/${menuId}`, registerData)
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Cardápio editado com sucesso!',
        });
        history.push(`/menu`);
      })
      .catch(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Já existe cardápio cadastrado.',
        });
      });
  };

  const handleDelete = () => {
    const array = meals.filter((item, index) => index !== idMealToDelete.index);
    setMeals(array);
    handleCloseDeleteDialog();
  };

  const handleAddTab = () => {
    setMeals([
      ...meals,
      {
        id: meals.length,
        descricao: null,
        time: null,
        cardapioRefeicaoItens: [],
      },
    ]);
  };

  const handleMealDescription = (val, index) => {
    const items = [...meals];
    const item = { ...items[index] };
    item.descricao = val;
    items[index] = item;
    setMeals(items);
  };

  const handleHour = (val, index) => {
    const items = [...meals];
    const item = { ...items[index] };
    item.hora = val;
    items[index] = item;
    setMeals(items);
  };

  const handleMealItems = (val, index) => {
    const items = [...meals];
    const item = { ...items[index] };
    item.cardapioRefeicaoItens = val;
    items[index] = item;
    setMeals(items);
  };

  return (
    <form className={classes.form} onSubmit={handleOpenRegister}>
      <ConfirmationDialog
        title="Excluir esta refeição?"
        open={isDeleteDialogOpen}
        isConfirmLoading={isDeleteLoading}
        handleConfirm={handleDelete}
        handleClose={() => handleCloseDeleteDialog()}
      />

      <ConfirmationDialog
        title="Salvar este registro afeta no cardápio que está sendo exibido no aplicativo."
        open={confirmEdit}
        handleConfirm={handleConfirmEdit}
        handleClose={() => handleCloseConfirmDialog()}
      />

      <div className={classes.formInputs}>
        <Typography className={classes.title} variant="h5" color="primary">
          {isEdit ? 'Edição' : 'Novo cardápio'}
        </Typography>
        <div className={classes.divDescEPubFields}>
          <div className={classes.divDesc}>
            <p className={classes.subTitle}>Nome</p>
            <TextField
              id="description"
              placeholder="Cardápio de segunda"
              value={description}
              className={classes.descriptionText}
              variant="outlined"
              onChange={(e) => setDescription(e.target.value)}
              required
              inputProps={{ maxLength: 300 }}
            />
          </div>
          <div className={classes.divDesc}>
            <p className={classes.subTitle}>
              Data de exibição
              <Tooltip title={tipSetDate}>
                <InfoIcon color="primary" />
              </Tooltip>
            </p>

            <TextField
              id="exibitionDate"
              className={classes.inputDate}
              variant="outlined"
              type="date"
              value={date}
              style={{ marginRight: '8px' }}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
        </div>
        <div className={classes.divDesc}>
          <p className={classes.subTitle}>Público-alvo</p>
          <Autocomplete
            multiple
            size="small"
            id="tags-outlined"
            options={targetAudiences}
            value={selectedTargets}
            onInputChange={(e) => handleFilterText(e?.target?.value)}
            onChange={(event, value) => handleSelectTargets(value)}
            getOptionLabel={(option) => option.descricao}
            loading={loadingTarget}
            onClose={() => handleFilterText('')}
            clearOnEscape
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                InputLabelProps={{
                  ...params.InputLabelProps,
                  style: {
                    color: 'rgba(0, 0, 0, 0.44)',
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingTarget ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className={classes.root}>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              TabIndicatorProps={{ style: { background: '#00796B' } }}
            >
              {meals.map((m, index) => (
                <Tab label={m.descricao ?? 'Refeição'} tabIndex={index} />
              ))}
              <RoundedButton
                className={classes.addMealRegister}
                onClick={handleAddTab}
                startIcon={<AddCircleOutline className={classes.addMealIcon} />}
              />
            </Tabs>
          </AppBar>
          {meals.map((m, index) => (
            <TabPanel value={valueTab} index={index}>
              <div className={classes.divDescEPubFields}>
                <div className={classes.divDesc}>
                  <p className={classes.subTitle}>Descrição da refeição</p>
                  <TextField
                    id="mealDescription"
                    placeholder="Almoço"
                    value={m.descricao}
                    className={classes.descriptionText}
                    variant="outlined"
                    onChange={(e) =>
                      handleMealDescription(e.target.value, index)
                    }
                    required
                    inputProps={{ maxLength: 50 }}
                  />
                </div>
                <div className={classes.divDesc}>
                  <p className={classes.subTitle}>Hora</p>
                  <TextField
                    id="hour"
                    className={classes.inputDate}
                    variant="outlined"
                    type="time"
                    value={m.hora}
                    style={{ marginRight: '8px' }}
                    onChange={(e) => handleHour(e.target.value, index)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className={classes.divDesc}>
                  <p className={classes.subTitle}>Itens</p>
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={
                      m.cardapioRefeicaoItens.map((c) => c.descricao ?? c) ?? []
                    }
                    value={
                      m.cardapioRefeicaoItens.map((c) => c.descricao ?? c) ?? []
                    }
                    freeSolo
                    onChange={(event, value) => handleMealItems(value, index)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          maxLength: 200,
                        }}
                        size="small"
                        required={m.cardapioRefeicaoItens.length === 0}
                      />
                    )}
                  />
                </div>
                <div className={classes.thash}>
                  <DeleteForever
                    color="primary"
                    onClick={() => handleOpenDeleteDialog({ index })}
                  />
                </div>
              </div>
            </TabPanel>
          ))}
        </div>
      </div>

      <div>
        <div className={classes.leftButtons}>
          <Checkbox
            color="primary"
            checked={published}
            onChange={(e) => setPublished(e.target.checked)}
          />
          <p className={classes.textPublish}>Publicar</p>
          <RoundedButton
            className={classes.loadingButton}
            variant="contained"
            color="primary"
            startIcon={<SubjectRounded />}
            onClick={handleOpenResume}
          >
            Resumo
          </RoundedButton>
        </div>

        <div className={classes.buttons}>
          <RoundedButton variant="contained" onClick={handleCancel}>
            Cancelar
          </RoundedButton>
          <RoundedButton
            className={classes.loadingButton}
            type="submit"
            variant="contained"
            color="primary"
          >
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </RoundedButton>
          <ResumeConfirmationDialog
            open={dialogIsOpen}
            isConfirmation={isRegisterDialog}
            isEdit={isEdit}
            isConfirmLoading={isLoading}
            handleConfirm={handleSubmit}
            handleClose={handleCloseDialog}
            descriptions={descriptions}
          />
        </div>
      </div>
    </form>
  );
};
