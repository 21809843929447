import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FeatureCard } from '..';

const useStyles = makeStyles((theme) => ({
  cards: {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: theme.spacing(1, 0),
  },
}));

export default ({ section }) => {
  const classes = useStyles();
  const itemsDesktop = section?.items.filter((item) => item.desktop);
  const hasItems = itemsDesktop.length > 0;

  section.items.forEach((item) => {
    if(item.subSectionTitle) {
      item.items.forEach((it) => {
        if (it.desktop) {
          itemsDesktop.push(it);
        }
      })
    }
  });

  return (
    <section hidden={!hasItems}>
      <Typography variant="subtitle2">
        {hasItems ? section?.title.toUpperCase() : null}
      </Typography>
      <div className={classes.cards}>
        {itemsDesktop.map((item) => (
          <FeatureCard key={item.name} item={item} />
        ))}
      </div>
    </section>
  );
};
