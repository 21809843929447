import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '600px',
    flexFlow: 'column',
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  lines: {
    display: 'inline-flex',
  },
  subtitle: {
    fontWeight: 'bold',
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  item: {
    width: '40%',
    paddingLeft: '2px',
  },
}));

export default ({ descriptions = {} }) => {
  const classes = useStyles();
  const menu = descriptions;
  const defaultWord = 'Não especificado';
  return (
    <section className={classes.root}>
      <div className={classes.lines}>
        <div className={classes.item}>
          <Typography className={classes.subtitle} color="primary">
            Descrição:
          </Typography>
          {menu.descricao ?? defaultWord}
        </div>
        <div>
          <Typography className={classes.subtitle} color="primary">
            Cadastrado por:
          </Typography>
          {menu.cadastradoPor ?? defaultWord}
        </div>
      </div>
      <div className={classes.lines}>
        <div className={classes.item}>
          <Typography className={classes.subtitle} color="primary">
            Público alvo:
          </Typography>
          {menu.publicosAlvo === '' ? 'Todos' : menu.publicosAlvo}
        </div>
        <div className={classes.item}>
          <Typography className={classes.subtitle} color="primary">
            Data de exibição:
          </Typography>
          {menu.dataExibicao ?? defaultWord}
        </div>
      </div>
      <div className={classes.item}>
        {menu.cardapioRefeicoes.map((m) => (
          <div>
            <Typography className={classes.subtitle} color="primary">
              {m.descricao} - {m.hora}
            </Typography>
            <div>
              {m.cardapioRefeicaoItens.map((i) => i.descricao).join(', ')}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
