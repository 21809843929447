import React, { useEffect, useState } from 'react';

import { Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InfoDialog from '../../components/InfoDialog/index';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';

const useStyles = makeStyles((theme) => ({
  divFoto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'Center',
    width: '280px',
    height: '200px',
    marginBottom: '10px',
  },
  molduraFoto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'Center',
    width: '180px',
    height: '188px',
    borderRadius: '50%',
    backgroundColor: '#f5f5f5',
  },
  divItemType: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    marginTop: '1px',
  },
  cellDialog: {
    padding: '0.5em',
    textAlign: 'center',
    width: '25%',
  },
  divOptionsConfigs: {
    marginLeft: '1em',
  },
  divConfigs: {
    margin: '16px 0px',
  },
  buttonProgress: {
    transition: 'all 0.3s',
    color: theme.palette.primary.light,
    marginLeft: '4px',
    width: '0px',
  },
}));

export default ({ exit, onClose, open }) => {
  const classes = useStyles();
  const setAlert = useAlert();
  const [foto, setFoto] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const requestPhotoResp = async (codigoResponsavel) => {
    setIsLoading(true);

    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'Não foi possível buscar os dados, entre em contato com a instituição.',
      });
      return;
    }

    const params = {
      codigoResponsavel,
      tokenJWT: tokenLs,
    };

    api
      .get('earlyExit/getFotoResp', {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.dados != null) {
            setFoto(response.data.dados.data[0].foto);
          }
        } else {
          setAlert({
            isOpen: true,
            type: 'error',
            message: response.data.mensagem
              ? response.data.mensagem
              : 'Ocorreu um erro inesperado!',
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um erro inesperado ao buscar a foto do responsável!',
        });
      });
  };

  useEffect(() => {
    if (open) {
      requestPhotoResp(exit.codigoResponsavel);
    } else {
      setFoto('');
    }
  }, [open]);

  return (
    <InfoDialog
      onClose={onClose}
      open={open}
      title="Detalhes do responsável terceiro"
    >
      <div className={classes.divFoto}>
        <div className={classes.molduraFoto}>
          {foto === '' ? (
            <CircularProgress
              size={isLoading ? 30 : 0}
              className={classes.buttonProgress}
            />
          ) : (
            <img
              src={`data:image/png;base64,${foto}`}
              className={classes.molduraFoto}
              alt="Foto terceiro"
            />
          )}
        </div>
      </div>
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          Nome completo:&nbsp;
        </Typography>
        <div className={classes.body}>{exit?.nomeCompletoResp}</div>
      </div>
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          Parentesco:&nbsp;
        </Typography>
        <div>{exit?.parentesco}</div>
      </div>
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          CPF:&nbsp;
        </Typography>
        <div>{exit?.cpf}</div>
      </div>
    </InfoDialog>
  );
};
