import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Toolbar,
  Dialog,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Add, DeleteForever, Search, Close, Check } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import {
  Container,
  RoundedInput,
  RoundedButton,
  LoadingButton,
  TableRowFocusable,
  SearchInput,
  MultipleValueInput,
} from '../../components';
import DefaultPage from '../../templates/DefaultPage';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';
import RemoveUserListModel from './RemoveUserListModel';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '1em',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  notFound: {
    marginTop: '2em',
    color: '#848484',
    fontWeight: '300 !important',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  tableCell: {
    wordBreak: 'break-word',
    width: '100%',
  },
  tableCellIcons: {
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '2em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  customButton: {
    borderRadius: '5px !important',
  },
  buttonAddUser: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  customToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  registerButton: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  customDialogContent: {
    marginLeft: '4em',
    marginRight: '4em',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const { idSector } = useParams();

  const perPageSearch = 5;
  const perPage = 10;

  const [sectorUsers, setSectorUsers] = useState(null);
  const [openRemoveUser, setOpenRemoveUser] = useState(false);
  const [userToRemoveFromList, setUserToRemoveFromList] = useState(null);

  const [open, setOpen] = useState(false);
  const [sectorName, setSectorName] = useState('');
  const [sectorMessage, setSectorMessage] = useState('');
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [userSearchName, setUserSearchName] = useState('');
  const [usersSearch, setUsersSearch] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState(1);
  const [totalSearch, setTotalSearch] = useState(0);

  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState([]);

  const getSelectedTargetsIds = () => selectedTargetAudiences.map((u) => u.id);

  const handleChangeUserSearchName = (value) => setUserSearchName(value.trim());
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUserSearchName('');
  };
  const handleCancel = () => history.push('/sector');
  const handleSuccess = () => history.push(`/sector`);

  const handleChangePageSearch = (event, nextPage) =>
    handleSearchUser(nextPage + 1);
  const handleChangePage = (event, nextPage) =>
    handleLoadUsers(sectorUsers, nextPage + 1);

  const handleSearchUser = (page = 1) => {
    setIsLoadingSearch(true);

    const params = {
      page,
      name: userSearchName,
      limit: perPageSearch,
    };

    api
      .get('users/getUserByName', {
        params,
      })
      .then((response) => {
        setUsersSearch(response.data.data);
        setPageSearch(page);
        setTotalSearch(response.data.total);
        setIsLoadingSearch(false);
      })
      .catch((err) => setIsLoadingSearch(false));
    // eslint-disable-next-line
  };

  const handleAlert = (typeAlert, message) => {
    setAlert({
      isOpen: true,
      type: typeAlert,
      message,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingSave(true);

    const updateData = {
      id: idSector,
      name: sectorName,
      message: sectorMessage,
      users: sectorUsers,
      targetAudiences: getSelectedTargetsIds(),
    };

    api
      .put('sector/updateSector', updateData)
      .then((res) => {
        if (!res.data.Sucesso) handleAlert('error', res.data.Mensagem);
        else {
          handleAlert('success', 'Setor atualizado com sucesso!');
          setIsLoadingSave(false);

          handleSuccess();
        }
      })
      .catch(() => {
        handleAlert(
          'error',
          'Ocorreu um erro inesperado ao tentar atualizar o setor.'
        );
        setIsLoadingSave(false);
      });
  };

  const addRemoveUser = (user) => {
    const usuarioAdicionado = sectorUsers.filter((s) => s.IdUsuario == user.id);

    if (usuarioAdicionado.length > 0) {
      setUserToRemoveFromList(user);
      setOpenRemoveUser(true);
    } else {
      const newUser = {
        IdUsuario: user.id,
        NomeUsuario: user.name,
        EmailUsuario: user.email,
      };
      const usersTemp = [newUser, ...sectorUsers];
      setSectorUsers(usersTemp);
      handleLoadUsers(usersTemp, page);
    }
  };

  const confirmRemoveUser = () => {
    if (!userToRemoveFromList) {
      handleAlert(
        'error',
        'Ocorreu um erro ao tentar excluir o usuário informado.'
      );
      return;
    }

    const usersTemp = sectorUsers.filter(
      (x) => x.IdUsuario !== userToRemoveFromList.id
    );
    setSectorUsers([...usersTemp]);
    handleLoadUsers(usersTemp, page);
    setOpenRemoveUser(false);
  };

  useEffect(() => {
    const params = {
      page: 1,
      limit: 10,
      idSector,
      appRole: ['admin'],
    };

    api
      .get('sector/getSectors', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso)
          handleAlert('error', response.data.Mensagem);
        else if (response.data.Dados.Data.length === 0)
          handleAlert('warning', 'Nenhum setor encontrado.');
        else {
          const sectorTemp = response.data.Dados.Data[0];

          setSectorName(sectorTemp.Nome);
          setSectorMessage(
            sectorTemp.MensagemAutomatica ? sectorTemp.MensagemAutomatica : ''
          );
          setSectorUsers(sectorTemp.Usuarios);

          handleLoadUsers(sectorTemp.Usuarios);

          api
            .get('target-audience', {
              params: {
                limit: 1000,
                appRole: ['admin'],
              },
            })
            .then((r) => {
              setTargetAudiences(r.data.data);
              let selectedTargets = [];
              sectorTemp.PublicosAlvo.forEach((x) => {
                const filtered = r.data.data.filter((t) => {
                  return t.id === x;
                });
                if (filtered.length > 0) {
                  selectedTargets = [...selectedTargets, filtered[0]];
                }
              });
              setSelectedTargetAudiences(selectedTargets);
            });
        }
      })
      .catch(() =>
        handleAlert(
          'error',
          'Ocorreu um erro inesperado ao tentar buscar o setor informado.'
        )
      );
    // eslint-disable-next-line
  }, [idSector]);

  const handleLoadUsers = (users, page = 1) => {
    setIsLoadingUsers(true);

    if (users == null || users.length === 0) {
      setUsers([]);
      setIsLoadingUsers(false);
      return;
    }

    const usersIds = users.map((u) => u.IdUsuario);

    const params = {
      page,
      ids: usersIds,
      limit: perPage,
    };

    api
      .get('users/getUsersByIds', {
        params,
      })
      .then((response) => {
        setPage(page);
        setUsers(response.data.data);
        setTotal(response.data.total);
        setIsLoadingUsers(false);
      })
      .catch(() => setIsLoadingUsers(false));
    // eslint-disable-next-line
  };

  const userAdd = (user) => {
    const usuarioAdicionado = sectorUsers.filter((s) => s.IdUsuario == user.id);

    return (
      <>
        {!usuarioAdicionado.length > 0 ? (
          <RoundedButton
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            className={classes.customButton}
            onClick={() => addRemoveUser(user)}
          >
            Adicionar
          </RoundedButton>
        ) : (
          <RoundedButton
            color="primary"
            variant="contained"
            startIcon={<Check />}
            className={classes.customButton}
            // onClick={() => addRemoveUser(user)}
          >
            Adicionado!
          </RoundedButton>
        )}
      </>
    );
  };

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <>
        {onClose ? (
          <div>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <DefaultPage title="Setores">
      <Container>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formInputs}>
            <Typography className={classes.title} variant="h5" color="primary">
              Edição de Setor
            </Typography>
            <div className={classes.formBody}>
              <RoundedInput
                className={classes.input}
                label="Nome do Setor"
                size="small"
                variant="outlined"
                value={sectorName}
                onChange={(e) => setSectorName(e.target.value)}
                required
              />
              <RoundedInput
                className={classes.input}
                label="Mensagem Padrão"
                size="small"
                multiline
                rows={4}
                variant="outlined"
                value={sectorMessage}
                onChange={(e) => setSectorMessage(e.target.value)}
              />
              <Typography
                className={classes.subTitle}
                variant="subtitle1"
                color="primary"
              >
                Público-alvo
              </Typography>
              <div className={classes.divTargetAudience}>
                <MultipleValueInput
                  customClasses={classes.fieldTargetAudience}
                  label="Procurar..."
                  variant="outlined"
                  labelProperty="descricao"
                  value={selectedTargetAudiences}
                  options={targetAudiences}
                  onChange={(event, value) => setSelectedTargetAudiences(value)}
                />
              </div>
              <Toolbar className={classes.customToolbar}>
                <Typography variant="subtitle1" color="primary">
                  Usuários ativos
                </Typography>
                <RoundedButton
                  className={classes.buttonAddUser}
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={handleClickOpen}
                >
                  Adicionar usuário
                </RoundedButton>
              </Toolbar>
              {isLoadingUsers ? (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {new Array(perPage)
                        .fill()
                        .map((e, i) => i)
                        .map((e) => (
                          <TableRowFocusable key={e} onClick={() => {}} hover>
                            <TableCell className={classes.tableCell}>
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRowFocusable>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : users?.length === 0 ? (
                <Typography className={classes.notFound} variant="body2">
                  Nenhum usuário está cadastrado a este setor.
                </Typography>
              ) : (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.map((user) => (
                          <TableRowFocusable key={user.id} hover>
                            <TableCell className={classes.tableCell}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    {user.name[0].toUpperCase()}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                  <Typography>{user.name}</Typography>
                                </ListItemText>
                              </ListItem>
                            </TableCell>
                            <TableCell className={classes.tableCellIcons}>
                              <DeleteForever
                                color="primary"
                                className={classes.iconCustom}
                                onClick={() => addRemoveUser(user)}
                              />
                            </TableCell>
                          </TableRowFocusable>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[perPage]}
                    component="div"
                    count={total}
                    rowsPerPage={perPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                  />
                </>
              )}
            </div>
          </div>
          <div className={classes.buttons}>
            <RoundedButton
              variant="contained"
              className={classes.customButton}
              onClick={handleCancel}
            >
              Cancelar
            </RoundedButton>
            <LoadingButton
              className={classes.loadingButton}
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isLoadingSave}
            >
              Salvar
            </LoadingButton>
          </div>
        </form>

        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogActions>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} />
          </DialogActions>
          <DialogContent className={classes.customDialogContent}>
            <Toolbar className={classes.customToolbar}>
              <SearchInput
                placeholder="Pesquisar usuário"
                variant="outlined"
                onChange={handleChangeUserSearchName}
              />
              <LoadingButton
                className={classes.registerButton}
                color="primary"
                variant="contained"
                startIcon={<Search />}
                isLoading={isLoadingSearch}
                onClick={() => handleSearchUser(1)}
              >
                Buscar
              </LoadingButton>
            </Toolbar>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersSearch?.map((user) => (
                    <TableRowFocusable key={user.id} hover>
                      <TableCell className={classes.tableCell}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                              {user.name[0].toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            <Typography>{user.name}</Typography>
                          </ListItemText>
                        </ListItem>
                      </TableCell>
                      <TableCell className={classes.tableCellIcons}>
                        {userAdd(user)}
                      </TableCell>
                    </TableRowFocusable>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[perPageSearch]}
              component="div"
              count={totalSearch}
              rowsPerPage={perPageSearch}
              page={pageSearch - 1}
              onChangePage={handleChangePageSearch}
            />
          </DialogContent>
          <DialogActions>
            <RoundedButton autoFocus onClick={handleClose} color="primary">
              OK
            </RoundedButton>
          </DialogActions>
        </Dialog>

        <RemoveUserListModel
          handleClose={() => setOpenRemoveUser(false)}
          openDelete={openRemoveUser}
          dialogUser={userToRemoveFromList}
          confirmDelete={() => confirmRemoveUser()}
        />
      </Container>
    </DefaultPage>
  );
};
