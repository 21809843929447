import React from 'react';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '@material-ui/core';

export default () => {
    return (
        <DefaultPage title="Novidades do aplicativo">
            <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{width: '80%', height: '100%', padding: '20px', backgroundColor: '#fff'}}>
                    <iframe
                        src="https://gvdasa.topdesk.net/solutions/open-knowledge-items/item/KI 1866/pt/ "
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                </div>
            </Container>
        </DefaultPage>
    );
};
