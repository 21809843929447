import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Toolbar, Typography, TextField, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, ListAlt } from '@material-ui/icons';
import {
  Container,
  RoundedButton,
  LoadingButton,
  MultipleValueInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';

import DefaultPage from '../../templates/DefaultPage';
import api from '../../services/api';
import InfoSettingDialog from './infoSettingDialog';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  field: {
    marginTop: '0.5em',
    marginBottom: '1.5em',
  },
  additionalConfig: {
    display: 'flex',
    marginBottom: '2em',
  },
  divChecks: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
  },
  checkOption: {
    display: 'flex',
    alignItems: 'center',
  },
  divBottomButtons: {
    display: 'flex',
    marginBottom: '15px',
  },
  flex1: {
    flex: 1,
  },
  buttons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default ({
  isEdit = false,
  isClone = false,
  headerTitle = 'Cadastro de configuração',
  setting = {},
}) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const settingId = setting?.id ?? 0;
  const checkListId = setting?.checkListId ?? 0;
  const [identifier, setIdentifier] = useState(setting?.identificador ?? '');
  const [textButtonSave, setTextButtonSave] = useState('Salvar');
  const [isLoading, setIsLoading] = useState(false);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState(
    setting?.publicosAlvo ?? []
  );
  const [itensDr, setItensDr] = useState([]);
  const [selectedItemDr, setSelectedItemDr] = useState(setting?.itens ?? []);
  const [habilitaIntegracaoDiario, setHabilitaIntegracaoDiario] = useState(
    setting?.habilitaIntegracaoDiario ?? false
  );
  const [
    restringeSomenteProfeTitular,
    setRestringeSomenteProfeTitular,
  ] = useState(setting?.restringeSomenteProfeTitular ?? false);
  const [liberaTempoReal, setLiberaTempoReal] = useState(
    setting?.liberaTempoReal ?? false
  );
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [settingSelected, setSettingSelected] = useState();

  useEffect(() => {
    if (isClone) {
      setTextButtonSave('Clonar');
    }
    api
      .get('target-audience', {
        params: {
          limit: 1000,
          appRole: ['coordination', 'admin'],
        },
      })
      .then((response) => {
        setTargetAudiences(response.data.data);
      });

    api
      .get('getItems', {
        params: { limit: 100, order: 0, appRole: ['coordination', 'admin'] },
      })
      .then((response) => {
        setItensDr(response.data.data);
      });
  }, []);

  const mapRegisterPayload = () => {
    const payload = {
      id: settingId,
      checkListId,
      identificador: identifier,
      publicosAlvo: selectedTargetAudiences,
      itens: selectedItemDr,
      habilitaIntegracaoDiario,
      restringeSomenteProfeTitular,
      liberaTempoReal,
    };
    return payload;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const registerData = mapRegisterPayload();

    if (isEdit) {
      api
        .put('editSetting', registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Item atualizado com sucesso!',
          });
          history.push(`/manage-settingsDR`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao atualizar o item.',
          });
        });
    } else {
      api
        .post('registerSetting', registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Configuração cadastrada com sucesso!',
          });
          history.push('/manage-settingsDR');
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao cadastrar a configuração.',
          });
        });
    }
  };

  const handleCancel = () => history.push('/manage-settingsDR');

  const handleOpenDetailsItem = () => {
    const setting = {
      identificador: identifier,
      itens: selectedItemDr,
      publicosAlvo: selectedTargetAudiences,
      habilitaIntegracaoDiario,
      liberaTempoReal,
      restringeSomenteProfeTitular,
    };
    setSettingSelected(setting);
    setOpenDetailsDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDetailsDialog(false);
  };

  return (
    <DefaultPage>
      <Container>
        <InfoSettingDialog
          setting={settingSelected}
          open={openDetailsDialog}
          title="Resumo"
          onClose={handleCloseDialog}
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <Toolbar>
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1.6em' }}
              color="primary"
            >
              {headerTitle}
            </Typography>
          </Toolbar>
          <div className={classes.formInputs}>
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Identificador
            </Typography>
            <TextField
              inputProps={{ maxLength: 45 }}
              required
              label="Ex.: Registro diário manhã"
              value={identifier}
              variant="outlined"
              onChange={(e) => setIdentifier(e.target.value)}
              className={classes.field}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Itens vinculados
            </Typography>
            <MultipleValueInput
              className={classes.field}
              label="Procurar..."
              variant="outlined"
              labelProperty="identDescConcat"
              value={selectedItemDr}
              options={itensDr}
              onChange={(event, value) => setSelectedItemDr(value)}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Público-alvo
            </Typography>
            <MultipleValueInput
              className={classes.field}
              label="Procurar..."
              variant="outlined"
              labelProperty="descricao"
              value={selectedTargetAudiences}
              options={targetAudiences}
              onChange={(event, value) => setSelectedTargetAudiences(value)}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Configurações adicionais
            </Typography>
            <div className={classes.additionalConfig}>
              <div className={classes.divChecks}>
                <div className={classes.checkOption}>
                  <Checkbox
                    color="primary"
                    checked={restringeSomenteProfeTitular}
                    onChange={() =>
                      setRestringeSomenteProfeTitular(
                        !restringeSomenteProfeTitular
                      )
                    }
                  />
                  <span>Restringe somente professor titular</span>
                </div>
                <div className={classes.checkOption}>
                  <Checkbox
                    color="primary"
                    checked={habilitaIntegracaoDiario}
                    onChange={() =>
                      setHabilitaIntegracaoDiario(!habilitaIntegracaoDiario)
                    }
                  />
                  <span>Usa integração com o diário de classe</span>
                </div>
              </div>
              <div className={classes.divChecks}>
                <div className={classes.checkOption}>
                  <Checkbox
                    color="primary"
                    checked={liberaTempoReal}
                    onChange={() => setLiberaTempoReal(!liberaTempoReal)}
                  />
                  <span>Libera em tempo real</span>
                </div>
                {/* <div className={classes.checkOption}>
                  <Checkbox checked={false}/>
                  <span>Indicar se envia notificação</span>
                </div> */}
              </div>
            </div>
            <div className={classes.divBottomButtons}>
              <div className={classes.flex1}>
                {/* <Checkbox checked={false}/>
                <span>Publicar</span> */}
              </div>
              <div className={classes.buttons}>
                <RoundedButton
                  style={{ marginRight: '1em' }}
                  color="primary"
                  variant="contained"
                  disabled
                >
                  <Visibility />
                </RoundedButton>
                <RoundedButton
                  style={{ marginRight: '4em' }}
                  color="primary"
                  variant="contained"
                  onClick={() => handleOpenDetailsItem()}
                >
                  <ListAlt />
                </RoundedButton>
                <RoundedButton
                  style={{ marginRight: '1em' }}
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancelar
                </RoundedButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                >
                  {textButtonSave}
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </DefaultPage>
  );
};
