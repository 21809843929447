import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  Checkbox,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EnviarArquivo from '../../components/EnviarArquivo';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';
import {
  RoundedInput,
  RoundedButton,
  LoadingButton,
  ConfirmationDialog,
} from '../../components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '800px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 'auto',
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    padding: '10px 15px 10px 25px',
  },
  inputMsg: {
    width: '100%',
  },
  footButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& button': {
      width: '100%',
      marginBottom: '10px',
    },
  },
  btnSendMsg: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: '10px',
    },
    marginLeft: '1em',
  },
  divContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      marginBottom: '10px',
    },
  },
}));

export default ({
  handleClose = () => {},
  open = false,
  selectedRecipients,
  selectedTargets,
  subjectText,
  idSector,
  linkAllstudents,
}) => {
  const classes = useStyles();
  const setAlert = useAlert();
  const history = useHistory();

  const [message, setMessage] = useState('');
  const [allowAnswer, setAllowAnswer] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [titleConfirmDialog, setTitleConfirmDialog] = useState('');

  const [base64, setBase64] = useState('');
  const [file, setFile] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleWarningConfirm = (message) => {
    setAlert({
      isOpen: true,
      type: 'warning',
      message,
    });
  };

  const handleSendMessage = () => {
    setLoadingConfirm(true);

    if (message.trim() === '') {
      handleWarningConfirm('Necessário digitar uma mensagem.');
      setLoadingConfirm(false);
      return;
    }

    const fileType = file !== null ? file.type : null;

    const registerData = {
      selectedRecipients: selectedRecipients.map((r) => {
        return {
          ...r,
          PessoasVinculadas: r?.PessoasVinculadas?.filter((p) => p.Selecionado),
        };
      }),
      selectedTargets,
      subjectText,
      message,
      allowAnswer,
      idSector,
      fileName: file !== null ? file.name : null,
      fileType,
      base64,
      linkAllstudents,
    };

    api
      .post('chat/createChatSector', registerData)
      .then((res) => {
        const dataTemp = res.data;
        setLoadingConfirm(false);
        if (!dataTemp.sucesso)
          setAlert({
            isOpen: true,
            type: 'error',
            message: dataTemp.mensagem,
          });
        else {
          if (selectedRecipients.length === 1) {
            setTitleConfirmDialog('Mensagem enviada com sucesso.');
          } else {
            setTitleConfirmDialog(
              'Mensagens enviadas com sucesso. Será necessário aguardar alguns minutos para que todas as mensagens possam ser enviadas.'
            );
          }
          setOpenDialogConfirm(true);
        }
      })
      .catch(() => {
        setLoadingConfirm(false);
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao iniciar chat.',
        });
      });
  };

  const handleConfirmDialog = () => {
    setOpenDialogConfirm(false);
    history.push(`/messageSector`);
  };

  const handleRemoveFile = () => {
    setBase64('');
    setFile('');
  };

  return (
    <Dialog
      maxWidth={false}
      onClose={() => handleClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <div className={classes.container}>
        <DialogActions className={classes.headerMain}>
          <Typography
            component="span"
            color="primary"
            style={{ marginRight: 'auto' }}
          >
            <strong>Enviar mensagem</strong>
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent style={{ padding: '5px 25px 25px 25px' }}>
          <RoundedInput
            className={classes.inputMsg}
            label="Mensagem"
            size="small"
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            inputProps={{ maxLength: '5000' }}
            placeholder="Mensagem de até 5000 caracteres"
          />
          <div className={classes.divContainer}>
            <Checkbox
              checked={allowAnswer}
              disableRipple
              color="primary"
              onChange={() => setAllowAnswer(!allowAnswer)}
            />
            <span>Permitir respostas</span>
            <EnviarArquivo setFile={setFile} setBase64={setBase64} />
            {file !== '' && <span>{file?.name}</span>}
            <div hidden={file === ''}>
              <IconButton onClick={handleRemoveFile} color="primary">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <span hidden={file !== ''}>Anexar</span>
          </div>
          {isMobile ? (
            <div className={classes.footButtons}>
              <LoadingButton
                color="primary"
                variant="contained"
                isLoading={loadingConfirm}
                onClick={() => handleSendMessage()}
              >
                Enviar
              </LoadingButton>
              <RoundedButton
                color="primary"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancelar
              </RoundedButton>
            </div>
          ) : (
            <div className={classes.footButtons}>
              <RoundedButton
                color="primary"
                variant="outlined"
                onClick={() => handleClose()}
              >
                Cancelar
              </RoundedButton>
              <LoadingButton
                color="primary"
                variant="contained"
                isLoading={loadingConfirm}
                className={classes.btnSendMsg}
                onClick={() => handleSendMessage()}
              >
                Enviar
              </LoadingButton>
            </div>
          )}
        </DialogContent>
      </div>
      <ConfirmationDialog
        title={titleConfirmDialog}
        open={openDialogConfirm}
        handleConfirm={() => handleConfirmDialog()}
        handleClose={() => handleConfirmDialog()}
      />
    </Dialog>
  );
};
