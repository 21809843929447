import React, { useState, useCallback } from 'react';
import { RoundedButton,  } from '../../components';
import Cropper from 'react-easy-crop'
import { makeStyles } from '@material-ui/core/styles';
import getCroppedImg from '../../services/cropImage'; 
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    height: '32em',
  },
  divTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  cropContainer: {
    display: 'flex',
    height: '26.8em',
    position: 'relative',
  },
  crop: {

  },
  confirma: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0.5em',
  },
}));

export default ({
  handleClose,
  imageBase64,
  file,
  setFileObject,
  setCropActive
}) => {
  const classes = useStyles();
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropChange = (crop) => {
    setCrop(crop);
  };
 
  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);
 
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageBase64,
        croppedAreaPixels,
        file[0].name,
        file[0].type
      )
      setCropActive(false);
      setFileObject([croppedImage]);
      handleClose();
    } catch (e) {
      console.error(e)
    }
  });

  return (
    <div className={classes.container}>
      <div className={classes.divTitle}>
        <Typography className={classes.title} variant="h5" color="primary">
          Recorte de imagem
        </Typography>
      </div>
      <div className={classes.cropContainer}>
        <div className={classes.crop}>
          <Cropper
            image={imageBase64}
            crop={crop}
            zoom={zoom}
            aspect={4/3}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
        </div>
      </div>
      <div className={classes.confirma}>
        <RoundedButton variant="contained" color="primary"
          onClick={() => showCroppedImage()}>
            Confirmar
        </RoundedButton>
      </div>
    </div>
  );
};
