import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { RoundedButton, LoadingButton } from '../../components';
import MenuResume from './MenuResume';

export default ({
  open,
  isConfirmation = false,
  isEdit = false,
  handleConfirm,
  isConfirmLoading,
  handleClose,
  descriptions,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography color="primary" variant="h4">
          {isConfirmation
            ? `Confirmar ${isEdit ? 'edição' : 'cadastro'}`
            : 'Resumo'}
        </Typography>
      </DialogTitle>
      <MenuResume descriptions={descriptions} />
      <DialogActions>
        <RoundedButton variant="contained" onClick={handleClose}>
          Fechar
        </RoundedButton>
        {isConfirmation && (
          <LoadingButton
            onClick={handleConfirm}
            isLoading={isConfirmLoading}
            variant="contained"
            color="primary"
            autoFocus
          >
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
