import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { RoundedButton } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';
import moment from 'moment';
import ClassesModal from '../../components/ClassesModal';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '700px',
    overflowX: 'hidden',
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    padding: '10px 15px 10px 25px'
  },
  buttons: {
    marginTop: '4em',
    textAlign: 'right',
  },
  divRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  divLabels: {
    width: '25%'
  },
  divInfos: {
    width: '65%',
    display: 'flex',
    alignItems: 'center',
  },
  fieldStyle: {
    padding: '10.5px 14px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '5px',
  },
  fieldL: {
    width: '450px',
  },
  fieldM: {
    width: '235px',
  },
  fieldS: {
    width: '150px',
  },
  fieldSS: {
    width: '70px',
  },
  fieldMessage: {
    width: '450px',
    height: '100px',
    overflow: 'auto',
  },
  divInfoMessage: {
    width: '65%',
  },
  divRowClose: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}));

export default ({
  handleClose = () => {},
  open = false,
  idEvent
}) => {
  const classes = useStyles();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenClassesModal, setIsOpenClassesModal] = useState(false);
  const [eventDescription, setEventDescription] = useState("");
  const [eventDate, setEventDate] = useState("Não utiliza");
  const [eventStart, setEventStart] = useState("-");
  const [eventFinish, setEventFinish] = useState("-");
  const [dateLimitAut, setDateLimitAut] = useState("Não utiliza");
  const [hourLimitAut, setHourLimitAut] = useState("-");
  const [dateLimitCancel, setDateLimitCancel] = useState("Não utiliza");
  const [hourLimitCancel, setHourLimitCancel] = useState("-");
  const [eventValue, setEventValue] = useState("Não utiliza");
  const [dateSendMessage, setDateSendMessage] = useState("Não utiliza");
  const [hourSendMessage, setHourSendMessage] = useState("-");
  const [sectorName, setSectorName] = useState("");
  const [numeberOfClasses, setNumberOfClasses] = useState(0);
  const [turmas, setTurmas] = useState([]);
  const [message, setMessage] = useState("");
  const [allowAnswer, setAllowAnswer] = useState("");
 
  useEffect(() => {
    if (open) {
      requestEventDetails();
    }
  }, [open]);

  const requestEventDetails = async () => {
    setIsLoading(true);
    const params = {
      idEvento: idEvent
    };

    api
      .get('chat/getEventDetails', {
        params,
      })
      .then((response) => {
        handleMountInfos(response.data);        
      })
      .catch(() => handleErrorEventDetail(null));
  };
  
  const handleErrorEventDetail = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: (message ?? 'Ocorreu um problema inesperado!'),
    });
  };

  const handleMountInfos = (infos) => {
    setTurmas(infos.TurmasVinculadas);
    setEventDescription(infos.Evento.Descricao);
    if (infos.Evento.UsaData) {      
      setEventDate(moment(infos.Evento.DataEvento).format('DD/MM/YYYY'));
      if (!infos.Evento.DiaInteiro) {
        setEventStart(moment(infos.Evento.HoraInicio).format('HH:mm'));
        setEventFinish(moment(infos.Evento.HoraFinal).format('HH:mm'));
      }
    }
    if (infos.Evento.UsaDataLimiteAut) {
      setDateLimitAut(moment(infos.Evento.DataLimiteAut).format('DD/MM/YYYY'));
      setHourLimitAut(moment(infos.Evento.DataLimiteAut).format('HH:mm'));
    }
    if (infos.Evento.DataLimiteCancela != null) {
      setDateLimitCancel(moment(infos.Evento.DataLimiteCancela).format('DD/MM/YYYY'));
      setHourLimitCancel(moment(infos.Evento.DataLimiteCancela).format('HH:mm'));
    }
    if (infos.Evento.GeraCobranca) { 
      setEventValue(infos.Evento.Valor);
    }
    if (infos.DetalhesChatAut.DataAgendamentoEnvio != null) {
      setDateSendMessage(moment(infos.DetalhesChatAut.DataAgendamentoEnvio).format('DD/MM/YYYY'));
      setHourSendMessage(moment(infos.DetalhesChatAut.DataAgendamentoEnvio).format('HH:mm'));
    }
    setSectorName(infos.DetalhesChatAut.Setor);
    setNumberOfClasses(infos.TurmasVinculadas.length);
    setMessage(infos.DetalhesChatAut.PrimeiraMensagem);
    if (infos.DetalhesChatAut.PermiteRespostas) {
      setAllowAnswer("Será permitido respostas.")
    }
    setIsLoading(false);
  };

  return (
    <Dialog 
      maxWidth={false}
      onClose={handleClose} 
      aria-labelledby="customized-dialog-title" 
      open={open}
    >
      <div className={classes.container} hidden={isLoading}>
        <DialogActions className={classes.headerMain}>
          <Typography 
            component={'span'} 
            color="primary"
            style={{ marginRight: 'auto' }}
          >
            <strong>Detalhes do Evento</strong>
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogActions>
        <DialogContent
          style={{ padding: '5px 25px 25px 25px' }}
        >
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Descrição evento</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div className={[classes.fieldL, classes.fieldStyle].join(" ")}>
                <span>{eventDescription}</span>
              </div>
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Data/hora</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div style={{marginRight: '1em'}} className={[classes.fieldS, classes.fieldStyle].join(" ")}>
                <span>{eventDate}</span>
              </div>              
              <div style={{marginRight: '0.5em'}} className={[classes.fieldSS, classes.fieldStyle].join(" ")}>
                <span>{eventStart}</span>
              </div>
              <span> - </span>
              <div style={{marginLeft: '0.5em'}} className={[classes.fieldSS, classes.fieldStyle].join(" ")}>
                <span>{eventFinish}</span>
              </div>
            </div>
          </div>          
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Data limite para autorizar</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div style={{marginRight: '1em'}} className={[classes.fieldS, classes.fieldStyle].join(" ")}>
                <span>{dateLimitAut}</span>
              </div> 
              <div style={{marginRight: '0.5em'}} className={[classes.fieldSS, classes.fieldStyle].join(" ")}>
                <span>{hourLimitAut}</span>
              </div>
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Data limite para cancelar</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div style={{marginRight: '1em'}} className={[classes.fieldS, classes.fieldStyle].join(" ")}>
                <span>{dateLimitCancel}</span>
              </div> 
              <div style={{marginRight: '0.5em'}} className={[classes.fieldSS, classes.fieldStyle].join(" ")}>
                <span>{hourLimitCancel}</span>
              </div>
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Valor</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div className={[classes.fieldM, classes.fieldStyle].join(" ")}>
                <span>{eventValue}</span>
              </div>               
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Envio agendado</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div style={{marginRight: '1em'}} className={[classes.fieldS, classes.fieldStyle].join(" ")}>
                <span>{dateSendMessage}</span>
              </div> 
              <div className={[classes.fieldSS, classes.fieldStyle].join(" ")}>
                <span>{hourSendMessage}</span>
              </div>
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Enviar como</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div className={[classes.fieldM, classes.fieldStyle].join(" ")}>
                <span>{sectorName}</span>
              </div>
            </div>
          </div>
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Para</span></Typography>
            </div>
            <div className={classes.divInfos}>
              <div className={[classes.fieldS, classes.fieldStyle].join(" ")}>
                <span>{numeberOfClasses}{numeberOfClasses == 1 ? " Turma" : " Turmas"}</span>
              </div>
              <RoundedButton
                  color="primary"
                  variant="outlined"
                  size='small'
                  style={{marginLeft: '1em'}}
                  onClick={() => setIsOpenClassesModal(true)}
                >
                  Ver turmas
                </RoundedButton>
            </div>
          </div> 
          <div className={classes.divRow}>
            <div className={classes.divLabels}>
              <Typography color="primary"><span>Mensagem</span></Typography>
            </div>
            <div className={classes.divInfoMessage}>
              <div className={[classes.fieldMessage, classes.fieldStyle].join(" ")}>
                <span>{message}</span>
              </div>
              <div style={{marginTop: '0.5em'}}><span>{allowAnswer}</span></div>
            </div>
          </div> 
          <div className={classes.divRowClose}>
            <RoundedButton
              color="primary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Fechar
            </RoundedButton>
          </div>        
        </DialogContent>
      </div>
      <ClassesModal
        open={isOpenClassesModal}
        handleClose={() => setIsOpenClassesModal(false)}
        turmas={turmas ?? []}
      />
    </Dialog>
  );
};
