import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  MenuItem,
  Badge,
  Typography,
  IconButton,
  Divider,
  Menu,
  ListItemIcon,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done, ExpandMore, ChevronRight, Person, Chat, GetApp, Send, Message, PictureAsPdf, ListAlt } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import moment from 'moment';
import {
  RoundedButton,
  Container,
  TableRowFocusable,
  RoundedInput,
  LoadingButton,
  ConfirmationDialog,
} from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import EventDetailsModal from './EventDetailsModal';
import InitChatAutorizationModal from './InitChatAutorizationModal';
import SendDetailsModal from './SendDetailsModal';
import AcceptDetailsModal from './AcceptDetailsModal';
import { trackPDF, trackExcel } from '../../reports/Chat/chatReport';

const useStyles = makeStyles((theme) => ({
  divContainerDetails: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  divPanelDetails: {
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    width: '30%',
    height: '80px',
    padding: '10px',
    color: '#A1A5A8',
    cursor: 'pointer',
  },
  rowDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '-20px',
  },
  iconSize: {
    width: '35px',
    height: '35px',
    color: theme.palette.primary.main,
  },
  numberDatail: {
    color: '#293136',
    fontSize: '32px',
  },
  tableCell: {
    width: '100%',
  },
  input: {
    margin: '0.8em auto 0.8em 0',
    borderRadius: '5px !important',
    maxWidth: '600px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  freezeCheck: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCellCustom: {
    minWidth: '150px',
    textAlign: 'center',
    width: '100%',
  },
  customButtonDefault: {
    marginLeft: '1em',
    marginTop: '5px',
  },
  listIconCustom: {
    minWidth: '0',
    marginRight: '5px',
  },
  divItems: {
    padding: '0 24px 0 24px',
    width: '100%',
    verticalAlign: 'middle'
  },
  tableCellIcons: {
    minWidth: '180px',
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  customToolbar: {
    margin: '3em 0 0 0',
  },
  customToolbarSub: {
    marginBottom: '1em',
    justifyContent: 'flex-end',
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
    whiteSpace: 'nowrap',
    minWidth: 'auto',
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30px',
  },
  containerLabel: {
    display: 'flex',
    justifyContent: 'center',
  },
  labelMessage: {
    color: '#B9727F',
    backgroundColor: '#FEE2E7',
    borderRadius: '12.2398px',
    border: '1px solid #E3D4D7',
    padding: '3px 8px',
    width: '95px',
  },
  cellVisualization: {
    width: '20%',
  },
  labelNoReply: {
    color: '#9f8e76',
    backgroundColor: '#ede8cd',
    borderRadius: '12.2398px',
    border: '1px solid #a0986e',
    padding: '3px 8px',
    width: '106px',
  },
  labelAutorization: {    
    color: '#43716F',
    backgroundColor: '#CDEDE9',
    borderRadius: '12.2398px',
    border: '1px solid #6EA09E',
    padding: '3px 8px',
    width: '88px',
  },
  labelNotAutorization: {
    color: '#B9727F',
    backgroundColor: '#FEE2E7',
    borderRadius: '12.2398px',
    border: '1px solid #E3D4D7',
    padding: '3px 8px',
    width: '88px',
  },
  skeletonSize: {
    '& .MuiSkeleton-text': {
      height: '50px',
      width: '50px',
    }
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const { idEvento } = useParams();

  const availableStatus = [
    {
      id: 1,
      description: 'Todos',
      parameter: null,
    },
    {
      id: 2,
      description: 'Aguardando Finalização',
      parameter: {
        waitClose: true,
      },
    },
    {
      id: 3,
      description: 'Não lido',
      parameter: {
        notRead: true,
      },
    },
  ];

  const availableSituations = [
    {
      id: 1,
      description: 'Mais recente',
      parameter: {
        order: 1,
      },
    },
    {
      id: 2,
      description: 'Ordem alfabética',
      parameter: {
        order: 2,
      },
    },
  ];

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [total, setTotal] = useState(0);

  const [sectors, setSectors] = useState([]);
  const [sectorId, setSectorId] = useState(null);

  const [filterStatus, setFilterStatus] = useState(availableStatus[0].parameter);
  const [filterSituation, setFilterSituation] = useState(availableSituations[0].parameter);
  const [text, setText] = useState('');
  const [situation, setSituation] = useState(availableSituations[0].id);
  const [status, setStatus] = useState(availableStatus[0].id);
  const [anchorElStatus, setAnchorElStatus] = useState(null);
  const [anchorElStatusSub, setAnchorElStatusSub] = useState(null);
  const [anchorElSituations, setAnchorElSituations] = useState(null);
  const [filter, setFilter] = useState({idEvento: idEvento});
  const [openInitAutorizationChat, setOpenInitAutorizationChat] = useState(false);
  const [recipientNumber, setRecipientNumber] = useState(0);
  const [percentageAccept, setPercentageAccept] = useState(0);
  const [numberNotViewed, setNumberNotViewed] = useState(0);
  const [numberViewed, setNumberViewed] = useState(0);
  const [numberNewMessages, setNumberNewMessages] = useState(0);
  const [autorizedNumber, setAutorizedNumber] = useState(0);
  const [notAutorizedNumber, setNotAutorizedNumber] = useState(0);
  const [noReplyNumber, setNoReplyNumber] = useState(0);
  const [sendComplete, setSendComplete] = useState(false);
  const [loadStatistics, setLoadStatistics] = useState(false);
  const [searchedStatistic, setSearchedStatistic] = useState(false);
  const [openEventDetailsModal, setOpenEventDetailsModal] = useState(false);
  const [openSendDetailsModal, setOpenSendDetailsModal] = useState(false);
  const [openAcceptDetailsModal, setOpenAcceptDetailsModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [anchorElOptionsReport, setAnchorElOptionsReport] = useState(null);
  const [openMsgLoteDialog, setOpenMsgLoteDialog] = useState(false);

  const availableOptionsReport = [
    {
      id: 1,
      description: 'PDF'
    },
    {
      id: 2,
      description: 'XLS'
    },
  ];

  const typeFiles = {
    PDF: 0,
    XLSX: 1,
  };

  const handleSearch = (message) => {
    if (message === text)
      return;

    setPage(1);
    setText(message);
    setFilter({
      ...filterSituation,
      ...filterStatus,
      name: message,
      idEvento: idEvento,
    });
  };

  const handleSendEnterPress = (text, e) => {
    if (e.key === 'Enter')
      handleSearch(text);
  };

  const handleCloseStatus = (id) => {
    setAnchorElStatus(null);
    setAnchorElStatusSub(null);
    
    if (id) {
      const filterTemp = availableStatus.find(x => x.id === id);

      setPage(1);
      setStatus(id);
      setSectorId(null);
      setFilterStatus(filterTemp.parameter);
      setFilter({
        ...filterSituation,
        ...filterTemp.parameter,
        idEvento: idEvento,        
      });
    };
  };

  const handleOpenStatusSub = (event) => {
    if (anchorElStatus)
      setAnchorElStatusSub(event.currentTarget);
  };

  const handleCloseStatusSub = (id) => {
    setAnchorElStatusSub(null);

    if (id) {
      setPage(1);
      setAnchorElStatus(null);
      setStatus(null);
      setSectorId(id);
      setFilterStatus({ idSetor: id });
      setFilter({
        ...filterSituation,
        idSector: id,
        idEvento: idEvento,
      });
    };
  };

  const handleCloseSituation = (id) => {
    setAnchorElSituations(null);

    if (id) {
      const filterTemp = availableSituations.find(x => x.id === id);
      
      setPage(1);
      setSituation(id);
      setFilterSituation(filterTemp.parameter);
      setFilter({
        ...filterStatus,
        ...filterTemp.parameter,
        idEvento: idEvento,
      });
    };
  };

  const handleOpenStatus = (event) => setAnchorElStatus(event.currentTarget);
  const handleOpenSituation = (event) => setAnchorElSituations(event.currentTarget);

  const handleChangePage = (event, nextPage) => requestChat(nextPage + 1);
  const handleChatMessage = (idChat) => history.push(`/message/chatSector/${idChat}`);
  const handleChatInBatch = () => {
    if (sendComplete) {
      history.push(`/message/chatAutInBatch/${idEvento}`);
    } else {
      setLoadStatistics(false);
      setOpenMsgLoteDialog(true);
      requestStatistic();
    }
  } 

  const handleViewLastMessage = (message, sendDate) => {
    if (message == null)
      return '';

    const maxLength = 35;

    const formatedMessage = message.length > maxLength ?
                            message.substring(0, maxLength).trimEnd() + '...' :
                            message;

    return `${formatedMessage} - ${moment(sendDate).format('DD/MM/YYYY HH:mm')}`;
  };

  const handleErrorChat = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: (message ?? 'Ocorreu um problema inesperado!'),
    });
    
    setChats([]);
    setPage(1);
    setTotal(0);
  };

  useEffect(() => {
    requestChat(page);
    if (!searchedStatistic) {
      setSearchedStatistic(true);
      requestStatistic();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    api
      .get('/sector/getSectorsByUser')
      .then((response) => {
        if (!response.data.Sucesso)
          setAlert({
            isOpen: true,
            type: 'warning',
            message: response.data.Mensagem,
          });
        else {
          setSectors([...response.data.Dados]);
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um problema inesperado ao buscar setores de usuário!',
        });
      });
    // eslint-disable-next-line
  }, []);

  const requestChat = async (pag) => {
    setIsLoading(true);

    const params = {
      page: pag, 
      limit: 10,
      ...filter,
    };

    api
      .get('chat/getChatAutorization', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso)
          handleErrorChat(response.data.Mensagem);
        else {
          setTotal(response.data.Dados.Total);
          setPage(response.data.Dados.Page);
          setChats([...response.data.Dados.Data]);
          setIsLoading(false);
        }
      })
      .catch(() => handleErrorChat(null));
  };

  const requestStatistic = async () => {
    const params = {
      idEvento: idEvento
    };

    api
      .get('chat/getStatisticAutorization', {
        params,
      })
      .then((response) => {
        setRecipientNumber(response.data.QtdDestinatarios);
        setNumberNotViewed(response.data.QtdNaoVisualizados);
        setNumberViewed(response.data.QtdVisualizados);
        setNumberNewMessages(response.data.NovasMensagens);
        setPercentageAccept(response.data.PercentualDeAceite);
        setAutorizedNumber(response.data.QtdAceitou)
        setNotAutorizedNumber(response.data.QtdNaoAceitou)
        setNoReplyNumber(response.data.QtdSemResposta);
        setSendComplete(response.data.EnvioMsgAutorizacaoConcluido);
        setLoadStatistics(true);
      })
      .catch(() => handleErrorChat(null));
  };

  const handleOpenOptionReport = (event) => setAnchorElOptionsReport(event.currentTarget);

  const handleCloseOptionReport = (id) => {
    setAnchorElOptionsReport(null);

    if (id == 1) {
      handleDocumentDownload(typeFiles.PDF);
    } else {
      handleDocumentDownload(typeFiles.XLSX);
    }
  };

  const handleDocumentDownload = async (typeFile) => {    
    setIsDownloading(true);

    const dataReport = await handleGetReport(); 
    
    const saidasFormatada = dataReport.map(d => ({
      DescricaoEvento: d.DescricaoEvento,
      NomeAluno: d.NomeAluno,
      DataEnvioMensagemPrincipal: moment(d.DataEnvioMensagemPrincipal).format('DD/MM/YYYY - HH:mm'),
      NomeResponsavel: d.NomeResponsavel,
      DataVisualizacao: d.DataVisualizacao == null ? "Não visualizado" : moment(d.DataVisualizacao).format('DD/MM/YYYY - HH:mm'),
      Autorizado: d.Autorizado == null ? "Sem resposta" : d.Autorizado ? "Sim" : "Não",
      DataAutorizacao: d.Autorizado != null ? moment(d.DataAutorizacao).format('DD/MM/YYYY - HH:mm') : "-",
      AutorizadoPor: d.AutorizadoPor != null ? d.AutorizadoPor : "-" 
    }));
    
    if (saidasFormatada.length > 0) {
      switch (typeFile) {
        case typeFiles.PDF:
          trackPDF(saidasFormatada);
          break;
        default:
          trackExcel(saidasFormatada);
      }
    }

    setIsDownloading(false);
  };

  const handleGetReport = async () => {
    const params = {
      idEvento
    };

    return api
      .get('chat/getReport', { params })
      .then((response) => {
        const data = response.data;        
        if (data.Sucesso) {
          return [...data.Dados];
        }
        else
          return [];
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Falha ao obter relatório!',
        });
        return [];
      });
  };
  
  return (
    <DefaultPage title="Mensagens dos setores" >
      <Container>
        <Toolbar>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            RELATÓRIO DO EVENTO
          </Typography>
          <LoadingButton
              color="primary"              
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleOpenOptionReport}
              style={{ marginLeft: 'auto' }}
              endIcon={<GetApp />}
              isLoading={isDownloading}
            >
              Baixar relatório
            </LoadingButton>
            <Menu
              id="simple-menu-situation"
              anchorEl={anchorElOptionsReport}
              keepMounted
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElOptionsReport)}
              onClose={() => setAnchorElOptionsReport(null)}
            >
              {availableOptionsReport.map((s) => (
                <MenuItem key={s.id} onClick={() => handleCloseOptionReport(s.id)}>
                  <span className={classes.containerIcon}>
                    {s.description == 'PDF' ? 
                    <PictureAsPdf color="primary"/> :
                    <ListAlt color="primary"/>}
                  </span>
                  <Typography variant="inherit">
                    {s.description}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
        </Toolbar>
        <div className={classes.divContainerDetails}>
          <div className={classes.divPanelDetails} onClick={() => setOpenSendDetailsModal(true)}>
            <div className={classes.rowDetails}>
              <span>DETALHES DO ENVIO</span>
              <Send className={classes.iconSize}></Send>
            </div>
            <div hidden={!loadStatistics}>
              <span className={classes.numberDatail}>{recipientNumber}</span>
            </div>
            <div className={classes.skeletonSize} hidden={loadStatistics}>
              <Skeleton animation="wave" />
            </div>
          </div>
          <div className={classes.divPanelDetails} onClick={() => setOpenAcceptDetailsModal(true)}>
            <div className={classes.rowDetails}>
              <span>DETALHES DO ACEITE</span>
              <Person className={classes.iconSize}></Person>
            </div>
            <div hidden={!loadStatistics}>
              <span className={classes.numberDatail}>{percentageAccept}%</span>
            </div>
            <div className={classes.skeletonSize} hidden={loadStatistics}>
              <Skeleton animation="wave" />
            </div>
          </div>
          <div style={{cursor: 'default'}} className={classes.divPanelDetails}>
            <div className={classes.rowDetails}>
              <span>NOVAS MENSAGENS</span>
              <Message className={classes.iconSize}></Message>
            </div> 
            <div hidden={!loadStatistics}>
              <span className={classes.numberDatail}>{numberNewMessages}</span>
            </div>
            <div className={classes.skeletonSize} hidden={loadStatistics}>
              <Skeleton animation="wave" />
            </div>
          </div>
        </div>
        <Toolbar className={classes.customToolbar}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            GERENCIAR DE MENSAGENS
          </Typography>
        </Toolbar>
        <Toolbar className={classes.customToolbarSub}>
          <RoundedInput
            variant="outlined"
            placeholder="Buscar"
            size="small"
            fullWidth
            className={classes.input}
            onBlur={(e) => handleSearch(e.target.value)}
            onKeyPress={(e) => handleSendEnterPress(e.target.value, e)}
          />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="outlined"
            onClick={() => setOpenEventDetailsModal(true)}
          >
            Detalhes do evento
          </RoundedButton>
          <RoundedButton
            className={classes.registerButton}
            disabled={!loadStatistics}
            color="primary"
            variant="contained"
            onClick={handleChatInBatch}
          >
            Mensagens em lote
          </RoundedButton>          
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>MONITORAMENTO</TableCell>
                <TableCell style={{textAlign: 'center'}}>STATUS</TableCell>
                <TableCell className={classes.tableCellCustom}>NÃO LIDO</TableCell>
                <TableCell>
                  <RoundedButton
                    endIcon={<ExpandMore />}
                    className={classes.customButtonDefault}
                    aria-controls="simple-menu-status" 
                    aria-haspopup="true" 
                    onClick={handleOpenStatus}
                  >
                    Filtrar
                  </RoundedButton>
                  <Menu
                    id="simple-menu-status"
                    anchorEl={anchorElStatus}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElStatus)}
                    onClose={() => handleCloseStatus(null)}
                  >
                    {availableStatus.map((a) => (
                      <MenuItem key={a.id} onClick={() => handleCloseStatus(a.id)}>
                        <span className={classes.containerIcon}>
                          {a.id === status &&
                            <ListItemIcon className={classes.listIconCustom}>
                              <Done fontSize="small" />
                            </ListItemIcon>
                          }
                        </span>
                        <Typography variant="inherit">
                          {a.description}
                        </Typography>
                      </MenuItem>
                    ))}
                  <Divider />
                    <MenuItem 
                      aria-controls="simple-menu-substatus" 
                      aria-haspopup="true"
                      onClick={handleOpenStatusSub}
                      onMouseEnter={handleOpenStatusSub}
                    >
                      <span style={{ minWidth: '30px' }} />
                      <Typography variant="inherit">Por setor</Typography>
                      <ListItemIcon style={{ marginLeft: 'auto' }}>
                        <ChevronRight style={{ marginLeft: 'auto' }} fontSize="small" />
                      </ListItemIcon>
                    </MenuItem>
                    <Menu
                      id="simple-menu-substatus"
                      anchorEl={anchorElStatusSub}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElStatusSub)}
                      onClose={() => handleCloseStatusSub(null)}
                    >
                      {sectors?.map((s) => (
                        <MenuItem key={s.Id} onClick={() => handleCloseStatusSub(s.Id)}>
                          <span className={classes.containerIcon}>
                            {s.Id === sectorId &&
                              <ListItemIcon className={classes.listIconCustom}>
                                <Done fontSize="small" />
                              </ListItemIcon>
                            }
                          </span>
                          <Typography variant="inherit">
                            {s.Nome}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Menu>
                </TableCell>                
                <TableCell className={classes.tableCellIcons}>
                  <RoundedButton
                    endIcon={<ExpandMore />}
                    className={classes.customButtonDefault}
                    aria-controls="simple-menu-situation" 
                    aria-haspopup="true" 
                    onClick={handleOpenSituation}
                  >
                    Ordenar por
                  </RoundedButton>
                  <Menu
                    id="simple-menu-situation"
                    anchorEl={anchorElSituations}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElSituations)}
                    onClose={() => handleCloseSituation(null)}
                  >
                    {availableSituations.map((s) => (
                      <MenuItem key={s.id} onClick={() => handleCloseSituation(s.id)}>
                        <span className={classes.containerIcon}>
                          {s.id === situation &&
                            <ListItemIcon className={classes.listIconCustom}>
                              <Done fontSize="small" />
                            </ListItemIcon>
                          }
                        </span>
                        <Typography variant="inherit">
                          {s.description}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10).fill().map((e, i) => i).map((e) => (
                    <TableRowFocusable key={e} onClick={() => {}} hover>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRowFocusable>
                  ))
                : chats?.map((chat) => (
                    <TableRowFocusable key={chat.IdChatSetor} hover>
                      <TableCell 
                        className={classes.tableCell}
                        style={{ minWidth: '350px' }}
                        onClick={() => handleChatMessage(chat.IdChatSetor)}
                      >
                        <ListItem>
                          <ListItemAvatar>                          
                            <Avatar>
                              {chat.NomeUsuarioCriador[0]?.toUpperCase()}
                            </Avatar>                          
                          </ListItemAvatar>
                          <ListItemText 
                            primary={
                              <span className={classes.centerItems}>
                                {chat.NomeUsuarioCriador}                 
                                <Badge 
                                  badgeContent={chat.QtdMensagensNaoLidas}
                                  style={{ marginLeft: "18px" }}
                                  max={999} 
                                  color="primary" 
                                /> 
                              </span>
                            } 
                            secondary={handleViewLastMessage(chat.UltimaMensagem, chat.UltimaMensagemDataEnvio)} 
                          />
                        </ListItem>
                      </TableCell>
                      <TableCell className={classes.cellVisualization}>
                        {chat.Autorizado == null && (
                          <div className={classes.containerLabel}>
                            <div className={classes.labelNoReply}>Sem resposta</div>
                          </div>
                        ) || chat.Autorizado == true && (
                          <div className={classes.containerLabel}>
                            <div className={classes.labelAutorization}>Autorizado</div>
                          </div>
                        ) || chat.Autorizado == false && (
                          <div className={classes.containerLabel}>
                            <div className={classes.labelNotAutorization}>Não autorizado</div>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellCustom}>
                        <Checkbox 
                          checked={chat.QtdMensagensNaoLidas > 0}
                          disableRipple
                          className={classes.freezeCheck}
                          color="primary" 
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell className={classes.tableCellIcons}>
                        <IconButton onClick={() => handleChatMessage(chat.IdChatSetor)}>
                          <Chat color="primary"/>
                        </IconButton>
                      </TableCell>                      
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>

      <EventDetailsModal
        open={openEventDetailsModal}
        idEvent={idEvento}
        handleClose={() => setOpenEventDetailsModal(false)}
      />
      <SendDetailsModal
        open={openSendDetailsModal}
        handleClose={() => setOpenSendDetailsModal(false)}
        numberViewed={numberViewed}
        numberNotViewed={numberNotViewed}
        recipientNumber={recipientNumber}
        idEvento={idEvento}
      />
      <AcceptDetailsModal
        open={openAcceptDetailsModal}
        handleClose={() => setOpenAcceptDetailsModal(false)}
        idEvento={idEvento}
        autorizedNumber={autorizedNumber}
        notAutorizedNumber={notAutorizedNumber}
        noReplyNumber={noReplyNumber}
      />
      <InitChatAutorizationModal
        open={openInitAutorizationChat}
        handleClose={() => setOpenInitAutorizationChat(false)}
        sectors={sectors ?? []}
      />
      <ConfirmationDialog
        title="Aguarde o término do envio das mensagens de autorização para enviar mensagens em lote. Tente novamente em alguns instantes."
        open={openMsgLoteDialog}
        handleConfirm={() => setOpenMsgLoteDialog(false)}
        handleClose={() => setOpenMsgLoteDialog(false)}
      />
    </DefaultPage>
  );
};