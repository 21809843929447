import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  time: {
    fontWeight: 'bold',
  },
}));

export default () => {
  const classes = useStyles();
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedTime = date.toLocaleTimeString();
  const formattedDate = date.toLocaleDateString();
  return (
    <div className={classes.root}>
      <Typography className={classes.time} color="primary">
        {formattedTime}
      </Typography>
      <Typography color="primary">{formattedDate}</Typography>
    </div>
  );
};
