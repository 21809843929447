import React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Checkbox,
} from '@material-ui/core';
import { RoundedButton } from '../../../../components';

const RespText = ({ value }) => {
  if (!value || value.length === 0) return <></>;

  const initTag = '#INICIONOME#';
  const endTag = '#FIMNOME#';

  const name = value.substring(
    value.indexOf(initTag) + initTag.length,
    value.indexOf(endTag)
  );

  const init = value.substring(0, value.indexOf(initTag));
  const end = value.substring(value.indexOf(endTag) + endTag.length);
  return (
    <>
      {init} <strong>{name}</strong>
      {end}
    </>
  );
};

const GenerateRespText = ({ value }) => {
  if (!value || value.length === 0) return <></>;

  const splitResp = value.split('#FIMLINHA#').filter((x) => x && x.length > 0);

  return splitResp?.map((x, i) => (
    <React.Fragment key={i}>
      <RespText value={x} />
      {splitResp.length - 1 > i && <br />}
    </React.Fragment>
  ));
};

const FormatSecundaryText = ({ value }) => {
  const papeisTemp = value.Papeis ?? '';
  const responsaveisTemp = value.ResponsavelPor ?? '';

  return (
    <>
      {papeisTemp}
      {papeisTemp?.length > 0 && responsaveisTemp?.length > 0 && <br />}
      {!(value?.PessoasVinculadas?.length > 0) && (
        <GenerateRespText value={responsaveisTemp} />
      )}
    </>
  );
};

export function RecipientItem({ handleSelect, recipient }) {
  return (
    <React.Fragment key={recipient.CodigoPessoa}>
      <ListItem
        button
        style={{
          paddingTop: '4px',
          paddingBottom: '4px',
        }}
        onClick={() => handleSelect(recipient)}
      >
        <ListItemIcon>
          <Checkbox
            checked={recipient.Selecionado}
            disableRipple
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            recipient.NomeSocial && recipient.NomeSocial.length > 0
              ? recipient.NomeSocial
              : recipient.Nome
          }
          secondary={<FormatSecundaryText value={recipient} />}
        />
      </ListItem>
      <Divider />
      {recipient?.PessoasVinculadas?.length > 0 ? (
        <>
          {recipient.PessoasVinculadas.map((pessoa) => (
            <div key={pessoa.CodigoPessoa} style={{ padding: '0.8rem' }}>
              <ListItem
                button
                style={{
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                }}
                onClick={() => handleSelect(recipient, pessoa)}
              >
                <ListItemText
                  primary={pessoa.Nome}
                  secondary={`${
                    pessoa.Turmas
                  } Papeis: ${pessoa.PapeisVinculo.map(
                    (pv) => pv.Descricao
                  ).join(',')}.`}
                />
                <RoundedButton
                  variant={pessoa.Selecionado ? 'contained' : 'text'}
                  color="primary"
                >
                  {pessoa.Selecionado
                    ? 'Estudante Vinculado'
                    : 'Vincular Estudante'}
                </RoundedButton>
              </ListItem>
            </div>
          ))}
          <Divider />
        </>
      ) : null}
    </React.Fragment>
  );
}
