import React from 'react';
import {
  HomeRounded,
  PostAddRounded,
  MyLocationRounded,
  GroupRounded,
  AppsRounded,
  GridOn,
  Assessment,
  AssignmentTurnedIn,
  PieChart,
  Forum,
  ExitToApp,
  FileCopy,
  List,
  ListAlt,
  MenuBook,
  Link,
} from '@material-ui/icons';

const sections = {
  general: {
    name: 'Geral',
  },
  communication: {
    name: 'Comunicação',
  },
  administrative: {
    name: 'Administrativo',
  },
  concierge: {
    name: 'Portaria',
  },
  coordination: {
    name: 'Coordenação',
  },
};

const features = [
  {
    id: 'home',
    name: 'Home',
    path: '/',
    icon: <HomeRounded />,
    section: sections.general.name,
    desktop: false,
    allowedRoles: ['all'],
    allowedPermissions: ['all'],
  },
  {
    id: 'target-audience',
    name: 'Público-alvo',
    path: '/target-audience',
    icon: <MyLocationRounded />,
    section: sections.general.name,
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: [
      'coordination',
      'communication',
      'completeConcierge',
      'admin',
    ],
    menuOptions: {
      exact: false,
    },
  },
  {
    id: 'message-sector',
    name: 'Mensagens dos setores',
    path: '/messageSector',
    icon: <Forum />,
    section: sections.general.name,
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: [
      'coordination',
      'communication',
      'completeConcierge',
      'admin',
      'sectorMessage',
    ],
    menuOptions: {
      exact: false,
    },
  },
  {
    id: 'early-exit',
    name: 'Saídas antecipadas',
    path: '/early-exit',
    icon: <ExitToApp />,
    section: sections.concierge.name,
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['earlyExitConcierge', 'admin'],
    menuOptions: {
      exact: false,
    },
  },
  {
    id: 'news',
    name: 'Notícias',
    newName: 'Mural',
    path: '/news',
    icon: <PostAddRounded />,
    section: sections.communication.name,
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['communication', 'admin', 'comunicationPublish'],
  },
  {
    id: 'applications',
    name: 'Aplicações',
    path: '/applications',
    icon: <AppsRounded />,
    section: sections.administrative.name,
    desktop: true,
    allowedRoles: ['admin', 'support', 'consulting'],
    menuOptions: {
      exact: false,
    },
  },
  {
    id: 'users',
    name: 'Usuários',
    path: '/users',
    icon: <GroupRounded />,
    section: sections.administrative.name,
    desktop: true,
    allowedRoles: ['admin', 'client', 'consulting'],
    allowedPermissions: ['admin'],
  },
  {
    id: 'sectors',
    name: 'Setores',
    path: '/sector',
    icon: <PieChart />,
    section: sections.administrative.name,
    desktop: true,
    allowedRoles: ['admin', 'client', 'consulting'],
    allowedPermissions: ['admin'],
  },
  {
    id: 'helloIArrived',
    name: 'Gerenciar portões',
    path: '/gates',
    icon: <GridOn />,
    section: sections.concierge.name,
    desktop: true,
    allowedRoles: ['admin', 'client', 'consulting'],
    allowedPermissions: ['completeConcierge', 'admin'],
  },
  {
    id: 'report-hello-i-arrived',
    name: 'Relatório',
    path: '/report-hello-i-arrived',
    icon: <Assessment />,
    section: sections.concierge.name,
    desktop: true,
    allowedRoles: ['admin', 'client', 'consulting'],
    allowedPermissions: ['completeConcierge', 'admin'],
  },
  {
    id: 'control-panel-hello-i-arrived',
    name: 'Painel de controle',
    path: '/control-panel-hello-i-arrived',
    icon: <AssignmentTurnedIn />,
    section: sections.concierge.name,
    desktop: true,
    allowedRoles: ['admin', 'client', 'consulting'],
    allowedPermissions: ['concierge', 'completeConcierge', 'admin'],
  },
  {
    id: 'menu',
    name: 'Cardápio',
    path: '/menu',
    icon: <MenuBook />,
    section: sections.coordination.name,
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['coordination', 'admin'],
    menuOptions: {
      exact: false,
    },
  },
  {
    id: 'diary-record',
    name: 'Gerenciar itens',
    path: '/manage-itemsDR',
    icon: <List />,
    section: sections.coordination.name,
    subSection: 'Registro diário',
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['coordination', 'admin'],
  },
  {
    id: 'diary-record',
    name: 'Gerenciar configurações',
    path: '/manage-settingsDR',
    icon: <ListAlt />,
    section: sections.coordination.name,
    subSection: 'Registro diário',
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['coordination', 'admin'],
  },
  {
    id: 'links',
    name: 'Links',
    path: '/links',
    icon: <Link />,
    section: sections.administrative.name,
    desktop: true,
    allowedRoles: ['admin', 'client'],
    allowedPermissions: [],
  },
  {
    id: 'configuration-files',
    name: 'Gerenciar configurações',
    path: '/configuration-files',
    icon: <FileCopy />,
    section: sections.coordination.name,
    subSection: 'Arquivos e circulares',
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['coordination', 'admin'],
  },
  {
    id: 'configuration-messages',
    name: 'Gerenciar configurações de mensagem',
    path: '/configuration-messages',
    icon: <Forum />,
    section: sections.coordination.name,
    subSection: 'Mensagem',
    desktop: true,
    allowedRoles: ['all'],
    allowedPermissions: ['coordination', 'admin'],
  },
];

const groupBySection = (featuresItems) =>
  Object.keys(sections).map((section) => {
    const filteredFeatures = [];
    const subSectonDone = [];

    featuresItems.forEach(function (feature) {
      if (feature.section === sections[section].name) {
        if (feature.subSection) {
          if (!subSectonDone.includes(feature.subSection)) {
            subSectonDone.push(feature.subSection);
            const subSection = {
              subSectionTitle: feature.subSection,
              items: featuresItems.filter(
                (f) =>
                  f.subSection === feature.subSection &&
                  f.section === sections[section].name
              ),
            };
            filteredFeatures.push(subSection);
          }
        } else {
          filteredFeatures.push(feature);
        }
      }
    });
    return {
      title: sections[section].name,
      items: filteredFeatures,
    };
  });

const filteredByRoles = (userRole) =>
  features.filter(
    (feature) =>
      feature.allowedRoles.includes('all') ||
      feature.allowedRoles.includes(userRole)
  );
const verifyPermissions = (
  filteredFeatures,
  userRole,
  userPermissions,
  isAppOwner
) => {
  if ((userRole !== 'client' && userRole !== null) || isAppOwner) {
    return filteredFeatures;
  }
  const verifiedFeatures = filteredFeatures.filter((feature) => {
    if (!feature.allowedPermissions) {
      return false;
    }

    if (!userPermissions.length) {
      return false;
    }

    if (feature.allowedPermissions.includes('all')) {
      return true;
    }

    return feature.allowedPermissions.some((permission) =>
      userPermissions.includes(permission)
    );
  });
  return verifiedFeatures;
};

const getFeatures = (userRole, userPermissions = [], isAppOwner = false) => {
  const filteredFeatures = filteredByRoles(userRole);
  const verifiedFeatures = verifyPermissions(
    filteredFeatures,
    userRole,
    userPermissions,
    isAppOwner
  );
  return verifiedFeatures;
};

export { getFeatures, groupBySection };
