import { useSelector } from 'react-redux';

export default () => {
  const isAppOwner = useSelector((state) => state.profile.isAppOwner);
  const userAppPermissions = useSelector(
    (state) => state.profile?.application?.userAppPermissions
  );

  const roles = userAppPermissions?.filter(
    (permission) => permission.appRole.name === 'admin'
  );

  const isAdmin = roles?.length > 0;

  if (isAppOwner || isAdmin) return true;

  return false;
};
