import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import {
  SearchInput,
  RoundedButton,
  Container,
  TableRowFocusable,
} from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    wordBreak: 'break-word',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const debounce = useDebounce();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [applications, setApplications] = useState([]);

  const handleOpenRegisterPage = () => history.push(`/applications/register`);

  const handleOpenEditPage = (applicationId) =>
    history.push(`/applications/${applicationId}`);

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setApplications([]);
    setPage(1);
    setTotal(0);
  };

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  useEffect(() => {
    const params = {
      page,
    };
    if (queryText) {
      params.query = queryText;
    }

    setIsLoading(true);
    api
      .get('applications', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setApplications(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
    // eslint-disable-next-line
  }, [page, queryText]);

  return (
    <DefaultPage title="Aplicações">
      <Container>
        <Toolbar>
          <SearchInput onChange={debounce(handleChangeQueryText)} />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Nome</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : applications?.map((application) => (
                    <TableRowFocusable
                      key={application.id}
                      onClick={() => handleOpenEditPage(application.id)}
                      hover
                    >
                      <TableCell className={classes.tableCell}>
                        {application.name}
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};
