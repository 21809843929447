import React from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Avatar,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  customDialogContent: {
    margin: '0 1em 4em 1em',
    padding: '0.9em',
  },
  tableCell: {
    padding: '0',
  },
  customDialogAction: {
    margin: '1em 1em 0 1em',
    padding: '0.9em',
  },
  customButton: {
    borderRadius: '5px !important',
  },
  subtitle: {
    fontSize: '1em',
    fontWeight: '500',
  },
}));

export default ({
  handleClose,
  open,
  loading,
  loadingReceived,
  loadingVisualized,
  data,
  received,
  visualized,
  loadMoreReceived,
  loadMoreVisualized,
}) => {
  const classes = useStyles();

  const formatLabelProp = (data) => {
    if (data == null) return '';

    if (data.TipoUsuario === 2) return 'Membro Setor';

    if (data.Papel === 1) return 'Estudante';

    if (data.ResponsavelPor == null || data?.ResponsavelPor.length === 0)
      return 'Responsável';

    const maxLength = 30;
    const messageTemp = `Responsável por: ${data.ResponsavelPor.map(
      (x) => x.Nome
    ).join(', ')}`;

    const formatedMessage =
      messageTemp.length > maxLength
        ? `${messageTemp.substring(0, maxLength).trimEnd()}...`
        : messageTemp;

    return formatedMessage;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogActions className={classes.customDialogAction}>
        {loading ? (
          <Skeleton
            style={{ marginRight: 'auto' }}
            variant="text"
            width="50%"
          />
        ) : (
          <Typography
            color="textSecondary"
            variant="subtitle2"
            style={{ marginRight: 'auto' }}
          >
            <strong>Detalhes de envio</strong> - Mensagem enviada no dia
            {'\u00A0'}
            {moment(data?.DataEnvio).format('DD/MM/YYYY')} às{'\u00A0'}
            {moment(data?.DataEnvio).format('HH:mm')}
          </Typography>
        )}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.customDialogContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography
                        className={classes.subtitle}
                        color="textSecondary"
                      >
                        Recebido por:
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? new Array(5)
                        .fill()
                        .map((e, i) => i)
                        .map((e) => (
                          <TableRow key={e}>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    : received.data?.map((r, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar className={classes.medium} src={r.Foto}>
                                  {r.Nome !== null
                                    ? r.Nome[0]?.toUpperCase()
                                    : ''}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={r.Nome}
                                secondary={formatLabelProp(r)}
                              />
                            </ListItem>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <ListItem>
                              <ListItemText
                                style={{ textAlign: 'right' }}
                                primary={moment(r.DataRecebimento).format(
                                  'HH:mm'
                                )}
                                secondary={moment(r.DataRecebimento).format(
                                  'DD/MM/YYYY'
                                )}
                              />
                            </ListItem>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {(loadMoreReceived || loadingReceived) && (
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                {loadingReceived ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button color="primary" onClick={loadMoreReceived}>
                    Carregar mais
                  </Button>
                )}
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      <Typography
                        className={classes.subtitle}
                        color="textSecondary"
                      >
                        Visualizado por:
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading
                    ? new Array(5)
                        .fill()
                        .map((e, i) => i)
                        .map((e) => (
                          <TableRow key={e}>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    : visualized.data?.map((v, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.tableCell}>
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar className={classes.medium} src={v.Foto}>
                                  {v.Nome !== null
                                    ? v.Nome[0]?.toUpperCase()
                                    : ''}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={v.Nome}
                                secondary={formatLabelProp(v)}
                              />
                            </ListItem>
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            <ListItem>
                              <ListItemText
                                style={{ textAlign: 'right' }}
                                primary={moment(v.DataLeitura).format('HH:mm')}
                                secondary={moment(v.DataLeitura).format(
                                  'DD/MM/YYYY'
                                )}
                              />
                            </ListItem>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
            {(loadMoreVisualized || loadingVisualized) && (
              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                {loadingVisualized ? (
                  <CircularProgress color="primary" />
                ) : (
                  <Button color="primary" onClick={loadMoreVisualized}>
                    Carregar mais
                  </Button>
                )}
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
