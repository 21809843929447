import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Creators as AlertActions } from '../../store/ducks/alert';

import { LoadingButton, RoundedInput, BackToButton } from '../../components';
import FormPage from '../../templates/FormPage';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: '16px',
  },
  input: {
    paddingBottom: '10px',
    width: '95%',
    alignSelf: 'center',
  },
  sendButton: {
    marginTop: '1em',
    alignSelf: 'flex-end',
  },
  instructions: {
    padding: '1em',
  },
  backButton: {
    position: 'absolute',
    top: '15px',
    left: '15px',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const handleForgotError = (err) => {
    setIsLoading(false);
    const dataIsArray = Array.isArray(err.response?.data);

    if (dataIsArray && err.response?.data.find((e) => e.field === 'email')) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Email inválido!',
      });
    } else if (!dataIsArray && err.response?.data?.error?.status === 500) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Ocorreu um problema inesperado!',
      });
    } else {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Problema de conexão!',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post('forgot', { email })
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Email enviado com sucesso!',
        });
      })
      .catch((err) => {
        handleForgotError(err);
      });
  };

  return (
    <FormPage>
      <BackToButton className={classes.backButton} to="/login" />
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.instructions}>
          <Typography variant="h6" color="primary">
            Esqueceu sua senha?
          </Typography>
          <Typography variant="subtitle2">
            Nós o enviaremos um link para alterar sua senha.
          </Typography>
        </div>
        <RoundedInput
          className={classes.input}
          label="Email"
          type="email"
          variant="outlined"
          size="small"
          inputProps={{ minLength: '6' }}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <LoadingButton
          className={classes.sendButton}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isLoading}
        >
          Enviar email
        </LoadingButton>
      </form>
    </FormPage>
  );
};
