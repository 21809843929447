import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TablePagination,
  Divider,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RoundedButton, RoundedInput } from '../../components';

const useStyles = makeStyles((theme) => ({
  customDialogContent: {
    textAlign: 'left',
    margin: '0',
    padding: '20px',
  },
  buttons: {
    textAlign: 'right',
    marginTop: '2em',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  customButton: {
    borderRadius: '5px !important',
  },
  confirmButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));
export default ({
  handleClose = () => {},
  open = false,
  confirm = () => {},
  students = [],
  onChange = () => {},
  onChangePage = () => {},
  onChangeSearch = () => {},
  page = 1,
  perPage = 10,
  total = 0,
  searchInputValue = '',
  loading = false,
}) => {
  const classes = useStyles();

  return (
    <Dialog 
      fullWidth={true}
      maxWidth={'xs'} 
      onClose={handleClose} 
      aria-labelledby="customized-dialog-title" 
      open={open}
    >
      <DialogContent className={classes.customDialogContent}>
        <DialogContentText>
          <Typography component={'span'} variant="h6" color="primary">
            Estudantes
          </Typography>
        </DialogContentText>
        <RoundedInput
          variant="outlined" 
          placeholder="Pesquisar"
          value={searchInputValue}
          onChange={(e) => onChangeSearch(e.target.value)}
          size="small"
          fullWidth
          className={classes.input}
        />     
        <FormControl style={{ width: '100%', marginTop: '10px' }}>
          <FormGroup>
            {students.map((s, index) => (
              <React.Fragment key={index}>
                <FormControlLabel
                  disabled={s.confirmed}
                  key={index}
                  control={<Checkbox color="primary" checked={s.checked} onChange={(e) => onChange(e.target.checked, s.id)} />}
                  label={s.name}
                />
                <Divider />
              </React.Fragment>
            ))}
          </FormGroup>
        </FormControl>  
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={onChangePage}
        />              
        <div className={classes.buttons}>
          <RoundedButton 
            variant="outlined"
            color="primary" 
            className={classes.customButton}
            onClick={handleClose}>
            Cancelar
          </RoundedButton>
          <RoundedButton 
            variant="contained"
            color="primary"
            className={classes.confirmButton}
            onClick={confirm}>
            Confirmar
          </RoundedButton>          
        </div>
        {loading &&
          <LinearProgress style={{ marginTop: '2em' }} color='primary' />
        }
      </DialogContent>
    </Dialog>
  );
};
