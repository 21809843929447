/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ConfigurationFilesForm from './ConfigurationFilesForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { configurationFileId } = useParams();
  const [configurationFileInfo, setConfigurationFileInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get(`configuration-file/info/${configurationFileId}`)
      .then(async (response) => {
        setConfigurationFileInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, []);

  return (
    <DefaultPage title="Edição de configurações">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <ConfigurationFilesForm
              isEdit
              configurationFileInfo={configurationFileInfo}
              configurationFileId={configurationFileId}
            />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
