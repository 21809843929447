import React, { useState, useEffect } from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ExpandMore,
  Add,
  DeleteForever,
  Edit,
  FileCopy,
  Search,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import {
  Container,
  RoundedButton,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';
import ConfirmCloseModal from '../../components/ConfirmCloseModal';

import DefaultPage from '../../templates/DefaultPage';
import InfoSettingDialog from './infoSettingDialog';

const useStyles = makeStyles(() => ({
  containerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  registerButton: {
    padding: 'auto 20px',
  },
  tableCellIdentity: {
    width: '82%',
  },
  tableCellOrder: {
    width: '18%',
    textAlign: 'center',
  },
  tableCellIcons: {
    display: 'flex',
    justifyContent: 'center',
  },
  divCheckBox: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState();
  const [textButtonOrderMenu, setTextButtonOrderMenu] = useState('Ordenar por');
  const [lastTextButtonOrderMenu, setLastTextButtonOrderMenu] = useState(
    'Ordenar por'
  );
  const [settings, setSettings] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderId, setOrderId] = useState(0);
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [settingSelected, setSettingSelected] = useState();
  const [settingId, setSettingId] = useState(0);
  const perPage = 10;

  const optionsOrderMenu = [
    {
      id: 1,
      description: 'Mais recente',
    },
    {
      id: 2,
      description: 'Ordem alfabética',
    },
  ];

  useEffect(() => {
    getSettings();
  }, [page, orderId]);

  const getSettings = () => {
    const params = {
      page,
      limit: perPage,
      order: orderId,
      appRole: ['coordination', 'admin'],
    };

    setIsLoading(true);
    api
      .get('getSettings', { params })
      .then((response) => {
        setTotal(response.data.total);
        setSettings(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setPage(1);
    setTotal(0);
  };

  const handleOpenPageCadSetting = () =>
    history.push(`/manage-settingsDR/register-settingDR`);

  const handleOpenOrderMenu = (e) => {
    setAnchorElMenu(e.currentTarget);
    setTextButtonOrderMenu('Ordenar por');
  };

  const handleCloseOrderMenu = () => {
    setTextButtonOrderMenu(lastTextButtonOrderMenu);
    setAnchorElMenu(null);
  };

  const handleSelectOrderMenu = (opt) => {
    setAnchorElMenu(null);
    setTextButtonOrderMenu(opt.description);
    setLastTextButtonOrderMenu(opt.description);
    setOrderId(opt.id);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  const handleOpenDetailsItem = (setting) => {
    setSettingSelected(setting);
    setOpenDetailsDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleOpenDeleteSetting = (id) => {
    setSettingId(id);
    setOpenConfirmationDialog(true);
  };

  const handleOpenEditSettingPage = (id) =>
    history.push(`/manage-settingsDR/edit/${id}`);

  const handleOpenCloneItem = (id) =>
    history.push(`/manage-settingsDR/clone/${id}`);

  const handleDeleteSetting = () => {
    setOpenConfirmationDialog(false);
    setIsLoading(true);

    api
      .delete(`/deleteSettingById/${settingId}`)
      .then(async () => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Item deletado com sucesso!',
        });
        getSettings();
      })
      .catch((err) => handleQueryError(err));
  };

  return (
    <DefaultPage title="Registro diário">
      <Container>
        <InfoSettingDialog
          setting={settingSelected}
          open={openDetailsDialog}
          title="Detalhes do cadastro"
          onClose={handleCloseDialog}
        />
        <ConfirmCloseModal
          handleClose={() => setOpenConfirmationDialog(false)}
          open={openConfirmationDialog}
          confirm={() => handleDeleteSetting()}
          title="Exclusão de configuração"
          message="Deseja excluir a configuração? Esta ação não poderá ser desfeita."
        />
        <Toolbar>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.6em' }}
            color="primary"
          >
            Gerenciar configurações
          </Typography>
        </Toolbar>
        <Toolbar className={classes.containerToolbar}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            Configurações cadastradas
          </Typography>
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenPageCadSetting}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellIdentity}>
                  IDENTIFICADOR
                </TableCell>
                <TableCell className={classes.tableCellOrder}>
                  <RoundedButton
                    endIcon={<ExpandMore />}
                    aria-controls="simple-menu-situation"
                    aria-haspopup="true"
                    onClick={handleOpenOrderMenu}
                  >
                    {textButtonOrderMenu}
                  </RoundedButton>
                  <Menu
                    anchorEl={anchorElMenu}
                    open={Boolean(anchorElMenu)}
                    onClose={() => handleCloseOrderMenu(null)}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {optionsOrderMenu.map((opt) => (
                      <MenuItem
                        key={opt.id}
                        onClick={() => handleSelectOrderMenu(opt)}
                      >
                        <Typography key={opt.description} variant="inherit">
                          {opt.description}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                new Array(10)
                  .fill()
                  .map((e, i) => i)
                  .map((e) => (
                    <TableRowFocusable key={e} onClick={() => {}} hover>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRowFocusable>
                  ))
              ) : settings?.length === 0 ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" color="textSecondary">
                      Nenhum registro encontrado.
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              ) : (
                settings?.map((set) => (
                  <TableRowFocusable key={set.id} onClick={() => {}} hover>
                    <TableCell className={classes.tableCellIdentity}>
                      <div>{set.identificador}</div>
                    </TableCell>
                    <TableCell className={classes.tableCellOrder}>
                      <div className={classes.tableCellIcons}>
                        <Tooltip title="Detalhes">
                          <span>
                            <Search
                              color="primary"
                              onClick={() => handleOpenDetailsItem(set)}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title="Editar">
                          <span>
                            <Edit
                              color="primary"
                              onClick={() => handleOpenEditSettingPage(set.id)}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title="Clonar">
                          <span>
                            <FileCopy
                              color="primary"
                              onClick={() => handleOpenCloneItem(set.id)}
                            />
                          </span>
                        </Tooltip>
                        <Tooltip title="Deletar">
                          <span>
                            <DeleteForever
                              color="primary"
                              onClick={() => handleOpenDeleteSetting(set.id)}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRowFocusable>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};
