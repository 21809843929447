import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Add,
  DeleteForever,
  Edit,
  FileCopy,
  Search,
  ExpandMore,
  Done,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import {
  RoundedButton,
  Container,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import ViewConfigurationFileModal from './ViewConfigurationFileModal';

const useStyles = makeStyles(() => ({
  registerButton: {
    padding: 'auto 20px',
  },
  tableCell: {
    width: '100%',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  freezeCheck: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCellCustom: {
    minWidth: '150px',
    textAlign: 'center',
    width: '100%',
  },
  customButtonDefault: {
    borderRadius: '5px !important',
    marginLeft: '1em',
    marginTop: '5px',
    minWidth: '130px',
  },
  listIconCustom: {
    minWidth: '0',
    marginRight: '5px',
  },
  tableCellIcons: {
    minWidth: '180px',
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  customToolbar: {
    margin: '3em 0 2em 0',
    justifyContent: 'flex-end',
  },
  titleTop: {
    maxWidth: '700px',
    marginRight: 'auto',
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30px',
  },
}));

export default () => {
  const classes = useStyles();
  const setAlert = useAlert();
  const history = useHistory();

  const perPage = 10;

  const availableSituations = [
    {
      id: 1,
      description: 'Mais recente',
      parameter: {
        order: 1,
      },
    },
    {
      id: 2,
      description: 'Ordem alfabética',
      parameter: {
        order: 2,
      },
    },
  ];

  //@todo xmr ver situações dos filtros
  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [configurationFiles, setConfigurationFiles] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [idConfigurationFileToDelete, setIdConfigurationFileToDelete] = useState(0);
  const [filterSituation, setFilterSituation] = useState(
    availableSituations[0].parameter
  );
  const [situation, setSituation] = useState(availableSituations[0].id);
  const [filter, setFilter] = useState(null);
  const [anchorElSituations, setAnchorElSituations] = useState(null);

  const [isDialogCloneOpen, setIsDialogCloneOpen] = useState(false);
  const [idCloneConfigurationFile, setIdCloneConfigurationFile] = useState(0);
  const [isDialogCloneLoading, setIsDialogCloneLoading] = useState(false);

  const [openConfigurationFile, setOpenConfigurationFile] = useState(false);
  const [loadingConfigurationFile, setLoadingConfigurationFile] = useState(false);
  const [dialogViewConfigurationFile, setDialogViewConfigurationFile] = useState(null);

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setConfigurationFiles([]);
    setPage(1);
    setTotal(0);
  };

  const handleOpenEditPage = (configurationFileId) =>
    history.push(`/configuration-files/${configurationFileId}`);

  const search = (pageP, queryTextP) => {
    const params = {
      page: pageP,
      limit: perPage,
    };
    if (queryText) {
      params.query = queryTextP;
    }

    setIsLoading(true);
    api
      .get('configuration-files', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setConfigurationFiles(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  const doSearch = useCallback(() => {
    search(page, queryText);
    // eslint-disable-next-line
  }, [page, queryText]);


  const viewConfigurationFile = (configurationFileId) => {
    setLoadingConfigurationFile(true);
    setDialogViewConfigurationFile(null);
    api
      .get(`configuration-file/info/${configurationFileId}`)
      .then(async (response) => {
        setDialogViewConfigurationFile(response.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
    setOpenConfigurationFile(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setIdConfigurationFileToDelete(0);
    setIsDeleteLoading(false);
  };

  const handleOpenDeleteDialog = (idConfigurationFile) => {
    setIdConfigurationFileToDelete(idConfigurationFile);
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);

    api
      .delete(`configuration-file/${idConfigurationFileToDelete}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Configuração excluída com sucesso!',
        });
        handleCloseDeleteDialog();
        doSearch();
      })
      .catch(() => {
        handleCloseDeleteDialog();
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Não foi possível excluir a configuração!',
        });
      });
  };

  const handleCloneError = () => {
    setIsDialogCloneLoading(false);
    setIsDialogCloneOpen(false);
    setIdCloneConfigurationFile(0);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível clonar a configuração!',
    });
  };

  const cloneConfigurationFile = (idConfigurationFile) => {
    setIdCloneConfigurationFile(idConfigurationFile);
    setIsDialogCloneOpen(true);
  };

  const closeCloneDialog = () => {
    setIsDialogCloneOpen(false);
    setIdCloneConfigurationFile(0);
  };

  const handleCloneConfigurationFile = () => {
    setIsDialogCloneLoading(true);

    api
      .post(`/configuration-file/clone/${idCloneConfigurationFile}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Configuração clonada com sucesso!',
        });
        setIsDialogCloneOpen(false);
        setIdCloneConfigurationFile(0);
        window.location.reload();
      })
      .catch((err) => {
        handleCloneError(err);
      });
  };

  const handleOpenRegisterPage = () =>
    history.push(`/configuration-files/create`);

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  const handleCloseSituation = (id) => {
    setAnchorElSituations(null);

    if (id) {
      const filterTemp = availableSituations.find((x) => x.id === id);

      setPage(1);
      setSituation(id);
      setFilterSituation(filterTemp.parameter);
      setFilter({
        ...filterTemp.parameter,
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenConfigurationFile(false)
  };

  const handleOpenSituation = (event) =>
    setAnchorElSituations(event.currentTarget);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  useEffect(() => {
    requestConfigurationFiles(page);
  }, [filter]);

  const requestConfigurationFiles = async (pag) => {
    setIsLoading(true);

    const params = {
      page: pag,
      limit: 10,
      ...filter,
    };

    api
      .get('configuration-files', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setConfigurationFiles(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  return (
    <DefaultPage title="Arquivos e circulares">
      <Container>
        <ConfirmationDialog
          title="Excluir esta configuração?"
          open={isDeleteDialogOpen}
          isConfirmLoading={isDeleteLoading}
          handleConfirm={handleDelete}
          handleClose={() => handleCloseDeleteDialog()}
        />
        <ConfirmationDialog
          title="Clonar esta configuração?"
          open={isDialogCloneOpen}
          isConfirmLoading={isDialogCloneLoading}
          handleConfirm={handleCloneConfigurationFile}
          handleClose={() => closeCloneDialog()}
        />
        <ViewConfigurationFileModal
          open={openConfigurationFile}
          dialogConfigurationFile={dialogViewConfigurationFile}
          isLoading={loadingConfigurationFile}
          title="Detalhes do cadastro"
          onClose={handleCloseDialog}
        />
        <Toolbar className={classes.customToolbar}>
          <Typography
            className={classes.titleTop}
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            GERENCIAR CONFIGURAÇÕES
          </Typography>
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  Identificador
                </TableCell>
                <TableCell className={classes.tableCellCustom}>
                  <RoundedButton
                    endIcon={<ExpandMore />}
                    className={classes.customButtonDefault}
                    aria-controls="simple-menu-situation"
                    aria-haspopup="true"
                    onClick={handleOpenSituation}
                  >
                    Ordenar por
                  </RoundedButton>
                  <Menu
                    id="simple-menu-situation"
                    anchorEl={anchorElSituations}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElSituations)}
                    onClose={() => handleCloseSituation(null)}
                  >
                    {availableSituations.map((s) => (
                      <MenuItem
                        key={s.id}
                        onClick={() => handleCloseSituation(s.id)}
                      >
                        <span className={classes.containerIcon}>
                          {s.id === situation && (
                            <ListItemIcon className={classes.listIconCustom}>
                              <Done fontSize="small" />
                            </ListItemIcon>
                          )}
                        </span>
                        <Typography variant="inherit">
                          {s.description}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : configurationFiles?.map((configurationFile) => (
                    <TableRowFocusable
                      key={configurationFile.id}
                      onClick={() => {}}
                      hover
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.title}>
                          {configurationFile.descricao}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.icons}>
                          <Search
                            color="primary"
                            onClick={() =>
                              viewConfigurationFile(configurationFile.id)
                            }
                          />
                          <Edit
                            color="primary"
                            onClick={() =>
                              handleOpenEditPage(configurationFile.id)
                            }
                          />
                          <FileCopy
                            color="primary"
                            onClick={() =>
                              cloneConfigurationFile(configurationFile.id)
                            }
                          />
                          <DeleteForever
                            color="primary"
                            onClick={() =>
                              handleOpenDeleteDialog(configurationFile.id)
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};
