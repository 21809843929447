import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Checkbox,
  MenuItem,
  Badge,
  Typography,
  IconButton,
  Menu,
  Grid,
  List,
  DialogContent,
  Dialog,
  ListItemIcon,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  ExpandMore,
  Person,
  Chat,
  Add,
  AssignmentTurnedInOutlined,
  Close,
  Refresh,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import DefaultPage from '../../templates/DefaultPage';
import {
  RoundedButton,
  Container,
  TableRowFocusable,
  RoundedInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import PerfilModal from './PerfilModal';
import InitChatModal from './InitChatModal';
import InitChatAutorizationModal from './InitChatAutorizationModal';
import RegisterReadButton from './components/RegisterReadButton';
import FilterChat from './components/FilterChat';
import { getAdminOrOwnerRole } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    width: '100%',
  },
  input: {
    backgroundColor: 'white',
    margin: '1em auto 0.8em 0',
    borderRadius: '5px !important',
    maxWidth: '600px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  freezeCheck: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCellCustom: {
    minWidth: '150px',
    textAlign: 'center',
    width: '100%',
  },
  customButtonDefault: {
    marginLeft: '1em',
    marginTop: '5px',
  },
  listIconCustom: {
    minWidth: '0',
    marginRight: '5px',
  },
  divItems: {
    padding: '0 24px 0 24px',
    width: '100%',
    verticalAlign: 'middle',
  },
  tableCellIcons: {
    minWidth: '180px',
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  mobileIcons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '8px',
  },
  customToolbar: {
    justifyContent: 'space-between',
  },
  clearFilterbutton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0px 24px 15px 0px',
  },
  customToolbarSub: {
    marginBottom: '1em',
    flexDirection: 'row',
    flexGrow: '1',
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
  },
  registerButton: {
    [theme.breakpoints.down('sm')]: {
      // css mobile
      opacity: 1,
      zIndex: 1000,
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '55px',
      width: '67%',
      maxWidth: '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      // css desktop
      padding: 'auto 20px',
      marginLeft: '1em',
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      opacity: 1,
    },
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '20px',
  },
  containerLabel: {
    display: 'flex',
    justifyContent: 'center',
  },
  labelAutorization: {
    color: '#43716F',
    backgroundColor: '#CDEDE9',
    borderRadius: '12.2398px',
    border: '1px solid #6EA09E',
    padding: '3px 8px',
    width: '95px',
  },
  labelMessage: {
    color: '#B9727F',
    backgroundColor: '#FEE2E7',
    borderRadius: '12.2398px',
    border: '1px solid #E3D4D7',
    padding: '3px 8px',
    width: '95px',
  },
  labelMessageLote: {
    color: '#B9727F',
    backgroundColor: '#FEE2E7',
    borderRadius: '12.2398px',
    border: '1px solid #E3D4D7',
    padding: '3px 8px',
    width: '120px',
  },
  recipientsNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '40px',
    minHeight: '40px',
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: '14px',
  },
  formatNumberRecip: {
    fontSize: '18px',
    color: '#FFFFFF',
  },
  assunto: {
    [theme.breakpoints.down('sm')]: {
      // css mobile
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      maxWidth: '18em',
      textAlign: 'left',
    },
    [theme.breakpoints.down('md')]: {
      // css desktop
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      maxWidth: '18em',
    },
  },
  borderContainer: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '8px',
  },
  checked: {
    borderColor: 'primary',
  },
  mobileNull: {
    display: 'none',
  },
  mobileTableCell: {
    variant: 'contained',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileCardHeader: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
  },
  mobileCard: {
    textAlign: 'left',
    borderRadius: '8px',
    color: 'gray',
  },
  MobileButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileButton: {
    height: '40%',
    width: '150%',
    margin: theme.spacing(0.5),
  },
  leftAlign: {
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    width: 300,
  },
  filteredText: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px',
    textAlign: 'start',
    color: theme.palette.primary.main,
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdminOrOwner = getAdminOrOwnerRole();

  const availableOptionsMessage = [
    {
      id: 1,
      description: 'Nova conversa',
    },
    {
      id: 2,
      description: 'Nova autorização',
    },
  ];

  const statusMap = {
    false: {
      true: 'Em andamento',
      false: 'Finalizado',
    },
    true: {
      true: 'Aguardando Finalização',
      false: 'Finalizado',
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [total, setTotal] = useState(0);
  const [sectors, setSectors] = useState([]);
  const [text, setText] = useState('');
  const [anchorElSituations, setAnchorElSituations] = useState(null);
  const [anchorElOptionsMessage, setAnchorElOptionsMessage] = useState(null);
  const [perfilData, setPerfilData] = useState(null);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [loadingPerfil, setLoadingPerfil] = useState(false);
  const [isChatDialogOpen, setIsChatDialogOpen] = useState(false);
  const [isOptionsMessageMenuOpen, setIsOptionsMessageMenuOpen] = useState(
    false
  );
  const [isPopUpMessage, setPopUpMessage] = useState(false);
  const [openInitChat, setOpenInitChat] = useState(false);
  const [openInitAutorizationChat, setOpenInitAutorizationChat] = useState(
    false
  );
  const [useLS, setUseLS] = useState(null);
  const [adminMode, setAdminMode] = useState(
    isAdminOrOwner
      ? JSON.parse(localStorage.getItem('admMode')) ?? false
      : false
  );
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem('filterSectorMessage')) ?? {
      waitClose: true,
      inProgress: true,
      order: 1,
      page: 1,
    }
  );
  const application = useSelector((state) => state.profile?.application);
  const [apiVersion, setApiVersion] = useState('');

  const availableSituations = [
    {
      id: 1,
      description: 'Mais recente',
      parameter: {
        order: 1,
      },
    },
    {
      id: 2,
      description: 'Ordem alfabética',
      parameter: {
        order: 2,
      },
    },
  ];

  useEffect(() => {
    if (application != null && apiVersion === '') {
      api
        .get(`apis/version/${application.id}`)
        .then((response) => {
          setApiVersion(response.data);
        })
        .catch(() => {
          setApiVersion('');
        });
    }
  }, [application]);

  useEffect(() => {
    setAdminMode(
      isAdminOrOwner
        ? JSON.parse(localStorage.getItem('admMode')) ?? false
        : false
    );
  }, [isAdminOrOwner]);

  const setLocalStorageFilter = (localStorageFilter) => {
    localStorage.setItem(
      'filterSectorMessage',
      JSON.stringify(localStorageFilter)
    );
  };

  const setLocalStorageAdmMode = (admMode) => {
    localStorage.setItem('admMode', JSON.stringify(admMode));
  };

  const handleSearch = (message) => {
    if (message === text) return;
    setText(message);
    setLocalStorageFilter({ ...filter, name: '', page: 1 });
    setFilter({ ...filter, name: message, page: 1 });
  };

  const handleSendEnterPress = (txt, e) => {
    if (e.key === 'Enter') handleSearch(txt);
  };

  const handleErrorPerfil = (message) => {
    setOpenPerfil(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message:
        message ?? 'Ocorreu um erro inesperado ao carregar perfil de usuário!',
    });

    setLoadingPerfil(false);
    setPerfilData(null);
  };

  const handleOpenPerfil = (idChat) => {
    setLoadingPerfil(true);
    setOpenPerfil(true);

    const params = {
      idChat,
    };

    api
      .get('chat/getChatInfoSector', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleErrorPerfil(response.data.Mensagem);
        else {
          setPerfilData(response.data.Dados);
          setLoadingPerfil(false);
        }
      })
      .catch(() => handleErrorPerfil(null));
  };

  const handleInitChat = () => {
    if (sectors && sectors.length > 0) setOpenInitChat(true);
    else
      setAlert({
        isOpen: true,
        type: 'warning',
        message:
          'É necessário estar vinculado a pelo menos um setor para iniciar uma conversa.',
      });
  };

  const HandleInitAutorization = () => {
    setOpenInitAutorizationChat(true);
  };

  const handleUpdateChatRead = async (value) => {
    setChats(
      chats.map((x) => (x.IdChatSetor === value.IdChatSetor ? value : x))
    );
  };

  const handleCloseOptionMessage = (id) => {
    setAnchorElOptionsMessage(null);
    setIsOptionsMessageMenuOpen(false);

    if (id === 1) {
      handleInitChat();
    } else {
      HandleInitAutorization();
    }
  };

  const handleOpenSituation = (event) =>
    setAnchorElSituations(event.currentTarget);

  const handleCloseSituation = () => setAnchorElSituations(false);

  const handleErrorChat = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });

    setChats([]);
    setTotal(0);
  };

  const requestChat = async (controller) => {
    setIsLoading(true);

    const params = {
      limit: 10,
      ...filter,
      adminMode,
    };

    api
      .get('chat/getChatSectors', {
        params,
        signal: controller?.signal,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleErrorChat(response.data.Mensagem);
        else {
          setTotal(response.data.Dados.Total);
          setChats([...response.data.Dados.Data]);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error?.message === 'canceled') return;
        handleErrorChat(null);
      });
    // eslint-disable-next-line
  };

  const handleChangePage = (event, nextPage) => {
    setLocalStorageFilter({ ...filter, page: nextPage + 1, name: '' });
    setFilter({ ...filter, page: nextPage + 1 });
  };
  const handleChatMessage = (chat) => {
    if (chat.CodigoSaidaEvento != null && chat.AssuntoMsgLote === null) {
      history.push(`/eventReport/${chat.CodigoSaidaEvento}`);
    } else if (chat.AssuntoMsgLote != null) {
      history.push(`/batchReport/${chat.IdAssuntoMsgLote}`);
    } else {
      history.push(`/message/chatSector/${chat.IdChatSetor}`);
    }
  };

  const handleViewLastMessage = (message, sendDate) => {
    if (message == null) return '';

    const maxLength = 35;

    const formatedMessage =
      message.length > maxLength
        ? `${message.substring(0, maxLength).trimEnd()}...`
        : message;

    return `${formatedMessage} - ${moment(sendDate).format(
      'DD/MM/YYYY HH:mm'
    )}`;
  };

  const handleViewLastMessageMobile = (message) => {
    if (message == null) return '';

    const maxLength = 80;

    const formatedMessage =
      message.length > maxLength
        ? `${message.substring(0, maxLength).trimEnd()}...`
        : message;

    return `${formatedMessage}`;
  };

  const handleViewLastMessageHourMobile = (date) => {
    return `${moment(date).format('DD/MM/YYYY HH:mm')}`;
  };

  const handleSituations = (id) => {
    setLocalStorageFilter({ ...filter, order: id, name: '' });
    setFilter({ ...filter, order: id });
    handleCloseSituation();
  };

  useEffect(() => {
    const controller = new AbortController();
    requestChat(controller);
    return () => {
      controller.abort();
    };
  }, [filter, adminMode]);

  const RefreshPageButton = ({ ...rest }) => (
    <RoundedButton
      {...rest}
      color="primary"
      variant="outlined"
      startIcon={<Refresh />}
      onClick={() => setFilter({ ...filter })}
    >
      Atualizar página
    </RoundedButton>
  );

  useEffect(() => {
    const params = {
      adminMode,
    };

    api
      .get('/sector/getSectorsByUser', { params })
      .then((response) => {
        if (!response.data.Sucesso)
          setAlert({
            isOpen: true,
            type: 'warning',
            message: response.data.Mensagem,
          });
        else {
          setSectors([...response.data.Dados]);
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um problema inesperado ao buscar setores de usuário!',
        });
      });

    api
      .get('/chat/getGeneralConfig')
      .then((response) => {
        setUseLS(response.data.UsaLoginService);
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um problema inesperado ao buscar as configurações gerais!',
        });
      });
  }, [adminMode]);

  const handleOpenChatDialog = () => {
    setIsChatDialogOpen(true);
  };

  const handleCloseChatDialog = () => {
    setIsChatDialogOpen(false);
  };

  const handleOpenOptionsMessageMenu = (event) => {
    setAnchorElOptionsMessage(event.currentTarget);
    setIsOptionsMessageMenuOpen(true);
  };

  const handleCloseOptionsMessageMenu = () => {
    setAnchorElOptionsMessage(null);
    setIsOptionsMessageMenuOpen(false);
  };

  const handleOpenPopUpMessage = () => {
    setPopUpMessage(true);
  };

  const handleClosePopUpMessage = () => {
    setPopUpMessage(false);
  };

  const handleOpenOptionMessage = () => {
    if (useLS && isMobile) {
      handleOpenChatDialog();
    } else {
      handleInitChat();
    }
  };

  const handleChangeFilter = (filters) => {
    setFilter(filters);
  };

  const handleChangeAdmMode = (admMode) => {
    setAdminMode(admMode);
    setLocalStorageAdmMode(admMode);
  };

  /* Sub-card de chat */
  const SubCard = ({ header, body }) => {
    return (
      <div>
        <div className={`${classes.mobileCardHeader}`}>{header}</div>
        <div className={`${(classes.leftAlign, classes.mobileCard)}`}>
          {body}
        </div>
      </div>
    );
  };

  /* Card de chat */
  const ChatTableRow = ({ chat }) => {
    function renderAvatarOrRecipientsNumber() {
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return (
          <ListItemAvatar>
            <Avatar>{chat.NomeUsuarioCriador[0]?.toUpperCase()}</Avatar>
          </ListItemAvatar>
        );
      }
      return (
        <div className={classes.recipientsNumber}>
          <span className={classes.formatNumberRecip}>
            {chat.QtdDestinatarios}
          </span>
        </div>
      );
    }

    function renderPrimaryContent() {
      const isEvento = chat.CodigoSaidaEvento != null;
      const isAssuntoMsgLoteNull =
        chat.IdAssuntoMsgLote == null && chat.AssuntoMsgLote === null;
      const displayText = isEvento
        ? chat.DescricaoEvento
        : isAssuntoMsgLoteNull
        ? chat.NomeUsuarioCriador
        : chat.AssuntoMsgLote;
      const mobileDisplayText =
        chat.AssuntoMsgLote === null
          ? chat.NomeUsuarioCriador
          : chat.AssuntoMsgLote;
      const exibeAssunto =
        isAssuntoMsgLoteNull &&
        !isEvento &&
        chat.Assunto &&
        chat.Assunto.length > 0;
      return (
        <>
          {exibeAssunto ? (
            <b className={classes.assunto}>{chat.Assunto}</b>
          ) : null}
          <span className={classes.centerItems}>
            {isMobile ? (
              mobileDisplayText
            ) : isAssuntoMsgLoteNull ? (
              <span className={classes.assunto}>{displayText}</span>
            ) : (
              <b className={classes.assunto}>{displayText}</b>
            )}
            <span>
              <Badge
                badgeContent={chat.QtdMensagensNaoLidas}
                style={{ marginLeft: '18px' }}
                max={999}
                color="primary"
              />
            </span>
          </span>
        </>
      );
    }

    function renderSecondaryContent() {
      return isMobile
        ? handleViewLastMessageMobile(chat.UltimaMensagem)
        : handleViewLastMessage(
            chat.UltimaMensagem,
            chat.UltimaMensagemDataEnvio
          );
    }

    function renderMessageTypeCardContent() {
      if (chat.CodigoSaidaEvento != null) {
        return <div className={classes.mobileTableCell}>Autorização</div>;
      }
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return <div className={classes.mobileTableCell}>Mensagem</div>;
      }
      if (chat.AssuntoMsgLote != null) {
        return <div className={classes.mobileTableCell}>Mensagem lote</div>;
      }
      return <div />;
    }

    function renderSectorCardContent() {
      const sectorName =
        sectors.find((sector) => chat.IdSetor === sector.Id)?.Nome || '';
      if (chat.IdSetor !== null) {
        return <div className={classes.mobileTableCell}>{sectorName}</div>;
      }
      return <div />;
    }

    function renderMessageStatusCardContent() {
      const messageStatus = statusMap[chat.SolicitaEncerramento][chat.Ativo];
      if (chat.SolicitaEncerramento !== null) {
        return <div className={classes.mobileTableCell}>{messageStatus}</div>;
      }
      return <div />;
    }

    function renderMessageTypeLabel() {
      if (chat.CodigoSaidaEvento != null) {
        return (
          <div className={classes.containerLabel}>
            <div>Autorização</div>
          </div>
        );
      }
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return (
          <div className={classes.containerLabel}>
            <div>Mensagem</div>
          </div>
        );
      }
      if (chat.AssuntoMsgLote != null) {
        return (
          <div className={classes.containerLabel}>
            <div>Mensagem lote</div>
          </div>
        );
      }
      return <div />;
    }

    function renderIconButton() {
      return (
        <>
          {!(
            chat.CodigoSaidaEvento != null || chat.IdAssuntoMsgLote != null
          ) ? (
            <IconButton onClick={() => handleOpenPerfil(chat.IdChatSetor)}>
              <Person color="primary" />
            </IconButton>
          ) : null}
        </>
      );
    }

    function renderOpenConversationButton() {
      return (
        (chat.CodigoSaidaEvento === null || chat.AssuntoMsgLote === null) && (
          <RoundedButton
            color="primary"
            variant="contained"
            className={`${classes.mobileButton}`}
            style={{
              height: '35px',
              whiteSpace: 'nowrap',
              width: '150px',
            }}
            onClick={() => handleChatMessage(chat)}
          >
            Abrir conversa
          </RoundedButton>
        )
      );
    }

    function renderMobileCardContent() {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SubCard header="Status" body={renderMessageStatusCardContent()} />
          </Grid>
          <Grid item xs={6}>
            <SubCard header="Setor" body={renderSectorCardContent()} />
          </Grid>
          <Grid item xs={6}>
            <SubCard header="Tipo" body={renderMessageTypeCardContent()} />
          </Grid>
          <Grid item xs={6}>
            <SubCard
              header="Data e hora"
              body={handleViewLastMessageHourMobile(
                chat.UltimaMensagemDataEnvio
              )}
              classes={{ content: classes.leftAlign }}
            />
          </Grid>

          <Grid item xs={12}>
            <div className={classes.MobileButtonContainer}>
              {renderOpenConversationButton()}
            </div>
          </Grid>
        </Grid>
      );
    }

    return (
      <TableRowFocusable key={chat.IdChatSetor} hover>
        <TableCell
          className={` ${
            isMobile ? classes.mobileTableCell : classes.tableCell
          }`}
          style={{ minWidth: '350px' }}
          onClick={() => handleChatMessage(chat)}
        >
          <div
            style={{
              border: isMobile ? '1px solid #ccc' : 'none',
              borderRadius: isMobile ? '10px' : '0',
              boxShadow: isMobile
                ? '0 0 5px 5px rgba(204, 204, 204, 0.5)'
                : 'none',
            }}
          >
            <ListItem
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: isMobile ? 'flex-start' : 'center',
              }}
            >
              {isMobile ? '' : renderAvatarOrRecipientsNumber()}
              <ListItemText
                variant="contained"
                primary={renderPrimaryContent()}
                secondary={renderSecondaryContent()}
              />
              {isMobile &&
                renderMobileCardContent(
                  chat,
                  handleOpenPerfil,
                  handleChatMessage,
                  classes
                )}
            </ListItem>
          </div>
        </TableCell>

        {isMobile ? null : (
          <>
            <TableCell className={classes.tableCellCustom}>
              {sectors
                .filter((sector) => chat.IdSetor === sector.Id)
                .map((filteredSector) => (
                  <span key={filteredSector.id}>{filteredSector.Nome}</span>
                ))}
            </TableCell>

            <TableCell className={classes.tableCellCustom}>
              {chat?.AssuntoMsgLote == null && chat?.UsuarioAtendente?.name}
            </TableCell>
            <TableCell className={classes.tableCellCustom}>
              {renderMessageTypeLabel()}
            </TableCell>
            <TableCell className={classes.tableCellCustom}>
              {statusMap[chat.SolicitaEncerramento][chat.Ativo]}
            </TableCell>
            <TableCell className={`${classes.tableCellIcons} `}>
              {!chat?.AssuntoMsgLote &&
                !chat?.CodigoSaidaEvento &&
                chat?.Ativo &&
                chat?.UltimaMensagem != null && (
                  <RegisterReadButton
                    infoChat={chat}
                    handleUpdate={(value) => handleUpdateChatRead(value)}
                  />
                )}
              {renderIconButton(handleOpenPerfil, chat)}
              <IconButton onClick={() => handleChatMessage(chat)}>
                <Chat color="primary" />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRowFocusable>
    );
  };

  return (
    <DefaultPage title="Mensagens dos setores">
      <Container>
        <Toolbar className={classes.customToolbar}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            GERENCIADOR DE MENSAGENS
          </Typography>

          {isAdminOrOwner && apiVersion >= '4.3.030' && (
            <div style={{ marginLeft: 'auto' }}>
              <>
                <Typography
                  color="primary"
                  display="inline"
                  variant="subtitle2"
                  style={{ marginRight: '1em', fontSize: '1.1em' }}
                >
                  Modo Administrador
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={adminMode}
                      onChange={() => handleChangeAdmMode(!adminMode)}
                    />
                  }
                />
              </>
            </div>
          )}

          {!isMobile && (
            <RefreshPageButton
              style={
                isAdminOrOwner && apiVersion >= '4.3.030'
                  ? { marginLeft: 'none' }
                  : { marginLeft: 'auto' }
              }
            />
          )}

          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            disabled={useLS == null || adminMode}
            onClick={
              useLS && !isMobile
                ? handleOpenOptionsMessageMenu
                : handleOpenOptionMessage
            }
          >
            Nova Conversa
          </RoundedButton>
          <Menu
            id="simple-menu-situation"
            anchorEl={anchorElOptionsMessage}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            keepMounted
            getContentAnchorEl={null}
            open={isOptionsMessageMenuOpen}
            onClose={handleCloseOptionsMessageMenu}
          >
            {availableOptionsMessage.map((s) => (
              <MenuItem
                key={s.id}
                onClick={() => handleCloseOptionMessage(s.id)}
              >
                <span className={classes.containerIcon}>
                  {s.description === 'Nova conversa' ? (
                    <Chat color="primary" />
                  ) : (
                    <AssignmentTurnedInOutlined color="primary" />
                  )}
                </span>
                <Typography variant="inherit">{s.description}</Typography>
              </MenuItem>
            ))}
          </Menu>
          <Dialog open={isChatDialogOpen} onClose={handleCloseChatDialog}>
            <DialogContent style={{ paddingBottom: '20px' }}>
              <List>
                <ListItem
                  button
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => {
                    handleInitChat();
                  }}
                >
                  <ListItemIcon>
                    <Chat color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Nova Conversa" />
                </ListItem>

                <ListItem
                  button
                  style={{ borderBottom: '1px solid #ccc' }}
                  onClick={() => {
                    handleCloseChatDialog();
                    handleOpenPopUpMessage();
                  }}
                >
                  <ListItemIcon>
                    <AssignmentTurnedInOutlined color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Nova Autorização" />
                </ListItem>
              </List>
            </DialogContent>
          </Dialog>
          <Dialog open={isPopUpMessage} onClose={handleClosePopUpMessage}>
            <DialogContent>
              <List
                style={{
                  display: 'flex',
                  alignItem: 'center',
                  paddingBottom: '20px',
                }}
              >
                <div>
                  Abra a aplicação pelo desktop para prosseguir para uma nova
                  autorização!
                </div>

                <IconButton onClick={handleClosePopUpMessage}>
                  <Close />
                </IconButton>
              </List>
            </DialogContent>
          </Dialog>
        </Toolbar>

        {isMobile ? (
          <Toolbar className={classes.customToolbarSub}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
              <RoundedInput
                variant="outlined"
                placeholder="Buscar"
                size="small"
                fullWidth
                className={classes.input}
                onBlur={(e) => handleSearch(e.target.value)}
                onKeyPress={(e) => handleSendEnterPress(e.target.value, e)}
              />
            </div>
            <FilterChat
              filter={filter}
              sectors={sectors}
              handleChangeFilter={handleChangeFilter}
              localStorageName="filterSectorMessage"
              adminMode={adminMode}
            />
          </Toolbar>
        ) : null}

        {isMobile && (
          <div style={{ textAlign: 'center', marginBottom: '.5em' }}>
            <RefreshPageButton />
          </div>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              {isMobile ? null : (
                <TableRow
                  style={{
                    border: '1px gray',
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                  }}
                >
                  <TableCell
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                    }}
                    className={classes.tableCellCustom}
                  >
                    <RoundedInput
                      id="searchImput"
                      variant="outlined"
                      placeholder="Buscar"
                      size="small"
                      fullWidth
                      className={classes.input}
                      style={{ minWidth: '300px' }}
                      onBlur={(e) => handleSearch(e.target.value)}
                      onKeyDown={(e) => handleSendEnterPress(e.target.value, e)}
                    />
                    <FilterChat
                      filter={filter}
                      sectors={sectors}
                      handleChangeFilter={handleChangeFilter}
                      localStorageName="filterSectorMessage"
                      adminMode={adminMode}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Setor
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Atendente
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Tipo
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Status
                  </TableCell>
                  {/* Dropdown Ordenar */}
                  <TableCell className={classes.tableCellIcons}>
                    <RoundedButton
                      endIcon={<ExpandMore />}
                      className={classes.customButtonDefault}
                      aria-controls="simple-menu-situation"
                      aria-haspopup="true"
                      onClick={handleOpenSituation}
                    >
                      Ordenação
                    </RoundedButton>
                    <Menu
                      id="simple-menu-situation"
                      anchorEl={anchorElSituations}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElSituations)}
                      onClose={handleCloseSituation}
                    >
                      {availableSituations.map((s) => (
                        <MenuItem
                          key={s.id}
                          onClick={() => handleSituations(s.id)}
                        >
                          <span className={classes.containerIcon}>
                            <Checkbox
                              edge="start"
                              checked={s.id === filter.order}
                              tabIndex={-1}
                              disableRipple
                              color="primary"
                            />
                          </span>
                          <Typography variant="inherit">
                            {s.description}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            {/* Card de chat */}
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : chats?.map((chat) => <ChatTableRow chat={chat} />)}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={(filter.page ?? 1) - 1}
          onChangePage={handleChangePage}
        />

        <PerfilModal
          handleClose={() => setOpenPerfil(false)}
          open={openPerfil}
          data={perfilData}
          isLoading={loadingPerfil}
        />
      </Container>

      <InitChatModal
        open={openInitChat}
        handleClose={() => setOpenInitChat(false)}
        sectors={sectors ?? []}
      />
      <InitChatAutorizationModal
        open={openInitAutorizationChat}
        handleClose={() => setOpenInitAutorizationChat(false)}
        sectors={sectors ?? []}
      />
    </DefaultPage>
  );
};
