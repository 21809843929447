import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '400px',
    minWidth: '280px',
    margin: '16px',
    borderRadius: '10px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.container}>{children}</Paper>
    </div>
  );
};
