import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory, Link } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import { Creators as UserActions } from '../../store/ducks/user';
import { Creators as AlertActions } from '../../store/ducks/alert';

import { LoadingButton, RoundedInput } from '../../components';
import FormPage from '../../templates/FormPage';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '250px',
    maxWidth: '70%',
    height: '81px',
    margin: '1.5rem 0rem 1rem 0rem',
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: '16px',
  },
  input: {
    paddingBottom: '10px',
    width: '95%',
    alignSelf: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonProgress: {
    transition: 'all 0.3s',
    color: theme.palette.primary.light,
    marginLeft: '4px',
    width: '0px',
  },
}));

const RememberLoginLabel = withStyles(() => ({
  root: {
    paddingLeft: '1rem',
  },
  label: {
    fontSize: '12px',
    opacity: '0.87',
    position: 'relative',
    top: '1px',
  },
}))(FormControlLabel);

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const handleLoginError = (err) => {
    setIsLoading(false);
    const dataIsArray = Array.isArray(err.response?.data);

    if (dataIsArray && err.response?.data.find((e) => e.field === 'login')) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Login não registrado!',
      });
    } else if (
      dataIsArray &&
      err.response?.data.find((e) => e.field === 'password')
    ) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Senha Incorreta!',
      });
    } else if (!dataIsArray && err.response?.data?.error?.status === 500) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Ocorreu um problema inesperado!',
      });
    } else {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Problema de conexão!',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const loginData = { login, password };
    api
      .post('token', loginData)
      .then((res) => {
        dispatch(UserActions.setToken(res.data.token));
        history.push('/');
      })
      .catch((err) => handleLoginError(err));
  };

  return (
    <FormPage>
      <Logo className={classes.logo} />
      <form className={classes.form} onSubmit={handleSubmit}>
        <RoundedInput
          className={classes.input}
          label="Login"
          variant="outlined"
          size="small"
          inputProps={{ minLength: '4' }}
          onChange={(e) => setLogin(e.target.value)}
          required
        />
        <RoundedInput
          className={classes.input}
          label="Senha"
          type="password"
          variant="outlined"
          size="small"
          inputProps={{ minLength: '6' }}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <RememberLoginLabel
          className={classes.rememberLogin}
          control={<Checkbox name="checkedB" color="primary" size="small" />}
          label="Lembrar login"
        />
        <div className={classes.row}>
          <Button color="primary" component={Link} to="/forgot">
            Esqueceu sua senha?
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isLoading}
          >
            Entrar
          </LoadingButton>
        </div>
      </form>
    </FormPage>
  );
};
