import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Header, SideMenu } from '../components';

const drawerWidth = '250px';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  mainContainer: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: drawerWidth,
    },
  },
}));

export default ({ children, title = '' }) => {
  const classes = useStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className={classes.root}>
      <Header title={title} handleDrawerToggle={handleDrawerToggle} />
      <SideMenu
        isMobileMenuOpen={isMobileMenuOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <main className={classes.mainContainer}>{children}</main>
    </div>
  );
};
