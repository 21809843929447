/* eslint-disable radix */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, MenuItem, Checkbox } from '@material-ui/core';
import { InfoRounded, SubjectRounded } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  RoundedInput,
  RoundedButton,
  LoadingButton,
  TreeSelector,
} from '../../components';
import ResumeConfirmationDialog from './ResumeConfirmationDialog';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';

export const handleMapDescriptions = (info) => {
  const fullSelectedTLevels = info.opcoes.niveisEnsino.filter((tLevel) =>
    info.selecionados.idsNivelEnsino.includes(String(tLevel.idNivelEnsino))
  );
  const fullSelectedCourses = (info.opcoes.cursos ?? []).filter((course) =>
    info.selecionados.idsCurso.includes(String(course.idCurso))
  );
  const fullSelectedCycles = (info.opcoes.ciclos ?? []).filter((cycle) =>
    info.selecionados.idsCiclo.includes(String(cycle.idCiclo))
  );
  const fullSelectedSchoolClasses = (
    info.opcoes.turmas ?? []
  ).filter((sClass) =>
    info.selecionados.idsTurma.includes(String(sClass.idTurma))
  );
  const tLevelsWithoutCoursesDecriptions = fullSelectedTLevels
    .filter(
      (tLevel) =>
        !fullSelectedCourses
          .map((course) => course.idNivelEnsino)
          .includes(tLevel.idNivelEnsino)
    )
    .map((tLevel) => `Todas as pessoas do ${tLevel.descricao}`);

  const coursesWithoutCyclesDecriptions = fullSelectedCourses
    .filter(
      (tLevel) =>
        !fullSelectedCycles
          .map((course) => course.idCurso)
          .includes(tLevel.idCurso)
    )
    .map((tLevel) => `Todas as pessoas do ${tLevel.descricao}`);

  const cyclesWithoutSchoolClassesDecriptions = fullSelectedCycles
    .filter(
      (tLevel) =>
        !fullSelectedSchoolClasses
          .map((course) => course.idCiclo)
          .includes(tLevel.idCiclo)
    )
    .map((tLevel) => `Todas as pessoas do ${tLevel.descricao}`);

  return {
    description: info.descricao,
    classSubject: info.usaTurmasDisciplina ? 'Sim' : 'Não',
    establishment: info.opcoes.estabelecimentos.find(
      (est) => est.idEstabelecimento === info.selecionados.idEstabelecimento
    )?.descricao,
    roles: info.opcoes.papeis
      .filter((role) =>
        info.selecionados.idsPapel.includes(String(role.idPapel))
      )
      .map((role) => role.descricao)
      .join(', '),
    teachingLevels: fullSelectedTLevels
      .map((tLevel) => tLevel.descricao)
      .join(', '),
    courses: fullSelectedCourses
      .map((course) => course.descricao)
      .concat(tLevelsWithoutCoursesDecriptions)
      .join(', '),
    cycles: fullSelectedCycles
      .map((cycle) => cycle.descricao)
      .concat(coursesWithoutCyclesDecriptions)
      .join(', '),
    schoolClasses: fullSelectedSchoolClasses
      .map((sClass) => sClass.descricao)
      .concat(cyclesWithoutSchoolClassesDecriptions)
      .join(', '),
    period: info.periodo,
  };
};

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  button: {
    width: 'fit-content',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
  },
  infoIcon: {
    margin: theme.spacing(1),
  },
  chkClassSubject: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '0em 0em 0.5em 0em',
  },
  labelChkClassSubject: {
    fontSize: '1rem',
  },
}));

export default ({
  isEdit = false,
  targetAudienceInfo = {},
  targetAudienceId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [description, setDescription] = useState(
    targetAudienceInfo.descricao ?? ''
  );
  const [period, setPeriod] = useState(targetAudienceInfo.periodo ?? '');
  const [isLoading, setIsLoading] = useState(false);

  const [establishments] = useState(
    targetAudienceInfo?.opcoes?.estabelecimentos ?? []
  );
  const [selectedEstablishment, setSelectedEstablishment] = useState(
    targetAudienceInfo?.selecionados?.idEstabelecimento ?? ''
  );
  const [roles] = useState(targetAudienceInfo?.opcoes?.papeis ?? []);
  const [selectedRoles, setSelectedRoles] = useState(
    targetAudienceInfo?.selecionados?.idsPapel ?? []
  );
  const [teachingLevels] = useState(
    targetAudienceInfo?.opcoes?.niveisEnsino ?? []
  );
  const [selectedTeachingLevels, setSelectedTeachingLevels] = useState(
    targetAudienceInfo?.selecionados?.idsNivelEnsino ?? []
  );
  const [courses, setCourses] = useState(
    targetAudienceInfo?.opcoes?.cursos ?? []
  );
  const [selectedCourses, setSelectedCourses] = useState(
    targetAudienceInfo?.selecionados?.idsCurso ?? []
  );
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [cycles, setCycles] = useState(
    targetAudienceInfo?.opcoes?.ciclos ?? []
  );
  const [selectedCycles, setSelectedCycles] = useState(
    targetAudienceInfo?.selecionados?.idsCiclo ?? []
  );
  const [isLoadingCycles, setIsLoadingCycles] = useState(false);
  const [schoolClasses, setSchoolClasses] = useState(
    targetAudienceInfo?.opcoes?.turmas ?? []
  );
  const [selectedSchoolClasses, setSelectedSchoolClasses] = useState(
    targetAudienceInfo?.selecionados?.idsTurma ?? []
  );
  const [isLoadingSchoolClasses, setIsLoadingSchoolClasses] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isRegisterDialog, setIsRegisterDialog] = useState(false);
  const [classSubject, setClassSubject] = useState(
    targetAudienceInfo.usaTurmasDisciplina ?? false
  );

  const handleCancel = () => history.push('/target-audience');

  const mapRegisterPayload = () => {
    const payload = {
      idEstabelecimento: parseInt(selectedEstablishment),
      descricao: description,
      usaTurmasDisciplina: classSubject,
      idsPapel: selectedRoles.map((role) => parseInt(role)),
      niveisEnsino: selectedTeachingLevels.map((id) => ({
        id: parseInt(id),
        cursos: courses
          .filter(
            (course) =>
              String(course.idNivelEnsino) === id &&
              selectedCourses.includes(String(course.idCurso))
          )
          .map((course) => ({
            id: course.idCurso,
            ciclos: cycles
              .filter(
                (cycle) =>
                  cycle.idCurso === course.idCurso &&
                  selectedCycles.includes(String(cycle.idCiclo))
              )
              .map((cycle) => ({
                id: cycle.idCiclo,
                turmas: schoolClasses
                  .filter(
                    (schoolClass) =>
                      schoolClass.idCiclo === cycle.idCiclo &&
                      selectedSchoolClasses.includes(
                        String(schoolClass.idTurma)
                      )
                  )
                  .map((schoolClass) => ({
                    id: schoolClass.idTurma,
                  })),
              })),
          })),
      })),
    };
    return payload;
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  const handleOpenDialog = () => {
    setDescriptions(
      handleMapDescriptions({
        descricao: description,
        usaTurmasDisciplina: classSubject,
        opcoes: {
          estabelecimentos: establishments,
          papeis: roles,
          niveisEnsino: teachingLevels,
          cursos: courses,
          ciclos: cycles,
          turmas: schoolClasses,
        },
        selecionados: {
          idEstabelecimento: selectedEstablishment,
          idsPapel: selectedRoles,
          idsNivelEnsino: selectedTeachingLevels,
          idsCurso: selectedCourses,
          idsCiclo: selectedCycles,
          idsTurma: selectedSchoolClasses,
        },
        periodo: period,
      })
    );
    setDialogIsOpen(true);
  };

  const handleOpenResume = () => {
    setIsRegisterDialog(false);
    handleOpenDialog();
  };

  const handleOpenRegister = (e) => {
    e.preventDefault();
    setIsRegisterDialog(true);
    handleOpenDialog();
  };

  const handleGetCourses = async () => {
    setIsLoadingCourses(true);
    if (selectedEstablishment === '' || selectedTeachingLevels.length === 0) {
      setIsLoadingCourses(false);
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'É necessário escolher um estabelecimento e um nível de ensino.',
      });
      return;
    }
    const params = {
      idEstablishment: selectedEstablishment,
      idsTeachingLevel: selectedTeachingLevels.join(),
    };
    await api.get('target-audience/courses', { params }).then((response) => {
      setCourses(response.data);
      if (selectedCourses) {
        const filteredCourses = selectedCourses.filter((c) =>
          response.data.includes(c)
        );
        setSelectedCourses(filteredCourses);
      }
    });
    setIsLoadingCourses(false);
  };

  const handleGetCycles = async () => {
    setIsLoadingCycles(true);
    if (selectedCourses.length === 0) {
      setIsLoadingCycles(false);
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'É necessário escolher um curso.',
      });
      return;
    }
    const params = {
      idsCourse: selectedCourses.join(),
    };
    await api.get('target-audience/cycles', { params }).then((response) => {
      setCycles(response.data);
      if (selectedCycles) {
        const filteredCycles = selectedCycles.filter((c) =>
          response.data.includes(c)
        );
        setSelectedCycles(filteredCycles);
      }
    });
    setIsLoadingCycles(false);
  };

  const handleGetSchoolClasses = async () => {
    setIsLoadingSchoolClasses(true);
    if (selectedCycles.length === 0 || period.length !== 5) {
      setIsLoadingSchoolClasses(false);
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'É necessário escolher um ciclo e digitar corretamente o período.',
      });
      return;
    }
    const params = {
      period,
      idsCycle: selectedCycles.join(),
      classSubject,
    };
    await api
      .get('target-audience/school-classes', { params })
      .then((response) => {
        setSchoolClasses(response.data);
        if (selectedSchoolClasses) {
          const filteredSchoolClasses = selectedSchoolClasses.filter((c) =>
            response.data.includes(c)
          );
          setSelectedSchoolClasses(filteredSchoolClasses);
        }
      });
    setIsLoadingSchoolClasses(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const registerData = mapRegisterPayload();

    if (isEdit) {
      api
        .put(`target-audience/${targetAudienceId}`, registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Público-alvo editado com sucesso!',
          });
          history.push(`/target-audience`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao editar público-alvo.',
          });
        });
    } else {
      api
        .post('target-audience', registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Público-alvo cadastrado com sucesso!',
          });
          history.push(`/target-audience`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao cadastrar público-alvo.',
          });
        });
    }
  };

  const handleSelectRoles = (event) => {
    const id = event.target.value;
    if (selectedRoles.includes(id)) {
      setSelectedRoles(selectedRoles.filter((tl) => tl !== id));
    } else {
      setSelectedRoles([...selectedRoles, id]);
    }
  };

  const handleSelectTeachingLevel = (event) => {
    const id = event.target.value;
    if (selectedTeachingLevels.includes(id)) {
      setSelectedTeachingLevels(
        selectedTeachingLevels.filter((tl) => tl !== id)
      );
    } else {
      setSelectedTeachingLevels([...selectedTeachingLevels, id]);
    }
  };

  const handleSelectCourses = (event) => {
    const id = event.target.value;
    if (id === 'ALL-PERSONS') {
      setSelectedCourses([]);
      return;
    }
    if (selectedCourses.includes(id)) {
      setSelectedCourses(selectedCourses.filter((tl) => tl !== id));
    } else {
      setSelectedCourses([...selectedCourses, id]);
    }
  };

  const handleSelectCycles = (event) => {
    const id = event.target.value;
    if (id === 'ALL-PERSONS') {
      setSelectedCycles([]);
      return;
    }
    if (selectedCycles.includes(id)) {
      setSelectedCycles(selectedCycles.filter((tl) => tl !== id));
    } else {
      setSelectedCycles([...selectedCycles, id]);
    }
  };

  const handleSelectSchoolClasses = (event) => {
    const id = event.target.value;
    if (id === 'ALL-PERSONS') {
      setSelectedSchoolClasses([]);
      return;
    }
    if (selectedSchoolClasses.includes(id)) {
      setSelectedSchoolClasses(selectedSchoolClasses.filter((tl) => tl !== id));
    } else {
      setSelectedSchoolClasses([...selectedSchoolClasses, id]);
    }
  };

  return (
    <form className={classes.form} onSubmit={handleOpenRegister}>
      <div className={classes.formInputs}>
        <Typography className={classes.title} variant="h5" color="primary">
          {isEdit ? 'Edição' : 'Cadastro'}
        </Typography>
        <RoundedInput
          className={classes.input}
          label="Descrição"
          size="small"
          value={description}
          inputProps={{ minLength: '4', maxLength: '300' }}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
        <RoundedInput
          className={classes.input}
          select
          label="Estabelecimento"
          size="small"
          value={selectedEstablishment}
          onChange={(e) => setSelectedEstablishment(e.target.value)}
          required
        >
          {establishments.map((establishment) => (
            <MenuItem
              key={establishment.idEstabelecimento}
              value={establishment.idEstabelecimento}
            >
              {establishment.descricao}
            </MenuItem>
          ))}
        </RoundedInput>
        <Typography className={classes.info}>
          <InfoRounded className={classes.infoIcon} />
          Os campos a seguir não são obrigatórios. Se não forem preenchidos,
          todas as opções serão consideradas.
        </Typography>
        <TreeSelector
          title="Papéis"
          items={roles}
          selecteds={selectedRoles}
          onSelect={handleSelectRoles}
          identifier="idPapel"
          description="descricao"
        />
        <TreeSelector
          title="Níveis de ensino"
          items={teachingLevels}
          selecteds={selectedTeachingLevels}
          onSelect={handleSelectTeachingLevel}
          identifier="idNivelEnsino"
          description="descricao"
        />
        <LoadingButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleGetCourses}
          isLoading={isLoadingCourses}
        >
          Confirmar
        </LoadingButton>
        <TreeSelector
          title="Cursos"
          items={courses}
          selecteds={selectedCourses}
          onSelect={handleSelectCourses}
          identifier="idCurso"
          description="descricao"
          groupId="idNivelEnsino"
          groupEntities={teachingLevels}
          groupDescription="descricao"
          oneOrAll
        />
        <LoadingButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleGetCycles}
          isLoading={isLoadingCycles}
        >
          Confirmar
        </LoadingButton>
        <TreeSelector
          title="Ciclos"
          items={cycles}
          selecteds={selectedCycles}
          onSelect={handleSelectCycles}
          identifier="idCiclo"
          description="descricao"
          groupId="idCurso"
          groupEntities={courses}
          groupDescription="descricao"
          oneOrAll
        />
        <RoundedInput
          className={classes.input}
          label="Período"
          placeholder={`${new Date().getFullYear()}0`}
          size="small"
          value={period}
          inputProps={{ minLength: '5', maxLength: '5' }}
          onChange={(e) => setPeriod(e.target.value)}
        />
        <div className={classes.chkClassSubject}>
          <Checkbox
            checked={classSubject}
            color="primary"
            onChange={() => setClassSubject(!classSubject)}
          />
          <span className={classes.labelChkClassSubject}>
            Turmas por disciplina
          </span>
        </div>
        <LoadingButton
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleGetSchoolClasses}
          isLoading={isLoadingSchoolClasses}
        >
          Confirmar
        </LoadingButton>
        <TreeSelector
          title="Turmas"
          items={schoolClasses}
          selecteds={selectedSchoolClasses}
          onSelect={handleSelectSchoolClasses}
          identifier="idTurma"
          description="descricao"
          groupId="idCiclo"
          groupEntities={cycles}
          groupDescription="descricao"
        />
      </div>
      <div className={classes.buttons}>
        <RoundedButton variant="contained" onClick={handleCancel}>
          Cancelar
        </RoundedButton>
        <RoundedButton
          className={classes.loadingButton}
          variant="contained"
          color="primary"
          startIcon={<SubjectRounded />}
          onClick={handleOpenResume}
        >
          Resumo
        </RoundedButton>
        <RoundedButton
          className={classes.loadingButton}
          type="submit"
          variant="contained"
          color="primary"
        >
          {isEdit ? 'Salvar' : 'Cadastrar'}
        </RoundedButton>
        <ResumeConfirmationDialog
          open={dialogIsOpen}
          isConfirmation={isRegisterDialog}
          isEdit={isEdit}
          isConfirmLoading={isLoading}
          handleConfirm={handleSubmit}
          handleClose={handleCloseDialog}
          descriptions={descriptions}
        />
      </div>
    </form>
  );
};
