import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    top: '25px',
    right: '-60px',
    width: '200px',
    transform: 'rotate(45deg)',
    textAlign: 'center',
    background: theme.palette.primary.main,
    color: '#fff',
    border: `2px solid ${theme.palette.primary.light}`,
    zIndex: theme.zIndex.drawer + 2,
    userSelect: 'none',
  },
}));

export default () => {
  const classes = useStyles();
  const env = process.env.REACT_APP_ENV;
  if (env === 'HML') {
    return <div className={classes.root}>HML</div>;
  }
  return null;
};
