import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import { Creators as ProfileActions } from '../../store/ducks/profile';
import { Creators as UserActions } from '../../store/ducks/user';
import { RoundedButton } from '../../components';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 2,
    background: 'white',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  error: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  backButton: {
    display: 'flex',
    width: 'fit-content',
  },
}));

const Error = ({ onBackToLogin }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.error}>
        <h1>Houve um problema inesperado.</h1>
        <RoundedButton
          className={classes.backButton}
          variant="contained"
          color="primary"
          onClick={onBackToLogin}
        >
          <ArrowBackRoundedIcon />
          Voltar para tela de login
        </RoundedButton>
      </div>
    </>
  );
};

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileError, setProfileError] = useState(null);

  const fetchAndSetProfile = useCallback(() => {
    api
      .get('profile')
      .then((res) => {
        dispatch(ProfileActions.setProfile(res.data));
        setLoadingProfile(false);
      })
      .catch((err) => {
        setProfileError(err);
      });
  }, [dispatch]);

  const handleBackToLogin = () => {
    setProfileError(null);
    dispatch(UserActions.logout());
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchAndSetProfile();
    } else {
      setLoadingProfile(false);
    }
  }, [isAuthenticated, fetchAndSetProfile]);

  return (
    <Backdrop className={classes.backdrop} open={loadingProfile}>
      {!profileError && <CircularProgress color="inherit" />}
      {profileError && <Error onBackToLogin={handleBackToLogin} />}
    </Backdrop>
  );
};
