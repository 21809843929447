import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { RoundedButton, LoadingButton } from '..';

export default ({
  title = '',
  open,
  handleConfirm,
  isConfirmLoading,
  handleClose,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogActions>
        <RoundedButton onClick={handleClose}>Cancelar</RoundedButton>
        <LoadingButton
          onClick={handleConfirm}
          isLoading={isConfirmLoading}
          color="primary"
          autoFocus
        >
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
