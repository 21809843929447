import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as NotificationActions } from '../../store/ducks/notification';
import { NotificationSection } from '../../components';
import api from '../../services/api';

export default () => {
  const dispatch = useDispatch();
  const notif = useSelector((state) => state.notification);

  const getNotification = () => {
    api
      .get('notification/getNotifications')
      .then((res) => {
        if (res.data.Sucesso) {
          const temp = res.data.Dados;

          dispatch(NotificationActions.setNotification({
            unread: temp.QtdNaoVisualizadas,
            notifications: [...temp.Notificacoes.Data],
          }));
        }
      })
  };

  useEffect(() => {
    getNotification();
    setInterval(getNotification, 15000);
  }, [dispatch]);

  return <NotificationSection notification={notif} />;
};
