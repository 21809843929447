export default (isAppOwner = false) =>
  [
    {
      name: 'admin',
      description: 'Administrador',
    },
    {
      name: 'communication',
      description: 'Comunicação - Gerenciamento',
    },
    {
      name: 'coordination',
      description: 'Coordenação',
    },
    {
      name: 'concierge',
      description: 'Portaria',
    },
    {
      name: 'completeConcierge',
      description: 'Portaria Completa',
    },
    {
      name: 'earlyExitConcierge',
      description: 'Portaria - Saída antecipada e com terceiros',
    },
    {
      name: 'sectorMessage',
      description: 'Mensagem dos setores',
    },
    {
      name: 'comunicationPublish',
      description: 'Comunicação - Publicar',
    },
  ].filter((perm) => (perm.name === 'admin' ? isAppOwner : true));
