import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Typography,
  FormControlLabel,
  Radio,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, Close } from '@material-ui/icons';
import { RoundedButton } from '..';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  modal: {
    marginLeft: '20em',
    marginTop: '8em',
    boxShadow: '1px 2px 16px 1px',
    borderRadius: '6px',
  },
  modalContent: {
    margin: '1em 0em 0em 0em',
  },
  modalTitle: {
    margin: '0em 1em 1em 1em',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3F575C',
    borderBottom: '1px solid #F0F1F1',
  },
  closeIcon: {
    float: 'right',
    marginTop: '-0.5em',
  },
  modalText: {
    margin: '0em 1em 1em 1em',
    fontSize: '18px',
    color: '#3F575C',
  },
  caixa: {
    border: '1px solid',
    borderRadius: '4px',
    padding: '8px',
    borderColor: '#B2BBBF',
    marginTop: '1em',
    margin: '0em 1em 1em 1em',
  },
  novo: {
    backgroundColor: '#CAE2E1',
    color: '#008179',
    padding: '1px 4px 1px 4px',
    marginRight: '2px',
    borderRadius: '5px',
  },
  modalItemTitle: {
    color: '#2D3A40',
    fontSize: '14px',
  },
  modalItens: {
    color: '#6D787D',
    fontSize: '12px',
  },
  botoes: {
    margin: '1em 0em 0em 0em',
    fontSize: '20px',
    textAlign: 'right',
    backgroundColor: '#F0F1F1',
  },
  botaoCancelar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: '#00796B',
    borderRadius: '0px',
  },
  botao: {
    borderRadius: '0px',
    margin: '1em 1em 1em 1em',
  },
}));

export default () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(false);
  const [config, setConfig] = useState(null);

  const closeModal = () => {
    setOpen(false);
  };

  const getConfiguration = () => {
    api.get('configuration/getConfigurations').then((res) => {
      setConfig(res.data);
      if (res.data.usaLoginService) {
        setOpen(!res.data.usaMural);
      }
    });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  const confirm = () => {
    if (selected) {
      window.open(
        `https://${config.subDominio}.painel.gvdasa.com.br/servico-mural/mural`,
        '_blank'
      );
    }
    closeModal();
  };

  return (
    <Dialog onClose={() => closeModal()} open={open}>
      <div className={classes.modalContent}>
        <div className={classes.modalTitle}>
          <Grid xs={12}>
            <Typography component="span" variant="h5">
              Notícias
            </Typography>
            <IconButton onClick={closeModal} className={classes.closeIcon}>
              <Close fontSize="large" />
            </IconButton>
          </Grid>
        </div>
        <div className={classes.modalText}>
          <Typography component="span" variant="p">
            Escolha qual plataforma deseja utilizar para criar novas notícias no
            aplicativo.
          </Typography>
        </div>

        <div className={classes.caixa}>
          <Grid xs={12}>
            <Typography component="span" variant="p" className={classes.novo}>
              Novo
            </Typography>
            <Typography
              component="span"
              variant="p"
              className={classes.modalItemTitle}
            >
              Painel GVdasa
            </Typography>
            <FormControlLabel
              key={1}
              value
              checked={selected}
              onChange={() => setSelected(true)}
              style={{ float: 'right' }}
              control={<Radio checkedIcon={<CheckCircle />} color="primary" />}
            />
          </Grid>
          <Grid xs={12}>
            <Typography
              component="span"
              variant="p"
              className={classes.modalItens}
            >
              Notícias criadas nesse portal são exibidas no <b>mural</b> do
              aplicativo
            </Typography>
          </Grid>
          <Grid xs={2} />
        </div>
        <div className={classes.caixa}>
          <Grid xs={12}>
            <Typography
              component="span"
              variant="p"
              className={classes.modalItemTitle}
            >
              Portal administrativo
            </Typography>
            <FormControlLabel
              key={0}
              value={false}
              checked={!selected}
              onChange={() => setSelected(false)}
              style={{ float: 'right' }}
              control={<Radio checkedIcon={<CheckCircle />} color="primary" />}
            />
          </Grid>
          <Grid xs={12}>
            <Typography
              component="span"
              variant="p"
              className={classes.modalItens}
            >
              Notícias criadas nesse portal são exibidas no{' '}
              <b>feed de notícias</b> do aplicativo
            </Typography>
          </Grid>
        </div>
        <div className={classes.botoes}>
          <RoundedButton
            variant="contained"
            className={classes.botaoCancelar}
            onClick={() => closeModal()}
          >
            Cancelar
          </RoundedButton>
          <RoundedButton
            variant="contained"
            color="primary"
            className={classes.botao}
            onClick={() => confirm()}
          >
            Confirmar
          </RoundedButton>
        </div>
      </div>
    </Dialog>
  );
};
