const debounce = (cb, wait = 1000, timeout) => (...args) => {
  clearTimeout(timeout);
  // eslint-disable-next-line no-param-reassign
  timeout = setTimeout(() => {
    cb(...args);
  }, wait);
};

const useDebounce = () => {
  return debounce;
};

export default useDebounce;
