import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoDialog from '../../components/InfoDialog/index';

const useStyles = makeStyles((theme) => ({
  divMain: {
    minWidth: '300px',
  },
  body: {
    marginTop: '1px',
  },
  cellDialog: {
    padding: '0.5em',
    textAlign: 'center',
    width: '25%',
  },
  divOptionsConfigs: {
    marginLeft: '1em',
  },
  divAditionalConfig: {
    marginBottom: '8px',
  },
}));

export default ({ dialogConfigurationMessage, onClose, open, title }) => {
  const classes = useStyles();

  return (
    <InfoDialog onClose={onClose} open={open} title={title}>
      <div className={classes.divMain}>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Descrição:&nbsp;
          </Typography>
          <div className={classes.body}>
            {dialogConfigurationMessage?.descricao ?? ''}
          </div>
        </div>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Público-alvo:&nbsp;
          </Typography>
          {dialogConfigurationMessage?.publicosAlvo.map((publico) => (
            <div key={publico.id}>{` - ${publico.descricao}`}</div>
          ))}
        </div>
        <Divider />
        <div className={classes.divAditionalConfig}>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Configurações adicionais:&nbsp;
          </Typography>
          <div hidden={!dialogConfigurationMessage?.usaChatDisciplina}>
            - Permite mensagem entre professor e responsável
          </div>
          <div hidden={!dialogConfigurationMessage?.ocultaDiscSemProfe}>
            - Não exibe disciplinas sem professor vinculado
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarimagemProf}>
            - Permite que o perfil professor envie imagem
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarArquivoProf}>
            - Permite que o perfil professor envie arquivo
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarImagemResp}>
            - Permite que o perfil estudante envie imagem
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarArquivoResp}>
            - Permite que o perfil estudante envie arquivo
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarImagemCoord}>
            - Permite que o perfil coordenador envie imagem
          </div>
          <div hidden={!dialogConfigurationMessage?.permiteEnviarArquivoCoord}>
            - Permite que o perfil coordenador envie arquivo
          </div>
          <div
            hidden={
              !dialogConfigurationMessage?.permiteRespEnviarArquivoSetores
            }
          >
            - Permite que o perfil estudante envie arquivo para setores
          </div>
          <div
            hidden={!dialogConfigurationMessage?.permiteRespEnviarImagemSetores}
          >
            - Permite que o perfil estudante envie imagem para setores
          </div>
          <div
            hidden={!dialogConfigurationMessage?.restringeEscritaProfTitular}
          >
            - Restringe o envio e a visualização somente para o professor
            titular
          </div>
          <div hidden={!dialogConfigurationMessage?.replicaTodosProfessores}>
            - Replicar mensagem para todos os professores
          </div>
          <div hidden={!dialogConfigurationMessage?.liberaEmTempoReal}>
            - Libera em tempo real
          </div>
          <div
            hidden={
              dialogConfigurationMessage?.liberaEmTempoReal ||
              dialogConfigurationMessage?.restringeEscritaProfTitular ||
              dialogConfigurationMessage?.usaChatDisciplina ||
              dialogConfigurationMessage?.permiteEnviarimagemProf ||
              dialogConfigurationMessage?.permiteEnviarImagemResp ||
              dialogConfigurationMessage?.permiteEnviarImagemCoord ||
              dialogConfigurationMessage?.permiteEnviarArquivoProf ||
              dialogConfigurationMessage?.permiteEnviarArquivoResp ||
              dialogConfigurationMessage?.permiteEnviarArquivoCoord ||
              dialogConfigurationMessage?.permiteRespEnviarArquivoSetores ||
              dialogConfigurationMessage?.permiteRespEnviarImagemSetores ||
              dialogConfigurationMessage?.replicaTodosProfessores
            }
          >
            Nenhuma configuração adicional cadastrada.
          </div>
        </div>
      </div>
    </InfoDialog>
  );
};
