import React from 'react';

import { Close } from '@material-ui/icons';
import {
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  divHeaderDetails: {
    display: 'flex',
    minWidth: '250px',
    alignItems: 'center',
    padding: '16px 24px 0px 24px',
  },
  titleHeaderDetails: {
    flex: 2,
  },
  icon: {
    marginRight: '-0.5em',
    marginTop: '-0.3em',
  },
}));

export default ({ onClose, open, title, children }) => {
  const classes = useStyles();

  return (
    <Dialog onClose={onClose} open={open}>
      <div className={classes.divHeaderDetails}>
        <Typography
          variant="h6"
          style={{ fontSize: '1.2em' }}
          color="primary"
          className={classes.titleHeaderDetails}
        >
          {title}
        </Typography>
        <Close className={classes.icon} color="primary" onClick={onClose} cursor="pointer"/>
      </div>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};
