import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/styles';

import {
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Switch,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { Close, Settings } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import { Creators as ConfigurationActions } from '../../store/ducks/configuration';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  divConfig: {
    height: '480px',
  },

  infoIcon: {
    fontSize: '14px',
  },

  descricao: {
    marginRight: '2em',
    marginLeft: '-1em',
    fontWeight: 'bold',
    opacity: '0.55',
    color: 'black',
    placeSelf: 'center',
  },
  menuItem: {
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    margin: '0px 14px 0px 14px',
    borderColor: 'rgba(0, 0, 0, 0.1) !important',
  },
  menuItemEnd: {
    borderBottom: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderRadius: '0px 0px 10px 10px',
    margin: '0px 14px -1px 14px',
    borderColor: 'rgba(0, 0, 0, 0.1) !important',
  },
  listItem: {
    margin: '0px 14px 0px 14px',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1) !important',
  },
  listItemAgenda: {
    margin: '0px 14px 0px 14px',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.1) !important',
  },
  useAgenda: {
    marginRight: '2em',
    opacity: '0.55',
    color: 'black',
    placeSelf: 'center',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.2em',
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 4px 0 16px',
  },
}));

export default ({ configuration }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);
  const tipSetAgenda = 'Disponível somente para o novo layout';
  const handleClose = () => {
    dispatch(
      ConfigurationActions.setAnchorConfig({
        anchorEl: null,
      })
    );
  };

  const getConfiguration = () => {
    api.get('configuration/getConfigurations').then((res) => {
      setConfig(res.data);
    });
  };

  const updateConfiguration = (newConfig) => {
    api.post('configuration/updateConfigurations', newConfig);
  };

  useEffect(() => {
    getConfiguration();
  }, [configuration?.anchorEl]);

  const updateConfig = (newConfig) => {
    setConfig(newConfig);
    updateConfiguration(newConfig);
  };

  const handleUsaNovaHomepage = () => {
    let newConfig = { ...config, usaNovaHomepage: !config.usaNovaHomepage };
    if (!newConfig.usaNovaHomepage) {
      newConfig = {
        ...config,
        usaNovaHomepage: !config.usaNovaHomepage,
        usaAgenda: false,
      };
    }
    updateConfig(newConfig);
  };

  const handleAgenda = () => {
    const newConfig = { ...config, usaAgenda: !config.usaAgenda };
    updateConfig(newConfig);
  };

  const handleAcessoSomenteAnoCorrente = () => {
    const newConfig = {
      ...config,
      acessoSomenteAnoCorrente: !config.acessoSomenteAnoCorrente,
    };
    updateConfig(newConfig);
  };

  const handleExibirNomeDocumento = () => {
    const newConfig = {
      ...config,
      exibirNomeDocumento: !config.exibirNomeDocumento,
    };
    updateConfig(newConfig);
  };

  const handleExibirEventoGestaoDeAula = () => {
    const newConfig = {
      ...config,
      exibirEventoGestaoDeAula: !config.exibirEventoGestaoDeAula,
    };
    updateConfig(newConfig);
  };

  const handleUsaAvaliacaoPortalAluno = () => {
    const newConfig = {
      ...config,
      usaAvaliacaoPortalAluno: !config.usaAvaliacaoPortalAluno,
      gerarBoletim: false,
      listarDisciplinasAvaliacao: false,
    };
    updateConfig(newConfig);
  };

  const handleUsaProgramaDeAulaPortalAluno = () => {
    const newConfig = {
      ...config,
      usaProgramaDeAulaPortalAluno: !config.usaProgramaDeAulaPortalAluno,
    };
    updateConfig(newConfig);
  };

  const handleExibirSubParcialVazia = () => {
    const newConfig = {
      ...config,
      exibirSubParcialVazia: !config.exibirSubParcialVazia,
    };
    updateConfig(newConfig);
  };

  const handleOcultaParcial = () => {
    const newConfig = {
      ...config,
      ocultaParcial:
        config.ocultaParcial != null && config.ocultaParcial === '1'
          ? '0'
          : config.ocultaParcial != null && config.ocultaParcial === '0'
          ? '1'
          : '0',
    };
    updateConfig(newConfig);
  };

  const handleOcultaParcialSubParcial = () => {
    const newConfig = {
      ...config,
      ocultaParcialSubParcial: !config.ocultaParcialSubParcial,
    };
    updateConfig(newConfig);
  };

  const handlePermiteDivulgacaoOcorrencia = () => {
    const newConfig = {
      ...config,
      permiteDivulgacaoOcorrencia: !config.permiteDivulgacaoOcorrencia,
    };
    updateConfig(newConfig);
  };

  const handleUsaConfArquivoPubliAlvo = () => {
    const newConfig = {
      ...config,
      usaConfArquivoPubliAlvo: !config.usaConfArquivoPubliAlvo,
    };
    updateConfig(newConfig);
  };

  const handleUsaConfChatPubliAlvo = () => {
    const newConfig = {
      ...config,
      usaConfChatPubliAlvo: !config.usaConfChatPubliAlvo,
    };
    updateConfig(newConfig);
  };

  const handleUsaLoginService = () => {
    const newConfig = {
      ...config,
      usaLoginService: !config.usaLoginService,
    };
    updateConfig(newConfig);
  };

  const handleUsaMural = () => {
    const newConfig = {
      ...config,
      usaMural: !config.usaMural,
    };

    updateConfig(newConfig);
    window.location.reload(true);
  };

  const handleGerarBoletim = () => {
    const newConfig = {
      ...config,
      gerarBoletim: !config.gerarBoletim,
      usaAvaliacaoPortalAluno: false,
    };

    updateConfig(newConfig);
    window.location.reload(true);
  };

  const handleListarDisciplinasAvaliacao = () => {
    const newConfig = {
      ...config,
      listarDisciplinasAvaliacao: !config.listarDisciplinasAvaliacao,
      usaAvaliacaoPortalAluno: false,
    };

    updateConfig(newConfig);
    window.location.reload(true);
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#00796B',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark'
              ? 'rgb(178 236 181)'
              : 'rgb(178 236 181)',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor:
        theme.palette.mode === 'light'
          ? 'rgba(0, 0, 0, 0.5)'
          : 'rgba(0, 0, 0, 0.5)',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    config && (
      <Menu
        anchorEl={configuration?.anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'right',
          horizontal: 'right',
        }}
        open={Boolean(configuration?.anchorEl)}
        onClose={handleClose}
      >
        <div className={classes.headContainer}>
          <Typography component="span" color="primary">
            <Settings />
          </Typography>
          <Typography component="span" color="primary">
            CONFIGURAÇÕES
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </div>
        <div className={classes.divConfig}>
          <MenuItem component="div" className={classes.menuItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Utilizar novo layout
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaNovaHomepage}
                          onChange={handleUsaNovaHomepage}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItemAgenda}>
            <ListItem style={{ paddingLeft: '0' }}>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.useAgenda}
                    >
                      Agenda
                      <Tooltip title={tipSetAgenda}>
                        <InfoIcon
                          className={classes.infoIcon}
                          color="primary"
                        />
                      </Tooltip>
                    </Typography>
                    <FormControlLabel
                      disabled={!config.usaNovaHomepage}
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaAgenda}
                          onChange={handleAgenda}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Acesso somente ano
                      <br />
                      corrente
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.acessoSomenteAnoCorrente}
                          onChange={handleAcessoSomenteAnoCorrente}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Arquivo público-alvo
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaConfArquivoPubliAlvo}
                          onChange={handleUsaConfArquivoPubliAlvo}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Chat público-alvo
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaConfChatPubliAlvo}
                          onChange={handleUsaConfChatPubliAlvo}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Exibir nome documento
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.exibirNomeDocumento}
                          onChange={handleExibirNomeDocumento}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Exibir agenda gestão de aulas
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.exibirEventoGestaoDeAula}
                          onChange={handleExibirEventoGestaoDeAula}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Permitir divulgação
                      <br />
                      ocorrência
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.permiteDivulgacaoOcorrencia}
                          onChange={handlePermiteDivulgacaoOcorrencia}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Usar login service
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaLoginService}
                          onChange={handleUsaLoginService}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.menuItemEnd}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Tornar o novo mural
                      <br />
                      como padrão
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaMural}
                          onChange={handleUsaMural}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Exibir subparcial vazia
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.exibirSubParcialVazia}
                          onChange={handleExibirSubParcialVazia}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Ocultar parcial
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.ocultaParcial == '1'}
                          onChange={handleOcultaParcial}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Ocultar subparcial
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.ocultaParcialSubParcial}
                          onChange={handleOcultaParcialSubParcial}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Usa avaliação portal aluno
                      <Tooltip title="Somente para integração Portal 2.0">
                        <InfoIcon
                          className={classes.infoIcon}
                          color="primary"
                        />
                      </Tooltip>
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaAvaliacaoPortalAluno}
                          onChange={handleUsaAvaliacaoPortalAluno}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>

          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Usa programa de aula portal aluno
                      <Tooltip title="Somente para integração Portal 2.0">
                        <InfoIcon
                          className={classes.infoIcon}
                          color="primary"
                        />
                      </Tooltip>
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.usaProgramaDeAulaPortalAluno}
                          onChange={handleUsaProgramaDeAulaPortalAluno}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>

          <MenuItem component="div" className={classes.listItem}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Exibir cards nas Avaliações
                      <Tooltip title="Somente para integração Portal 2.0">
                        <InfoIcon
                          className={classes.infoIcon}
                          color="primary"
                        />
                      </Tooltip>
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.listarDisciplinasAvaliacao}
                          onChange={handleListarDisciplinasAvaliacao}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
          <MenuItem component="div" className={classes.menuItemEnd}>
            <ListItem>
              <ListItemText
                primary={
                  <div className={classes.textContainer}>
                    <Typography
                      color="primary"
                      display="inline"
                      className={classes.descricao}
                    >
                      Gerar boletim
                      <Tooltip title="Somente para integração Portal 2.0">
                        <InfoIcon
                          className={classes.infoIcon}
                          color="primary"
                        />
                      </Tooltip>
                    </Typography>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          sx={{ m: 1 }}
                          checked={config.gerarBoletim}
                          onChange={handleGerarBoletim}
                        />
                      }
                    />
                  </div>
                }
              />
            </ListItem>
          </MenuItem>
        </div>
      </Menu>
    )
  );
};
