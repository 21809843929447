import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RoundedInput } from '..';

export default ({
  options = [],
  loading = false,
  value = null,
  identifierProperty = 'id',
  labelProperty = 'name',
  onValueChange = () => {},
  onTextChange = () => {},
  disabled = false,
  size = 'medium',
  PaperComponent = undefined,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Autocomplete
      open={open}
      autoSelect
      disabled={disabled}
      size={size}
      value={value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, val) =>
        option[identifierProperty] === val[identifierProperty]
      }
      getOptionLabel={(option) => option[labelProperty]}
      filterOptions={(filteredOptions) => filteredOptions}
      onInputChange={onTextChange}
      onChange={onValueChange}
      options={options}
      loading={loading}
      PaperComponent={PaperComponent}
      renderInput={(params) => (
        <RoundedInput
          {...params}
          {...rest}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
