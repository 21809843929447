import React from 'react';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '../../components';
import FormExit from './FormExit';

export default (props) => {
  const saidaSelecionada = props.history.location.state || {};

  const formatarResponsavel = (resp) => {
    if (!resp || !resp.codigoResponsavel || !resp.nomeResponsavel) return null;

    return {
      id: resp.codigoResponsavel,
      name: resp.nomeResponsavel,
    };
  };

  const formatarEstudantes = (saida) => {
    if (!saida || !saida.estudantes || saida.estudantes.length === 0)
      return null;

    return saida.estudantes.map((e) => ({
      id: e.codigoEstudante,
      name: e.nomeEstudante,
      codigoEmpresa: e.codigoEmpresa,
      codigoUnidade: e.codigoUnidade,
    }));
  };

  return (
    <DefaultPage title="Saídas antecipadas">
      <Container>
        <FormExit
          titulo="Editar saída antecipada"
          codigoSaidaAntecipadaParam={saidaSelecionada?.codigoSaidaAntecipada}
          responsavelParam={formatarResponsavel(saidaSelecionada)}
          estudantesParam={formatarEstudantes(saidaSelecionada)}
          dataParam={saidaSelecionada?.dataSaida}
          dataInicio={saidaSelecionada?.dataInicio}
          horaSaida={saidaSelecionada?.horaSaida}
          motivoParam={saidaSelecionada?.motivo}
          observacaoParam={saidaSelecionada?.observacao}
          registroTabSec={saidaSelecionada?.registroTabSec}
        />
      </Container>
    </DefaultPage>
  );
};
