import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { RoundedButton } from '../../components';

const useStyles = makeStyles((theme) => ({
  divCabDialog: {
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: '300px',
    minWidth: '180px',
    paddingLeft: '15px',
  },
  turmas: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '200px',
    overflow: 'auto',    
  },
  buttonClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  descTurma: {
    padding: '5px',
  },
}));

export default ({
  handleClose,
  open,
  turmas=[],
}) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={handleClose} 
      open={open}
      >
      <div className={classes.divCabDialog}>
        <Typography
          color="primary"
        >
        <strong>Turmas</strong>
        </Typography> 
        <IconButton aria-label="close" onClick={handleClose}>
          <Close />
        </IconButton>
      </div>      
      <DialogContent>   
        <div className={classes.turmas}>
          {turmas?.map((tur) => (
            <span key={tur.IdTurma} className={classes.descTurma}> - {tur.DescricaoTurma != undefined ? tur.DescricaoTurma : tur.descricaoTurma}</span>
          ))}
        </div>
        <div className={classes.buttonClose}>
          <RoundedButton 
            onClick={handleClose} 
            color="primary"
            variant="contained"
            size='small'
          >Fechar</RoundedButton>     
        </div>
      </DialogContent>
    </Dialog>
  );
};