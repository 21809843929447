/* eslint-disable radix */
import React from 'react';

import ConfigurationFilesForm from './ConfigurationFilesForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '../../components';

export default () => {
  return (
    <DefaultPage title="Cadastro de configurações">
        <Container>
            <ConfigurationFilesForm />
        </Container>
    </DefaultPage>
  );
};
