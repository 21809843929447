/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MenuForm from './MenuForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { menuId } = useParams();

  const [menuInfo, setMenuInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const params = { appRole: ['coordination', 'admin'] };
    api
      .get(`menu/info/${menuId}`, { params })
      .then(async (response) => {
        setMenuInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [menuId]);

  return (
    <DefaultPage title="Clonagem de Cardápio">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? <LoadingForm /> : <MenuForm menuInfo={menuInfo} />}
        </Container>
      )}
    </DefaultPage>
  );
};
