import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api/v1',
});

api.interceptors.request.use(async (config) => {
  const newConfig = config;
  const bearerToken = localStorage.getItem('token');
  const appId = localStorage.getItem('application');
  if (!newConfig?.url?.endsWith('token') && bearerToken) {
    newConfig.headers.Authorization = `Bearer ${bearerToken}`;
    newConfig.headers.appId = appId;
  }
  return newConfig;
});

export default api;
