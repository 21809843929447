import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Grid,
  MenuItem,
  Badge,
  Typography,
  IconButton,
  Menu,
  ListItemIcon,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Done,
  ExpandMore,
  Chat,
  Send,
  Message,
  Refresh,
} from '@material-ui/icons';
import { Skeleton, Alert } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router-dom';

import moment from 'moment';
import DefaultPage from '../../templates/DefaultPage';
import {
  RoundedButton,
  Container,
  TableRowFocusable,
  RoundedInput,
  ConfirmationDialog,
} from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import InitChatAutorizationModal from './InitChatAutorizationModal';
import SendDetailsBatchModal from './SendDetailsBatchModal';
import RegisterReadButton from './components/RegisterReadButton';
import FilterChat from './components/FilterChat';
import { getAdminOrOwnerRole } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  divContainerDetails: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  divPanelDetails: {
    border: '1px solid #E3E3E3',
    borderRadius: '5px',
    width: '45%',
    height: '80px',
    padding: '10px',
    color: '#A1A5A8',
    cursor: 'pointer',
  },
  rowDetails: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '-10px',
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '-15px',
  },
  iconSize: {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      width: '35px',
      height: '35px',
      color: theme.palette.primary.main,
    },
  },
  numberDatail: {
    color: '#293136',
    fontSize: '32px',
  },
  tableCell: {
    width: '100%',
  },
  input: {
    margin: '0.8em auto 0.8em 0',
    borderRadius: '5px !important',
    maxWidth: '600px',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  freezeCheck: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  tableCellCustom: {
    minWidth: '150px',
    textAlign: 'center',
    width: '100%',
  },
  customButtonDefault: {
    marginLeft: '1em',
    marginTop: '5px',
  },
  listIconCustom: {
    minWidth: '0',
    marginRight: '5px',
  },
  divItems: {
    padding: '0 24px 0 24px',
    width: '100%',
    verticalAlign: 'middle',
  },
  tableCellIcons: {
    minWidth: '180px',
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  customToolbar: {
    margin: '3em 0 0 0',
  },
  customToolbarSub: {
    marginBottom: '1em',
    justifyContent: 'flex-end',
  },
  centerItems: {
    display: 'flex',
    alignItems: 'center',
  },
  registerButton: {
    [theme.breakpoints.down('sm')]: {
      // css mobile
      opacity: 1,
      zIndex: 1000,
      position: 'fixed',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: '55px',
      width: '67%',
      maxWidth: '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      // css desktop
      padding: 'auto 20px',
      marginLeft: '1em',
      whiteSpace: 'nowrap',
      minWidth: 'auto',
      opacity: 1,
    },
  },
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30px',
  },
  containerLabel: {
    display: 'flex',
    justifyContent: 'center',
  },
  cellVisualization: {
    width: '20%',
  },
  labelNoReply: {
    color: '#9f8e76',
    backgroundColor: '#ede8cd',
    borderRadius: '12.2398px',
    border: '1px solid #a0986e',
    padding: '3px 8px',
    width: '106px',
  },
  labelAutorization: {
    color: '#43716F',
    backgroundColor: '#CDEDE9',
    borderRadius: '12.2398px',
    border: '1px solid #6EA09E',
    padding: '3px 8px',
    width: '88px',
  },
  labelNotAutorization: {
    color: '#B9727F',
    backgroundColor: '#FEE2E7',
    borderRadius: '12.2398px',
    border: '1px solid #E3D4D7',
    padding: '3px 8px',
    width: '88px',
  },
  skeletonSize: {
    '& .MuiSkeleton-text': {
      height: '50px',
      width: '50px',
    },
  },
  MobileButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mobileTableCell: {
    variant: 'contained',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileNull: {
    display: 'none',
  },
  divModoAdmTag: {
    margin: '24px 24px 0px 24px',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();
  const { idAssuntoMsgLote } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isAdminOrOwner = getAdminOrOwnerRole();

  const [isLoading, setIsLoading] = useState(false);
  const [chats, setChats] = useState([]);
  const [total, setTotal] = useState(0);

  const [sectors, setSectors] = useState([]);

  const [text, setText] = useState('');
  const [anchorElSituations, setAnchorElSituations] = useState(null);
  const [openInitAutorizationChat, setOpenInitAutorizationChat] = useState(
    false
  );
  const [recipientNumber, setRecipientNumber] = useState(0);
  const [numberNotViewed, setNumberNotViewed] = useState(0);
  const [numberViewed, setNumberViewed] = useState(0);
  const [numberNewMessages, setNumberNewMessages] = useState(0);
  const [loadStatistics, setLoadStatistics] = useState(false);
  const [searchedStatistic, setSearchedStatistic] = useState(false);
  const [openSendDetailsModal, setOpenSendDetailsModal] = useState(false);
  const [assunto, setAssunto] = useState('');
  const [filter, setFilter] = useState(
    JSON.parse(localStorage.getItem('filterChatsInBatch')) ?? {
      order: 1,
      page: 1,
    }
  );
  const [adminMode, setAdminMode] = useState(
    isAdminOrOwner
      ? JSON.parse(localStorage.getItem('admMode')) ?? false
      : false
  );

  useEffect(() => {
    setAdminMode(
      isAdminOrOwner
        ? JSON.parse(localStorage.getItem('admMode')) ?? false
        : false
    );
  }, [isAdminOrOwner]);

  const availableSituations = [
    {
      id: 1,
      description: 'Mais recente',
      parameter: {
        order: 1,
      },
    },
    {
      id: 2,
      description: 'Ordem alfabética',
      parameter: {
        order: 2,
      },
    },
  ];

  const statusMap = {
    false: {
      true: 'Em andamento',
      false: 'Finalizado',
    },
    true: {
      true: 'Aguardando Finalização',
      false: 'Finalizado',
    },
  };

  const setLocalStorageFilter = (localStorageFilter) => {
    localStorage.setItem(
      'filterChatsInBatch',
      JSON.stringify(localStorageFilter)
    );
  };

  const handleCloseSituation = () => setAnchorElSituations(false);

  const handleSituations = (id) => {
    setLocalStorageFilter({ ...filter, order: id, name: '' });
    setFilter({ ...filter, order: id });
    handleCloseSituation();
  };

  const handleSearch = (message) => {
    if (message === text) return;

    setText(message);
    setLocalStorageFilter({ ...filter, name: '', page: 1 });
    setFilter({
      ...filter,
      name: message,
      page: 1,
    });
  };

  const handleSendEnterPress = (text, e) => {
    if (e.key === 'Enter') handleSearch(text);
  };

  const handleOpenSituation = (event) =>
    setAnchorElSituations(event.currentTarget);

  const handleChangePage = (event, nextPage) => {
    setLocalStorageFilter({ ...filter, page: nextPage + 1, name: '' });
    setFilter({ ...filter, page: nextPage + 1 });
  };

  useEffect(() => {
    if (history.action === 'PUSH') {
      setLocalStorageFilter({
        order: 1,
        page: 1,
      });
      setFilter({
        order: 1,
        page: 1,
      });
    }
  }, [history]);

  const handleChatMessage = (idChat) =>
    history.push(`/message/chatSector/${idChat}`);

  const handleChatInBatch = () => {
    history.push(`/message/chatInBatch/${idAssuntoMsgLote}`);
  };

  const handleViewLastMessage = (message, sendDate) => {
    if (message == null) return '';

    const maxLength = 35;

    const formatedMessage =
      message.length > maxLength
        ? `${message.substring(0, maxLength).trimEnd()}...`
        : message;

    return `${formatedMessage} - ${moment(sendDate).format(
      'DD/MM/YYYY HH:mm'
    )}`;
  };

  const handleViewLastMessageHourMobile = (date) => {
    return `${moment(date).format('DD/MM/YYYY HH:mm')}`;
  };

  const handleViewLastMessageMobile = (message) => {
    if (message == null) return '';

    const maxLength = 80;

    const formatedMessage =
      message.length > maxLength
        ? `${message.substring(0, maxLength).trimEnd()}...`
        : message;

    return `${formatedMessage}`;
  };

  const handleUpdateChatRead = async (value) => {
    setChats(
      chats.map((x) => (x.IdChatSetor === value.IdChatSetor ? value : x))
    );
  };

  const handleErrorChat = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });

    setChats([]);
    setTotal(0);
  };

  useEffect(() => {
    requestChat();
    if (!searchedStatistic) {
      setSearchedStatistic(true);
      requestStatistic();
    }
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    api
      .get('/sector/getSectorsByUser')
      .then((response) => {
        if (!response.data.Sucesso)
          setAlert({
            isOpen: true,
            type: 'warning',
            message: response.data.Mensagem,
          });
        else {
          setSectors([...response.data.Dados]);
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um problema inesperado ao buscar setores de usuário!',
        });
      });
    // eslint-disable-next-line
  }, []);

  const requestChat = async () => {
    setIsLoading(true);

    const params = {
      limit: 10,
      idAssuntoMsgLote,
      ...filter,
      adminMode,
    };

    api
      .get('chat/getChatsInBatch', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleErrorChat(response.data.Mensagem);
        else {
          setChats([...response.data.Dados.Data]);
          setTotal(response.data.Dados.Total);
          setAssunto(
            response.data.Dados.Data.length > 0
              ? response.data.Dados.Data[0].AssuntoMsgLote
              : null
          );
          setIsLoading(false);
        }
      })
      .catch(() => handleErrorChat(null));
  };

  const requestStatistic = async () => {
    const params = {
      idAssuntoMsgLote,
    };

    api
      .get('chat/getStatisticBatch', {
        params,
      })
      .then((response) => {
        setRecipientNumber(response.data.QtdDestinatarios);
        setNumberNewMessages(response.data.QtdMensagensNaoLidas);
        setNumberNotViewed(response.data.QtdMensagensNaoVisualizadas);
        setNumberViewed(response.data.QtdMensagensVisualizadas);
        setLoadStatistics(true);
      })
      .catch(() => handleErrorChat(null));
  };

  const RefreshPageButton = ({ ...rest }) => (
    <RoundedButton
      {...rest}
      color="primary"
      variant="outlined"
      startIcon={<Refresh />}
      onClick={() => setFilter({ ...filter })}
    >
      Atualizar página
    </RoundedButton>
  );

  /* Sub-card de chat */
  const SubCard = ({ header, body }) => {
    return (
      <div>
        <div className={`${classes.mobileCardHeader}`}>{header}</div>
        <div className={`${(classes.leftAlign, classes.mobileCard)}`}>
          {body}
        </div>
      </div>
    );
  };

  const handleChangeFilter = (filters) => {
    setFilter(filters);
  };

  const ChatTableRow = ({ chat }) => {
    function renderAvatarOrRecipientsNumber() {
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return (
          <ListItemAvatar>
            <Avatar>{chat.NomeUsuarioCriador[0]?.toUpperCase()}</Avatar>
          </ListItemAvatar>
        );
      }
      return (
        <div className={classes.recipientsNumber}>
          <span className={classes.formatNumberRecip}>
            {chat.QtdDestinatarios}
          </span>
        </div>
      );
    }

    function renderPrimaryContent() {
      return (
        <span className={classes.centerItems}>
          {isMobile ? (
            chat.NomeUsuarioCriador
          ) : (
            <span className={classes.assunto}>
              {chat.CodigoSaidaEvento != null
                ? `${chat.DescricaoEvento}`
                : chat.IdAssuntoMsgLote == null && `${chat.NomeUsuarioCriador}`}
              {chat.Assunto !== null && chat.Assunto !== '' && chat.Assunto}
            </span>
          )}

          <span>
            <Badge
              badgeContent={chat.QtdMensagensNaoLidas}
              style={{ marginLeft: '18px' }}
              max={999}
              color="primary"
            />
          </span>
        </span>
      );
    }

    function renderSecondaryContent() {
      return isMobile
        ? handleViewLastMessageMobile(chat.UltimaMensagem)
        : handleViewLastMessage(
            chat.UltimaMensagem,
            chat.UltimaMensagemDataEnvio
          );
    }

    function renderMessageTypeCardContent() {
      if (chat.CodigoSaidaEvento != null) {
        return (
          <div className={classes.mobileTableCell} variant="contained">
            Autorização
          </div>
        );
      }
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return (
          <div className={classes.mobileTableCell} variant="contained">
            Mensagem
          </div>
        );
      }
      if (chat.AssuntoMsgLote != null) {
        return (
          <div className={classes.mobileTableCell} variant="contained">
            Mensagem lote
          </div>
        );
      }
      return <div />;
    }

    function renderMessageStatusCardContent() {
      const messageStatus = statusMap[chat.SolicitaEncerramento][chat.Ativo];
      if (chat.SolicitaEncerramento !== null) {
        return <div className={classes.mobileTableCell}>{messageStatus}</div>;
      }
      return <div />;
    }

    function renderMessageTypeLabel() {
      if (chat.CodigoSaidaEvento != null) {
        return (
          <div className={classes.containerLabel}>
            <div className={classes.labelAutorization}>Autorização</div>
          </div>
        );
      }
      if (chat.CodigoSaidaEvento === null && chat.AssuntoMsgLote === null) {
        return (
          <div className={classes.containerLabel}>
            <div className={classes.labelMessage}>Mensagem</div>
          </div>
        );
      }
      if (chat.AssuntoMsgLote != null) {
        return (
          <div className={classes.containerLabel}>
            <div className={classes.labelMessageLote}>Mensagem lote</div>
          </div>
        );
      }
      return <div />;
    }

    function renderOpenConversationButton() {
      return (
        (chat.CodigoSaidaEvento === null || chat.AssuntoMsgLote === null) && (
          <RoundedButton
            color="primary"
            variant="contained"
            className={`${classes.mobileButton}`}
            style={{
              height: '35px',
              whiteSpace: 'nowrap',
              width: '150px',
            }}
            onClick={() => handleChatMessage(chat.IdChatSetor)}
          >
            Abrir conversa
          </RoundedButton>
        )
      );
    }

    function renderMobileCardContent() {
      return (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SubCard header="Tipo" body={renderMessageTypeCardContent()} />
          </Grid>
          <Grid item xs={6}>
            <SubCard header="Status" body={renderMessageStatusCardContent()} />
          </Grid>
          <Grid item xs={6}>
            <SubCard
              header="Data e hora"
              body={handleViewLastMessageHourMobile(
                chat.UltimaMensagemDataEnvio
              )}
              classes={{ content: classes.leftAlign }}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.MobileButtonContainer}>
              {renderOpenConversationButton()}
            </div>
          </Grid>
        </Grid>
      );
    }

    return (
      <TableRowFocusable key={chat.IdChatSetor} hover>
        <TableCell
          className={` ${
            isMobile ? classes.mobileTableCell : classes.tableCell
          }`}
          style={{ minWidth: '350px' }}
          onClick={() => handleChatMessage(chat.IdChatSetor)}
        >
          <div
            style={{
              border: isMobile ? '1px solid #ccc' : 'none',
              borderRadius: isMobile ? '10px' : '0',
              boxShadow: isMobile
                ? '0 0 5px 5px rgba(204, 204, 204, 0.5)'
                : 'none',
            }}
          >
            <ListItem
              style={{
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: 'flex-start',
              }}
            >
              {isMobile ? '' : renderAvatarOrRecipientsNumber(chat, classes)}
              <ListItemText
                primary={renderPrimaryContent(chat, classes)}
                secondary={renderSecondaryContent(chat, isMobile, classes)}
              />
              {isMobile &&
                renderMobileCardContent(chat, handleChatMessage, classes)}
            </ListItem>
          </div>
        </TableCell>
        <TableCell
          className={isMobile ? classes.mobileNull : classes.tableCellCustom}
        >
          {chat?.UsuarioAtendente?.name}
        </TableCell>
        <TableCell className={classes.tableCellCustom}>
          {statusMap[chat.SolicitaEncerramento][chat.Ativo]}
        </TableCell>
        {isMobile ? null : (
          <>
            <TableCell className={`${classes.tableCellIcons} `}>
              {chat?.Ativo && (
                <RegisterReadButton
                  infoChat={chat}
                  handleUpdate={(value) => handleUpdateChatRead(value)}
                />
              )}
              <IconButton onClick={() => handleChatMessage(chat.IdChatSetor)}>
                <Chat color="primary" />
              </IconButton>
            </TableCell>
          </>
        )}
      </TableRowFocusable>
    );
  };

  return (
    <DefaultPage
      title={
        assunto != null
          ? `${assunto} | Mensagem Lote`
          : chats[0]?.CodigoSaidaEvento != null
          ? 'Autorização'
          : 'Mensagem'
      }
    >
      <Container>
        <div hidden={!adminMode} className={classes.divModoAdmTag}>
          <Alert severity="warning" style={{ fontSize: '12px' }}>
            Modo Administrador - Permite apenas a edição de mensagens já
            enviadas por qualquer setor. Não é possível enviar novas mensagens
            ou realizar outras ações.
          </Alert>
        </div>
        <Toolbar>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            RELATÓRIO
          </Typography>
        </Toolbar>
        <div className={classes.divContainerDetails}>
          <div
            className={classes.divPanelDetails}
            onClick={() => setOpenSendDetailsModal(true)}
          >
            <div className={classes.rowDetails}>
              <span>DETALHES DO ENVIO</span>
              <Send className={classes.iconSize} />
            </div>
            <div hidden={!loadStatistics}>
              <span className={classes.numberDatail}>{recipientNumber}</span>
            </div>
            <div className={classes.skeletonSize} hidden={loadStatistics}>
              <Skeleton animation="wave" />
            </div>
          </div>
          <div
            style={{ cursor: 'default' }}
            className={classes.divPanelDetails}
          >
            <div className={classes.rowDetails}>
              <span>NOVAS MENSAGENS</span>
              <Message className={classes.iconSize} />
            </div>
            <div hidden={!loadStatistics}>
              <span className={classes.numberDatail}>{numberNewMessages}</span>
            </div>
            <div className={classes.skeletonSize} hidden={loadStatistics}>
              <Skeleton animation="wave" />
            </div>
          </div>
        </div>
        <Toolbar className={classes.customToolbar}>
          <Typography
            variant="subtitle2"
            style={{ fontSize: '1.2em' }}
            color="primary"
          >
            GERENCIAR DE MENSAGENS
          </Typography>
        </Toolbar>
        <Toolbar className={classes.customToolbarSub}>
          <RoundedInput
            variant="outlined"
            placeholder="Buscar"
            size="small"
            fullWidth
            className={classes.input}
            onBlur={(e) => handleSearch(e.target.value)}
            onKeyPress={(e) => handleSendEnterPress(e.target.value, e)}
          />
          <FilterChat
            filter={filter}
            sectors={sectors}
            handleChangeFilter={handleChangeFilter}
            localStorageName="filterChatsInBatch"
            showSectorSection={false}
            showTypeSection={false}
          />
          {!isMobile && (
            <RefreshPageButton
              style={{ minWidth: '170px', marginLeft: '15px' }}
            />
          )}
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            disabled={!loadStatistics}
            onClick={handleChatInBatch}
          >
            Mensagens em lote
          </RoundedButton>
        </Toolbar>

        {isMobile && (
          <div style={{ textAlign: 'center', marginBottom: '.5em' }}>
            <RefreshPageButton />
          </div>
        )}

        <TableContainer>
          <Table>
            {isMobile ? null : (
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    Monitoramento
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Atendente
                  </TableCell>
                  <TableCell className={classes.tableCellCustom}>
                    Status
                  </TableCell>
                  <TableCell className={classes.tableCellIcons}>
                    <RoundedButton
                      endIcon={<ExpandMore />}
                      className={classes.customButtonDefault}
                      aria-controls="simple-menu-situation"
                      aria-haspopup="true"
                      onClick={handleOpenSituation}
                    >
                      Ordenar por
                    </RoundedButton>
                    <Menu
                      id="simple-menu-situation"
                      anchorEl={anchorElSituations}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      open={Boolean(anchorElSituations)}
                      onClose={() => handleCloseSituation}
                    >
                      {availableSituations.map((s) => (
                        <MenuItem
                          key={s.id}
                          onClick={() => handleSituations(s.id)}
                        >
                          <span className={classes.containerIcon}>
                            {s.id === filter.order && (
                              <ListItemIcon className={classes.listIconCustom}>
                                <Done fontSize="small" />
                              </ListItemIcon>
                            )}
                          </span>
                          <Typography variant="inherit">
                            {s.description}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        {new Array(6)
                          .fill()
                          .map((_, i) => i)
                          .map((_, i) => (
                            <TableCell key={i}>
                              <Skeleton animation="wave" />
                            </TableCell>
                          ))}
                      </TableRowFocusable>
                    ))
                : chats?.map((chat) => <ChatTableRow chat={chat} />)}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={(filter.page ?? 1) - 1}
          onChangePage={handleChangePage}
        />
      </Container>

      <InitChatAutorizationModal
        open={openInitAutorizationChat}
        handleClose={() => setOpenInitAutorizationChat(false)}
        sectors={sectors ?? []}
      />
      <SendDetailsBatchModal
        open={openSendDetailsModal}
        handleClose={() => setOpenSendDetailsModal(false)}
        numberViewed={numberViewed}
        numberNotViewed={numberNotViewed}
        recipientNumber={recipientNumber}
        idAssuntoMsgLote={idAssuntoMsgLote}
      />
    </DefaultPage>
  );
};
