import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import SettingsForm from './settingsForm';
import { LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { id } = useParams();

  const [setting, setSetting] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get(`/getSettingById/${id}`)
      .then(async (response) => {
        setSetting(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [id]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <SettingsForm isEdit={true} headerTitle="Edição de configuração" setting={setting}></SettingsForm>
          )}
        </>
      )}
    </>
  );
};
