/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import UserForm from './UserForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { userId } = useParams();

  const userApplications = useSelector((state) => state.profile?.applications);

  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get(`users/${userId}`)
      .then((response) => {
        const userRoles = response.data.roles.map((role) => role.name);
        const infos = {
          login: response.data.login,
          name: response.data.name,
          email: response.data.email,
          roles: userRoles,
          selectedApplications: [
            ...new Set(
              response.data.userAppPermissions.map((uap) =>
                String(uap.application_id)
              )
            ),
          ],
          selectedPermissions: [
            ...new Set(
              response.data.userAppPermissions.map(
                (uap) => `${uap.application_id},${uap.appRole.name}`
              )
            ),
          ],
          selectedEstablishments: [
            ...new Set(
              response.data.userAppPermissions
                .filter((uap) => uap.establishment_id !== null)
                .map(
                  (uap) =>
                    `${uap.application_id},${uap.appRole.name},${uap.establishment_id}`
                )
            ),
          ],
        };
        setUserInfo(infos);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [userId]);

  return (
    <DefaultPage title="Edição de Usuário">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading || userApplications.length === 0 ? (
            <LoadingForm />
          ) : (
            <UserForm
              isEdit
              userInfo={{ applications: userApplications, ...userInfo }}
              userId={userId}
            />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
