import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  TablePagination,
  TextField,
  MenuItem,
  Grid,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
  ButtonGroup,
} from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Skeleton } from '@material-ui/lab';
import DefaultPage from '../../templates/DefaultPage';
import { SearchInput, RoundedInput, LoadingButton } from '../../components';
import { Creators as AlertActions } from '../../store/ducks/alert';
import api from '../../services/api';
import { trackPDF, trackExcel } from '../../reports/HelloIArrived/trackReport';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '10%',
    marginRight: '10%',
    width: '100%',
    '@media (max-width:600px)': {
      marginLeft: '3%',
      marginRight: '3%',
    },
  },
  divTop: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '3em',
  },
  divBottom: {
    alignItems: 'right',
    width: '100%',
    marginTop: '3em',
  },
  divItems: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1.5em',
    width: '100%',
  },
  divReport: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '3.5em',
    marginBottom: '2.5em',
    width: '100%',
  },
  customButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  notFound: {
    marginTop: '3.5em',
    textAlign: 'center',
    width: '100%',
  },
  downloadButton: {
    marginTop: '1.5em',
    marginLeft: 'auto',
  },
  customItem: {
    marginTop: '1.5em',
    marginRight: '10px',
    flexDirection: 'row',
  },
  customGrid: {
    marginBottom: '5px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '5px',
  },
  customGridLoad: {
    marginBottom: '10px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '10px',
  },
  reportTitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  subButton: {
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
  },
  customParagraph: {
    margin: '0.4em',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const availableSituations = [
    {
      name: 'todos',
      description: 'Todos',
    },
    {
      name: 'cancelado',
      description: 'Cancelado',
    },
    {
      name: 'chegou',
      description: 'Chegou',
    },
    {
      name: 'estaProximo',
      description: 'Está próximo',
    },
    {
      name: 'estudanteEntregue',
      description: 'Estudante entregue',
    },
  ];

  const typeFiles = {
    PDF: 0,
    XLSX: 1,
  };

  const perPage = 20;
  const [openDownload, setOpenDownload] = useState(false);
  const anchorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [name, setName] = useState(null);
  const [date, setDate] = useState(null);
  const [situation, setSituation] = useState(availableSituations[0].name);
  const [report, setReport] = useState(null);
  const [reportFilter, setReportFilter] = useState(null);

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const handleGetReport = async (params) => {
    return api
      .get('/olaCheguei/getReport', { params })
      .then((response) => {
        return response.data.relatorio;
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Falha ao obter relatório!',
        });
        return null;
      });
  };

  const handleSetReport = (data, filter) => {
    setReportFilter(filter);
    setReport(data);
  };

  const handleSend = async (page = 1) => {
    setIsLoading(true);

    const params = { name, date, situation, page, limit: perPage };
    const dataReport = await handleGetReport(params);

    if (dataReport) {
      if (dataReport.Total > 0) {
        handleSetReport(dataReport, params);
      } else {
        setAlert({
          isOpen: true,
          type: 'warning',
          message: 'Nenhum registro encontrado!',
        });

        handleSetReport(null, null);
      }
    }

    setIsLoading(false);
  };

  const handleChangePage = (event, nextPage) => handleSend(nextPage + 1);

  const handleNotFound = () => {
    setAlert({
      isOpen: true,
      type: 'warning',
      message: 'Nenhum registro a ser emitido!',
    });
  };

  const handleDocumentDownload = async (typeFile) => {
    setOpenDownload(false);
    setIsDownloading(true);

    if (!reportFilter) {
      handleNotFound();
      setIsDownloading(false);
      return;
    }

    const params = {
      name: reportFilter.name,
      date: reportFilter.date,
      situation: reportFilter.situation,
    };
    const dataReport = await handleGetReport(params);

    if (dataReport)
      switch (typeFile) {
        case typeFiles.PDF:
          trackPDF(dataReport.Data);
          break;
        default:
          trackExcel(dataReport.Data);
      }
    else handleNotFound();

    setIsDownloading(false);
  };

  const loadInfo = (info) => (info == null ? 'ND' : info);

  const loadInfoDate = (info) => {
    try {
      return info == null ? 'ND' : moment(info).format('HH:mm - DD/MM/YYYY');
    } catch {
      return 'Indisponível';
    }
  };

  const handleToggle = () => setOpenDownload((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDownload(false);
  };

  return (
    <DefaultPage title="Relatório">
      <div className={classes.root}>
        <div className={classes.divTop}>
          <SearchInput onChange={(text) => setName(text.trim())} />
          <LoadingButton
            className={classes.customButton}
            color="primary"
            onClick={() => handleSend()}
            isLoading={isLoading}
            variant="contained"
          >
            Filtrar
          </LoadingButton>
        </div>
        <div className={classes.divItems}>
          <TextField
            type="date"
            className={classes.customItem}
            onChange={(e) => setDate(e.target.value)}
          />
          <RoundedInput
            className={classes.customItem}
            select
            size="small"
            value={situation}
            onChange={(e) => setSituation([e.target.value])}
          >
            {availableSituations.map((s) => (
              <MenuItem key={s.name} value={s.name}>
                {s.description}
              </MenuItem>
            ))}
          </RoundedInput>
          <ButtonGroup
            className={classes.downloadButton}
            ref={anchorRef}
            aria-label="split button"
            variant="text"
          >
            <LoadingButton
              color="primary"
              aria-controls={openDownload ? 'split-button-menu' : undefined}
              aria-expanded={openDownload ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              endIcon={<GetAppIcon />}
              isLoading={isDownloading}
            >
              Baixar relatório
            </LoadingButton>
          </ButtonGroup>

          <Popper
            open={openDownload}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        onClick={() => handleDocumentDownload(typeFiles.PDF)}
                        className={classes.subButton}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDocumentDownload(typeFiles.XLSX)}
                        className={classes.subButton}
                      >
                        Excel
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>

        <div className={classes.divReport}>
          {isLoading ? (
            new Array(5).fill().map(() => (
              <Grid className={classes.customGridLoad} container spacing={2}>
                {new Array(3).fill().map(() => (
                  <>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                      <Skeleton animation="wave" />
                    </Grid>
                  </>
                ))}
              </Grid>
            ))
          ) : (
            <>
              {report && (
                <>
                  {report.Data.map((r) => (
                    <Grid className={classes.customGrid} container spacing={0}>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>
                            Responsável:{' '}
                          </span>
                          {loadInfo(r.NomeCompletoResponsavel)}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>Acesso: </span>
                          {loadInfo(r.Acesso)}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>Status: </span>
                          {loadInfo(r.Status)}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>
                            Estudante:{' '}
                          </span>
                          {loadInfo(r.Estudante)}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>Curso: </span>
                          {loadInfo(r.Ciclo)} - {loadInfo(r.Curso)} -{' '}
                          {loadInfo(r.Turma)}
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <p className={classes.customParagraph}>
                          <span className={classes.reportTitle}>
                            Hora/Data:{' '}
                          </span>
                          {loadInfoDate(r.Data)}
                        </p>
                      </Grid>
                      {r.Justificativa && (
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <p className={classes.customParagraph}>
                            <span className={classes.reportTitle}>
                              Justificativa:{' '}
                            </span>
                            {r.Justificativa}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  ))}

                  <div className={classes.divBottom}>
                    <TablePagination
                      rowsPerPageOptions={[report.PerPage]}
                      component="div"
                      count={report.Total}
                      rowsPerPage={report.PerPage}
                      page={report.Page - 1}
                      onChangePage={handleChangePage}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </DefaultPage>
  );
};
