import React, { useState, useEffect } from 'react';
import {
  SearchInput,
  RoundedButton,
  Container,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import DefaultPage from '../../templates/DefaultPage';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, FileCopy } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import api from '../../services/api';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';

const useStyles = makeStyles(() => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  title: {
    flexGrow: '1',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default () => {

  const debounce = useDebounce();
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [gates, setGates] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogCloneOpen, setIsDialogCloneOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [idGateToDelete, setIdGateToDelete] = useState(0);
  const [idGateToClone, setIdGateToClone] = useState(0);
  const [isDialogCloneLoading, setIsDialogCloneLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const perPage = 10;

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  const handleOpenEditPage = (idPortao) => history.push(`/gates/editGate${idPortao}`);

  const handleOpenRegisterPage = () => history.push(`/gates/createGate`);

  useEffect(() => {
    const params = {
      page,
      limit: perPage,
    };
    if (queryText) {
      params.query = queryText;
    }

    setIsLoading(true);
    api
      .get('gates/getGates', { params })
      .then((response) => {
        setTotal(response.data.total);
        setGates(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
    // eslint-disable-next-line
  }, [page, queryText]);

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIdGateToDelete(0);
  };

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setGates([]);
    setPage(1);
    setTotal(0);
  };

  //DELETE
  const deleteGate = (id) => {
    setIdGateToDelete(id);
    setIsDialogOpen(true);
  };

  const handleDeleteError = () => {
    setIsDialogLoading(false);
    setIsDialogOpen(false);
    setIdGateToDelete(0);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível excluir o portão!',
    });
  };

  const handleDelete = () => {
    setIsDialogLoading(true);

    const params = {
      id: idGateToDelete
    };

    api
      .delete(`gates/deleteGate`, { params })
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Portão excluído com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogOpen(false);
        setIdGateToDelete(0);
        window.location.reload();
      })
      .catch((err) => {
        handleDeleteError(err);
      });
  };
  
  //CLONE
  const cloneGate = (id) => {
    setIdGateToClone(id);
    setIsDialogCloneOpen(true);
  };

  const closeCloneDialog = () => {
    setIsDialogCloneOpen(false);
    setIdGateToClone(0);
  };

  const handleCloneError = () => {
    setIsDialogCloneLoading(false);
    setIsDialogCloneOpen(false);
    setIdGateToClone(0);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível clonar o portão!',
    });
  };

  const handleCloneNews = () => {
    setIsDialogCloneLoading(true);

    const params = {
      id: idGateToClone,
    };

    api
      .post(`gates/cloneGate`, params)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Portão clonado com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogCloneOpen(false);
        setIdGateToClone(0);
        window.location.reload();
      })
      .catch((err) => {
        handleCloneError(err);
      });
  };


  return (
    <DefaultPage title="Gerenciar Portões">
      <Container>
      <ConfirmationDialog
          title="Excluir este portão?"
          open={isDialogOpen}
          isConfirmLoading={isDialogLoading}
          handleConfirm={handleDelete}
          handleClose={() => closeDialog()}
        />
        <ConfirmationDialog
          title="Clonar este portão?"
          open={isDialogCloneOpen}
          isConfirmLoading={isDialogCloneLoading}
          handleConfirm={handleCloneNews}
          handleClose={() => closeCloneDialog()}
        />
      <Toolbar>
        <SearchInput onChange={debounce(handleChangeQueryText)} />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
      </Toolbar>
      <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Titulo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : gates?.map((ga) => (
                    <TableRowFocusable
                      key={ga.id}
                      onClick={() => {}}
                      hover
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.title}>{ga.descricao}</div>
                        <div className={classes.icons}>
                          <Edit
                            color="primary"
                            onClick={() => handleOpenEditPage(ga.id)}
                          />
                          <FileCopy
                            color="primary"
                            onClick={() => cloneGate(ga.id)}
                          />
                          <DeleteForever
                            color="primary"
                            onClick={() => deleteGate(ga.id)}
                          />
                        </div>
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};