import React from 'react';
import { TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const StyleTableRow = withStyles({
  root: {
    cursor: 'pointer',
    '&:focus': {
      backgroundColor: '#0000000a',
    },
  },
})(TableRow);

export default ({ children, onClick = () => {}, ...rest }) => {
  const handleKeyPress = (event) => {
    const code = event.which || event.keyCode || 0;
    if (code === 13 || code === 32) {
      onClick();
    }
  };
  return (
    <StyleTableRow
      {...rest}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      tabIndex="0"
    >
      {children}
    </StyleTableRow>
  );
};
