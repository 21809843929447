import React from 'react';
import { List, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Recipient } from '.';

const useStyles = makeStyles((theme) => ({
  alertCustom: {
    width: '100%',
  },
  overCustom: {
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
    maxHeight: '380px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5F5F5',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D8D8D8',
      },
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
      borderRadius: '3px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: '#E6E6E6',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
    },
  },
}));

export function RecipientList({
  recipients,
  handleSelectRecipient,
  hasMoreRecipients,
  loadingMore,
  loadingRecipient,
  handleRequestRecipients,
  selectedTargets,
  recipientNameSearch,
}) {
  const classes = useStyles();
  return (
    <div
      style={{
        marginTop: '15px',
        textAlign: 'center',
      }}
    >
      {loadingRecipient ? <CircularProgress size={30} color="primary" /> : null}
      {recipients && recipients.length > 0 ? (
        <List className={classes.overCustom}>
          {recipients.map((recipient) => (
            <Recipient.Item
              key={recipient.CodigoPessoa}
              handleSelect={handleSelectRecipient}
              recipient={recipient}
            />
          ))}
          {hasMoreRecipients && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              {loadingMore ? (
                <CircularProgress size={30} color="primary" />
              ) : (
                <Button
                  color="primary"
                  onClick={() =>
                    handleRequestRecipients(
                      selectedTargets,
                      recipientNameSearch,
                      true
                    )
                  }
                >
                  Carregar mais
                </Button>
              )}
            </div>
          )}
        </List>
      ) : (
        <Alert className={classes.alertCustom} severity="info">
          Nenhum destinatário encontrado!
        </Alert>
      )}
    </div>
  );
}
