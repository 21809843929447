import { createActions, createReducer } from 'reduxsauce';
import { extractUserFromToken, nameInitials } from '../../helpers';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setToken: ['token'],
  logout: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = () => {
  const userFromToken = extractUserFromToken(localStorage.getItem('token'));

  if (!userFromToken) {
    localStorage.removeItem('token');
    localStorage.removeItem('application');
    localStorage.removeItem('selectedfilters');
  } else {
    userFromToken.initials = nameInitials(userFromToken.name);
  }

  return {
    user: userFromToken,
    token: localStorage.getItem('token'),
    isAuthenticated: !!userFromToken,
  };
};

const setToken = (state = INITIAL_STATE(), action) => {
  localStorage.setItem('token', action.token);
  const userFromToken = extractUserFromToken(action.token);
  if (!userFromToken) {
    localStorage.removeItem('token');
    localStorage.removeItem('application');
    localStorage.removeItem('selectedfilters');
  } else {
    userFromToken.initials = nameInitials(userFromToken.name);
  }

  return {
    ...state,
    token: action.token,
    user: userFromToken,
    isAuthenticated: !!userFromToken,
  };
};
const logout = (state = INITIAL_STATE()) => {
  localStorage.removeItem('token');
  localStorage.removeItem('application');
  localStorage.removeItem('selectedfilters');
  return { ...state, isAuthenticated: false };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE(), {
  [Types.SET_TOKEN]: setToken,
  [Types.LOGOUT]: logout,
});
