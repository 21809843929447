import React from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { RoundedButton } from '../../components';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  customDialogContent: {
    textAlign: 'center',
    marginLeft: '4em',
    marginRight: '4em',
    marginBottom: '2em',
  },
  buttons: {
    marginTop: '1.5em',
  },
  customButton: {
    borderRadius: '5px !important',
  },
  confirmButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
}));

export default ({
  handleClose,
  openDelete,
  dialogUser,
  confirmDelete
}) => {
  const classes = useStyles();

  return (
    <Dialog 
      fullWidth={true}
      maxWidth={'sm'} 
      onClose={handleClose} 
      aria-labelledby="customized-dialog-title" 
      open={openDelete}>
      <DialogActions>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.customDialogContent}>
        <DialogContentText>
          <Typography component={'span'} variant="h5" color="primary">
            Este usuário será apagado
          </Typography>
        </DialogContentText>
        <DialogContentText>
          Tem certeza que deseja excluir o usuário <strong>{dialogUser && dialogUser.name}</strong>?
        </DialogContentText>

        <div className={classes.buttons}>
          <RoundedButton 
            variant="outlined"
            color="primary" 
            className={classes.customButton}
            onClick={handleClose}>
            Cancelar
          </RoundedButton>
          <RoundedButton 
            className={classes.confirmButton}
            variant="contained"
            color="primary"
            onClick={confirmDelete}>
            Confirmar
          </RoundedButton>          
        </div>

      </DialogContent>
    </Dialog>
  );
};
