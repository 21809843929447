import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Box,
  TextField,
  MenuItem,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import ShortTextIcon from '@material-ui/icons/ShortText';
import SubjectIcon from '@material-ui/icons/Subject';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  RoundedButton,
  RoundedInput,
  LoadingButton,
} from '../../components';
import { Creators as AlertActions } from '../../store/ducks/alert';
import ConfirmCloseModal from '../../components/ConfirmCloseModal';

import DefaultPage from '../../templates/DefaultPage';
import api from '../../services/api';
import SemIcone from '../../assets/icons/iconsRD/semIcone.svg';
import Banho from '../../assets/icons/iconsRD/banho.svg';
import Calcado from '../../assets/icons/iconsRD/calcado.svg';
import Dores from '../../assets/icons/iconsRD/dores.svg';
import Emergencia from '../../assets/icons/iconsRD/emergencia.svg';
import Febre from '../../assets/icons/iconsRD/febre.svg';
import Fruta from '../../assets/icons/iconsRD/fruta.svg';
import Hidratacao from '../../assets/icons/iconsRD/hidratacao.svg';
import Lanche from '../../assets/icons/iconsRD/lanche.svg';
import Mamadeira from '../../assets/icons/iconsRD/mamadeira.svg';
import Observacoes from '../../assets/icons/iconsRD/observacoes.svg';
import Outros from '../../assets/icons/iconsRD/outros.svg';
import Refeicao from '../../assets/icons/iconsRD/refeicao.svg';
import Sono from '../../assets/icons/iconsRD/sono.svg';
import Toilet from '../../assets/icons/iconsRD/toilet.svg';
import Trocas from '../../assets/icons/iconsRD/trocas.svg';
import Balao from '../../assets/icons/iconsRD/balao.svg';
import SmileFeliz from '../../assets/icons/iconsRD/smileFeliz.svg';
import SmileTriste from '../../assets/icons/iconsRD/smileTriste.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  btnAddTab: {
    borderRadius: '0px',
  },
  iconAddTab: {
    color: '#00796B',
    width: '20px',
    height: '20px',
  },
  divContFields: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '0.5em',
    marginBottom: '1.5em',
  },
  field70: {
    width: '70%',
  },
  field100: {
    width: '100%',
  },
  comboOptions: {
    width: '28%',
  },
  divIconOptions: {
    display: 'flex',
    alignItems: 'center',
  },
  iconTypeOption: {
    marginRight: '0.3em',
    width: '19px',
    height: '19px',
    filter:
      'invert(33%) sepia(99%) saturate(413%) hue-rotate(124deg) brightness(91%) contrast(101%)',
  },
  iconOption: {
    marginRight: '0.5em',
    width: '19px',
    height: '19px',
  },
  iconOptionColor: {
    marginRight: '0.5em',
    width: '19px',
    height: '19px',
    filter:
      'invert(33%) sepia(99%) saturate(413%) hue-rotate(124deg) brightness(91%) contrast(101%)',
  },
  containerOptions: {
    display: 'flex',
    marginTop: '2em',
    marginBottom: '0.5em',
  },
  rowOption: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
  },
  optionsColumn: {
    display: 'flex',
    alignItems: 'center',
    flex: 1.5,
  },
  checkColumn: {
    display: 'flex',
    justifyContent: 'center',
    flex: 0.5,
  },
  checkBox: {
    padding: '15px',
  },
  complementColumn: {
    display: 'flex',
    flex: 1,
  },
  textTypeComplement: {
    width: '90%',
  },
  textDescriptionComplement: {
    width: '100%',
  },
  optionIcon: {
    width: '20px',
    height: '20px',
    marginRight: '0.3em',
  },
  textSingleAnswer: {
    flex: 1,
  },
  btnDeleteOption: {
    minWidth: '34px',
  },
  checkBoxSingleAnswer: {
    flex: 0.5,
  },
  btnAddOption: {
    lineHeight: 'initial',
  },
  iconAddOption: {
    color: '#00796B',
  },
  containerOptionsButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnDeleteTab: {
    minWidth: '40px',
  },
  breakColumn: {
    display: 'flex',
    alignItems: 'center',
    flex: 1.5,
  },
  timeField: {
    width: '5em',
    marginLeft: '0.8em',
    marginRight: '2em',
  },
  divSaveButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  saveButton: {
    margin: '0px 15px 15px 0px',
  },
  textField: {
    flex: 1.5,
  },
  exempleText: {
    marginBottom: '1em',
  },
}));

export default ({
  isEdit = false,
  isClone = false,
  headerTitle = 'Cadastro de itens',
  item = {},
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [establishments, setEstablishments] = useState([]);
  const [registeredIdentifier, setRegisteredIdentifier] = useState([]);
  const [valueTab, setValueTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [textButtonSave, setTextButtonSave] = useState('Salvar');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const exampleText = 'Exemplo de visualização do item no aplicativo:';
  const iconOptions = [
    {
      originalName: 'semIcone',
      description: 'Sem ícone',
      htmlIcon: <img alt="" src={SemIcone} className={classes.iconOption} />,
    },
    {
      originalName: 'banho',
      description: 'Banho',
      htmlIcon: <img alt="" src={Banho} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'balao',
      description: 'Balão',
      htmlIcon: <img alt="" src={Balao} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'calcado',
      description: 'Calçado',
      htmlIcon: (
        <img alt="" src={Calcado} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'dores',
      description: 'Dores',
      htmlIcon: <img alt="" src={Dores} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'emergencia',
      description: 'Emergência',
      htmlIcon: (
        <img alt="" src={Emergencia} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'febre',
      description: 'Febre',
      htmlIcon: <img alt="" src={Febre} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'fruta',
      description: 'Fruta',
      htmlIcon: <img alt="" src={Fruta} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'hidratacao',
      description: 'Hidratação',
      htmlIcon: (
        <img alt="" src={Hidratacao} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'lanche',
      description: 'Lanche',
      htmlIcon: <img alt="" src={Lanche} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'mamadeira',
      description: 'Mamadeira',
      htmlIcon: (
        <img alt="" src={Mamadeira} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'observacoes',
      description: 'Observações',
      htmlIcon: (
        <img alt="" src={Observacoes} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'outros',
      description: 'Outros',
      htmlIcon: <img alt="" src={Outros} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'refeicao',
      description: 'Refeição',
      htmlIcon: (
        <img alt="" src={Refeicao} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'smileFeliz',
      description: 'Rosto Feliz',
      htmlIcon: (
        <img alt="" src={SmileFeliz} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'smileTriste',
      description: 'Rosto Triste',
      htmlIcon: (
        <img alt="" src={SmileTriste} className={classes.iconOptionColor} />
      ),
    },
    {
      originalName: 'sono',
      description: 'Sono',
      htmlIcon: <img alt="" src={Sono} className={classes.iconOptionColor} />,
    },
    {
      originalName: 'toilet',
      description: 'Toilet',
      htmlIcon: <img alt="" src={Toilet} className={classes.iconOptionColor} />,
    },
  ];
  const typeOptions = [
    {
      type: 'radio',
      description: 'Resposta única',
      componentIcon: (
        <RadioButtonCheckedIcon className={classes.iconTypeOption} />
      ),
    },
    {
      type: 'time',
      description: 'Tempo (inicio/fim)',
      componentIcon: <AccessTimeIcon className={classes.iconTypeOption} />,
    },
    {
      type: 'time',
      description: 'Tempo (duração)',
      componentIcon: <HourglassEmptyIcon className={classes.iconTypeOption} />,
    },
    {
      type: 'textbox',
      description: 'Texto parágrafo',
      componentIcon: <SubjectIcon className={classes.iconTypeOption} />,
    },
    {
      type: 'text',
      description: 'Texto simples',
      componentIcon: <ShortTextIcon className={classes.iconTypeOption} />,
    },
  ];
  const complementTypeOptions = [
    { type: 'text', description: 'Texto (30 caracteres)' },
    { type: 'textbox', description: 'Texto (50 caracteres)' },
    { type: 'time', description: 'Hora' },
    { type: 'number', description: 'Quantidade' },
  ];
  const [items, setItems] = useState([
    {
      labelTab: 'Item',
      tipo: 'radio',
      tipoDescricao: 'Resposta única',
      id: -1,
      identificador: '',
      descricaoIcone: 'semIcone',
      descricao: '',
      idEstabelecimento: null,
      opcoes: [
        {
          id: -1,
          descricao: 'Opção',
          tipo: 'radio',
          tipoDescricao: 'Resposta única',
          usaComplemento: false,
          complementoDescricao: '',
          complementoTipo: '',
          complementoTipoDescricao: '',
        },
      ],
    },
  ]);

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  useEffect(() => {
    if (isClone) {
      setTextButtonSave('Clonar');
      item.identificador = `Cópia_${item.identificador}`;
      setItems([item]);
    }
    if (isEdit) {
      setItems([item]);
    }

    const params = { appRole: ['coordination', 'admin'] };

    api
      .get('getEstablishmentsDR', { params })
      .then((response) => {
        setEstablishments(response.data);
        if (!isEdit && !isClone && response.data.length === 1) {
          handleSetItems(response.data[0].idEstabelecimento, 0, 3);
        }
      })
      .catch((err) => handleQueryError(err));

    api
      .get('getRegisteredIdentifier', { params })
      .then((response) => {
        if (isEdit) {
          const index = response.data.indexOf(item.identificador);
          response.data.splice(index, 1);
          setRegisteredIdentifier(response.data);
        } else {
          setRegisteredIdentifier(response.data);
        }
      })
      .catch((err) => handleQueryError(err));
  }, []);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleAddTab = () => {
    setItems([
      ...items,
      {
        labelTab: 'Item',
        tipo: 'radio',
        tipoDescricao: 'Resposta única',
        id: -1,
        identificador: '',
        descricaoIcone: 'semIcone',
        descricao: '',
        idEstabelecimento:
          establishments.length === 1
            ? establishments[0].idEstabelecimento
            : null,
        opcoes: [
          {
            id: -1,
            descricao: 'Opção',
            tipo: 'radio',
            tipoDescricao: 'Resposta única',
            usaComplemento: false,
            complementoDescricao: '',
            complementoTipo: '',
            complementoTipoDescricao: '',
          },
        ],
      },
    ]);
    setValueTab(items.length);
  };

  const handleSetItems = (newValue, itemIndex, field) => {
    setItems(
      items.map((item, index) => {
        if (index === itemIndex) {
          switch (field) {
            case 0:
              item.identificador = newValue;
              if (newValue !== '') {
                item.labelTab = newValue;
              } else {
                item.labelTab = 'Item';
              }
              break;
            case 1:
              item.descricaoIcone = newValue;
              break;
            case 2:
              item.descricao = newValue;
              break;
            case 3:
              item.idEstabelecimento = newValue;
              break;
            default:
          }
        }
        return item;
      })
    );
  };

  const handleSetSingleAnswerOption = (
    newValue,
    itemIndex,
    optIndex,
    field
  ) => {
    setItems(
      items.map((item, index1) => {
        if (index1 === itemIndex) {
          item.opcoes.map((opt, index2) => {
            if (index2 === optIndex) {
              switch (field) {
                case 0:
                  opt.descricao = newValue;
                  break;
                case 1:
                  opt.usaComplemento = newValue;
                  if (!newValue) {
                    opt.complementoTipo = '';
                    opt.complementoDescricao = '';
                    opt.complementoTipoDescricao = '';
                  }
                  break;
                case 2:
                  const typeOpt = complementTypeOptions.find(
                    (e) => e.type == newValue
                  );
                  opt.complementoTipo = typeOpt.type;
                  opt.complementoTipoDescricao = typeOpt.description;
                  break;
                case 3:
                  opt.complementoDescricao = newValue;
                  break;
                default:
              }
            }
            return opt;
          });
        }
        return item;
      })
    );
  };

  const handleChangeTypeOption = (newValue, itemIndex) => {
    const typeOpt = typeOptions.find((e) => e.description == newValue);

    setItems(
      items.map((item, index) => {
        if (index === itemIndex) {
          item.opcoes = [];
          if (typeOpt.description === 'Tempo (inicio/fim)') {
            item.opcoes.push(
              {
                id: -1,
                descricao: newValue === 'Resposta única' ? 'Opção' : '',
                tipo: typeOpt.type,
                tipoDescricao: typeOpt.description,
                usaComplemento: false,
                complementoDescricao: '',
                complementoTipo: '',
                complementoTipoDescricao: '',
              },
              {
                id: -1,
                descricao: newValue === 'Resposta única' ? 'Opção' : '',
                tipo: typeOpt.type,
                tipoDescricao: typeOpt.description,
                usaComplemento: false,
                complementoDescricao: '',
                complementoTipo: '',
                complementoTipoDescricao: '',
              }
            );
          } else {
            item.opcoes.push({
              id: -1,
              descricao: newValue === 'Resposta única' ? 'Opção' : '',
              tipo: typeOpt.type,
              tipoDescricao: typeOpt.description,
              usaComplemento: false,
              complementoDescricao: '',
              complementoTipo: '',
              complementoTipoDescricao: '',
            });
          }
          item.tipo = typeOpt.type;
          item.tipoDescricao = typeOpt.description;
        }
        return item;
      })
    );
  };

  const handleAddOption = (itemIndex) => {
    setItems(
      items.map((item, index) => {
        if (index === itemIndex) {
          item.opcoes.push({
            id: -1,
            descricao: 'Opção',
            tipo: item.tipo,
            tipoDescricao: item.tipoDescricao,
            usaComplemento: false,
            complementoDescricao: '',
            complementoTipo: '',
          });
        }
        return item;
      })
    );
  };

  const handleDeleteOption = (itemIndex, optIndex) => {
    setItems(
      items.map((item, index) => {
        if (index === itemIndex) {
          if (item.opcoes.length > 1) {
            item.opcoes.splice(optIndex, 1);
          }
        }
        return item;
      })
    );
  };

  const handleDeleteTab = (itemIndex) => {
    if (items.length > 1) {
      items.splice(itemIndex, 1);
      setItems(items);
      setValueTab(itemIndex - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEdit) {
      setOpenConfirmationDialog(true);
    } else {
      handleEditItem();
    }
  };

  const handleEditItem = async () => {
    setOpenConfirmationDialog(false);
    setIsLoading(true);
    let blankFields = 0;
    let identifierAlreadyExists = -1;

    items.forEach((item, index) => {
      if (blankFields === 0) {
        if (
          item.identificador.trim() === '' ||
          item.descricao.trim() === '' ||
          item.idEstabelecimento === null
        ) {
          handleFieldError(
            index,
            item.identificador,
            item.descricao,
            item.idEstabelecimento
          );
          blankFields++;
          return;
        }
        item.opcoes.forEach((opt) => {
          if (opt.descricao.trim() === '') {
            handleFieldErrorOpt(index, 'Verificar campos obrigatórios!');
            blankFields++;
          }
        });
        identifierAlreadyExists = registeredIdentifier.findIndex(
          (r) => r === item.identificador
        );
        if (identifierAlreadyExists >= 0) {
          handleFieldErrorOpt(index, 'Identificador já cadastrado!');
          blankFields++;
        }
      }
    });

    if (blankFields === 0) {
      const payload = {
        registros: items.map((item) => item),
      };

      if (isEdit) {
        api
          .put('editItem', payload)
          .then(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'success',
              message: 'Item atualizado com sucesso!',
            });
            history.push(`/manage-itemsDR`);
          })
          .catch(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'error',
              message: 'Ocorreu um erro ao atualizar o item.',
            });
          });
      } else {
        api
          .post('registerItems', payload)
          .then(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'success',
              message: isClone
                ? 'Iten clonado com sucesso!'
                : 'Itens cadastrados com sucesso!',
            });
            history.push(`/manage-itemsDR`);
          })
          .catch(() => {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'error',
              message: isClone
                ? 'Ocorreu um erro ao clonar o item.'
                : 'Ocorreu um erro ao cadastrar os itens.',
            });
          });
      }
    }
  };

  const handleFieldError = (
    index,
    identificador,
    descricao,
    idEstabelecimento
  ) => {
    let msg = 'Verificar campos obrigatórios!';
    if (idEstabelecimento === null) msg = 'Campo Estabelecimento obrigatório!';
    else if (identificador.trim() === '')
      msg = 'Campo Identificador obrigatório!';
    else if (descricao.trim() === '') msg = 'Campo Descrição obrigatório!';
    setIsLoading(false);
    setValueTab(index);
    setAlert({
      isOpen: true,
      type: 'warning',
      message: msg,
    });
  };

  const handleFieldErrorOpt = (index, msg) => {
    setIsLoading(false);
    setValueTab(index);
    setAlert({
      isOpen: true,
      type: 'warning',
      message: msg,
    });
  };

  const handleCancel = () => history.push('/manage-itemsDR');

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
  };

  return (
    <DefaultPage title="Registro diário">
      <Container>
        <ConfirmCloseModal
          handleClose={() => setOpenConfirmationDialog(false)}
          open={openConfirmationDialog}
          confirm={() => handleEditItem()}
          title="Edição de item"
          message="ATENÇÃO! A edição do item afetará todos os registros. As respostas salvas serão apagadas. Tem certeza? Esta ação não poderá ser desfeita. Ao salvar, mesmo sem alterar nada, as respostas vinculadas também serão apagadas."
        />
        <form onSubmit={handleSubmit}>
          <Toolbar>
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1.6em' }}
              color="primary"
            >
              {headerTitle}
            </Typography>
          </Toolbar>
          <Container>
            <Tabs
              className={classes.containerTabs}
              value={valueTab}
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
            >
              {items.map((item, itemIndex) => (
                <Tab
                  key={itemIndex}
                  label={item.labelTab}
                  tabIndex={itemIndex}
                  className={classes.tabs}
                />
              ))}
              {!isClone && !isEdit ? (
                <RoundedButton
                  className={classes.btnAddTab}
                  onClick={handleAddTab}
                >
                  <AddCircleOutline className={classes.iconAddTab} />
                </RoundedButton>
              ) : (
                <></>
              )}
            </Tabs>
            {items.map((item, itemIndex) => (
              <TabPanel key={itemIndex} value={valueTab} index={itemIndex}>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: '1em' }}
                  color="primary"
                >
                  Estabelecimento
                </Typography>
                <div className={classes.divContFields}>
                  <RoundedInput
                    required
                    className={classes.field100}
                    select
                    label="Estabelecimento"
                    variant="outlined"
                    InputLabelProps={{ shrink: item.idEstabelecimento != null }}
                    value={item.idEstabelecimento}
                    onChange={(e) =>
                      handleSetItems(e.target.value, itemIndex, 3)
                    }
                  >
                    {establishments.map((estab) => (
                      <MenuItem
                        key={estab.idEstabelecimento}
                        value={estab.idEstabelecimento}
                      >
                        {estab.descricao}
                      </MenuItem>
                    ))}
                  </RoundedInput>
                </div>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: '1em' }}
                  color="primary"
                >
                  Identificador
                </Typography>
                <div className={classes.divContFields}>
                  <TextField
                    required
                    label="Ex.: Registro diário manhã"
                    value={item.identificador}
                    variant="outlined"
                    inputProps={{ maxLength: 45 }}
                    onChange={(e) =>
                      handleSetItems(e.target.value, itemIndex, 0)
                    }
                    className={classes.field70}
                  />
                  <RoundedInput
                    className={classes.comboOptions}
                    select
                    variant="outlined"
                    value={item.descricaoIcone}
                    onChange={(e) =>
                      handleSetItems(e.target.value, itemIndex, 1)
                    }
                  >
                    {iconOptions.map((io) => (
                      <MenuItem key={io.description} value={io.originalName}>
                        <div className={classes.divIconOptions}>
                          {io.htmlIcon}
                          {io.description}
                        </div>
                      </MenuItem>
                    ))}
                  </RoundedInput>
                </div>
                <Typography
                  variant="subtitle2"
                  style={{ fontSize: '1em' }}
                  color="primary"
                >
                  Descrição do item
                </Typography>
                <div className={classes.divContFields}>
                  <TextField
                    required
                    label="Ex.: Jantar"
                    value={item.descricao}
                    variant="outlined"
                    inputProps={{ maxLength: 45 }}
                    onChange={(e) =>
                      handleSetItems(e.target.value, itemIndex, 2)
                    }
                    className={classes.field70}
                  />
                  <RoundedInput
                    className={classes.comboOptions}
                    select
                    variant="outlined"
                    value={item.tipoDescricao}
                    onChange={(e) =>
                      handleChangeTypeOption(e.target.value, itemIndex)
                    }
                  >
                    {typeOptions.map((typeOpt) => (
                      <MenuItem
                        key={typeOpt.description}
                        value={typeOpt.description}
                      >
                        <div className={classes.divIconOptions}>
                          {typeOpt.componentIcon}
                          {typeOpt.description}
                        </div>
                      </MenuItem>
                    ))}
                  </RoundedInput>
                </div>
                <>
                  <div className={classes.containerOptions}>
                    <div style={{ flex: 1.5 }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: '1em' }}
                      >
                        {item.typeOptionSelected === 'Resposta única'
                          ? 'OPÇÕES'
                          : 'DESCRIÇÃO'}
                      </Typography>
                    </div>
                    <div style={{ flex: 0.5 }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: '1em', textAlign: 'center' }}
                      >
                        COMPLEMENTO
                      </Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: '1em' }}
                      >
                        TIPO COMPLEMENTO
                      </Typography>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Typography
                        variant="subtitle1"
                        style={{ fontSize: '1em' }}
                      >
                        DESCRIÇÃO COMPLEMENTO
                      </Typography>
                    </div>
                  </div>
                  {item.opcoes.map((opt, optIndex) => (
                    <div key={optIndex} className={classes.rowOption}>
                      <div className={classes.optionsColumn}>
                        {(item.tipoDescricao === 'Resposta única' && (
                          <RadioButtonCheckedIcon
                            className={classes.optionIcon}
                          />
                        )) ||
                          (item.tipoDescricao === 'Respostas múltiplas' && (
                            <CheckBoxOutlinedIcon
                              className={classes.optionIcon}
                            />
                          ))}
                        {item.tipoDescricao === 'Resposta única' ? (
                          <>
                            <TextField
                              required
                              className={classes.textSingleAnswer}
                              value={opt.descricao}
                              inputProps={{ maxLength: 45 }}
                              onChange={(e) =>
                                handleSetSingleAnswerOption(
                                  e.target.value,
                                  itemIndex,
                                  optIndex,
                                  0
                                )
                              }
                            />
                            <RoundedButton
                              className={classes.btnDeleteOption}
                              onClick={() =>
                                handleDeleteOption(itemIndex, optIndex)
                              }
                            >
                              <CloseIcon />
                            </RoundedButton>
                          </>
                        ) : (
                          <>
                            <div className={classes.textField}>
                              <TextField
                                required
                                label="Descrição"
                                variant="outlined"
                                className={classes.textDescriptionComplement}
                                value={opt.descricao}
                                inputProps={{ maxLength: 30 }}
                                onChange={(e) =>
                                  handleSetSingleAnswerOption(
                                    e.target.value,
                                    itemIndex,
                                    optIndex,
                                    0
                                  )
                                }
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className={classes.checkColumn}>
                        <Checkbox
                          checked={opt.usaComplemento}
                          color="primary"
                          className={classes.checkBox}
                          onChange={() =>
                            handleSetSingleAnswerOption(
                              !opt.usaComplemento,
                              itemIndex,
                              optIndex,
                              1
                            )
                          }
                        />
                      </div>
                      <div className={classes.complementColumn}>
                        <RoundedInput
                          select
                          variant="outlined"
                          disabled={!opt.usaComplemento}
                          value={opt.complementoTipo}
                          onChange={(e) =>
                            handleSetSingleAnswerOption(
                              e.target.value,
                              itemIndex,
                              optIndex,
                              2
                            )
                          }
                          className={classes.textTypeComplement}
                        >
                          {complementTypeOptions.map((typeOpt, index) => (
                            <MenuItem key={index} value={typeOpt.type}>
                              <div>{typeOpt.description}</div>
                            </MenuItem>
                          ))}
                        </RoundedInput>
                      </div>
                      <div className={classes.complementColumn}>
                        <TextField
                          variant="outlined"
                          label="Descrição complemento"
                          disabled={!opt.usaComplemento}
                          className={classes.textDescriptionComplement}
                          value={opt.complementoDescricao}
                          inputProps={{ maxLength: 45 }}
                          onChange={(e) =>
                            handleSetSingleAnswerOption(
                              e.target.value,
                              itemIndex,
                              optIndex,
                              3
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                  {(item.tipoDescricao === 'Resposta única' && (
                    <RoundedButton
                      className={classes.btnAddOption}
                      onClick={() => handleAddOption(itemIndex)}
                      startIcon={
                        <AddCircleOutline className={classes.iconAddOption} />
                      }
                      color="primary"
                    >
                      Adicionar opção
                    </RoundedButton>
                  )) ||
                    (item.tipoDescricao === 'Tempo (inicio/fim)' && (
                      <>
                        <Typography
                          variant="h1"
                          color="primary"
                          className={classes.exempleText}
                          style={{ fontSize: '1.2em' }}
                        >
                          {exampleText}
                        </Typography>
                        <div className={classes.breakColumn}>
                          <Typography
                            variant="h1"
                            style={{ fontSize: '1.2em' }}
                          >
                            Início
                          </Typography>
                          <TextField
                            disabled
                            variant="outlined"
                            value="00:00"
                            size="small"
                            className={classes.timeField}
                          />
                          <Typography
                            variant="h1"
                            style={{ fontSize: '1.2em' }}
                          >
                            Fim
                          </Typography>
                          <TextField
                            disabled
                            variant="outlined"
                            value="00:00"
                            size="small"
                            className={classes.timeField}
                          />
                        </div>
                      </>
                    )) ||
                    (item.tipoDescricao === 'Tempo (duração)' && (
                      <>
                        <Typography
                          variant="h1"
                          color="primary"
                          className={classes.exempleText}
                          style={{ fontSize: '1.2em' }}
                        >
                          {exampleText}
                        </Typography>
                        <div className={classes.breakColumn}>
                          <Typography
                            variant="h1"
                            style={{ fontSize: '1.2em' }}
                          >
                            Duração
                          </Typography>
                          <TextField
                            disabled
                            variant="outlined"
                            value="00:00"
                            size="small"
                            className={classes.timeField}
                          />
                        </div>
                      </>
                    )) ||
                    (item.tipoDescricao === 'Texto parágrafo' && (
                      <Typography
                        variant="h1"
                        style={{ fontSize: '1.2em', fontStyle: 'italic' }}
                      >
                        Limite: Aceita respostas até 1000 caracteres.
                      </Typography>
                    )) ||
                    (item.tipoDescricao === 'Texto simples' && (
                      <Typography
                        variant="h1"
                        style={{ fontSize: '1.2em', fontStyle: 'italic' }}
                      >
                        Limite: Aceita respostas até 100 caracteres.
                      </Typography>
                    ))}
                </>
                <div className={classes.containerOptionsButtons}>
                  <Tooltip title="Excluir">
                    <span>
                      <RoundedButton
                        className={classes.btnDeleteTab}
                        onClick={() => handleDeleteTab(itemIndex)}
                        disabled={itemIndex === 0}
                        color="primary"
                      >
                        <DeleteForeverIcon />
                      </RoundedButton>
                    </span>
                  </Tooltip>
                </div>
              </TabPanel>
            ))}
          </Container>
          <div className={classes.divSaveButton}>
            <RoundedButton
              className={classes.saveButton}
              variant="contained"
              onClick={handleCancel}
            >
              Cancelar
            </RoundedButton>
            <LoadingButton
              className={classes.saveButton}
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isLoading}
            >
              {textButtonSave}
            </LoadingButton>
          </div>
        </form>
      </Container>
    </DefaultPage>
  );
};
