import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Toolbar, Typography, TextField, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, ListAlt } from '@material-ui/icons';
import {
  Container,
  RoundedButton,
  LoadingButton,
  MultipleValueInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';

import DefaultPage from '../../templates/DefaultPage';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  field: {
    marginTop: '0.5em',
    marginBottom: '1.5em',
  },
  field50: {
    marginTop: '0.5em',
    marginBottom: '1.5em',
    maxWidth: '50%',
  },
  divBottomButtons: {
    display: 'flex',
    marginBottom: '15px',
  },
  buttons: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default ({
  isEdit = false,
  headerTitle = 'Cadastro de link',
  link = {},
}) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);
  const [chave] = useState(link?.chave ?? '');
  const [valor, setValor] = useState(link?.valor ?? '');
  const [descEmpresa] = useState(link?.descEmpresa ?? 'Não informado');
  const [descUnidade] = useState(link?.descUnidade ?? 'Não informado');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const registerData = {
      id: link.id,
      valor,
    };

    if (isEdit) {
      api
        .put('editLink', registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Link atualizado com sucesso!',
          });
          history.push(`/links`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao atualizar o link.',
          });
        });
    }
  };

  const handleCancel = () => history.push('/links');

  return (
    <DefaultPage title="Links">
      <Container>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Toolbar>
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1.6em' }}
              color="primary"
            >
              {headerTitle}
            </Typography>
          </Toolbar>
          <div className={classes.formInputs}>
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Identificador
            </Typography>
            <TextField
              disabled
              size="small"
              value={chave}
              variant="outlined"
              className={classes.field50}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Empresa
            </Typography>
            <TextField
              disabled
              size="small"
              value={descEmpresa}
              variant="outlined"
              className={classes.field50}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Unidade
            </Typography>
            <TextField
              disabled
              size="small"
              value={descUnidade}
              variant="outlined"
              className={classes.field50}
            />
            <Typography
              variant="subtitle2"
              style={{ fontSize: '1em' }}
              color="primary"
            >
              Link
            </Typography>
            <TextField
              size="small"
              value={valor}
              variant="outlined"
              onChange={(e) => setValor(e.target.value)}
              className={classes.field}
              inputProps={{ maxLength: 300 }}
            />
            <div className={classes.divBottomButtons}>
              <div className={classes.buttons}>
                <RoundedButton
                  style={{ marginRight: '1em' }}
                  variant="contained"
                  onClick={handleCancel}
                >
                  Cancelar
                </RoundedButton>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                >
                  Salvar
                </LoadingButton>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </DefaultPage>
  );
};
