import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { Creators as AlertActions } from '../../store/ducks/alert';

import { LoadingButton, RoundedInput, BackToButton } from '../../components';
import FormPage from '../../templates/FormPage';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: '16px',
  },
  input: {
    paddingBottom: '10px',
    width: '95%',
    alignSelf: 'center',
  },
  sendButton: {
    marginTop: '1em',
    alignSelf: 'flex-end',
  },
  instructions: {
    padding: '1em',
  },
  backButton: {
    position: 'absolute',
    top: '15px',
    left: '15px',
  },
}));

export default ({ isDefinePassword }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const handleResetError = (err) => {
    setIsLoading(false);
    const dataIsArray = Array.isArray(err.response?.data);

    if (dataIsArray && err.response?.data.find((e) => e.field === 'email')) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Email inválido!',
      });
    } else if (!dataIsArray && err.response?.status === 400) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Link de redefinição inválido!',
      });
    } else if (!dataIsArray && err.response?.data?.error?.status === 500) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Ocorreu um problema inesperado!',
      });
    } else {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'Problema de conexão!',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== passwordConfirmation) {
      setAlert({
        isOpen: true,
        type: 'error',
        message: 'As senhas não são iguais!',
      });
      return;
    }
    setIsLoading(true);
    const resetData = {
      token,
      newPassword: password,
      newPassword_confirmation: passwordConfirmation,
    };
    api
      .post('reset', resetData)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: `Senha ${
            isDefinePassword ? 'definida' : 'alterada'
          } com sucesso!`,
        });
        history.push('/login');
      })
      .catch((err) => {
        handleResetError(err);
      });
  };

  return (
    <FormPage>
      <BackToButton className={classes.backButton} to="/login" />
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.instructions}>
          <Typography variant="h6" color="primary">
            {isDefinePassword ? 'Criar sua senha' : 'Redefinir senha'}
          </Typography>
        </div>
        <RoundedInput
          className={classes.input}
          label="Senha"
          type="password"
          variant="outlined"
          size="small"
          inputProps={{ minLength: '6' }}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <RoundedInput
          className={classes.input}
          label="Confime a senha"
          type="password"
          variant="outlined"
          size="small"
          inputProps={{ minLength: '6' }}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          required
        />
        <LoadingButton
          className={classes.sendButton}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isLoading}
        >
          {isDefinePassword ? 'Criar' : 'Redefinir'}
        </LoadingButton>
      </form>
    </FormPage>
  );
};
