import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setNotification: ['notification'],
  setAnchor: ['anchor'],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  anchorEl: null,
  unread: 0,
  notifications: [],
};

const setNotification = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    unread: action.notification.unread,
    notifications: action.notification.notifications,
  };
};

const setAnchor = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    anchorEl: action.anchor.anchorEl,
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_NOTIFICATION]: setNotification,
  [Types.SET_ANCHOR]: setAnchor,
});
