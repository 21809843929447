export default () => [
  {
    name: 'admin',
    description: 'Administrador',
  },
  {
    name: 'client',
    description: 'Cliente',
  },
  {
    name: 'consulting',
    description: 'Consultoria',
  },
  {
    name: 'support',
    description: 'Suporte',
  },
];
