import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMap,
} from 'react-leaflet';
import InfoIcon from '@material-ui/icons/Info';
import {
  LoadingButton,
  MultipleValueInput,
  Container,
  RoundedButton,
} from '../../components';
import { Creators as AlertActions } from '../../store/ducks/alert';
import DefaultPage from '../../templates/DefaultPage';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    margin: '2em',
  },
  cab: {
    color: theme.palette.primary.main,
    textAlign: 'left',
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '0.2em',
    marginTop: '0em',
  },
  subTitle: {
    color: theme.palette.primary.main,
    fontSize: '17px',
    fontWeight: 'bold',
    marginBottom: '0px',
    marginRight: '0.5em',
  },
  titleText: {
    marginTop: '1em',
    width: '100%',
  },
  divDescEPubFields: {
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    flexFlow: 'row wrap',
    width: '100%',
  },
  divDesc: {
    color: '#414141',
    fontSize: '17px',
    width: '45%',
    minWidth: '250px',
    marginRight: '10px',
  },
  fieldTargetAudience: {
    flexGrow: 1,
    marginTop: '1em',
  },
  createButton: {
    marginLeft: 'auto',
  },
  divToolTip: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  divProximity: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  divMet: {
    minWidth: '100px',
    width: '100px',
    marginRight: '1em',
  },
  divLabelMet: {
    width: '80px',
    minWidth: '80px',
  },
  map: {
    marginTop: '1.5em',
    marginBottom: '1.5em',
  },
  '.leaflet-container': {
    width: '100%',
    height: '400px',
  },
  buttons: {
    display: 'flex',
    margin: '1em 1em',
    justifyContent: 'flex-end',
  },
  createBut: {
    marginLeft: '14px',
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [descricaoPortao, setDescricaoPortao] = useState('');
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState([]);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [distanciaProx, setDistanciaProx] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [startPosition] = useState([-19.79, -51.14]);
  const [markPosition, setMarkPosition] = useState(null);
  const [zoon] = useState(3);
  const [currentLoc, setCurrentLoc] = useState(false);
  const tipSetGate =
    'Ao se aproximar desta distância o responsável será incluido na coluna "Está próximo" do painel de controle.';
  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  useEffect(() => {
    api
      .get('target-audience', {
        params: {
          limit: 1000,
          appRole: ['completeConcierge', 'admin'],
        },
      })
      .then((response) => {
        setTargetAudiences(response.data.data);
      });
  }, []);

  function SetMarker() {
    useMapEvents({
      click(map) {
        setMarkPosition(map.latlng);
      },
    });
    return markPosition === null ? null : (
      <Marker position={markPosition}>
        <Popup>Local do Portão</Popup>
      </Marker>
    );
  }

  function SetCurrentLocal() {
    const map = useMap();
    if (!currentLoc) {
      const loc = map.locate({ setView: true, maxZoom: 14 });
      if (loc != null) {
        setCurrentLoc(true);
      }
    }
    return null;
  }

  const handleCancel = () => history.push('/gates');

  const handleRegisterError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível cadastrar o portão!',
    });
  };

  const handleInfoRequest = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'É necessário informar a localização do portão no mapa!',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    if (markPosition != null) {
      formData.append('lat', markPosition.lat);
      formData.append('lng', markPosition.lng);
    } else {
      return handleInfoRequest();
    }

    formData.append('descricao', descricaoPortao);
    formData.append('distanciaProx', distanciaProx);
    formData.append(
      'idsPublicoAlvo',
      selectedTargetAudiences.map((ta) => ta.id)
    );

    api
      .post('/gates/createGate', formData)
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Portão cadastrado com sucesso!',
        });
        history.push('/gates');
      })
      .catch((err) => {
        handleRegisterError(err);
      });
  };

  return (
    <DefaultPage title="Criar portão">
      <Container>
        <form className={classes.form} onSubmit={handleSubmit}>
          <p className={classes.cab}>Cadastro de portão</p>
          <div className={classes.divDescEPubFields}>
            <div className={classes.divDesc}>
              <p className={classes.subTitle}>Descrição do portão</p>
              <TextField
                label="Descrição do portão"
                className={classes.titleText}
                variant="outlined"
                onChange={(e) => setDescricaoPortao(e.target.value)}
                required
              />
            </div>
            <div className={classes.divDesc}>
              <p className={classes.subTitle}>Público-alvo</p>
              <MultipleValueInput
                customClasses={classes.fieldTargetAudience}
                label="Procurar..."
                variant="outlined"
                labelProperty="descricao"
                value={selectedTargetAudiences}
                options={targetAudiences}
                onChange={(event, value) => setSelectedTargetAudiences(value)}
              />
            </div>
          </div>
          <div className={classes.divToolTip}>
            <p className={classes.subTitle}>Notificação por proximidade</p>
            <Tooltip title={tipSetGate}>
              <InfoIcon color="primary" />
            </Tooltip>
          </div>
          <div className={classes.divProximity}>
            <div className={classes.divMet}>
              <TextField
                label="123"
                className={classes.titleText}
                variant="outlined"
                onChange={(e) => setDistanciaProx(e.target.value)}
                type="number"
              />
            </div>
            <div className={classes.divLabelMet}>
              <TextField
                label="Metros"
                className={classes.titleText}
                variant="outlined"
                disabled
              />
            </div>
          </div>
          <p className={classes.subTitle}>
            Selecione a localização do portão no mapa:
          </p>
          <div className={classes.map}>
            <MapContainer
              center={startPosition}
              zoom={zoon}
              scrollWheelZoom
              className={classes['.leaflet-container']}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <SetCurrentLocal />
              <SetMarker />
            </MapContainer>
          </div>
          <div className={classes.buttons}>
            <div>
              <RoundedButton variant="contained" onClick={handleCancel}>
                Cancelar
              </RoundedButton>
            </div>
            <div className={classes.createBut}>
              <LoadingButton
                className={classes.createButton}
                type="submit"
                variant="contained"
                color="primary"
                isLoading={isLoading}
              >
                Cadastrar
              </LoadingButton>
            </div>
          </div>
        </form>
      </Container>
    </DefaultPage>
  );
};
