import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Html5QrcodeScanner } from "html5-qrcode";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Fab } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory } from 'react-router-dom';
import { io } from 'socket.io-client';
import CryptoJS from "crypto-js";

export default () => {
  const history = useHistory();
  const [socket, setSocket] = useState(null);
  const application = useSelector((state) => state.profile?.application);

  const startSocket = () => {
    setSocket(
      io(`${process.env.REACT_APP_API_WEBSOCKET}`, {
        auth: {
          token: application.app_key,
        },
      })
    );
  };

  useEffect(() => {
    if (application) {
      startSocket();
    }
  }, [application]);

  useEffect(() => {
    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    const onScanSuccess = (decodedText, decodedResult) => {
      var bytes = CryptoJS.AES.decrypt(decodedText, 'R1ZPbGFDaGVndWVpU2VjcmV0');
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (socket !== null && decryptedData !== null) {
        socket.emit("arrived", socket.id, decryptedData);
      }

      html5QrcodeScanner.clear();
      history.push(`/control-panel-hello-i-arrived`);
    }

    const onScanFailure = (error) => {
      console.warn(`Code scan error = ${error}`);
    }

    let html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      /* verbose= */ false
    );
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

  }, []);

  return (
    <div>
      <Link to="/control-panel-hello-i-arrived">
        <Fab style={{ margin: 10 }} color="primary">
          <ArrowBack />
        </Fab>
      </Link>
      <div id="reader" width="600px"></div>
      <Button>
      </Button>
    </div>
  );
};


