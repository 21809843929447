import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
  },
  itemText: {
    paddingLeft: '2em',
    opacity: '0.75',
  },
  subSectionItem: {
    paddingLeft: '4em',
    opacity: '0.75',
  },
  activeLink: {
    fontWeight: 'bold',
  },
}));

export default ({ title, items }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const hasItems = items.length > 0;
  const defaultMenuOptions = {
    exact: true,
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getMenuOptions = (item) => {
    return {
      ...defaultMenuOptions,
      ...item?.menuOptions,
    };
  };

  return (
    <List className={classes.root} hidden={!hasItems}>
      <ListItem button className={classes.itemText} onClick={handleClick}>
        <ListItemText
          primary={title}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {items?.map((i) => (
          <ListItem
            key={i.name}
            className={classes.subSectionItem}
            button
            component={NavLink}
            exact={getMenuOptions(i).exact}
            to={i.path}
            activeClassName={classes.activeLink}
          >
            {i.name}
          </ListItem>
        ))}
      </Collapse>
    </List>
  );
};
