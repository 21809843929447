import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Creators as UserActions } from '../../store/ducks/user';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { name, initials } = useSelector((state) => state.user?.user);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    localStorage.removeItem('filterSectorMessage');
    localStorage.removeItem('filterChatsInBatch');
    localStorage.removeItem('admMode');
    localStorage.removeItem('chatSketch');
    dispatch(UserActions.logout());
  };

  return (
    <div className={classes.root}>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{initials}</Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography>{name}</Typography>
        </ListItemText>
      </ListItem>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'center', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Perfil</MenuItem>
        <MenuItem onClick={handleLogout}>Sair</MenuItem>
      </Menu>
    </div>
  );
};
