import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useParams } from 'react-router-dom';
import {
  Container,
  RoundedInput,
  RoundedButton,
  LoadingButton,
  ConfirmationDialog,
  AutoCompleteSearchInput,
  NotFound,
} from '../../components';
import DefaultPage from '../../templates/DefaultPage';
import ApiConfiguration from './apiConfiguration';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
  },
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  button: {
    marginLeft: '1em',
  },
}));

export default () => {
  const classes = useStyles();
  const { applicationId } = useParams();
  const debounce = useDebounce();
  const setAlert = useAlert();

  const [name, setName] = useState('');
  const [appKey, setAppKey] = useState('');
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isUserQueryLoading, setIsUserQueryLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userQuery, setUserQuery] = useState('');
  const [notFound, setNotFound] = useState(false);

  const handleDeleteError = () => {
    setIsDialogLoading(false);
    setIsDialogOpen(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível excluir o usuário!',
    });
  };

  const handleDelete = () => {
    setIsDialogLoading(true);
    api
      .delete(`applications/${applicationId}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Aplicação excluída com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogOpen(false);
      })
      .catch((err) => {
        handleDeleteError(err);
      });
  };

  const handleEditError = (err) => {
    setIsLoading(false);
    const errorMessage = err?.response?.data?.find((e) => e.message)?.message;

    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível editar a aplicação!',
    });
  };

  const handleQueryUserError = () => {
    setIsUserQueryLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setUsers([]);
  };

  const handleChangeQueryText = (event, text) => {
    setUser((actualUser) => {
      if (text.trim() !== actualUser?.name) {
        setUserQuery(text.trim());
      }

      return actualUser;
    });
  };

  const handleUserChange = (event, value) => {
    setUser(value);
  };

  const usersHasThisUser = (usersToFind, userToFind) =>
    usersToFind.find((usr) => usr.id === userToFind.id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const editData = {
      name,
      owner_id: user.id,
      app_key: appKey,
    };

    api
      .put(`applications/${applicationId}`, editData)
      .then(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Aplicação atualizada com sucesso!',
        });
      })
      .catch((err) => {
        handleEditError(err);
      });
  };

  useEffect(() => {
    api
      .get(`applications/${applicationId}`)
      .then(async (response) => {
        await setUsers((actualUsers) => {
          if (
            response.data?.user &&
            !usersHasThisUser(actualUsers, response.data?.user)
          ) {
            return [response.data.user, ...actualUsers];
          }
          return actualUsers;
        });
        setUser(response.data?.user);
        setName(response.data.name);
        setAppKey(response.data.app_key);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [applicationId]);

  useEffect(() => {
    const params = {
      limit: 5,
    };
    if (userQuery) {
      params.query = userQuery;
    }

    setIsUserQueryLoading(true);
    api
      .get('users', {
        params,
      })
      .then((response) => {
        setUser((actualUser) => {
          setUsers(() => {
            if (
              actualUser &&
              !usersHasThisUser(response.data.data, actualUser)
            ) {
              return [actualUser, ...response.data.data];
            }
            return response.data.data;
          });
          return actualUser;
        });
        setIsUserQueryLoading(false);
      })
      .catch((err) => handleQueryUserError(err));
    // eslint-disable-next-line
  }, [userQuery]);

  return (
    <DefaultPage title="Aplicação">
      {notFound ? (
        <NotFound />
      ) : (
        <div className={classes.root}>
          <Container>
            <form className={classes.form} onSubmit={handleSubmit}>
              <ConfirmationDialog
                title="Excluir esta aplicação?"
                open={isDialogOpen}
                isConfirmLoading={isDialogLoading}
                handleConfirm={handleDelete}
                handleClose={() => setIsDialogOpen(false)}
              />
              <div className={classes.formInputs}>
                <Typography
                  className={classes.title}
                  variant="h5"
                  color="primary"
                >
                  Informações básicas
                </Typography>
                <RoundedInput
                  className={classes.input}
                  label="Nome"
                  size="small"
                  value={name}
                  inputProps={{ minLength: '4' }}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <AutoCompleteSearchInput
                  label="Proprietário"
                  size="small"
                  value={user}
                  options={users}
                  onValueChange={handleUserChange}
                  onTextChange={debounce(handleChangeQueryText)}
                  loading={isUserQueryLoading}
                  required
                />
                <RoundedInput
                  className={classes.input}
                  label="APP Key"
                  size="small"
                  value={appKey}
                  inputProps={{ minLength: '64', maxLength: '64' }}
                  onChange={(e) => setAppKey(e.target.value)}
                />
              </div>
              <div className={classes.buttons}>
                <RoundedButton
                  variant="contained"
                  onClick={() => setIsDialogOpen(true)}
                  startIcon={<DeleteIcon />}
                >
                  Excluir
                </RoundedButton>
                <LoadingButton
                  className={classes.button}
                  type="submit"
                  variant="contained"
                  color="primary"
                  isLoading={isLoading}
                >
                  Salvar
                </LoadingButton>
              </div>
            </form>
          </Container>
          <ApiConfiguration applicationId={applicationId} />
        </div>
      )}
    </DefaultPage>
  );
};
