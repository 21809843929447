import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
  },
  section: {},
}));

const FormSection = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton variant="text" animation="wave" />
      {new Array(10)
        .fill()
        .map((e, i) => i)
        .map((e) => (
          <Skeleton key={e} animation="wave" />
        ))}
    </div>
  );
};

export default () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton variant="text" animation="wave" />
      <FormSection />
      <FormSection />
      <FormSection />
    </div>
  );
};
