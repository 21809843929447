import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RoundedInput } from '..';

export default ({
  options,
  customClasses = '',
  value,
  labelProperty,
  onChange,
  ...rest
}) => {
  return (
    <Autocomplete
      multiple
      className={customClasses}
      options={options}
      value={value}
      getOptionLabel={(option) => option[labelProperty]}
      onChange={onChange}
      renderInput={(params) => <RoundedInput {...params} {...rest} />}
    />
  );
};
