import React from 'react';
import DefaultPage from '../../templates/DefaultPage';
import NewsForm from './NewsForm';
import { Container } from '../../components';

export default () => {
  return (
    <DefaultPage title="Notícias">
      <Container>
        <NewsForm />
      </Container>
    </DefaultPage>
  );
};
