import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FiberManualRecord } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  divIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  number: {
    position: 'absolute',
    marginTop: '1px',
    marginLeft: '1px',
    color: '#FFFFFF',
    fontSize: '12px'
  },
  circleIcon: {
    color: '#00796B',
  },
}));

export default ({ number }) => {
  const classes = useStyles();
  return (
    <div className={classes.divIcon}>
      <span className={classes.number}>{number}</span>
      <FiberManualRecord className={classes.circleIcon} />
    </div>
  );
};
