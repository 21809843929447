import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  paper: {
    display: 'flex',
    flexFlow: 'column',
    padding: '1em',
    margin: '1em',
    width: '100%',
    height: 'fit-content',
    maxWidth: (props) => theme.breakpoints.values[props.maxWidth],
  },
}));

export default React.forwardRef(({ children, maxWidth = 'sm' }, ref) => {
  const classes = useStyles({ maxWidth });
  return (
    <div tabIndex={-1} className={classes.root} ref={ref}>
      <Paper className={classes.paper}>{children}</Paper>
    </div>
  );
});
