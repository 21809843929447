import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, FileCopy, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import ResumeConfirmationDialog from './ResumeConfirmationDialog';
import {
  SearchInput,
  RoundedButton,
  Container,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import { handleMapDescriptions } from './TargetAudienceForm';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';

const useStyles = makeStyles(() => ({
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  title: {
    flexGrow: '1',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default () => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const history = useHistory();

  const perPage = 10;

  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [idTargetToDelete, setIdTargetToDelete] = useState(0);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [descriptions, setDescriptions] = useState({});
  const [loadingResume, setLoadingResume] = useState(false);

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setTargetAudiences([]);
    setPage(1);
    setTotal(0);
  };

  const handleCloseDialog = () => {
    setDialogIsOpen(false);
  };

  const handleOpenResumeDialog = (targetAudienceId) => {
    setLoadingResume(true);
    setDialogIsOpen(true);
    const params = {
      appRole: ['coordination', 'communication', 'completeConcierge', 'admin'],
    };
    api
      .get(`target-audience/info/${targetAudienceId}`, params)
      .then(async (response) => {
        setDescriptions(handleMapDescriptions(response.data));
      })
      .finally(() => {
        setLoadingResume(false);
      });
  };

  const handleOpenEditPage = (targetAudienceId) =>
    history.push(`/target-audience/${targetAudienceId}`);

  const handleOpenClonePage = (targetAudienceId) =>
    history.push(`/target-audience/clone/${targetAudienceId}`);

  const search = (pageP, queryTextP) => {
    const params = {
      page: pageP,
      limit: perPage,
      appRole: ['coordination', 'communication', 'completeConcierge', 'admin'],
    };
    if (queryText) {
      params.query = queryTextP;
    }

    setIsLoading(true);
    api
      .get('target-audience', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setTargetAudiences(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  const doSearch = useCallback(() => {
    search(page, queryText);
    // eslint-disable-next-line
  }, [page, queryText]);

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setIdTargetToDelete(0);
    setIsDeleteLoading(false);
  };

  const handleOpenDeleteDialog = (idTargetAudience) => {
    setIdTargetToDelete(idTargetAudience);
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);

    api
      .delete(`target-audience/${idTargetToDelete}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Público-alvo excluído com sucesso!',
        });
        handleCloseDeleteDialog();
        doSearch();
      })
      .catch(() => {
        handleCloseDeleteDialog();
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Não foi possível excluir o público-alvo!',
        });
      });
  };

  const handleOpenRegisterPage = () =>
    history.push(`/target-audience/register`);

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  return (
    <DefaultPage title="Público-alvo">
      <Container>
        <ConfirmationDialog
          title="Excluir este público-alvo?"
          open={isDeleteDialogOpen}
          isConfirmLoading={isDeleteLoading}
          handleConfirm={handleDelete}
          handleClose={() => handleCloseDeleteDialog()}
        />
        <Toolbar>
          <SearchInput onChange={debounce(handleChangeQueryText)} />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Nome</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : targetAudiences?.map((targetAudience) => (
                    <TableRowFocusable
                      key={targetAudience.id}
                      onClick={() => {}}
                      hover
                    >
                      <TableCell className={classes.tableCell}>
                        <div className={classes.title}>
                          {targetAudience.descricao}
                        </div>
                        <div className={classes.icons}>
                          <Search
                            color="primary"
                            onClick={() =>
                              handleOpenResumeDialog(targetAudience.id)
                            }
                          />
                          <Edit
                            color="primary"
                            onClick={() =>
                              handleOpenEditPage(targetAudience.id)
                            }
                          />
                          <FileCopy
                            color="primary"
                            onClick={() =>
                              handleOpenClonePage(targetAudience.id)
                            }
                          />
                          <DeleteForever
                            color="primary"
                            onClick={() =>
                              handleOpenDeleteDialog(targetAudience.id)
                            }
                          />
                        </div>
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
        />
        <ResumeConfirmationDialog
          open={dialogIsOpen}
          isLoading={loadingResume}
          handleClose={handleCloseDialog}
          descriptions={descriptions}
        />
      </Container>
    </DefaultPage>
  );
};
