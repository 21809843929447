import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  Container,
  RoundedInput,
  RoundedButton,
  LoadingButton,
  AutoCompleteSearchInput,
} from '../../components';
import DefaultPage from '../../templates/DefaultPage';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const debounce = useDebounce();
  const setAlert = useAlert();

  const [name, setName] = useState('');
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserQueryLoading, setIsUserQueryLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userQuery, setUserQuery] = useState('');

  const handleCancel = () => history.push('/applications');

  const handleHandleSuccess = (applicationId) =>
    history.push(`/applications/${applicationId}`);

  const handleRegisterError = (err) => {
    setIsLoading(false);
    const errorMessage = err?.response?.data?.find((e) => e.message)?.message;

    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível cadastrar a aplicação',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const registerData = {
      name,
      owner_id: user.id,
    };

    api
      .post('applications', registerData)
      .then((res) => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Aplicação cadastrada com sucesso!',
        });
        handleHandleSuccess(res.data.id);
      })
      .catch((err) => {
        handleRegisterError(err);
      });
  };

  const handleQueryUserError = () => {
    setIsUserQueryLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setUsers([]);
  };

  const handleChangeQueryText = (event, text) => {
    setUser((actualUser) => {
      if (text.trim() !== actualUser?.name) {
        setUserQuery(text.trim());
      }

      return actualUser;
    });
  };

  const handleUserChange = (event, value) => {
    setUser(value);
  };

  const usersHasThisUser = (usersToFind, userToFind) =>
    usersToFind.find((usr) => usr.id === userToFind.id);

  useEffect(() => {
    const params = {
      limit: 5,
    };
    if (userQuery) {
      params.query = userQuery;
    }

    setIsUserQueryLoading(true);
    api
      .get('users', {
        params,
      })
      .then((response) => {
        setUser((actualUser) => {
          setUsers(() => {
            if (
              actualUser &&
              !usersHasThisUser(response.data.data, actualUser)
            ) {
              return [actualUser, ...response.data.data];
            }
            return response.data.data;
          });
          return actualUser;
        });
        setIsUserQueryLoading(false);
      })
      .catch((err) => handleQueryUserError(err));
    // eslint-disable-next-line
  }, [userQuery]);

  return (
    <DefaultPage title="Cadastro de aplicação">
      <Container>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formInputs}>
            <Typography className={classes.title} variant="h5" color="primary">
              Cadastro
            </Typography>
            <RoundedInput
              className={classes.input}
              label="Nome"
              size="small"
              inputProps={{ minLength: '4' }}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <AutoCompleteSearchInput
              label="Proprietário"
              size="small"
              value={user}
              options={users}
              onValueChange={handleUserChange}
              onTextChange={debounce(handleChangeQueryText)}
              loading={isUserQueryLoading}
              required
            />
          </div>
          <div className={classes.buttons}>
            <RoundedButton variant="contained" onClick={handleCancel}>
              Cancelar
            </RoundedButton>
            <LoadingButton
              className={classes.loadingButton}
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isLoading}
            >
              Cadastrar
            </LoadingButton>
          </div>
        </form>
      </Container>
    </DefaultPage>
  );
};
