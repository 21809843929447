import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { RoundedButton, LoadingButton } from '../../components';
import TargetResume from './TargetResume';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

export default ({
  open,
  isConfirmation = false,
  isEdit = false,
  handleConfirm,
  isLoading = false,
  isConfirmLoading,
  handleClose,
  descriptions,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.root }}
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        <Typography color="primary" variant="h4">
          {isConfirmation
            ? `Confirmar ${isEdit ? 'edição' : 'cadastro'}`
            : 'Resumo'}
        </Typography>
      </DialogTitle>
      <TargetResume isLoading={isLoading} descriptions={descriptions} />
      <DialogActions>
        <RoundedButton variant="contained" onClick={handleClose}>
          Fechar
        </RoundedButton>
        {isConfirmation && (
          <LoadingButton
            onClick={handleConfirm}
            isLoading={isConfirmLoading}
            variant="contained"
            color="primary"
            autoFocus
          >
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
