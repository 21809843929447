import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Typography,
  TextField,
  Box,
  Tab,
  Tabs,
  Checkbox,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close, ErrorOutline, CheckCircle } from '@material-ui/icons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import api from '../../services/api';
import useAlert from '../../hooks/useAlert';
import IconCircleNumber from '../../components/IconCircleNumber';
import {
  RoundedButton,
  RoundedInput,
  ConfirmationDialog,
  LoadingButton,
} from '../../components';
import ClassesModal from '../../components/ClassesModal';
import EnviarArquivo from '../../components/EnviarArquivo';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.grey[500],
  },
  headerMain: {
    padding: '10px 15px 10px 25px',
  },
  addMealRegister: {
    width: '5%',
  },
  iconLabelWrapper: {
    flexDirection: 'row',
  },
  labelIconSize: {
    minHeight: 0,
  },
  cursor: {
    cursor: 'auto',
  },
  divComboEvt: {
    display: 'flex',
    alignItems: 'center',
  },
  comboEvt: {
    marginLeft: '1em',
    flex: 2,
  },
  containerDefault: {
    minHeight: '300px',
  },
  divAutData: {
    marginTop: '1.5em',
    height: '185px',
    overflow: 'auto',
  },
  labelAutData: {
    display: 'flex',
    alignItems: 'center',
  },
  viewOnly: {
    marginLeft: '1em',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E5F6FD',
    padding: '0.3em 0.8em',
    borderRadius: '4px',
    color: '#7D7D7D',
  },
  errorIcon: {
    color: '#7D7D7D',
    marginRight: '0.3em',
    width: '18px',
    height: '18px',
  },
  divInfosEvt: {
    marginTop: '1em',
  },
  divRowFieldsViewOnly: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    marginBottom: '1em',
  },
  divlabel: {
    width: '30%',
  },
  divFields: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
  },
  fieldStyle: {
    padding: '10.5px 14px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '5px',
  },
  fieldDescription: {
    minWidth: '400px',
    minWidth: '150px',
  },
  fieldDescriptionSmall: {
    minWidth: '300px',
    minWidth: '100px',
  },
  fieldDate: {
    minWidth: '150px',
    minWidth: '80px',
    marginRight: '1em',
  },
  fieldStartTime: {
    minWidth: '70px',
    minWidth: '70px',
    marginRight: '1em',
  },
  fieldEndTime: {
    minWidth: '70px',
    minWidth: '70px',
    marginLeft: '1em',
  },
  fieldTimeLimitAut: {
    minWidth: '70px',
    minWidth: '70px',
  },
  fieldValue: {
    minWidth: '215px',
    minWidth: '80px',
  },
  divRowSend: {
    display: 'flex',
    alignItems: 'center',
  },
  divRowSendHow: {
    display: 'flex',
  },
  sendLabel: {
    width: '15%',
  },
  sendLabelHow: {
    width: '15%',
    paddingTop: '10px',
  },
  spacer15: {
    width: '15%',
  },
  inputDate: {
    marginTop: '0.5em',
    marginRight: '1em !important',
  },
  sectors: {
    display: 'flex',
    width: '85%',
    flexWrap: 'wrap',
  },
  sectorChbox: {
    display: 'flex',
    alignItems: 'center',
  },
  studantsField: {
    width: '85%',
  },
  footButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  btnBack: {
    marginLeft: '1em',
  },
  btnContinue: {
    marginLeft: '1em',
  },
  divMessage: {
    display: 'flex',
  },
  divEditor: {
    width: '85%',
  },
  input: {
    width: '100%',
  },
  toolbar: {
    border: 'none',
  },
  editor: {
    border: '1px solid #bdbdbd',
    height: '100px',
    overflow: 'auto',
    '& .public-DraftStyleDefault-block': {
      margin: '0px',
    },
  },
  divContainerConfirmation: {
    height: '280px',
    overflow: 'auto',
  },
  divRowConfirmation: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1em',
  },
  messageLabel: {
    width: '15%',
    alignSelf: 'flex-start',
  },
  divViewMessage: {
    width: '80%',
    height: '100px',
    borderRadius: '5px',
    border: '1px solid rgba(0, 0, 0, 0.26)',
    overflow: 'auto',
    padding: '5px',
    '& p': { margin: '0px' },
  },

  removeFile: {
    padding: '0px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ({ handleClose = () => {}, open = false, sectors = [] }) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [valueTab, setValueTab] = useState(0);
  const [eventSelected, setEventSelected] = useState(null);
  const [events, setEvents] = useState([]);
  const [allLinkedClasses, setAllLinkedClasses] = useState([]);
  const [linkedClasses, setLinkedClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [eventDescription, setEventDescription] = useState(null);
  const [eventDescriptionRed, setEventDescriptionRed] = useState(null);
  const [useDate, setUseDate] = useState(null);
  const [date, setDate] = useState(null);
  const [allDay, setAllDay] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [totalTime, setTotalTime] = useState(null);
  const [useLimitDateAut, setUseLimitDateAut] = useState(null);
  const [limitDateForAut, setLimitDateForAut] = useState(null);
  const [limitAutTime, setLimitAutTime] = useState(null);
  const [limitDateForCancel, setLimitDateForCancel] = useState(null);
  const [limitCancelTime, setLimitCancelTime] = useState(null);
  const [eventValue, setEventValue] = useState(null);
  const [genPay, setGenPay] = useState(null);
  const [scheduleShipping, setScheduleShipping] = useState(false);
  const [sendDate, setSendDate] = useState(moment().format('YYYY-MM-DD'));
  const [sendHour, setSendHour] = useState(moment().format('HH:mm'));
  const [selectedSector, setSelectedSector] = useState(null);
  const [nameSectorSelected, setNameSectorSelected] = useState('');
  const [titleConfirmationDialog, setTitleConfirmationDialog] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogOpenSector, setIsDialogOpenSector] = useState(false);
  const [isDialogOpenMessage, setIsDialogOpenMessage] = useState(false);
  const [allowAnswer, setAllowAnswer] = useState(false);
  const [message, setMessage] = useState('');
  const [isOpenClassesModal, setIsOpenClassesModal] = useState(false);
  const [textButtonContinue, setTextButtonContinue] = useState('Continuar');
  const [titleSendMessages, setTitleSendMessages] = useState('');
  const [
    isOpenFinalConfirmationDialog,
    setIsOpenFinalConfirmationDialog,
  ] = useState(false);
  const [file, setFile] = useState('');
  const [mimetype, setMimeType] = useState('');
  const [base64, setBase64] = useState('');
  const handleChangeTab = () => {
    if (valueTab < 3) {
      if (valueTab === 1 && selectedSector === null && sectors.length > 1) {
        setIsDialogOpenSector(true);
      } else if (valueTab === 2 && message.trim() == '') {
        setIsDialogOpenMessage(true);
      } else {
        setValueTab(valueTab + 1);
        if (valueTab + 1 === 3) {
          setTextButtonContinue('Enviar');
        }
      }
    } else {
      handleFinish();
    }
  };

  const handleBack = () => {
    setValueTab(valueTab - 1);
    if (valueTab - 1 < 3) {
      setTextButtonContinue('Continuar');
    }
  };

  const handleCancel = () => {
    setValueTab(0);
    setEventSelected(null);
    setSelectedSector(null);
    handleClose();
  };

  useEffect(() => {
    const msgDefault =
      'Será necessário aguardar alguns minutos para que todas as mensagens possam ser enviadas.';
    setTitleSendMessages(
      scheduleShipping
        ? `Mensagens agendadas com sucesso. ${msgDefault}`
        : `Mensagens enviadas com sucesso. ${msgDefault}`
    );
  }, [scheduleShipping]);

  useEffect(() => {
    if (open) {
      setIsLoading(true);
      api
        .get(`chat/getEvents`)
        .then(async (response) => {
          if (!response.data.tabelaExistente) {
            setTitleConfirmationDialog('O GVcollege não está atualizado.');
            setIsDialogOpen(true);
          } else if (response.data.mensagemErro != null) {
            handleError(response.data.mensagemErro);
            handleCancel();
          } else if (response.data.eventos.length === 0) {
            setTitleConfirmationDialog(
              'Você já enviou mensagem para todos os eventos cadastrados.'
            );
            setIsDialogOpen(true);
          } else {
            setAllLinkedClasses(response.data.turmasVinculadas);
            setEvents(response.data.eventos);
          }
          setIsLoading(false);
        })
        .catch((err) => handleError(null));

      if (sectors.length === 1) {
        setSelectedSector(sectors[0].Id);
        setNameSectorSelected(sectors[0].Nome);
      }
    }
  }, [open]);

  const handleError = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });
  };

  const handleEventSelected = (eventId) => {
    setEventSelected(eventId);

    setLinkedClasses(allLinkedClasses.filter((c) => c.idEvento === eventId));

    const event = events.find((e) => e.id === eventId);
    setEventDescription(event.descricao);
    setEventDescriptionRed(event.descReduzida);
    setUseDate(event.usaData ? 'Sim' : 'Não');
    if (event.usaData) {
      setDate(moment(event.dataEvento).format('DD/MM/YYYY'));
      if (!event.diaInteiro) {
        setStartTime(moment(event.horaInicio).format('HH:mm'));
        setEndTime(moment(event.horaFinal).format('HH:mm'));
      } else {
        setStartTime('Não');
        setEndTime('Não');
      }
    }
    setAllDay(event.diaInteiro ? 'Sim' : 'Não');
    setUseLimitDateAut(event.usaDataLimiteAut ? 'Sim' : 'Não');
    setLimitDateForAut(moment(event.dataLimiteAut).format('DD/MM/YYYY'));
    setLimitAutTime(moment(event.dataLimiteAut).format('HH:mm'));

    if (event.dataLimiteCancela != null) {
      setLimitDateForCancel(
        moment(event.dataLimiteCancela).format('DD/MM/YYYY')
      );
      setLimitCancelTime(moment(event.dataLimiteCancela).format('HH:mm'));
    } else {
      setLimitDateForCancel('Não');
      setLimitCancelTime('Não');
    }

    setGenPay(event.geraCobranca ? 'Sim' : 'Não');
    if (event.geraCobranca) {
      setEventValue(`R$${event.valor}`);
    }
  };

  const handleSelectorSector = (e, s) => {
    setSelectedSector(e.target.value);
    setNameSectorSelected(s.Nome);
  };

  const handleFinish = async () => {
    setIsLoading(true);

    const registerData = {
      idEvent: eventSelected,
      scheduleShipping,
      sendDate: `${sendDate} ${sendHour}`,
      idSector: selectedSector,
      message,
      allowAnswer,
      autMessage: true,
      fileName: file !== null ? file.name : null,
      mimetype,
      base64,
    };
    api
      .post('chat/createChatAutorization', registerData)
      .then((res) => {
        if (res.data.Sucesso) {
          setIsLoading(false);
          setIsOpenFinalConfirmationDialog(true);
        } else {
          handleError(res.data.Mensagem);
          setIsLoading(false);
        }
      })
      .catch(() => {
        handleError('Ocorreu um erro inesperado ao iniciar chat.');
      });
  };

  const handleFinalConfirmation = () => {
    setIsOpenFinalConfirmationDialog(false);
    history.push('/messageSector');
  };

  const handleRemoveFile = () => {
    setFile('');
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={() => handleCancel()}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <ConfirmationDialog
        title={titleConfirmationDialog}
        open={isDialogOpen}
        handleConfirm={handleCancel}
        handleClose={() => handleCancel()}
      />
      <ConfirmationDialog
        title="Um setor deve ser selecionado."
        open={isDialogOpenSector}
        handleConfirm={() => setIsDialogOpenSector(false)}
        handleClose={() => setIsDialogOpenSector(false)}
      />
      <ConfirmationDialog
        title="O campo mensagem deve ser preenchido."
        open={isDialogOpenMessage}
        handleConfirm={() => setIsDialogOpenMessage(false)}
        handleClose={() => setIsDialogOpenMessage(false)}
      />
      <ConfirmationDialog
        title={titleSendMessages}
        open={isOpenFinalConfirmationDialog}
        handleConfirm={() => handleFinalConfirmation()}
        handleClose={() => handleFinalConfirmation()}
      />
      <DialogActions className={classes.headerMain}>
        <Typography
          component="span"
          color="primary"
          style={{ marginRight: 'auto' }}
        >
          <strong>NOVA AUTORIZAÇÃO</strong>
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => handleCancel()}
        >
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent style={{ padding: '5px 25px 25px 25px' }}>
        <Tabs
          value={valueTab}
          variant="fullWidth"
          TabIndicatorProps={{ style: { background: 'none' } }}
        >
          <Tab
            tabIndex={0}
            label="Autorização"
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIconSize,
              root: classes.cursor,
            }}
            icon={<IconCircleNumber number={1} />}
          />
          <Tab
            label="Envio"
            tabIndex={1}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIconSize,
              root: classes.cursor,
            }}
            icon={<IconCircleNumber number={2} />}
          />
          <Tab
            label="Mensagem"
            tabIndex={2}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIconSize,
              root: classes.cursor,
            }}
            icon={<IconCircleNumber number={3} />}
          />
          <Tab
            label="Confirmação"
            tabIndex={3}
            classes={{
              wrapper: classes.iconLabelWrapper,
              labelIcon: classes.labelIconSize,
              root: classes.cursor,
            }}
            icon={<IconCircleNumber number={4} />}
          />
        </Tabs>
        <TabPanel
          value={valueTab}
          index={0}
          className={classes.containerDefault}
        >
          <div className={classes.divComboEvt}>
            <Typography color="primary">
              <strong>Evento</strong>
            </Typography>
            <RoundedInput
              className={classes.comboEvt}
              select
              label="Selecione a autorização"
              size="small"
              variant="outlined"
              value={eventSelected}
              onChange={(e) => handleEventSelected(e.target.value)}
              required
            >
              {events?.map((evt) => (
                <MenuItem key={evt.id} value={evt.id}>
                  {evt.descricao}
                </MenuItem>
              ))}
            </RoundedInput>
          </div>
          <div className={classes.divAutData} hidden={eventSelected === null}>
            <div className={classes.labelAutData}>
              <Typography color="primary" variant="h6">
                Dados do evento
              </Typography>
              <div className={classes.viewOnly}>
                <ErrorOutline className={classes.errorIcon} />
                <span>
                  Somente visualização, alterações devem ser realizadas via
                  GVCollege.
                </span>
              </div>
            </div>
            <div className={classes.divInfosEvt}>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Descrição</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[
                      classes.fieldDescription,
                      classes.fieldStyle,
                    ].join(' ')}
                  >
                    {eventDescription}
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Descrição reduzida</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[
                      classes.fieldDescriptionSmall,
                      classes.fieldStyle,
                    ].join(' ')}
                  >
                    {eventDescriptionRed}
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Usa data</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {useDate}
                  </div>
                </div>
              </div>
              <div hidden={useDate === 'Não'}>
                <div className={classes.divRowFieldsViewOnly}>
                  <div className={classes.divlabel}>
                    <span>Data/Hora</span>
                  </div>
                  <div className={classes.divFields}>
                    <div
                      className={[classes.fieldDate, classes.fieldStyle].join(
                        ' '
                      )}
                    >
                      {date}
                    </div>
                    <div
                      className={[
                        classes.fieldStartTime,
                        classes.fieldStyle,
                      ].join(' ')}
                    >
                      {startTime}
                    </div>
                    <span>-</span>
                    <div
                      className={[
                        classes.fieldEndTime,
                        classes.fieldStyle,
                      ].join(' ')}
                    >
                      {endTime}
                    </div>
                    <span>{totalTime}</span>
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Dia inteiro</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {allDay}
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Usa data limite para autorizar</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {useLimitDateAut}
                  </div>
                </div>
              </div>
              <div hidden={useLimitDateAut === 'Não'}>
                <div className={classes.divRowFieldsViewOnly}>
                  <div className={classes.divlabel}>
                    <span>Data limite para autorizar</span>
                  </div>
                  <div className={classes.divFields}>
                    <div
                      className={[classes.fieldDate, classes.fieldStyle].join(
                        ' '
                      )}
                    >
                      {limitDateForAut}
                    </div>
                    <div
                      className={[
                        classes.fieldTimeLimitAut,
                        classes.fieldStyle,
                      ].join(' ')}
                    >
                      {limitAutTime}
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Data limite para cancelar</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {limitDateForCancel}
                  </div>
                  <div
                    className={[
                      classes.fieldTimeLimitAut,
                      classes.fieldStyle,
                    ].join(' ')}
                  >
                    {limitCancelTime}
                  </div>
                </div>
              </div>
              <div className={classes.divRowFieldsViewOnly}>
                <div className={classes.divlabel}>
                  <span>Gera cobrança</span>
                </div>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {genPay}
                  </div>
                </div>
              </div>
              <div hidden={genPay === 'Não'}>
                <div className={classes.divRowFieldsViewOnly}>
                  <div className={classes.divlabel}>
                    <span>Valor</span>
                  </div>
                  <div className={classes.divFields}>
                    <div
                      className={[classes.fieldValue, classes.fieldStyle].join(
                        ' '
                      )}
                    >
                      {eventValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          value={valueTab}
          index={1}
          className={classes.containerDefault}
        >
          <div className={classes.divSend}>
            <div className={classes.divRowSend}>
              <Typography color="primary" className={classes.sendLabel}>
                <strong>Envio</strong>
              </Typography>
              <Checkbox
                checked={scheduleShipping}
                disableRipple
                color="primary"
                onChange={() => setScheduleShipping(!scheduleShipping)}
              />
              <span>Agendar envio</span>
            </div>
            <div hidden={!scheduleShipping}>
              <div className={classes.divRowSend}>
                <div className={classes.spacer15} />
                <TextField
                  label="Data"
                  size="small"
                  className={classes.inputDate}
                  variant="outlined"
                  type="date"
                  style={{ marginRight: '8px' }}
                  defaultValue={sendDate}
                  onChange={(e) => setSendDate(e.target.value)}
                />
                <TextField
                  label="Hora"
                  size="small"
                  className={classes.inputDate}
                  variant="outlined"
                  type="time"
                  defaultValue={sendHour}
                  onChange={(e) => setSendHour(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.divRowSendHow}>
              <Typography color="primary" className={classes.sendLabelHow}>
                <strong>Enviar como</strong>
              </Typography>
              <div className={classes.sectors}>
                {sectors && sectors.length > 1 ? (
                  <div className={classes.sectorChbox}>
                    <RadioGroup row defaultValue="top">
                      {sectors.map((s, index) => (
                        <FormControlLabel
                          key={index}
                          value={s.Id}
                          checked={s.Id == selectedSector}
                          onChange={(e) => handleSelectorSector(e, s)}
                          style={{ marginRight: '35px', marginLeft: 'inherit' }}
                          control={
                            <Radio
                              checkedIcon={<CheckCircle />}
                              color="primary"
                            />
                          }
                          label={s.Nome}
                        />
                      ))}
                    </RadioGroup>
                  </div>
                ) : (
                  <div className={classes.sectorChbox}>
                    <RadioGroup row defaultValue="top">
                      <FormControlLabel
                        style={{ marginLeft: '1px' }}
                        value={sectors[0]?.Id}
                        checked
                        disabled
                        control={
                          <Radio
                            checkedIcon={<CheckCircle />}
                            color="primary"
                          />
                        }
                        label={sectors[0]?.Nome}
                      />
                    </RadioGroup>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel
          value={valueTab}
          index={2}
          className={classes.containerDefault}
        >
          <div className={classes.divMessage}>
            <Typography color="primary" className={classes.sendLabel}>
              <strong>Mensagem</strong>
            </Typography>
            <div className={classes.divEditor}>
              <RoundedInput
                className={classes.input}
                label="Mensagem"
                size="small"
                placeholder="Mensagem de até 5000 caracteres"
                multiline
                inputProps={{ maxLength: '5000' }}
                rows={4}
                variant="outlined"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Checkbox
                checked={allowAnswer}
                disableRipple
                color="primary"
                onChange={() => setAllowAnswer(!allowAnswer)}
              />
              <span>Permitir respostas</span>
              <EnviarArquivo
                setFile={setFile}
                setMimeType={setMimeType}
                setBase64={setBase64}
              />
            </div>
          </div>
          <div className={classes.divMessage}>
            <Typography
              color="primary"
              className={classes.sendLabel}
              hidden={file === ''}
            >
              <strong>Anexo</strong>
            </Typography>
            {file !== '' && <span>{file?.name}</span>}
            <div hidden={file === ''}>
              <IconButton
                onClick={handleRemoveFile}
                color="primary"
                className={classes.removeFile}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <span hidden={file === ''}>Anexar</span>
          </div>
        </TabPanel>
        <TabPanel
          value={valueTab}
          index={3}
          className={classes.containerDefault}
        >
          <div className={classes.divContainerConfirmation}>
            <div className={classes.divRowConfirmation}>
              <Typography color="primary" className={classes.sendLabel}>
                <strong>Evento selecionado</strong>
              </Typography>
              <div className={classes.divFieldFull}>
                <div
                  className={[
                    classes.fieldDescription,
                    classes.fieldStyle,
                  ].join(' ')}
                >
                  {eventDescription}
                </div>
              </div>
            </div>
            <div hidden={!scheduleShipping}>
              <div className={classes.divRowConfirmation}>
                <Typography color="primary" className={classes.sendLabel}>
                  <strong>Envio agendado</strong>
                </Typography>
                <div className={classes.divFields}>
                  <div
                    className={[classes.fieldDate, classes.fieldStyle].join(
                      ' '
                    )}
                  >
                    {moment(sendDate).format('DD/MM/YYYY')}
                  </div>
                  <div
                    className={[
                      classes.fieldTimeLimitAut,
                      classes.fieldStyle,
                    ].join(' ')}
                  >
                    {sendHour}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.divRowConfirmation}>
              <Typography color="primary" className={classes.sendLabel}>
                <strong>Enviar como</strong>
              </Typography>
              <div className={classes.divFields}>
                <div className={classes.fieldStyle}>{nameSectorSelected}</div>
              </div>
            </div>
            <div className={classes.divRowConfirmation}>
              <Typography color="primary" className={classes.sendLabel}>
                <strong>Para</strong>
              </Typography>
              <div className={classes.divFields}>
                <span>{linkedClasses.length} Turmas</span>
                <RoundedButton
                  color="primary"
                  variant="outlined"
                  size="small"
                  className={classes.btnBack}
                  onClick={() => setIsOpenClassesModal(true)}
                >
                  Ver turmas
                </RoundedButton>
              </div>
            </div>
            <div className={classes.divRowConfirmation}>
              <Typography color="primary" className={classes.messageLabel}>
                <strong>Mensagem</strong>
              </Typography>
              <div
                style={{ whiteSpace: 'pre-wrap' }}
                className={classes.divViewMessage}
              >
                {message}
              </div>
            </div>
            <div hidden={!allowAnswer}>
              <div className={classes.divRowConfirmation}>
                <div className={classes.spacer15} />
                <span>Será permitido respostas</span>
              </div>
            </div>
            <div className={classes.divRowConfirmation}>
              <Typography color="primary" className={classes.sendLabel}>
                <strong>Anexo</strong>
              </Typography>
              <span>{file?.name}</span>
            </div>
          </div>
        </TabPanel>
        <div className={classes.footButtons}>
          <RoundedButton
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </RoundedButton>
          <div hidden={valueTab === 0}>
            <RoundedButton
              color="primary"
              variant="outlined"
              className={classes.btnBack}
              onClick={() => handleBack()}
            >
              Voltar
            </RoundedButton>
          </div>
          <LoadingButton
            color="primary"
            variant="contained"
            className={classes.btnContinue}
            onClick={() => handleChangeTab()}
            disabled={eventSelected === null}
            isLoading={isLoading}
          >
            {textButtonContinue}
          </LoadingButton>
        </div>
      </DialogContent>
      <ClassesModal
        open={isOpenClassesModal}
        handleClose={() => setIsOpenClassesModal(false)}
        turmas={linkedClasses ?? []}
      />
    </Dialog>
  );
};
