/* eslint-disable radix */
import React from 'react';
import { useSelector } from 'react-redux';
import UserForm from './UserForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '../../components';

export default () => {
  const userApplications = useSelector((state) => state.profile?.applications);
  return (
    <DefaultPage title="Cadastro de Usuário">
      <Container>
        <UserForm userInfo={{ applications: userApplications }} />
      </Container>
    </DefaultPage>
  );
};
