import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ptBR } from '@material-ui/core/locale';
import { EnvRibbon } from './components';
import Routes from './routes/Routes';
import store from './store';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#00796B',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  },
  ptBR
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <EnvRibbon />
        <Routes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
