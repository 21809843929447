import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setConfiguration: ['configuration'],
  setAnchorConfig: ['anchor'],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  anchorEl: null,
  configurations: {},
};

const setConfiguration = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    configurations: action.configuration.configurations,
  };
};

const setAnchorConfig = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    anchorEl: action.anchor.anchorEl,
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_CONFIGURATION]: setConfiguration,
  [Types.SET_ANCHOR_CONFIG]: setAnchorConfig,
});
