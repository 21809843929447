import Login from './Login';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import NotFound from './NotFound';
import Forbidden from './Forbidden';
import NewReleases from './NewReleases';
import Home from './Home';
import Users from './Users';
import RegisterUser from './Users/RegisterUser';
import EditUser from './Users/EditUser';
import Applications from './Applications';
import EditApplication from './Applications/editApplication';
import RegisterApplication from './Applications/registerApplication';
import TargetAudience from './TargetAudience';
import RegisterTargetAudience from './TargetAudience/registerTargetAudience';
import EditTargetAudience from './TargetAudience/editTargetAudience';
import CloneTargetAudience from './TargetAudience/cloneTargetAudience';
import Feed from './Feed';
import CreateNews from './Feed/createNews';
import EditNews from './Feed/editNews';
import ManageGates from './HelloIArrived/manageGates';
import CreateGate from './HelloIArrived/createGate';
import EditGate from './HelloIArrived/editGate';
import ReportHelloArrived from './HelloIArrived/report';
import ControlPanelHelloArrived from './HelloIArrived/controlPanel';
import Sector from './Sectors/sector';
import CreateSector from './Sectors/createSector';
import EditSector from './Sectors/editSector';
import MessageSector from './Message/sectorMessage';
import ChatSector from './Message/sectorChat';
import EventReport from './Message/EventReport';
import BatchReport from './Message/BatchReport';
import ChatAutorizationInBatch from './Message/ChatAutorizationInBatch';
import ChatInBatch from './Message/ChatInBatch';
import QrScanner from './HelloIArrived/qrScanner';
import EarlyExit from './EarlyExit';
import CreateExit from './EarlyExit/createExit';
import EditExit from './EarlyExit/editExit';
import Menu from './Menu';
import RegisterMenu from './Menu/registerMenu';
import EditMenu from './Menu/editMenu';
import CloneMenu from './Menu/cloneMenu';
import ManageItemsDR from './DiaryRecord';
import RegisterItemsDR from './DiaryRecord/registerItem';
import CloneItemRD from './DiaryRecord/cloneItem';
import EditItemRD from './DiaryRecord/editItem';
import ManageSettingsDR from './DiaryRecord/manageSettings';
import RegisterSettingDR from './DiaryRecord/registerSetting';
import EditSetting from './DiaryRecord/editSetting';
import CloneSetting from './DiaryRecord/cloneSetting';
import ConfigurationFiles from './ConfigurationFiles';
import CreateConfigurationFiles from './ConfigurationFiles/createConfigurationFiles';
import EditConfigurationFiles from './ConfigurationFiles/editConfigurationFiles';
import ConfigurationMessages from './ConfigurationMessages';
import CreateConfigurationMessages from './ConfigurationMessages/createConfigurationMessages';
import EditConfigurationMessages from './ConfigurationMessages/editConfigurationMessages';
import Links from './Links'
import EditLink from './Links/editLink';

const Core = {
  Login,
  ForgotPassword,
  ResetPassword,
  NotFound,
  Forbidden,
};

const Feature = {
  Applications,
  EditApplication,
  RegisterApplication,
  Users,
  RegisterUser,
  EditUser,
  Home,
  NewReleases,
  TargetAudience,
  RegisterTargetAudience,
  EditTargetAudience,
  CloneTargetAudience,
  Feed,
  CreateNews,
  EditNews,
  ManageGates,
  CreateGate,
  EditGate,
  ReportHelloArrived,
  ControlPanelHelloArrived,
  Sector,
  CreateSector,
  EditSector,
  MessageSector,
  ChatSector,
  QrScanner,
  EarlyExit,
  CreateExit,
  EditExit,
  Menu,
  RegisterMenu,
  EditMenu,
  CloneMenu,
  ManageItemsDR,
  RegisterItemsDR,
  CloneItemRD,
  EditItemRD,
  ManageSettingsDR,
  RegisterSettingDR,
  EditSetting,
  CloneSetting,
  EventReport,
  ChatAutorizationInBatch,
  BatchReport,
  ChatInBatch,
  ConfigurationFiles,
  CreateConfigurationFiles,
  EditConfigurationFiles,
  ConfigurationMessages,
  CreateConfigurationMessages,
  EditConfigurationMessages,
  Links,
  EditLink,
};

export { Core, Feature };
