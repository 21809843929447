import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { SearchInput } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    marginTop: '1em',
  },
  title: {
    fontWeight: 'bold',
  },
  checkboxes: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minHeight: '2rem',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  searchInput: {
    margin: '1rem 0rem',
  },
  groupSpacing: {
    display: 'flex',
    flexFlow: 'column',
    marginLeft: theme.spacing(1),
  },
}));

const MapedItems = ({
  items,
  identifier,
  selecteds,
  onSelect,
  description,
  title = '',
  oneOrAll = false,
}) => {
  return (
    <>
      {oneOrAll && (
        <FormControlLabel
          checked={selecteds.length === 0}
          value="ALL-PERSONS"
          onChange={onSelect}
          control={
            <Checkbox name={`Todas as pessoas do ${title}`} color="primary" />
          }
          label={`Todas as pessoas do ${title}`}
        />
      )}
      {items.map((item) => (
        <FormControlLabel
          key={item[identifier]}
          checked={selecteds.includes(String(item[identifier]))}
          value={item[identifier]}
          onChange={onSelect}
          control={<Checkbox name={item[description]} color="primary" />}
          label={item[description]}
        />
      ))}
    </>
  );
};

export default ({
  title,
  items,
  selecteds,
  onSelect,
  identifier,
  description = 'description',
  groupId,
  groupEntities,
  groupDescription = 'description',
  oneOrAll = false,
}) => {
  const classes = useStyles();

  const [searchText, setSearchText] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [itemsGrouped, setItemsGrouped] = useState([]);

  const findGroupDescription = (id) =>
    groupEntities.find((group) => String(group[groupId]) === String(id))?.[
      groupDescription
    ];

  useEffect(() => {
    const newItemsFiltered = items.filter((item) =>
      item[description].toLowerCase().includes(searchText.toLowerCase())
    );

    let newItemsGrouped = [];

    if (groupId) {
      newItemsGrouped = newItemsFiltered.reduce((groupedItems, item) => {
        // eslint-disable-next-line no-param-reassign
        groupedItems[item[groupId]] = groupedItems[item[groupId]] ?? [];
        groupedItems[item[groupId]].push(item);
        return groupedItems;
      }, {});
    }
    setItemsFiltered(newItemsFiltered);
    setItemsGrouped(newItemsGrouped);
  }, [searchText, items, groupId, description]);

  return (
    <section className={classes.root}>
      <Typography className={classes.title} variant="subtitle1" color="primary">
        {title}
      </Typography>
      <SearchInput
        customClasses={classes.searchInput}
        onChange={(text) => setSearchText(text.trim())}
      />
      <div className={classes.checkboxes}>
        {!groupId && (
          <MapedItems
            items={itemsFiltered}
            identifier={identifier}
            selecteds={selecteds}
            onSelect={onSelect}
            description={description}
          />
        )}
        {groupId &&
          Object.keys(itemsGrouped).map((group) => (
            <Fragment key={group}>
              <Typography variant="subtitle1">
                {findGroupDescription(group)}
              </Typography>
              <div className={classes.groupSpacing}>
                <MapedItems
                  items={itemsGrouped[group]}
                  identifier={identifier}
                  selecteds={selecteds}
                  onSelect={onSelect}
                  description={description}
                  oneOrAll={oneOrAll}
                  title={findGroupDescription(group)}
                />
              </div>
            </Fragment>
          ))}
      </div>
    </section>
  );
};
