import React from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Menu,
  MenuItem,
  Typography,
  IconButton,
  Divider,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { Creators as NotificationActions } from '../../store/ducks/notification';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '600px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.2em',
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 4px 0 16px',
  },
}));

export default ({ notification }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      NotificationActions.setAnchor({
        anchorEl: null,
      })
    );
  };

  const handleClickNotification = (notif) => {
    handleClose();

    if (notif.TipoId === 9)
      history.push(`/message/chatSector/${notif.Parametro}`);
    if (notif.TipoId === 16) history.push(`/early-exit`);
  };

  return (
    <Menu
      anchorEl={notification?.anchorEl}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(notification?.anchorEl)}
      onClose={handleClose}
    >
      <div className={classes.headContainer}>
        <Typography component="span" color="primary">
          Notificações
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </div>
      {notification?.notifications.length === 0 ? (
        <MenuItem disabled>Você não possui notificações no momento.</MenuItem>
      ) : (
        notification?.notifications.map((n, index) => (
          <div key={index}>
            <MenuItem
              component="div"
              onClick={() => handleClickNotification(n)}
            >
              <ListItem style={{ paddingLeft: '0' }}>
                <ListItemAvatar>
                  <Avatar src={n.ImagemUrl}>
                    {n.Titulo[0]?.toUpperCase()}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div className={classes.textContainer}>
                      <Typography display="inline">{n.Titulo}</Typography>
                      <Typography
                        color="primary"
                        display="inline"
                        style={{ marginLeft: '1em' }}
                      >
                        {n.Assunto}
                      </Typography>
                    </div>
                  }
                  secondary={n.Mensagem}
                  secondaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </ListItem>
            </MenuItem>
            {notification?.notifications.length !== index + 1 && <Divider />}
          </div>
        ))
      )}
    </Menu>
  );
};
