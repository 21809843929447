/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import TargetAudienceForm from './TargetAudienceForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { targetAudienceId } = useParams();

  const [targetAudienceInfo, setTargetAudienceInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const params = {
      appRole: ['coordination', 'communication', 'completeConcierge', 'admin'],
    };
    api
      .get(`target-audience/info/${targetAudienceId}`, { params })
      .then(async (response) => {
        setTargetAudienceInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [targetAudienceId]);

  return (
    <DefaultPage title="Edição de Público-alvo">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <TargetAudienceForm
              isEdit
              targetAudienceInfo={targetAudienceInfo}
              targetAudienceId={targetAudienceId}
            />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
