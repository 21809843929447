import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
  },
  subtitle: {
    display: 'inline',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginRight: theme.spacing(1),
  },
  item: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  fullWidth: {
    width: '100%',
  },
}));

export default ({ descriptions = {}, isLoading = false }) => {
  const classes = useStyles();

  const items = [
    { description: 'Descrição:', property: 'description' },
    { description: 'Estabelecimento:', property: 'establishment' },
    { description: 'Papéis:', property: 'roles' },
    { description: 'Níveis de ensino:', property: 'teachingLevels' },
    { description: 'Cursos:', property: 'courses' },
    { description: 'Ciclos:', property: 'cycles' },
    { description: 'Turmas:', property: 'schoolClasses' },
    { description: 'Usa turmas disciplinas:', property: 'classSubject' },
    { description: 'Período:', property: 'period' },
  ];
  const defaultWord = 'Não especificado';

  const getDescription = (descriptionList, property) => {
    return `${
      descriptionList[property] && descriptionList[property].length
        ? descriptionList[property]
        : defaultWord
    }.`;
  };

  return (
    <section className={classes.root}>
      {items.map((item) => (
        <div className={classes.item} key={item.description}>
          <Typography className={classes.fullWidth}>
            <Typography className={classes.subtitle} color="primary">
              {item.description}
            </Typography>
            {isLoading && (
              <Skeleton
                className={classes.fullWidth}
                variant="text"
                animation="wave"
              />
            )}
            {!isLoading && getDescription(descriptions, item.property)}
          </Typography>
        </div>
      ))}
    </section>
  );
};
