import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { WarningRounded } from '@material-ui/icons';
import { BackToButton } from '../../components';
import DefaultPage from '../../templates/DefaultPage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    padding: '1.5em',
    fontSize: '1.5em',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningIcon: {
    paddingBottom: '1rem',
    fontSize: '5em',
    color: theme.palette.primary.main,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <DefaultPage title="Home">
      <div className={classes.root}>
        <WarningRounded className={classes.warningIcon} />
        Acesso proibido volte para a Home.
        <BackToButton to="/" primary />
      </div>
    </DefaultPage>
  );
};
