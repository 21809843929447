import React from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Avatar,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Card,
  CardContent,
  Chip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  customDialogContent: {
    marginLeft: '1em',
    marginRight: '1em',
    marginBottom: '3em',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  subtitle: {
    fontSize: '1.15em',
    fontWeight: '500',
  },
}));

export default ({ handleClose, open, data, isLoading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const Profile = () => {
    const SubCard = ({ vinculo }) => {
      return (
        <Card variant="outlined">
          <CardContent>
            <ListItem>
              <ListItemAvatar>
                {isLoading ? (
                  <Skeleton variant="circle" className={classes.medium} />
                ) : (
                  <Avatar className={classes.medium} src={vinculo.Foto}>
                    {vinculo.Nome[0]?.toUpperCase()}
                  </Avatar>
                )}
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography className={classes.subtitle} color="primary">
                    Estudante
                  </Typography>
                }
                secondary={`${vinculo.Matricula.trim()} - ${vinculo.Nome}`}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography className={classes.subtitle} color="primary">
                    Informações
                  </Typography>
                }
                secondary={vinculo.CursoCicloTurma.map((c) => {
                  return (
                    <ListItemText
                      secondary={`${c.Curso} - ${c.Ciclo} - ${c.Turma}`}
                    />
                  );
                })}
              />
            </ListItem>
          </CardContent>
        </Card>
      );
    };

    const Vinculos = ({ vinculos }) => {
      return (
        <div style={{ maxHeight: '400px', overflowY: 'auto', margin: '0px' }}>
          <Typography className={classes.subtitle} color="primary">
            Vínculos
          </Typography>
          {isLoading ? (
            <div>
              {new Array(5).fill().map((e, i) => (
                <div key={i}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </div>
              ))}
            </div>
          ) : vinculos.length === 0 ? (
            <Typography variant="subtitle1" color="textSecondary">
              Nenhum vínculo encontrado.
            </Typography>
          ) : (
            vinculos.map((vinculo, index) => (
              <SubCard key={index} isLoading={isLoading} vinculo={vinculo} />
            ))
          )}
        </div>
      );
    };

    const Cursos = ({ cursos }) => {
      return (
        <div style={{ maxHeight: '400px', overflowY: 'auto', margin: '0px' }}>
          <Typography className={classes.subtitle} color="primary">
            Cursos
          </Typography>
          {isLoading ? (
            <div>
              {new Array(5).fill().map((e, i) => (
                <div key={i}>
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </div>
              ))}
            </div>
          ) : cursos.length === 0 ? (
            <Typography variant="subtitle1" color="textSecondary">
              Nenhum curso encontrado.
            </Typography>
          ) : (
            cursos.map((c) => <p>{`${c.Curso} - ${c.Ciclo} - ${c.Turma}`}</p>)
          )}
        </div>
      );
    };

    return (
      <Card variant="outlined">
        <CardContent>
          <ListItem>
            <ListItemAvatar>
              {isLoading ? (
                <Skeleton variant="circle" className={classes.large} />
              ) : (
                <Avatar src={data?.FotoProprietario} className={classes.large}>
                  {data?.NomeProprietario[0]?.toUpperCase()}
                </Avatar>
              )}
            </ListItemAvatar>
          </ListItem>
          <Typography variant="h6" color="primary">
            {isLoading ? (
              <Skeleton variant="text" width="50%" />
            ) : data?.PapelProprietario === 2 ? (
              'Responsável'
            ) : (
              'Estudante'
            )}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {isLoading ? (
              <Skeleton variant="text" width="50%" />
            ) : (
              data?.NomeProprietario
            )}
          </Typography>

          <Typography className={classes.subtitle} color="primary">
            E-mail
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {isLoading ? (
              <Skeleton width="50%" />
            ) : data?.EmailProprietario ? (
              data.EmailProprietario
            ) : (
              'Não cadastrado'
            )}
          </Typography>
          <Typography className={classes.subtitle} color="primary">
            Telefone
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {isLoading ? (
              <Skeleton width="50%" />
            ) : data?.TelefoneProprietario ? (
              data.TelefoneProprietario
            ) : (
              'Não cadastrado'
            )}
          </Typography>
          {data?.ResponsavelPor.length === 0 ? (
            <Cursos isLoading={isLoading} cursos={data?.CursosDoAluno} />
          ) : (
            <Vinculos isLoading={isLoading} vinculos={data?.ResponsavelPor} />
          )}
        </CardContent>
      </Card>
    );
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogActions>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogActions>
      {isMobile ? (
        <Profile />
      ) : (
        <DialogContent className={classes.customDialogContent}>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h6" color="primary">
                  {isLoading ? (
                    <Skeleton variant="text" width="50%" />
                  ) : (
                    data?.NomeProprietario
                  )}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle1" color="textSecondary">
                  {isLoading ? (
                    <Skeleton variant="text" width="50%" />
                  ) : data?.PapelProprietario === 2 ? (
                    'Responsável'
                  ) : (
                    'Estudante'
                  )}
                </Typography>
              }
            />
            <ListItemAvatar>
              {isLoading ? (
                <Skeleton variant="circle" className={classes.large} />
              ) : (
                <Avatar src={data?.FotoProprietario} className={classes.large}>
                  {data?.NomeProprietario[0]?.toUpperCase()}
                </Avatar>
              )}
            </ListItemAvatar>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography className={classes.subtitle} color="primary">
                  E-mail
                </Typography>
              }
              secondary={
                <Typography variant="subtitle1" color="textSecondary">
                  {isLoading ? (
                    <Skeleton width="50%" />
                  ) : data?.EmailProprietario ? (
                    data.EmailProprietario
                  ) : (
                    'Não cadastrado'
                  )}
                </Typography>
              }
            />
            <ListItemText
              primary={
                <Typography className={classes.subtitle} color="primary">
                  Telefone
                </Typography>
              }
              secondary={
                <Typography variant="subtitle1" color="textSecondary">
                  {isLoading ? (
                    <Skeleton width="50%" />
                  ) : data?.TelefoneProprietario ? (
                    data.TelefoneProprietario
                  ) : (
                    'Não cadastrado'
                  )}
                </Typography>
              }
            />
          </ListItem>
          {data?.ResponsavelPor.length === 0 ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.subtitle} color="primary">
                        Cursos
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading &&
                    new Array(5)
                      .fill()
                      .map((e, i) => i)
                      .map((e) => (
                        <TableRow key={e}>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))}
                  {!isLoading && data?.CursosDoAluno.length === 0 ? (
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" color="textSecondary">
                          Nenhum curso encontrado.
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <ListItem>
                          <ListItemText
                            secondary={data?.CursosDoAluno.map((c) => {
                              return (
                                <ListItemText
                                  secondary={`${c.Curso} - ${c.Ciclo} - ${c.Turma}`}
                                />
                              );
                            })}
                          />
                        </ListItem>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography className={classes.subtitle} color="primary">
                        Vínculos
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading &&
                    new Array(5)
                      .fill()
                      .map((e, i) => i)
                      .map((e) => (
                        <TableRow key={e}>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))}
                  {!isLoading && data?.ResponsavelPor.length === 0 ? (
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" color="textSecondary">
                          Nenhum vínculo encontrado.
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ) : (
                    data?.ResponsavelPor.map((r, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <ListItem>
                            <ListItemAvatar>
                              <Avatar className={classes.medium} src={r.Foto}>
                                {r.Nome[0]?.toUpperCase()}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Typography
                                  className={classes.subtitle}
                                  color="primary"
                                >
                                  Estudante{' '}
                                  {r?.VinculadoAoChat ? (
                                    <Chip
                                      label="Vinculado ao chat"
                                      color="primary"
                                    />
                                  ) : null}
                                </Typography>
                              }
                              secondary={`${r.Matricula.trim()} - ${r.Nome}`}
                            />
                          </ListItem>
                        </TableCell>
                        <TableCell>
                          <ListItem>
                            <ListItemText
                              primary={
                                <Typography
                                  className={classes.subtitle}
                                  color="primary"
                                >
                                  Informações
                                </Typography>
                              }
                              secondary={r.CursoCicloTurma.map((c) => {
                                return (
                                  <ListItemText
                                    secondary={`${c.Curso} - ${c.Ciclo} - ${c.Turma}`}
                                  />
                                );
                              })}
                            />
                          </ListItem>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
};
