import React from 'react';
import { useSelector } from 'react-redux';
import ForbiddenPage from '../pages/Forbidden';

export default ({ component: Component, idFeature }) => {
  const loading = useSelector((state) => state.profile?.loading);
  const features = useSelector((state) => state.profile?.features);
  if (loading) return <Component />;

  const userHasPermission = features.some(
    (feature) => feature.id === idFeature
  );

  if (userHasPermission) return <Component />;

  return <ForbiddenPage />;
};
