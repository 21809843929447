function maiorOuIgual(versaoAtual, versaoReferencia) {
  if (versaoAtual === null) return false;
  const versaoAtualValores = versaoAtual.split('.').map(parseInt);
  const versaoReferenciaValores = versaoReferencia.split('.').map(parseInt);
  if (versaoAtualValores.length !== versaoReferenciaValores.length)
    return false;
  for (let index = 0; index < versaoAtualValores.length; index++) {
    if (versaoAtualValores[index] < versaoReferenciaValores[index])
      return false;
    if (versaoAtualValores[index] > versaoReferenciaValores[index]) return true;
  }
  return true;
}
function menor(versaoAtual, versaoReferencia) {
  return !this.maiorOuIgual(versaoAtual, versaoReferencia);
}

export default { maiorOuIgual, menor };
