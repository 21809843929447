import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import { RoundedButton, Container, TableRowFocusable } from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import DeleteSectorModal from './DeleteSectorModal';
import ViewSectorModal from './ViewSectorModal';

const useStyles = makeStyles((theme) => ({
  tableCell: {
    wordBreak: 'break-word',
    width: '100%',
  },
  tableCellIcons: {
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  iconCustom: {
    marginLeft: '1rem',
  },
  registerButton: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  customToolbar: {
    marginTop: '1em',
    justifyContent: 'flex-end',
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const limitPreviewUsers = 8;

  const [openDelete, setOpenDelete] = useState(false);
  const [dialogDeleteSector, setDialogDeleteSector] = useState(null);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const [openView, setOpenView] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [dialogViewSector, setDialogViewSector] = useState(null);
  const [previewUsers, setPreviewUsers] = useState([]);
  const [previewTargets, setPreviewTargets] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sectors, setSectors] = useState([]);

  const handleOpenRegisterPage = () => history.push(`/sector/create`);
  const handleEditSector = (id) => history.push(`/sector/editSector/${id}`);
  const handleChangePage = (event, nextPage) => setPage(nextPage + 1);

  const handleError = (message) => {
    setIsLoading(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });

    setSectors([]);
    setPage(1);
    setTotal(0);
  };

  const deleteSector = (sector) => {
    setDialogDeleteSector(sector);
    setOpenDelete(true);
  };

  const viewSector = (sector) => {
    setLoadingView(true);

    setPreviewUsers([]);
    setDialogViewSector(sector);
    setOpenView(true);

    if (sector.Usuarios && sector.Usuarios.length > 0) {
      const params = {
        page: 1,
        ids: sector.Usuarios,
        limit: limitPreviewUsers,
      };

      api
        .get('users/getUsersByIds', {
          params,
        })
        .then((response) => {
          let usersTemp = [...response.data.data.map((x) => x.name)];
          const extraUsersCount = response.data.total - usersTemp.length;

          if (extraUsersCount > 0)
            usersTemp = [
              ...usersTemp,
              ...new Array(extraUsersCount).fill(null),
            ];

          setPreviewUsers([...usersTemp]);
          setLoadingView(false);
        })
        .catch((err) => setLoadingView(false));
      // eslint-disable-next-line
    } else setLoadingView(false);

    if (sector.PublicosAlvo && sector.PublicosAlvo.length > 0) {
      api
        .get('target-audience', {
          params: {
            limit: 1000,
            appRole: ['admin'],
          },
        })
        .then((r) => {
          let selectedTargets = [];
          sector.PublicosAlvo.forEach((x) => {
            const filtered = r.data.data.filter((t) => {
              return t.id === x;
            });
            selectedTargets = [...selectedTargets, filtered[0]];
          });
          setPreviewTargets(selectedTargets);
        });
      // eslint-disable-next-line
    } else setLoadingView(false);
  };

  const confirmDeleteSector = () => {
    if (!dialogDeleteSector) return;

    setIsLoadingDelete(true);

    const params = {
      idSector: dialogDeleteSector.Id,
    };

    api
      .delete('sector/deleteSector', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) {
          setAlert({
            isOpen: true,
            type: 'error',
            message: response.data.Mensagem,
          });
          setDialogDeleteSector(null);
        } else {
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Setor deletado com sucesso!',
          });

          setSectors(sectors.filter((s) => s.Id !== dialogDeleteSector.Id));
          setDialogDeleteSector(null);
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao tentar deletar o setor!',
        });
        setDialogDeleteSector(null);
      });

    setIsLoadingDelete(false);
    setOpenDelete(false);
  };

  useEffect(() => {
    setIsLoading(true);

    const params = {
      page,
      limit: 10,
      appRole: ['admin'],
    };

    api
      .get('sector/getSectors', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleError(response.data.Mensagem);
        else {
          setTotal(response.data.Dados.Total);
          setSectors([...response.data.Dados.Data]);
          setIsLoading(false);
        }
      })
      .catch((err) => handleError(null));
    // eslint-disable-next-line
  }, [page]);

  return (
    <DefaultPage title="Setores">
      <Container>
        <Toolbar className={classes.customToolbar}>
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>Nome</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading
                ? new Array(10)
                    .fill()
                    .map((e, i) => i)
                    .map((e) => (
                      <TableRowFocusable key={e} onClick={() => {}} hover>
                        <TableCell>
                          <Skeleton animation="wave" />
                        </TableCell>
                      </TableRowFocusable>
                    ))
                : sectors?.map((sector) => (
                    <TableRowFocusable key={sector.Id} hover>
                      <TableCell className={classes.tableCell}>
                        {sector.Nome}
                      </TableCell>
                      <TableCell className={classes.tableCellIcons}>
                        <Search
                          color="primary"
                          onClick={() => viewSector(sector)}
                        />
                        <Edit
                          color="primary"
                          className={classes.iconCustom}
                          onClick={() => handleEditSector(sector.Id)}
                        />
                        <DeleteForever
                          color="primary"
                          className={classes.iconCustom}
                          onClick={() => deleteSector(sector)}
                        />
                      </TableCell>
                    </TableRowFocusable>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={total}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handleChangePage}
        />

        <DeleteSectorModal
          handleClose={() => setOpenDelete(false)}
          openDelete={openDelete}
          loadingDelete={isLoadingDelete}
          dialogSector={dialogDeleteSector}
          confirmDeleteSector={confirmDeleteSector}
        />

        <ViewSectorModal
          handleClose={() => setOpenView(false)}
          open={openView}
          dialogSector={dialogViewSector}
          usersPreview={previewUsers}
          targetsPreview={previewTargets}
          limitPreview={limitPreviewUsers}
          isLoading={loadingView}
        />
      </Container>
    </DefaultPage>
  );
};
