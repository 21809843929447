/* eslint-disable */
import React from 'react';
import { useEffect } from 'react';
import FeedbackComponent from '../../components/FeedbackComponent';
import { makeStyles } from '@material-ui/core/styles';
import useAlert from '../../hooks/useAlert';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: '-webkit-center',
    marginTop: '6em',
  },
}));

export default ({ setModalOpen }) => {
  const classes = useStyles();
  const setAlert = useAlert();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://wc-feedbacks.gvdasa.com.br/';
    script.async = true;
    script.type = 'module';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
  }, []);

  const feedback = (id, texto) => {
    if (texto.includes('success')) {
      setAlert({
        isOpen: true,
        type: 'success',
        message: 'Feedback cadastrado com sucesso!',
      });
      setTimeout(() => {
        onClose();
      }, '2000');
    }
  };
  const onClose = () => {
    setModalOpen(false);
  };

  return (
    <div className={classes.container}>
      <FeedbackComponent
        idProduto="mobile4"
        textoTitulo="Envie seu feedback sobre o Portal"
        onErro={(id) => feedback(id, 'error')}
        onEnviarFeedback={(id) => feedback(id, 'success')}
        onFecharModal={() => onClose()}
      />
    </div>
  );
};
