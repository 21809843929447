import React, { useState, useEffect, useRef } from 'react';
import {
  TablePagination,
  Toolbar,
  TextField,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Grid,
  Typography,
  Divider,
  ButtonGroup,
  IconButton,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, GetApp } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DefaultPage from '../../templates/DefaultPage';
import {
  SearchInput,
  RoundedButton,
  Container,
  LoadingButton,
} from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import ConfirmCloseModal from '../../components/ConfirmCloseModal';
import { trackPDF, trackExcel } from '../../reports/EarlyExit/exitReport';
import InfoRespDialog from './infoRespDialog';

const useStyles = makeStyles((theme) => ({
  divItems: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '1.5em',
    marginTop: '1em',
    padding: '0 24px 0 24px',
    width: '100%',
    '& .MuiGrid-container': {
      padding: '5px 0 5px 0',
    },
  },
  subButton: {
    paddingLeft: '2.5em',
    paddingRight: '2.5em',
  },
  alertCustom: {
    width: '100%',
  },
  downloadButton: {
    marginTop: '1.5em',
    marginLeft: 'auto',
  },
  textCustom: {
    fontSize: '1em !important',
    marginBottom: '4px',
    fontWeight: '700',
  },
  textRed: {
    color: '#FF0000',
  },
  textCustomSub: {
    fontSize: '1em !important',
    margin: '0 0 0 6px',
    color: '#717171',
  },
  textCustomSubClick: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1em !important',
    margin: '0 0 0 6px',
    '&:hover': {
      fontWeight: '500',
    },
  },
  registerButton: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  buttonDefaultMark: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginBottom: '5px',
  },
  btnDefaultMarkPrimary: {
    '& .Mui-disabled': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
    },
  },
  btnBlueMarkPrimary: {
    '& .Mui-disabled': {
      backgroundColor: '#00225A',
      boxShadow:
        '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
      color: '#fff',
    },
  },
  buttonBlueMark: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '5px',
    marginBottom: '5px',
  },
  customToolbar: {
    marginTop: '1em',
    justifyContent: 'flex-end',
  },
  customSearch: {
    maxWidth: '700px',
    marginRight: 'auto',
  },
  customItem: {
    marginTop: '1.5em',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '5px',
  },
  gridSkeleton: {
    padding: '0 8px',
  },
  skeletonCustom: {},
  containerCustom: {
    height: '100%',
  },
  formControl: {
    margin: '0',
    minWidth: 120,
  },
}));

export default () => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const history = useHistory();

  const perPage = 20;

  const availableSituations = [
    {
      id: 0,
      descricao: 'Todos',
    },
    {
      id: 1,
      descricao: 'Finalizado',
    },
    {
      id: 2,
      descricao: 'Cancelado',
    },
    {
      id: 3,
      descricao: 'Ativo',
    },
  ];

  const turmasIniciais = [
    {
      id: 0,
      descricao: 'Todos',
    },
  ];

  const estabelecimentosIniciais = [
    {
      id: 0,
      descricao: 'Todos',
    },
  ];

  const typeFiles = {
    PDF: 0,
    XLSX: 1,
  };

  const anchorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [turmas, setTurmas] = useState(turmasIniciais);
  const [estabelecimentos, setEstabelecimentos] = useState(
    estabelecimentosIniciais
  );
  const [nome, setNome] = useState('');
  const [situation, setSituation] = useState(availableSituations[0]);
  const [turma, setTurma] = useState(turmasIniciais[0]);
  const [estabelecimento, setEstabelecimento] = useState(
    estabelecimentosIniciais[0]
  );
  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
  const [pagina, setPagina] = useState(1);
  const [total, setTotal] = useState(0);
  const [saidas, setSaidas] = useState([]);
  const [openCancel, setOpenCancel] = useState(null);
  const [openFinalize, setOpenFinalize] = useState(null);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingFinalize, setLoadingFinalize] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openRespDetails, setOpenRespDetails] = useState(false);
  const [exitSelected, setExitSelected] = useState({});

  const handleChangePage = (event, nextPage) => {
    setPagina(nextPage + 1);
  };

  const handleLoadTurmas = (data, turmaParam) => {
    const dataTemp = [...data];

    const dataActual = dataTemp.find((x) => x.id === turmaParam.id);
    const turmaTemp = turmaParam.id === 0 || dataActual ? [] : [turmaParam];

    if (dataTemp.length === 0) setTurmas([...turmasIniciais, ...turmaTemp]);
    else setTurmas([...turmasIniciais, ...dataTemp, ...turmaTemp]);
  };

  const handleRequestError = () => {
    setSaidas([]);
    setTotal(0);
    setPagina(1);
    setTurmas([...turmasIniciais]);
    setTurma(turmasIniciais[0]);
    setIsLoading(false);
  };

  const requestExits = async (situacaoParam, turmaParam, estabParam) => {
    setIsLoading(true);

    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'Não foi possível buscar as saídas, entre em contato com a instituição.',
      });
      return;
    }

    const params = {
      nome,
      situacao: situacaoParam.id === 0 ? null : situacaoParam.id,
      dataFinal,
      dataInicial,
      codigoTurma: turmaParam.id === 0 ? null : turmaParam.id,
      pagina,
      porPagina: perPage,
      idEstabelecimento:
        estabParam.idEstabelecimento === 0
          ? null
          : estabParam.idEstabelecimento,
      tokenJWT: tokenLs,
      appRole: ['earlyExitConcierge', 'admin'],
    };

    api
      .get('earlyExit/getExits', {
        params,
      })
      .then((response) => {
        const { data } = response;

        if (response.status === 200) {
          handleLoadTurmas(data.dados.turmas, turmaParam);

          const saidasTemp = data.dados.saidas;
          setSaidas([...saidasTemp.data]);
          setPagina(saidasTemp.page);
          setTotal(saidasTemp.total);
          setIsLoading(false);
        } else {
          handleRequestError();
        }
      })
      .catch((err) => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Erro ao obter as saidas antecipadas.',
        });
        handleRequestError();
      });
  };

  const handleEstabelecimentos = () => {
    const params = { appRole: ['earlyExitConcierge', 'admin'] };
    api.get(`target-audience/establishments/`, { params }).then((response) => {
      setEstabelecimentos([...estabelecimentosIniciais, ...response.data]);
    });
  };

  useEffect(() => {
    requestExits(situation, turma, estabelecimento);
    if (estabelecimentos.length <= 1) {
      handleEstabelecimentos();
    }
  }, [pagina, dataInicial, dataFinal, nome]);

  const handleSetSituation = (value) => {
    const sitTemp = availableSituations.find((x) => x.descricao === value);

    if (sitTemp) {
      setSituation(sitTemp);
      requestExits(sitTemp, turma, estabelecimento);
    }
  };

  const handleSetTurma = (value) => {
    const turmaTemp = turmas.find((x) => x.descricao === value);

    if (turmaTemp) {
      setTurma(turmaTemp);
      requestExits(situation, turmaTemp, estabelecimento);
    }
  };

  const formatCursos = (cursos) => {
    const cursosTemp = cursos.estudantes.map((x) => x.cursos);
    const merged = [].concat.apply([], cursosTemp);

    return merged;
  };

  const handleCreateExit = () => history.push(`/early-exit/create`);

  const handleEditExit = (saida) => {
    history.push(`/early-exit/edit`, saida);
  };

  const confirmCancel = async () => {
    if (!openCancel) return;

    setLoadingCancel(true);

    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'Não foi possível realizar o cancelamento, entre em contato com a instituição.',
      });
      return;
    }

    const params = {
      codigoSaidaAntecipada: openCancel.codigoSaidaAntecipada,
      registroTabSec: openCancel.registroTabSec,
      tokenJWT: tokenLs,
      codigoResponsavel: openCancel.codigoResponsavel,
    };

    api
      .delete('earlyExit/cancelExit', {
        params,
      })
      .then((response) => {
        const { data } = response;

        if (response.status === 200) {
          setAlert({
            isOpen: true,
            type: 'success',
            message: data.mensagem,
          });

          const saidasTemp = saidas;
          const index = saidasTemp.findIndex(
            (x) => x.codigoSaidaAntecipada === openCancel.codigoSaidaAntecipada
          );

          if (index > -1) {
            saidasTemp[index].situacao = data.dados;
            setSaidas([...saidasTemp]);
          }
        } else {
          setAlert({
            isOpen: true,
            type: 'error',
            message: data.mensagem,
          });
        }

        setLoadingCancel(false);
        setOpenCancel(null);
      })
      .catch((err) => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: err.response.data.error,
        });
        setLoadingCancel(false);
        setOpenCancel(null);
      });
  };

  const handleToggle = () => setOpenDownload((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDownload(false);
  };

  const handleNotFound = () => {
    setAlert({
      isOpen: true,
      type: 'warning',
      message: 'Nenhum registro a ser emitido!',
    });
  };

  const handleGetReport = async (situacaoParam, turmaParam, estabParam) => {
    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'Não foi possível buscar as saídas, entre em contato com a instituição.',
      });
      return;
    }

    const params = {
      nome,
      situacao: situacaoParam.id === 0 ? null : situacaoParam.id,
      dataFinal,
      dataInicial,
      codigoTurma: turmaParam.id === 0 ? null : turmaParam.id,
      pagina,
      porPagina: perPage,
      idEstabelecimento:
        estabParam.idEstabelecimento === 0
          ? null
          : estabParam.idEstabelecimento,
      tokenJWT: tokenLs,
      appRole: ['earlyExitConcierge', 'admin'],
    };

    let exits = [];

    await api
      .get('earlyExit/getExits', { params })
      .then((response) => {
        const { data } = response;
        if (response.status === 200) exits = data.dados.saidas.data;
        return [];
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Falha ao obter relatório!',
        });
        return [];
      });

    return exits;
  };

  const obterDataConformeTipoSaida = (saida) => {
    if (saida.tipo === 'P' || saida.tipo === 'T') {
      return `${moment(saida.dataInicio).format('DD/MM/YYYY')} | ${moment(
        saida.dataTermino
      ).format('DD/MM/YYYY')}`;
    }
    if (saida.dataSaida === null) {
      return moment(saida.dataInicio).format('DD/MM/YYYY');
    }
    return moment(saida.dataSaida).format('DD/MM/YYYY');
  };

  const obterHoraSaida = (saida) => {
    if (saida.dataSaida === null) {
      return moment(saida.horaSaida).format('HH:mm');
    }
    return moment(saida.dataSaida).format('HH:mm');
  };

  const handleDocumentDownload = async (typeFile) => {
    setOpenDownload(false);
    setIsDownloading(true);

    if (saidas.length === 0) {
      handleNotFound();
      setIsDownloading(false);
      return;
    }

    const dataReport = await handleGetReport(situation, turma, estabelecimento);

    const saidasFormatada = dataReport.map((x) => ({
      codigoResponsavel: x.codigoResponsavel,
      dataSaida: obterDataConformeTipoSaida(x),
      horaSaida: x.tipo !== 'P' && x.tipo !== 'T' ? obterHoraSaida(x) : ' - ',
      motivo: x.motivo ?? '',
      nomeResponsavel: x.nomeResponsavel ?? '',
      estudantes: x.estudantes.map((e) => e.nomeEstudante).join(', '),
      cursos: formatCursos(x).join(', '),
      quemAutorizou: x.nomeUsuario ?? '',
      situacao: x.situacao ?? '',
      observacao: x.observacao ?? '',
    }));

    if (saidasFormatada.length > 0)
      switch (typeFile) {
        case typeFiles.PDF:
          trackPDF(saidasFormatada);
          break;
        default:
          trackExcel(saidasFormatada);
      }
    else {
      handleNotFound();
    }

    setIsDownloading(false);
  };

  const handleSetEstabelecimento = (value) => {
    const estabelecimentoTemp = estabelecimentos.find(
      (x) => x.descricao === value
    );

    if (estabelecimentoTemp) {
      setEstabelecimento(estabelecimentoTemp);
      requestExits(situation, turma, estabelecimentoTemp);
    }
  };

  const handleFinalizeExit = async () => {
    if (!openFinalize) return;

    setLoadingFinalize(true);

    let tokenLs = null;
    await api.get('/configuration/obterAccessTokenLS').then((response) => {
      tokenLs = response.data.access_token;
    });

    if (tokenLs === null) {
      setAlert({
        isOpen: true,
        type: 'error',
        message:
          'Não foi possível finalizar a saída antecipada, entre em contato com a instituição.',
      });
      return;
    }

    const params = {
      codigoSaidaAntecipada: openFinalize.codigoSaidaAntecipada,
      registroTabSec: openFinalize.registroTabSec,
      tokenJWT: tokenLs,
      codigoResponsavel: openFinalize.codigoResponsavel,
    };

    api
      .put('earlyExit/finalizeExit', params)
      .then((response) => {
        if (response.status === 200) {
          setLoadingFinalize(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Saída finalizada.',
          });

          const saidasTemp = saidas;
          const index = saidasTemp.findIndex(
            (x) =>
              x.codigoSaidaAntecipada === openFinalize.codigoSaidaAntecipada
          );

          if (index > -1) {
            saidasTemp[index].situacao = 'Finalizado';
            setSaidas([...saidasTemp]);
          }
        } else {
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Erro ao finalizar a saída antecipada.',
          });
        }
        setLoadingCancel(false);
        setOpenFinalize(null);
      })
      .catch((err) => {
        setAlert({
          isOpen: true,
          type: 'error',
          message: err.response.data.error,
        });
        setLoadingCancel(false);
        setOpenFinalize(null);
      });
  };

  const TextoSituacao = (situacao, dataFinalizacao, dataCancelamento) => {
    const situacaoTemp = situacao.toUpperCase();
    if (situacaoTemp === 'FINALIZADO') {
      const data =
        dataFinalizacao === null
          ? moment().format('DD/MM/YYYY')
          : moment(dataFinalizacao).format('DD/MM/YYYY');
      const hora =
        dataFinalizacao === null
          ? moment().format('HH:mm')
          : moment(dataFinalizacao).format('HH:mm');
      return `${situacao} em ${data} às ${hora}`;
    }
    if (situacaoTemp === 'CANCELADO') {
      const data =
        dataCancelamento === null
          ? moment().format('DD/MM/YYYY')
          : moment(dataCancelamento).format('DD/MM/YYYY');
      const hora =
        dataCancelamento === null
          ? moment().format('HH:mm')
          : moment(dataCancelamento).format('HH:mm');
      return `${situacao} em ${data} às ${hora}`;
    }
    return situacao;
  };

  const obterTipoSaida = (tipo) => {
    if (tipo === 'R') return 'Antecipada';

    return 'Terceiro';
  };

  const obterDescricaoTipoSaida = (tipo) => {
    if (tipo === 'P') {
      return 'Permanente';
    }
    return 'Temporária';
  };

  const obtemNomeQuemBuscara = (nomeResp, tipo, parentesco) => {
    if (tipo === 'R') return nomeResp;

    if (parentesco != null) return `${nomeResp} | ${parentesco}`;

    return nomeResp;
  };

  const handleOpenRespDetails = (exit) => {
    setExitSelected(exit);
    setOpenRespDetails(true);
  };

  const handleCloseDialog = () => {
    setOpenRespDetails(false);
  };

  return (
    <DefaultPage title="Saídas antecipadas">
      <Container>
        <InfoRespDialog
          exit={exitSelected}
          open={openRespDetails}
          onClose={handleCloseDialog}
        />
        <Toolbar className={classes.customToolbar}>
          <SearchInput
            customClasses={classes.customSearch}
            onChange={debounce((text) => setNome(text.trim()))}
            colorIcon="disabled"
            placeholder="Digite o nome do responsável ou do estudante"
          />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleCreateExit}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>
        <div className={classes.divItems}>
          <form className={classes.container} noValidate>
            <TextField
              id="start-date"
              label="Data Inicial"
              type="date"
              style={{ marginRight: '8px' }}
              defaultValue={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="end-date"
              label="Data Final"
              type="date"
              style={{ marginRight: '8px' }}
              defaultValue={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <FormControl className={classes.formControl}>
            <InputLabel id="inp-situation">Situação</InputLabel>
            <Select
              labelId="inp-situation"
              id="rr-situacao"
              style={{ marginRight: '8px' }}
              value={situation?.descricao}
              onChange={(e) => handleSetSituation(e.target.value)}
            >
              {availableSituations.map((s, index) => (
                <MenuItem key={index} value={s.descricao}>
                  {s.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="inp-turma">Turma</InputLabel>
            <Select
              labelId="inp-turma"
              id="rr-turma"
              style={{ marginRight: '8px' }}
              value={turma?.descricao}
              onChange={(e) => handleSetTurma(e.target.value)}
            >
              {turmas.map((t) => (
                <MenuItem key={t.id} value={t.descricao}>
                  {t.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="inp-turma">Estabelecimento</InputLabel>
            <Select
              labelId="inp-turma"
              id="rr-turma"
              value={estabelecimento?.descricao}
              onChange={(e) => handleSetEstabelecimento(e.target.value)}
            >
              {estabelecimentos.map((e) => (
                <MenuItem key={e.idEstabelecimento} value={e.descricao}>
                  {e.descricao}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ButtonGroup
            className={classes.downloadButton}
            ref={anchorRef}
            aria-label="split button"
            variant="text"
          >
            <LoadingButton
              color="primary"
              aria-controls={openDownload ? 'split-button-menu' : undefined}
              aria-expanded={openDownload ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              style={{ marginLeft: 'auto' }}
              endIcon={<GetApp />}
              isLoading={isDownloading}
            >
              Baixar relatório
            </LoadingButton>
          </ButtonGroup>

          <Popper
            open={openDownload}
            anchorEl={anchorRef.current}
            role={undefined}
            style={{ zIndex: '2' }}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      <MenuItem
                        onClick={() => handleDocumentDownload(typeFiles.PDF)}
                        className={classes.subButton}
                      >
                        PDF
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleDocumentDownload(typeFiles.XLSX)}
                        className={classes.subButton}
                      >
                        Excel
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className={classes.divItems}>
          {isLoading ? (
            new Array(20).fill().map((e, i) => (
              <Grid key={i} container spacing={2}>
                <Grid
                  className={classes.gridSkeleton}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <Grid container spacing={0}>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridSkeleton}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <Grid container spacing={0}>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                    <Grid className={classes.gridSkeleton} item xs={12}>
                      <Skeleton animation="wave" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))
          ) : (
            <>
              {saidas.length === 0 ? (
                <Alert className={classes.alertCustom} severity="info">
                  Ops! Nenhuma saída antecipada encontrada para o período
                  selecionado.
                </Alert>
              ) : (
                saidas.map((s, index) => (
                  <React.Fragment key={index}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4} lg={4}>
                        {s.nomeUsuario && (
                          <Grid container>
                            <Grid item>
                              <Typography className={classes.textCustom}>
                                Criado por:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography className={classes.textCustomSub}>
                                {s.nomeUsuario}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Quem buscará:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography
                              className={
                                s.tipoPessoaSaida === 'R'
                                  ? classes.textCustomSub
                                  : classes.textCustomSubClick
                              }
                              onClick={() =>
                                s.tipoPessoaSaida === 'R'
                                  ? ''
                                  : handleOpenRespDetails(s)
                              }
                            >
                              {obtemNomeQuemBuscara(
                                s.nomeResponsavel,
                                s.tipoPessoaSaida,
                                s.parentesco
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Estudantes:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography className={classes.textCustomSub}>
                              {s.estudantes
                                .map((x) => x.nomeEstudante)
                                .join(', ')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Cursos:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography className={classes.textCustomSub}>
                              {formatCursos(s).map((x, i) => (
                                <React.Fragment key={i}>
                                  {x}
                                  <br />
                                </React.Fragment>
                              ))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={4} lg={4}>
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Data:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography
                              className={classes.textCustomSub}
                              style={
                                s.vencido && s.situacao === 'Ativo'
                                  ? { color: '#FF0000' }
                                  : {}
                              }
                            >
                              {obterDataConformeTipoSaida(s)}
                            </Typography>
                          </Grid>
                        </Grid>
                        {s.tipo !== 'P' && s.tipo !== 'T' && (
                          <Grid container>
                            <Grid item>
                              <Typography xs={4} className={classes.textCustom}>
                                Hora:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography
                                className={classes.textCustomSub}
                                style={
                                  s.vencido && s.situacao === 'Ativo'
                                    ? { color: '#FF0000' }
                                    : {}
                                }
                              >
                                {obterHoraSaida(s)}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        {(s.tipo === 'P' || s.tipo === 'T') && (
                          <Grid container>
                            <Grid item>
                              <Typography xs={4} className={classes.textCustom}>
                                Tipo:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography className={classes.textCustomSub}>
                                {obterDescricaoTipoSaida(s.tipo)}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Situação:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography className={classes.textCustomSub}>
                              {TextoSituacao(
                                s.situacao,
                                s.dataFinalizacao,
                                s.dataCancelamento
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item>
                            <Typography xs={4} className={classes.textCustom}>
                              Motivo:
                            </Typography>
                          </Grid>
                          <Grid xs={8} item>
                            <Typography className={classes.textCustomSub}>
                              {s.motivo}
                            </Typography>
                          </Grid>
                        </Grid>
                        {s.observacao && (
                          <Grid container>
                            <Grid item>
                              <Typography xs={4} className={classes.textCustom}>
                                Observação:
                              </Typography>
                            </Grid>
                            <Grid xs={8} item>
                              <Typography className={classes.textCustomSub}>
                                {s.observacao}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} md={4} style={{ textAlign: 'right' }}>
                        <Grid container className={classes.containerCustom}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: 'right' }}
                          >
                            {s.origem === 'P' && (
                              <span className={classes.btnDefaultMarkPrimary}>
                                <RoundedButton
                                  className={classes.buttonDefaultMark}
                                  color="primary"
                                  disabled
                                  variant="outlined"
                                >
                                  Via portal
                                </RoundedButton>
                              </span>
                            )}
                            <span className={classes.btnBlueMarkPrimary}>
                              <RoundedButton
                                className={classes.buttonBlueMark}
                                color="primary"
                                disabled
                                variant="contained"
                              >
                                {obterTipoSaida(s.tipoPessoaSaida)}
                              </RoundedButton>
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{
                              display: 'flex',
                              alignItems: 'end',
                              justifyContent: 'flex-end',
                            }}
                          >
                            {s.situacao === 'Ativo' && (
                              <span>
                                <RoundedButton
                                  className={classes.registerButton}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => setOpenFinalize(s)}
                                >
                                  ⠀Finalizar⠀
                                </RoundedButton>
                              </span>
                            )}
                          </Grid>
                          {s.situacao === 'Ativo' && s.origem === 'P' && (
                            <Grid item style={{ alignSelf: 'end' }} xs={12}>
                              <IconButton onClick={() => handleEditExit(s)}>
                                <Edit color="primary" />
                              </IconButton>
                              <IconButton onClick={() => setOpenCancel(s)}>
                                <DeleteForever color="primary" />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {saidas.length !== index + 1 && (
                      <Grid container style={{ margin: '15px 0 15px 0' }}>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                ))
              )}
            </>
          )}
        </div>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={pagina - 1}
          onChangePage={handleChangePage}
        />
        <ConfirmCloseModal
          handleClose={() => setOpenCancel(null)}
          open={openCancel != null}
          loading={loadingCancel}
          confirm={confirmCancel}
          title="Cancelar saída antecipada"
          message="Tem certeza? Essa ação não pode ser revertida"
        />
        <ConfirmCloseModal
          handleClose={() => setOpenFinalize(null)}
          open={openFinalize != null}
          loading={loadingFinalize}
          confirm={handleFinalizeExit}
          title="Finalizar saída antecipada"
          message="Tem certeza? Essa ação não pode ser revertida"
        />
      </Container>
    </DefaultPage>
  );
};
