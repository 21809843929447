import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const StyledField = withStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    '& input:focus + fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextField);

export default ({
  placeholder = 'Procurar...',
  customClasses = '',
  variant = 'standard',
  colorIcon = 'inherit',
  onChange = () => {},
}) => {
  return (
    <StyledField
      placeholder={placeholder}
      className={customClasses}
      variant={variant}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search color={colorIcon} />
          </InputAdornment>
        ),
      }}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};
