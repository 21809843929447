import { combineReducers } from 'redux';

import user from './user';
import alert from './alert';
import profile from './profile';
import notification from './notification';
import configuration from './configuration';

export default combineReducers({
  user,
  alert,
  profile,
  notification,
  configuration,
});
