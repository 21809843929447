import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ConfigurationActions } from '../../store/ducks/configuration';
import { Configurations } from '../../components';
import api from '../../services/api';

export default () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.configuration);

  const getConfiguration = () => {
    api.get('configuration/getConfigurations').then((res) => {
      config.configurations = res.data;
      dispatch(
        ConfigurationActions.setConfiguration({
          configurations: res.data,
        })
      );
    });
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return <Configurations configuration={config} />;
};
