import React, { useEffect, useRef, useState } from 'react';
import api from '../../services/api';

export default ({
  idProduto,
  onErro,
  onEnviarFeedback,
  onFecharModal,
  textoTitulo,
  idFuncionalidade,
}) => {
  const [token, setToken] = useState();
  const [tenant, setTenant] = useState();
  const elementRef = useRef(null);

  useEffect(() => {
    api.get('/configuration/obterAccessTokenLS').then((response) => {
      setToken(response.data.access_token);
    });
    api.get('/configuration/getConfigurations').then((response) => {
      setTenant(response.data.tenant);
    });
  }, []);

  useEffect(() => {
    const onEnviarFeedbackListener = (event) =>
      onEnviarFeedback && onEnviarFeedback(event.detail);
    const error = (event) => onErro && onErro(event.detail);
    const fecharModal = (event) => onFecharModal && onFecharModal(event.detail);
    const ref = elementRef.current;
    if (ref) {
      ref.addEventListener('enviarFeedback', onEnviarFeedbackListener);
      ref.addEventListener('erro', error);
      ref.addEventListener('fecharModal', fecharModal);
      return () => {
        ref.removeEventListener('enviarFeedback', onEnviarFeedbackListener);
        ref.removeEventListener('erro', error);
        ref.removeEventListener('fecharModal', fecharModal);
      };
    }
  }, [onErro, onEnviarFeedback, onFecharModal]);

  return React.createElement('feedback-component', {
    ref: elementRef,
    'id-produto': idProduto,
    'texto-titulo': textoTitulo,
    'id-funcionalidade': idFuncionalidade,
    'id-tenant': tenant,
    'token-jwt': token,
  });
};
