import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  Toolbar,
  Dialog,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Add, DeleteForever, Search, Close, Check } from '@material-ui/icons';
import {
  Container,
  RoundedInput,
  RoundedButton,
  LoadingButton,
  TableRowFocusable,
  SearchInput,
  MultipleValueInput,
} from '../../components';
import DefaultPage from '../../templates/DefaultPage';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '1em',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  notFound: {
    marginTop: '2em',
    color: '#848484',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  tableCell: {
    wordBreak: 'break-word',
    width: '100%',
  },
  tableCellIcons: {
    width: 'auto',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '2em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  customButton: {
    borderRadius: '5px !important',
  },
  buttonAddUser: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  customToolbar: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  registerButton: {
    borderRadius: '5px !important',
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  customDialogContent: {
    marginLeft: '4em',
    marginRight: '4em',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default () => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const perPageSearch = 5;
  const perPage = 10;

  const [open, setOpen] = useState(false);
  const [sectorName, setSectorName] = useState('');
  const [sectorMessage, setSectorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const [usersAdd, setUsersAdd] = useState([]);
  const [userSearchName, setUserSearchName] = useState('');
  const [usersSearch, setUsersSearch] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [pageSearch, setPageSearch] = useState(1);
  const [totalSearch, setTotalSearch] = useState(0);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState([]);

  const handleChangeUserSearchName = (value) => setUserSearchName(value.trim());
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setUserSearchName('');
  };
  const handleCancel = () => history.push('/sector');
  const handleHandleSuccess = () => history.push(`/sector`);

  const handleChangePageSearch = (event, nextPage) =>
    handleSearchUser(nextPage + 1);
  const handleChangePage = (event, nextPage) => setPage(nextPage + 1);

  const getSelectedTargetsIds = () => selectedTargetAudiences.map((u) => u.id);

  const handleSearchUser = (page = 1) => {
    setIsLoadingSearch(true);

    const params = {
      page,
      name: userSearchName,
      limit: perPageSearch,
    };

    api
      .get('users/getUserByName', {
        params,
      })
      .then((response) => {
        setUsersSearch(response.data.data);
        setPageSearch(page);
        setTotalSearch(response.data.total);
        setIsLoadingSearch(false);
      })
      .catch((err) => setIsLoadingSearch(false));
    // eslint-disable-next-line
  };

  const handleRegisterError = (errorMessage) => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível cadastrar o setor',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const registerData = {
      name: sectorName,
      message: sectorMessage,
      users: usersAdd,
      targetAudiences: getSelectedTargetsIds(),
    };

    api
      .post('sector/createSector', registerData)
      .then((res) => {
        if (!res.data.Sucesso) handleRegisterError(res.data.Mensagem);
        else {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Setor cadastrado com sucesso!',
          });
          handleHandleSuccess();
        }
      })
      .catch(() => {
        handleRegisterError(
          'Ocorreu um erro inesperado ao tentar cadastrar o setor.'
        );
      });
  };

  const addRemoveUser = (user) => {
    const usuarioAdicionado = usersAdd.filter((u) => u.IdUsuario == user.id);

    if (usuarioAdicionado.length > 0)
      setUsersAdd(usersAdd.filter((u) => u.IdUsuario !== user.id));
    else {
      const newUser = {
        IdUsuario: user.id,
        NomeUsuario: user.name,
        EmailUsuario: user.email,
      };
      setUsersAdd([...usersAdd, newUser]);
    }
  };

  useEffect(
    () => {
      setIsLoading(true);

      setTotal(usersAdd.length);

      const init = (page - 1) * perPage;
      setUsers(usersAdd.slice(init, init + perPage));
      setIsLoading(false);

      api
        .get('target-audience', {
          params: {
            limit: 1000,
            appRole: ['admin'],
          },
        })
        .then((response) => {
          setTargetAudiences(response.data.data);
        });
    },
    [page, usersAdd],
    []
  );

  const userAdd = (user) => {
    const usuarioAdicionado = usersAdd.filter((u) => u.IdUsuario == user.id);

    return (
      <>
        {!usuarioAdicionado.length > 0 ? (
          <RoundedButton
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            className={classes.customButton}
            onClick={() => addRemoveUser(user)}
          >
            Adicionar
          </RoundedButton>
        ) : (
          <RoundedButton
            color="primary"
            variant="contained"
            startIcon={<Check />}
            className={classes.customButton}
            onClick={() => addRemoveUser(user)}
          >
            Adicionado!
          </RoundedButton>
        )}
      </>
    );
  };

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <>
        {onClose ? (
          <div>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <Close />
            </IconButton>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <DefaultPage title="Setores">
      <Container>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div className={classes.formInputs}>
            <Typography className={classes.title} variant="h5" color="primary">
              Cadastro de Setor
            </Typography>
            <div className={classes.formBody}>
              <RoundedInput
                className={classes.input}
                label="Nome do Setor"
                size="small"
                inputProps={{ maxLength: '40', minLength: '2' }}
                variant="outlined"
                onChange={(e) => setSectorName(e.target.value)}
                required
              />
              <RoundedInput
                className={classes.input}
                label="Mensagem Padrão"
                size="small"
                multiline
                inputProps={{ maxLength: '250' }}
                rows={4}
                variant="outlined"
                onChange={(e) => setSectorMessage(e.target.value)}
              />
              <Typography
                className={classes.subTitle}
                variant="subtitle1"
                color="primary"
              >
                Público-alvo
              </Typography>
              <div className={classes.divTargetAudience}>
                <MultipleValueInput
                  customClasses={classes.fieldTargetAudience}
                  label="Procurar..."
                  variant="outlined"
                  labelProperty="descricao"
                  value={selectedTargetAudiences}
                  options={targetAudiences}
                  onChange={(event, value) => setSelectedTargetAudiences(value)}
                />
              </div>
              <Toolbar className={classes.customToolbar}>
                <Typography variant="subtitle1" color="primary">
                  Usuários ativos
                </Typography>
                <RoundedButton
                  className={classes.buttonAddUser}
                  color="primary"
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={handleClickOpen}
                >
                  Adicionar usuário
                </RoundedButton>
              </Toolbar>
              {users?.length === 0 ? (
                <Typography className={classes.notFound} variant="body2">
                  Nenhum usuário está cadastrado a este setor.
                </Typography>
              ) : (
                <>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.map((user) => (
                          <TableRowFocusable key={user.IdUsuario} hover>
                            <TableCell className={classes.tableCell}>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar className={classes.avatar}>
                                    {user.NomeUsuario[0].toUpperCase()}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                  <Typography>{user.NomeUsuario}</Typography>
                                </ListItemText>
                              </ListItem>
                            </TableCell>
                            <TableCell className={classes.tableCellIcons}>
                              <DeleteForever
                                color="primary"
                                className={classes.iconCustom}
                                onClick={() => addRemoveUser(user)}
                              />
                            </TableCell>
                          </TableRowFocusable>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[perPage]}
                    component="div"
                    count={total}
                    rowsPerPage={perPage}
                    page={page - 1}
                    onChangePage={handleChangePage}
                  />
                </>
              )}
            </div>
          </div>
          <div className={classes.buttons}>
            <RoundedButton
              variant="contained"
              className={classes.customButton}
              onClick={handleCancel}
            >
              Cancelar
            </RoundedButton>
            <LoadingButton
              className={classes.loadingButton}
              type="submit"
              variant="contained"
              color="primary"
              isLoading={isLoading}
            >
              Cadastrar
            </LoadingButton>
          </div>
        </form>

        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogActions>
            <DialogTitle id="customized-dialog-title" onClose={handleClose} />
          </DialogActions>
          <DialogContent className={classes.customDialogContent}>
            <Toolbar className={classes.customToolbar}>
              <SearchInput
                placeholder="Pesquisar usuário"
                variant="outlined"
                onChange={handleChangeUserSearchName}
              />
              <LoadingButton
                className={classes.registerButton}
                color="primary"
                variant="contained"
                startIcon={<Search />}
                isLoading={isLoadingSearch}
                onClick={() => handleSearchUser(1)}
              >
                Buscar
              </LoadingButton>
            </Toolbar>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersSearch?.map((user) => (
                    <TableRowFocusable key={user.id} hover>
                      <TableCell className={classes.tableCell}>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                              {user.name[0].toUpperCase()}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText>
                            <Typography>{user.name}</Typography>
                          </ListItemText>
                        </ListItem>
                      </TableCell>
                      <TableCell className={classes.tableCellIcons}>
                        {userAdd(user)}
                      </TableCell>
                    </TableRowFocusable>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[perPageSearch]}
              component="div"
              count={totalSearch}
              rowsPerPage={perPageSearch}
              page={pageSearch - 1}
              onChangePage={handleChangePageSearch}
            />
          </DialogContent>
          <DialogActions>
            <RoundedButton autoFocus onClick={handleClose} color="primary">
              OK
            </RoundedButton>
          </DialogActions>
        </Dialog>
      </Container>
    </DefaultPage>
  );
};
