export default (name) => {
  if (!name) return null;

  const words = name.split(' ');

  if (words.length === 1) {
    return words[0].slice(0, 2).toUpperCase();
  }
  return words[0]
    .slice(0, 1)
    .concat(words[words.length - 1].slice(0, 1))
    .toUpperCase();
};
