import React from 'react';
import { Drawer, Toolbar, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuContent } from '..';

const useStyles = makeStyles(() => ({
  drawerWidth: {
    width: (props) => props.drawerWidth,
  },
}));

export default ({
  isMobileMenuOpen,
  handleDrawerToggle,
  drawerWidth = '250px',
}) => {
  const classes = useStyles({ drawerWidth });
  return (
    <nav>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          open={isMobileMenuOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerWidth,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <MenuContent />
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerWidth,
          }}
          open
        >
          <Toolbar />
          <MenuContent />
        </Drawer>
      </Hidden>
    </nav>
  );
};
