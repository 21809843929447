import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoDialog from '../../components/InfoDialog/index';

const useStyles = makeStyles((theme) => ({
  divMain: {
    minWidth: '300px',
  },
  body: {
    marginTop: '1px',
  },
  cellDialog: {
    padding: '0.5em',
    textAlign: 'center',
    width: '25%',
  },
  divOptionsConfigs: {
    marginLeft: '1em',
  },
  divAditionalConfig: {
    marginBottom: '8px',
  },
}));

export default ({ dialogConfigurationFile, onClose, open, title }) => {
  const classes = useStyles();

  return (
    <InfoDialog onClose={onClose} open={open} title={title}>
      <div className={classes.divMain}>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Descrição:&nbsp;
          </Typography>
          <div className={classes.body}>
            {dialogConfigurationFile?.descricao ?? '' }
          </div>
        </div>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Público-alvo:&nbsp;
          </Typography>
          {dialogConfigurationFile?.publicosAlvo.map((publico) => (
            <div key={publico.id}>{` - ${publico.descricao}`}</div>
          ))}
        </div>
        <Divider />
        <div className={classes.divAditionalConfig}>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Configurações adicionais:&nbsp;
          </Typography>
          <div hidden={!dialogConfigurationFile?.restringeSomenteProfTitular}>
            - Restringe somente professor titular
          </div>
          <div hidden={!dialogConfigurationFile?.liberaEmTempoReal}>
            - Libera em tempo real
          </div>
          <div
            hidden={
              dialogConfigurationFile?.liberaEmTempoReal ||
              dialogConfigurationFile?.restringeSomenteProfTitular
            }
          >
            Nenhuma configuração adicional cadastrada.
          </div>
        </div>
      </div>
    </InfoDialog>
  );
};
