import React from 'react';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '../../components';
import FormExit from './FormExit';

export default () => {
  return (
    <DefaultPage title="Saídas antecipadas">
      <Container>
        <FormExit titulo='Nova saída antecipada' />
      </Container>
    </DefaultPage>
  );
};
