import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { MenuSubSection } from '..';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px',
  },
  header: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  itemText: {
    paddingLeft: '2em',
    opacity: '0.75',
  },
  activeLink: {
    fontWeight: 'bold',
  },
}));

export default ({ title, items }) => {
  const config = useSelector((state) => state.configuration.configurations);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const hasItems = items.length > 0;
  const defaultMenuOptions = {
    exact: true,
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getMenuOptions = (item) => {
    return {
      ...defaultMenuOptions,
      ...item?.menuOptions,
    };
  };

  return (
    <List className={classes.root} hidden={!hasItems}>
      <ListItem button className={classes.header} onClick={handleClick}>
        <ListItemText
          classes={{ primary: classes.header }}
          primary={title?.toUpperCase()}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {items?.map((i) =>
          i.subSectionTitle ? (
            <>
              <MenuSubSection
                key={i.subSectionTitle}
                title={i.subSectionTitle}
                items={i.items}
              />
            </>
          ) : i.id === 'news' && config.usaMural ? (
            <ListItem
              key={i.newName}
              className={classes.itemText}
              button
              component="a"
              exact={getMenuOptions(i).exact}
              href={`https://${config.subDominio}.painel.gvdasa.com.br/servico-mural/mural`}
              target="_blank"
              activeClassName={classes.activeLink}
            >
              {i.newName}
            </ListItem>
          ) : (
            <ListItem
              key={i.name}
              className={classes.itemText}
              button
              component={NavLink}
              exact={getMenuOptions(i).exact}
              to={i.path}
              activeClassName={classes.activeLink}
            >
              {i.name}
            </ListItem>
          )
        )}
      </Collapse>
    </List>
  );
};
