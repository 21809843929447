import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as AlertActions } from '../../store/ducks/alert';
import { SnackbarAlert } from '../../components';

export default () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(AlertActions.setAlert({ ...alert, isOpen: false }));
  };

  return <SnackbarAlert alert={alert} onClose={handleAlertClose} />;
};
