import { createActions, createReducer } from 'reduxsauce';

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setAlert: ['alert'],
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  isOpen: false,
  type: '',
  message: '',
};

const setAlert = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.alert,
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_ALERT]: setAlert,
});
