import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { RoundedButton, SearchInput, LoadingButton } from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles(() => ({
  content: {
    padding: '15px 25px 15px 25px',
  },
  searchSector: {
    marginBottom: '20px',
  },
  divbtn: {
    display: 'flex',
    justifyContent: 'end',
    padding: '15px 0px',
  },
  cancelButton: {
    marginRight: '15px',
  },
  divRadioGroup: {
    height: '250px',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  radioOption: {
    border: '1px solid #d7dde0',
    borderRadius: '5px',
    margin: '0px 10px 8px 0px',
    justifyContent: 'space-between',
    '& .MuiFormControlLabel-label': {
      marginLeft: '10px',
    },
  },
}));

export default ({ handleClose, open, idMessage, nameSectorForwarded }) => {
  const classes = useStyles();
  const setAlert = useAlert();
  const debounce = useDebounce();
  const history = useHistory();

  const [selectedSector, setSelectedSector] = useState(null);
  const [sectors, setSectors] = useState([]);
  const [filtredSectors, setFiltredSectors] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleError = (message) => {
    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um problema inesperado!',
    });

    setSectors([]);
    setPage(1);
  };

  useEffect(() => {
    if (open) {
      setSelectedSector(null);
      const params = {
        page,
        limit: 1000,
        appRole: [
          'coordination',
          'communication',
          'completeConcierge',
          'admin',
          'sectorMessage',
        ],
      };
      api
        .get('sector/getSectors', {
          params,
        })
        .then((response) => {
          if (!response.data.Sucesso) handleError(response.data.Mensagem);
          else {
            setSectors([...response.data.Dados.Data]);
            setFiltredSectors([...response.data.Dados.Data]);
          }
        })
        .catch((err) => handleError(null));
    }
  }, [open]);

  const filterSectors = (text) => {
    setFiltredSectors(
      sectors.filter(
        (s) => s.Nome.toLowerCase().includes(text) || s.Nome.includes(text)
      )
    );
  };

  const handleToForwardMessage = () => {
    setIsLoading(true);
    const data = {
      idMessage,
      idSector: selectedSector,
      nameSectorForwarded,
    };

    api
      .post('chat/toForwardMessage', data)
      .then((res) => {
        const dataTemp = res.data;
        if (dataTemp.sucesso) {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Mensagem encaminhada com sucesso.',
          });
          history.push('/messageSector');
        }
      })
      .catch(() => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Ocorreu um erro inesperado ao encaminhar a mensagem.',
        });
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent className={classes.content}>
        <Typography color="textSecondary" style={{ marginRight: 'auto' }}>
          <strong>Encaminhar mensagem</strong>
        </Typography>
        <Typography color="textSecondary" style={{ marginRight: 'auto' }}>
          <p>
            Selecione um setor para receber essa mensagem e ficar responsável
            por dar continuidade na conversa.
          </p>
        </Typography>
        <SearchInput
          variant="outlined"
          placeholder="Buscar setor"
          customClasses={classes.searchSector}
          onChange={debounce(filterSectors)}
        />
        <div className={classes.divRadioGroup}>
          <RadioGroup column>
            {filtredSectors.map((s, index) => (
              <FormControlLabel
                className={classes.radioOption}
                key={index}
                labelPlacement="start"
                value={s.Id}
                checked={s.Id == selectedSector}
                onChange={(e) => setSelectedSector(e.target.value)}
                control={
                  <Radio checkedIcon={<CheckCircle />} color="primary" />
                }
                label={s.Nome}
              />
            ))}
          </RadioGroup>
        </div>
        <div className={classes.divbtn}>
          <RoundedButton
            className={classes.cancelButton}
            color="primary"
            onClick={handleClose}
          >
            Cancelar
          </RoundedButton>
          <LoadingButton
            isLoading={isLoading}
            className={classes.registerButton}
            color="primary"
            variant="contained"
            onClick={handleToForwardMessage}
          >
            Encaminhar
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};
