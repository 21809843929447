import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '4px',
    width: '100%',
    [theme.breakpoints.up(960 + 250)]: {
      padding: theme.spacing(3, 2),
    },
  },
  paper: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: 'fit-content',
    maxWidth: theme.breakpoints.values.lg,
  },
}));

export default ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>{children}</Paper>
    </div>
  );
};
