import React from 'react';

import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

import InfoDialog from '../../components/InfoDialog/index';

const useStyles = makeStyles(() => ({
  divMain: {
    minWidth: '300px',
  },
  body: {
    marginTop: '1px',
  },
  cellDialog: {
    padding: '0.5em',
    textAlign: 'center',
    width: '25%',
  },
  divOptionsConfigs: {
    marginLeft: '1em',
  },
  divAditionalConfig: {
    marginBottom: '8px',
  }
}));

export default ({setting, onClose, open, title}) => {
  const classes = useStyles();

  return (
    <InfoDialog
      onClose={onClose}
      open={open}
      title={title}
    >
      <div className={classes.divMain}>    
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Identificador:&nbsp;
          </Typography>
          <div className={classes.body}>
            {setting?.identificador}
          </div>
        </div>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Itens vinculados:&nbsp;
          </Typography>
          {setting?.itens.map((item) => (
            <div key={item.id}>
              {" - " + item.descricao}
            </div>
          ))}
        </div>
        <Divider />
        <div>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Público-alvo:&nbsp;
          </Typography>
          {setting?.publicosAlvo.map((publico) => (
            <div key={publico.id}>
              {" - " + publico.descricao}
            </div>
          ))}
        </div>
        <Divider />
        <div className={classes.divAditionalConfig}>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
            Configurações adicionais:&nbsp;
          </Typography>
          <div hidden={!setting?.restringeSomenteProfeTitular}>
            - Restringe somente professor titular
          </div>
          <div hidden={!setting?.habilitaIntegracaoDiario}>
            - Usa integração com o diário de classe
          </div>
          <div hidden={!setting?.liberaTempoReal}>
            - Libera em tempo real
          </div>
          <div hidden={(setting?.liberaTempoReal || setting?.restringeSomenteProfeTitular || setting?.habilitaIntegracaoDiario)}>
            Nenhuma configuração adicional cadastrada.
          </div>
        </div>
      </div>
    </InfoDialog>
  )
}