import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import lottie from 'lottie-web';
import { BackToButton } from '..';
import notFoundAnimation from '../../assets/lottie-404Animation.json';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
  },
  instructions: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default () => {
  const classes = useStyles();

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById('notfound'),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: notFoundAnimation,
    });
    return () => {
      lottie.destroy(animation);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div id="notfound" />
      <Typography variant="h5" color="primary">
        Página não encontrada!
      </Typography>
      <div className={classes.instructions}>
        <BackToButton to="/" primary />
        <Typography variant="h6" color="primary" component="span">
          Voltar para a home.
        </Typography>
      </div>
    </div>
  );
};
