import { createActions, createReducer } from 'reduxsauce';
import { nameInitials } from '../../helpers';
import { getFeatures, groupBySection } from '../../services/features';
/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setSelectedApplication: ['application'],
  setProfile: ['profile'],
  logout: [],
});

/**
 * Handlers
 */
const INITIAL_STATE = () => ({
  loading: true,
  features: getFeatures(null),
  featureSections: groupBySection(getFeatures(null)),
  role: null,
  applications: [],
  application: null,
});

const setSelectedApplication = (state = INITIAL_STATE(), action) => {
  localStorage.setItem('application', action.application.id);
  const userAppPermissions = action.application.userAppPermissions.map(
    (permission) => permission.appRole.name
  );
  const isAppOwner = state.id === action.application.owner_id;
  const features = getFeatures(state.role, userAppPermissions, isAppOwner);
  const featureSections = groupBySection(features);
  return {
    ...state,
    loading: false,
    features,
    featureSections,
    application: action.application,
  };
};

const setProfile = (state = INITIAL_STATE(), action) => {
  let application = null;
  let features = getFeatures(action.profile.role);
  let featureSections = groupBySection(features);
  let isAppOwner = false;

  const applications = action.profile.applications.map((app) => ({
    ...app,
    initials: nameInitials(app.name),
  }));
  try {
    const appId = localStorage.getItem('application');
    application = action.profile.applications.find(
      (app) => app.id === parseInt(appId, 10)
    );
  } finally {
    if (!application) [application] = action.profile.applications;
  }
  if (application) {
    localStorage.setItem('application', application.id);
    const userAppPermissions = application.userAppPermissions.map(
      (permission) => permission.appRole.name
    );
    isAppOwner = action.profile.id === application.owner_id;
    const appOwner = action.profile.id === application.owner_id;
    features = getFeatures(action.profile.role, userAppPermissions, appOwner);
    featureSections = groupBySection(features);
  }

  return {
    ...state,
    ...action.profile,
    loading: false,
    features,
    featureSections,
    applications,
    application, 
    isAppOwner,   
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE(), {
  [Types.SET_SELECTED_APPLICATION]: setSelectedApplication,
  [Types.SET_PROFILE]: setProfile,
});
