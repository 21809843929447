import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Typography, Checkbox } from '@material-ui/core';
import { ListAlt } from '@material-ui/icons';
import {
  RoundedInput,
  RoundedButton,
  LoadingButton,
  MultipleValueInput,
} from '../../components';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';
import ViewConfigurationMessageModal from './ViewConfigurationMessageModal';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
    marginBottom: '1em',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '2em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  loadingButton: {
    marginLeft: '1em',
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },

  divTargetAudience: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5em',
    marginBottom: '0.5em',
  },
  fieldTargetAudience: {
    flexGrow: 1,
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1em',
  },
  checkboxRecoil: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1em',
    marginLeft: '2em',
  },
}));

export default ({
  isEdit = false,
  configurationMessageInfo = {},
  configurationMessageId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const setAlert = useAlert();

  const [isLoading, setIsLoading] = useState(false);

  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState(
    configurationMessageInfo.publicosAlvo ?? []
  );

  const [configurationName, setConfigurationName] = useState(
    configurationMessageInfo.descricao ?? ''
  );
  const [chatLesson, setChatLesson] = useState(
    configurationMessageInfo.usaChatDisciplina ?? false
  );
  const [dontShowDiscipline, setDontShowDiscipline] = useState(
    configurationMessageInfo.ocultaDiscSemProfe ?? false
  );
  const [allowSendImageTeacher, setAllowSendImageTeacher] = useState(
    configurationMessageInfo.permiteEnviarimagemProf ?? false
  );
  const [allowSendImageResp, setAllowSendImageResp] = useState(
    configurationMessageInfo.permiteEnviarImagemResp ?? false
  );
  const [allowSendImageCoord, setAllowSendImageCoord] = useState(
    configurationMessageInfo.permiteEnviarImagemCoord ?? false
  );
  const [allowSendFileTeacher, setAllowSendFileTeacher] = useState(
    configurationMessageInfo.permiteEnviarArquivoProf ?? false
  );
  const [allowSendFileResp, setAllowSendFileResp] = useState(
    configurationMessageInfo.permiteEnviarArquivoResp ?? false
  );
  const [allowSendFileCoord, setAllowSendFileCoord] = useState(
    configurationMessageInfo.permiteEnviarArquivoCoord ?? false
  );
  const [allowRespSendFileSectors, setAllowRespSendFileSectors] = useState(
    configurationMessageInfo.permiteRespEnviarArquivoSetores ?? false
  );
  const [allowRespSendImageSectors, setAllowRespSendImageSectors] = useState(
    configurationMessageInfo.permiteRespEnviarImagemSetores ?? false
  );
  const [releaseRealTime, setReleaseRealTime] = useState(
    configurationMessageInfo.liberaEmTempoReal ?? false
  );
  const [
    replicateMessageToAllTeachers,
    setReplicateMessageToAllTeachers,
  ] = useState(configurationMessageInfo.replicaTodosProfessores ?? false);
  const [restrictTeacher, setRestrictTeacher] = useState(
    configurationMessageInfo.restringeEscritaProfTitular ?? false
  );

  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [settingSelected, setSettingSelected] = useState();
  const application = useSelector((state) => state.profile?.application);
  const [apiVersion, setApiVersion] = useState('');

  useEffect(() => {
    if (application != null && apiVersion === '') {
      api
        .get(`apis/version/${application.id}`)
        .then((response) => {
          setApiVersion(response.data);
        })
        .catch(() => {
          setApiVersion('');
        });
    }
  }, [application]);

  const handleCancel = () => history.push('/configuration-messages');
  const handleHandleSuccess = () => history.push(`/configuration-messages`);

  const handleRegisterError = (errorMessage) => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível cadastrar a configuração',
    });
  };

  const handleOpenDetailsItem = () => {
    const setting = {
      descricao: configurationName,
      liberaEmTempoReal: releaseRealTime,
      restringeEscritaProfTitular: restrictTeacher,
      replicaTodosProfessores: replicateMessageToAllTeachers,
      usaChatDisciplina: chatLesson,
      ocultaDiscSemProfe: dontShowDiscipline,
      permiteEnviarimagemProf: allowSendImageTeacher,
      permiteEnviarImagemResp: allowSendImageResp,
      permiteEnviarImagemCoord: allowSendImageCoord,
      permiteEnviarArquivoProf: allowSendFileTeacher,
      permiteEnviarArquivoResp: allowSendFileResp,
      permiteEnviarArquivoCoord: allowSendFileCoord,
      permiteRespEnviarArquivoSetores: allowRespSendFileSectors,
      permiteRespEnviarImagemSetores: allowRespSendImageSectors,
      publicosAlvo: selectedTargetAudiences,
    };
    setSettingSelected(setting);
    setOpenDetailsDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDetailsDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const registerData = {
      Descricao: configurationName,
      LiberaEmTempoReal: releaseRealTime,
      RestringeEscritaSomenteProfTitular: restrictTeacher,
      ReplicaTodosProfessores: replicateMessageToAllTeachers,
      UsaChatDisciplina: chatLesson,
      OcultaDiscSemProfe: dontShowDiscipline,
      PermiteEnviarimagemProf: allowSendImageTeacher,
      PermiteEnviarImagemResp: allowSendImageResp,
      PermiteEnviarImagemCoord: allowSendImageCoord,
      PermiteEnviarArquivoProf: allowSendFileTeacher,
      PermiteEnviarArquivoResp: allowSendFileResp,
      PermiteEnviarArquivoCoord: allowSendFileCoord,
      PermiteRespEnviarArquivoSetores: allowRespSendFileSectors,
      PermiteRespEnviarImagemSetores: allowRespSendImageSectors,
      idsPublicoAlvo: selectedTargetAudiences.map((ta) => ta.id),
    };

    if (isEdit) {
      api
        .put(`configuration-message/${configurationMessageId}`, registerData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Configuração editada com sucesso!',
          });
          history.push(`/configuration-messages`);
        })
        .catch(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'error',
            message: 'Ocorreu um erro ao tentar editar a configuração.',
          });
        });
    } else {
      api
        .post('configuration-messages', registerData)
        .then((res) => {
          if (res.statusText != 'OK') handleRegisterError(res.data.Mensagem);
          else {
            setIsLoading(false);
            setAlert({
              isOpen: true,
              type: 'success',
              message: 'Configuração cadastrada com sucesso!',
            });
            handleHandleSuccess();
          }
        })
        .catch(() => {
          handleRegisterError(
            'Ocorreu um erro inesperado ao tentar cadastrar a configuração.'
          );
        });
    }
  };

  useEffect(() => {
    api
      .get('target-audience', {
        params: {
          limit: 1000,
          appRole: ['coordination', 'admin'],
        },
      })
      .then((response) => {
        setTargetAudiences(response.data.data);
      });
  }, []);

  useEffect(() => {
    if (!chatLesson) setDontShowDiscipline(false);
  }, [chatLesson]);

  return (
    <>
      <ViewConfigurationMessageModal
        dialogConfigurationMessage={settingSelected}
        open={openDetailsDialog}
        title="Resumo"
        onClose={handleCloseDialog}
      />
      <form className={classes.form} onSubmit={handleSubmit}>
        <div className={classes.formInputs}>
          <Typography className={classes.title} variant="h5" color="primary">
            {isEdit ? 'Edição' : 'Cadastro'}
          </Typography>
          <div className={classes.formBody}>
            <RoundedInput
              className={classes.input}
              label="Descrição"
              size="small"
              inputProps={{ maxLength: '100', minLength: '2' }}
              variant="outlined"
              value={configurationName}
              onChange={(e) => setConfigurationName(e.target.value)}
              required
            />
          </div>
          <Typography
            className={classes.subTitle}
            variant="subtitle1"
            color="primary"
          >
            Público-alvo
          </Typography>
          <div className={classes.divTargetAudience}>
            <MultipleValueInput
              customClasses={classes.fieldTargetAudience}
              label="Procurar..."
              variant="outlined"
              labelProperty="descricao"
              value={selectedTargetAudiences}
              options={targetAudiences}
              onChange={(event, value) => setSelectedTargetAudiences(value)}
            />
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={chatLesson}
              onChange={() => setChatLesson(!chatLesson)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite mensagem entre professor e responsável
            </p>
          </div>
          {chatLesson && apiVersion >= '4.2.403' ? (
            <div className={classes.checkboxRecoil}>
              <Checkbox
                className={classes.checkUseLinkProp}
                checked={dontShowDiscipline}
                onChange={() => setDontShowDiscipline(!dontShowDiscipline)}
                color="primary"
              />
              <p className={classes.pUseLink}>
                Não exibe disciplinas sem professor vinculado
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendImageTeacher}
              onChange={() => setAllowSendImageTeacher(!allowSendImageTeacher)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil professor envie imagem
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendFileTeacher}
              onChange={() => setAllowSendFileTeacher(!allowSendFileTeacher)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil professor envie arquivo
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendImageResp}
              onChange={() => setAllowSendImageResp(!allowSendImageResp)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil estudante envie imagem
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendFileResp}
              onChange={() => setAllowSendFileResp(!allowSendFileResp)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil estudante envie arquivo
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendImageCoord}
              onChange={() => setAllowSendImageCoord(!allowSendImageCoord)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil coordenador envie imagem
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowSendFileCoord}
              onChange={() => setAllowSendFileCoord(!allowSendFileCoord)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil coordenador envie arquivo
            </p>
          </div>

          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowRespSendFileSectors}
              onChange={() =>
                setAllowRespSendFileSectors(!allowRespSendFileSectors)
              }
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil estudante envie arquivo para setores
            </p>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={allowRespSendImageSectors}
              onChange={() =>
                setAllowRespSendImageSectors(!allowRespSendImageSectors)
              }
              color="primary"
            />
            <p className={classes.pUseLink}>
              Permite que o perfil estudante envie imagem para setores
            </p>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={restrictTeacher}
              onChange={() => setRestrictTeacher(!restrictTeacher)}
              color="primary"
            />
            <p className={classes.pUseLink}>
              Restringe o envio e a visualização somente para o professor
              titular
            </p>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={replicateMessageToAllTeachers}
              onChange={() =>
                setReplicateMessageToAllTeachers(!replicateMessageToAllTeachers)
              }
              color="primary"
            />
            <p className={classes.pUseLink}>
              Replicar mensagem para todos os professores
            </p>
          </div>
          <div className={classes.checkbox}>
            <Checkbox
              className={classes.checkUseLinkProp}
              checked={releaseRealTime}
              onChange={() => setReleaseRealTime(!releaseRealTime)}
              color="primary"
            />
            <p className={classes.pUseLink}>Liberar em tempo real</p>
          </div>
        </div>
        <div className={classes.buttons}>
          <RoundedButton
            style={{ marginRight: '4em' }}
            color="primary"
            variant="contained"
            onClick={() => handleOpenDetailsItem()}
          >
            <ListAlt />
          </RoundedButton>
          <RoundedButton
            variant="contained"
            className={classes.customButton}
            onClick={handleCancel}
          >
            Cancelar
          </RoundedButton>
          <LoadingButton
            className={classes.loadingButton}
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isLoading}
          >
            {isEdit ? 'Salvar' : 'Cadastrar'}
          </LoadingButton>
        </div>
      </form>
    </>
  );
};
