import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, MenuItem } from '@material-ui/core';
import { Creators as AlertActions } from '../../store/ducks/alert';
import {
  RoundedInput,
  RoundedButton,
  LoadingButton,
  RoleValidator,
  TreeSelector,
} from '../../components';

import api from '../../services/api';
import rolesService from '../../services/availableRoles';
import permissionsService from '../../services/availablePermissions';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '0em 2em',
  },
  title: {
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 0em',
  },
  button: {
    marginLeft: '1em',
  },
}));

const isAppOwner = (apps, userId, appId) =>
  apps.some((app) => app.id === parseInt(appId, 10) && app.owner_id === userId);

const availableRoles = rolesService();
const mapApplications = (apps) =>
  apps.map((app) => ({
    description: app.name,
    idApp: app.id,
  }));

export default ({ isEdit = false, userInfo = {}, userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: adminId } = useSelector((state) => state.user?.user);

  const [applications, setApplications] = useState(
    mapApplications(userInfo?.applications ?? [])
  );
  const [login, setLogin] = useState(userInfo?.login ?? '');
  const [email, setEmail] = useState(userInfo?.email ?? '');
  const [name, setName] = useState(userInfo?.name ?? '');
  const [roles, setRoles] = useState(userInfo?.roles ?? ['client']);
  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState(
    userInfo?.selectedPermissions ?? []
  );
  const [selectedApplications, setSelectedApplications] = useState(
    userInfo?.selectedApplications ?? []
  );
  const [establishments, setEstablishments] = useState([]);
  const [establishmentsPermissions, setEstablishmentsPermissions] = useState(
    []
  );
  const [selectedEstablishments, setSelectedEstablishments] = useState(
    userInfo?.selectedEstablishments ?? []
  );

  const handleCancel = () => history.push('/users');

  const isClientRole = roles.includes('client');

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const findAppDescription = (id) =>
    applications.find((app) => app.idApp === parseInt(id, 10))?.description;

  const mapPermissionsPayload = () => {
    const anotherPayload = selectedEstablishments.map((estab) => {
      const [idApp, idPermission, idEstablishment] = estab.split(',');
      return {
        idApp: parseInt(idApp, 10),
        idPermission,
        idEstablishment: parseInt(idEstablishment, 10),
      };
    });
    let permPayload = selectedPermissions.filter((p) => {
      const [idApp, idPerm] = p.split(',');
      const exist =
        anotherPayload.filter(
          (ep) => ep.idApp === parseInt(idApp, 10) && ep.idPermission === idPerm
        ).length > 0;
      return !exist;
    });
    permPayload = permPayload.map((p) => {
      const [idApp, idPermission] = p.split(',');
      return {
        idApp: parseInt(idApp, 10),
        idPermission,
        idEstablishment: null,
      };
    });
    return anotherPayload.concat(permPayload);
  };

  const handleSelectApplications = (event) => {
    const id = event.target.value;
    if (selectedApplications.includes(id)) {
      setSelectedApplications(selectedApplications.filter((tl) => tl !== id));
    } else {
      setSelectedApplications([...selectedApplications, id]);
    }
  };

  const handleSelectPermissions = (event) => {
    const id = event.target.value;
    if (selectedPermissions.includes(id)) {
      setSelectedPermissions(selectedPermissions.filter((tl) => tl !== id));
    } else {
      setSelectedPermissions([...selectedPermissions, id]);
    }
  };

  const handleSelectEstablishments = (event) => {
    const id = event.target.value;
    if (selectedEstablishments.includes(id)) {
      setSelectedEstablishments(
        selectedEstablishments.filter((tl) => tl !== id)
      );
    } else {
      setSelectedEstablishments([...selectedEstablishments, id]);
    }
  };

  const handleSubmitError = (err) => {
    setIsLoading(false);
    const errorMessage =
      Array.isArray(err?.response?.data) &&
      err?.response?.data?.find((e) => e.message)?.message;

    setAlert({
      isOpen: true,
      type: 'error',
      message:
        errorMessage ||
        `Não foi possível ${isEdit ? 'editar' : 'cadastrar'} o usuário!`,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const userData = {
      login,
      name,
      email,
      roles,
      permissions: mapPermissionsPayload(),
    };

    if (isEdit) {
      api
        .put(`users/${userId}`, userData)
        .then(() => {
          setIsLoading(false);
          updateEmailPortalUser(userId, userData.email);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Usuário atualizado com sucesso!',
          });
          history.push(`/users`);
        })
        .catch((err) => {
          handleSubmitError(err);
        });
    } else {
      api
        .post('users', userData)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Usuário cadastrado com sucesso!',
          });
          history.push(`/users`);
        })
        .catch((err) => {
          handleSubmitError(err);
        });
    }
  };

  useEffect(() => {
    const newPermissions = selectedApplications.reduce((perms, app) => {
      return [
        ...perms,
        ...permissionsService(
          isAppOwner(userInfo?.applications, adminId, app)
        ).map((p) => ({
          idApp: app,
          idPermission: `${app},${p.name}`,
          description: p.description,
          permissionDescription: `${findAppDescription(app)} - ${
            p.description
          }`,
        })),
      ];
    }, []);
    setPermissions(newPermissions);
    setSelectedPermissions(
      selectedPermissions.filter((p) =>
        selectedApplications.includes(p.split(',')[0])
      )
    );
    if (selectedApplications.length) {
      api
        .get(`target-audience/establishments/${selectedApplications.join()}`)
        .then((response) => {
          setEstablishments(response.data);
        });
    }
    // eslint-disable-next-line
  }, [selectedApplications]);

  useEffect(() => {
    const newEstablishmentsPermissions = selectedPermissions.reduce(
      (estabs, perm) => {
        const permAppId = perm.split(',')[0];
        const estabsByApp = establishments.filter(
          (estab) => estab.idApp === parseInt(permAppId, 10)
        );
        return [
          ...estabs,
          ...estabsByApp.map((estab) => ({
            ...estab,
            idEstabPermission: `${perm},${estab.idEstabelecimento}`,
            idPermission: perm,
          })),
        ];
      },
      []
    );
    setEstablishmentsPermissions(newEstablishmentsPermissions);
    setSelectedEstablishments(
      selectedEstablishments.filter((estab) =>
        selectedPermissions.some((perm) => estab.startsWith(perm))
      )
    );
    // eslint-disable-next-line
  }, [selectedPermissions, establishments]);

  useEffect(() => {
    setApplications(mapApplications(userInfo?.applications ?? []));
    // eslint-disable-next-line
  }, [userInfo.applications]);

  const updateEmailPortalUser = (userId, userEmail) => {
    const updateData = {
      id: userId,
      email: userEmail,
    };

    api
      .put('sector/updateEmailUserSector', updateData)
      .then((res) => {})
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um erro inesperado ao tentar atualizar o email do usuário no vinculo dos setores.',
        });
      });
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <div className={classes.formInputs}>
        <Typography className={classes.title} variant="h5" color="primary">
          {isEdit ? 'Edição' : 'Cadastro'}
        </Typography>
        <RoundedInput
          className={classes.input}
          label="Login"
          size="small"
          value={login}
          inputProps={{ minLength: '4' }}
          onChange={(e) => setLogin(e.target.value)}
          required
        />
        <RoundedInput
          className={classes.input}
          label="Nome"
          size="small"
          value={name}
          inputProps={{ minLength: '4' }}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <RoundedInput
          className={classes.input}
          label="Email"
          type="email"
          size="small"
          value={email}
          inputProps={{ minLength: '6' }}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <RoleValidator allowedRoles={['admin', 'consulting']}>
          <RoundedInput
            className={classes.input}
            select
            label="Papel"
            size="small"
            value={roles}
            onChange={(e) => setRoles([e.target.value])}
            required
          >
            {availableRoles.map((role) => (
              <MenuItem key={role.name} value={role.name}>
                {role.description}
              </MenuItem>
            ))}
          </RoundedInput>
        </RoleValidator>
        {isClientRole ? (
          <>
            <TreeSelector
              title="Aplicações"
              items={applications}
              selecteds={selectedApplications}
              onSelect={handleSelectApplications}
              identifier="idApp"
            />
            <TreeSelector
              title="Permissões"
              items={permissions}
              selecteds={selectedPermissions}
              onSelect={handleSelectPermissions}
              identifier="idPermission"
              groupId="idApp"
              groupEntities={applications}
            />
            <TreeSelector
              title="Estabelecimentos"
              items={establishmentsPermissions}
              selecteds={selectedEstablishments}
              onSelect={handleSelectEstablishments}
              identifier="idEstabPermission"
              description="descricao"
              groupId="idPermission"
              groupEntities={permissions}
              groupDescription="permissionDescription"
            />
          </>
        ) : null}
      </div>
      <div className={classes.buttons}>
        <RoundedButton
          className={classes.button}
          variant="contained"
          onClick={handleCancel}
        >
          Cancelar
        </RoundedButton>
        <LoadingButton
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isLoading}
        >
          {isEdit ? 'Salvar' : 'Cadastrar'}
        </LoadingButton>
      </div>
    </form>
  );
};
