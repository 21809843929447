import React, { useState, useEffect, useRef } from 'react';
import {
  ListItem,
  ListItemText,
  Grid,
  Typography,
  Paper,
  Card,
  IconButton,
  FormControlLabel,
  Switch,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
  Divider,
  List,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Check,
  DoneAll,
  Block,
  Close,
  Send,
  ErrorOutline,
} from '@material-ui/icons';
import { Skeleton, Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { useSelector } from 'react-redux';
import EnviarArquivo from '../../components/EnviarArquivo';
import DefaultPage from '../../templates/DefaultPage';
import { RoundedInput, RoundedButton } from '../../components';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';

import ConfirmCloseModal from '../../components/ConfirmCloseModal';
import DetailsModal from './DetailsModal';
import RecipientsBatchModal from './RecipientsBatchModal';
import DetailsByMessage from './DetailsByMessage';
import { getAdminOrOwnerRole } from '../../helpers';
import { setSketch, getSketch } from '../../hooks/useSketch';

const useStyles = makeStyles((theme) => ({
  customButtonDefault: {
    borderRadius: '5px !important',
    marginLeft: '1em',
    marginTop: '5px',
  },
  input: {
    height: '65px',
    display: 'flex',
    width: '100%',
    margin: '0.8em 0em',
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
  },
  containerCustom: {
    marginLeft: '3%',
    marginRight: '3%',
    width: '100%',
    '@media (max-width:600px)': {
      marginLeft: '3%',
      marginRight: '3%',
    },
  },
  messageArea: {
    backgroundColor: '#F4F4F4',
    height: '500px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      backgroundColor: '#F5F5F5',
    },
    '&:hover': {
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#D8D8D8',
      },
    },
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
      borderRadius: '3px',
      backgroundColor: '#F5F5F5',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: '#E6E6E6',
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3);',
    },
  },
  messageProp: {
    display: 'inline-block',
    maxWidth: '80%',
    borderRadius: '10px',
    padding: '5px 15px',
    backgroundColor: theme.palette.primary.main,
    '& .MuiListItemText-primary': {
      fontSize: '1em',
      color: '#F5F5F5',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.8em',
      color: 'rgba(245, 245, 245, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexWrap: 'wrap',
    },
  },
  messageRem: {
    display: 'inline-block',
    maxWidth: '80%',
    borderRadius: '10px',
    padding: '5px 15px',
    borderWidth: '2px',
    '& .MuiListItemText-primary': {
      fontSize: '1em',
      color: '#3B3B3B',
    },
    '& .MuiListItemText-secondary': {
      fontSize: '0.8em',
      color: '#A0A0A0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      flexWrap: 'wrap',
    },
  },
  deleteMessageCustom: {
    display: 'flex',
    alignItems: 'center',
    color: '#dbdbdb',
    fontStyle: 'italic',
  },
  recipientsNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
  },
  formatNumberRecip: {
    fontSize: '20px',
    color: '#FFFFFF',
  },
  allRecipientsText: {
    marginLeft: '1em',
    cursor: 'pointer',
    fontSize: '16px',
    textDecoration: 'underline',
  },
  viewOnly: {
    marginTop: '1em',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#E5F6FD',
    padding: '0.3em 0.8em',
    borderRadius: '4px',
    color: '#7D7D7D',
  },
  errorIcon: {
    color: '#7D7D7D',
    marginRight: '0.3em',
    width: '18px',
    height: '18px',
  },
  downloadFile: {
    textDecoration: 'none',
  },
  img: {
    maxWidth: '200px',
  },
  infoFile: {
    color: '#00796B',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    border: `1.5px solid ${theme.palette.text.secondary}`,
    borderRadius: theme.spacing(1),
  },
  divDataSeparator: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateMessageSeparator: {
    fontSize: '12px',
    fontWeight: '700',
    color: '#7D7D7D',
  },
  divModoAdmTag: {
    marginBottom: '15px',
  },
}));

let listMessages = [];
let updateScroll = true;

export default () => {
  const classes = useStyles();
  const setAlert = useAlert();
  const { idAssuntoMsgLote } = useParams();
  const isAdminOrOwner = getAdminOrOwnerRole();

  const messagesEndRef = useRef(null);
  const textRef = useRef();

  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [firstMessage, setFirstMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [messages, setMessages] = useState([]);

  const [openClose, setOpenClose] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);

  const [openDelete, setOpenDelete] = useState(null);
  const [openCancel, setOpenCancel] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);

  const [openDetails, setOpenDetails] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingReceived, setLoadingReceived] = useState(false);
  const [loadingVisualized, setLoadingVisualized] = useState(false);
  const [dataDetails, setDataDetails] = useState(null);
  const [pageReceived, setPageReceived] = useState(1);
  const [pageVisualized, setPageVisualized] = useState(1);
  const [receivedDetails, setReceivedDetails] = useState([]);
  const [visualizedDetails, setVisualizedDetails] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const [editMessage, setEditMessage] = useState(0);

  const [text, setText] = useState('');
  const [newText, setNewText] = useState('');
  const [openRecipientsModal, setOpenRecipientsModal] = useState(false);
  const [recipientNumber, setRecipientNumber] = useState(0);
  const [allowAnswer, setAllowAnswer] = useState(false);
  const [chatAtivo, setChatAtivo] = useState(true);
  const [openDetailsByMessage, setOpenDetailsByMessage] = useState(false);
  const [idLoteMsg, setIdLoteMsg] = useState(false);
  const [base64, setBase64] = useState('');
  const [file, setFile] = useState('');
  const [assunto, setAssunto] = useState('');
  const [adminMode, setAdminMode] = useState(
    isAdminOrOwner
      ? JSON.parse(localStorage.getItem('admMode')) ?? false
      : false
  );
  const [finishSendBatch, setFinishSendBatch] = useState(false);
  const application = useSelector((state) => state.profile?.application);
  const [apiVersion, setApiVersion] = useState('');

  useEffect(() => {
    if (application != null && apiVersion === '') {
      api
        .get(`apis/version/${application.id}`)
        .then((response) => {
          setApiVersion(response.data);
        })
        .catch(() => {
          setApiVersion('');
        });
    }
  }, [application]);

  useEffect(() => {
    setAdminMode(
      isAdminOrOwner
        ? JSON.parse(localStorage.getItem('admMode')) ?? false
        : false
    );
  }, [isAdminOrOwner]);

  useEffect(() => {
    if (idAssuntoMsgLote) {
      setText(getSketch(idAssuntoMsgLote));
    }
  }, [idAssuntoMsgLote]);

  const messagesScrollToBottom = () => {
    const el = messagesEndRef.current;

    if (el) el.scrollTop = el.scrollHeight - el.clientHeight;
  };

  const handleUpdateMessages = () =>
    setMessages([...handleGroupMessages(listMessages)]);

  const handleChangeText = (value) => {
    setSketch(idAssuntoMsgLote, value);
    setText(value);
  };

  const handleCloseMenu = () => setMenuOpenId(null);
  const handleCloseEdit = () => setEditMessage(null);

  const handleOpenMenu = (event, message) => {
    if (
      message.ProprietarioMensagem &&
      message.TipoUsuarioRemetente === 2 &&
      !message.Excluida
    ) {
      setMenuOpenId(message.IdStatusEnvioMsg);
      setAnchorEl(event.currentTarget);
      return;
    }

    if (
      message.IdMensagem == null ||
      (!message.ProprietarioMensagem && !adminMode) ||
      message.IdMensagem === -1 ||
      message.Excluida
    ) {
      return;
    }
    setMenuOpenId(message.IdMensagem);
    setAnchorEl(event.currentTarget);
  };

  const handleOpenEditMessage = (id, message) => {
    setNewText(message.Mensagem);
    setEditMessage(id);
    handleCloseMenu();
  };

  const handleSendEnterPress = (textEnter, e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage(textEnter, null, null);
      e.currentTarget.blur();
    }
  };

  const handleEditMessageEnterPress = (message, newText, e) => {
    if (newText?.trim() === '') return;

    if (e.key === 'Enter' && !e.shiftKey) {
      setNewText(text);
      requestEditMessage(message, newText);
    }
  };

  const requestEditMessage = (message, newText) => {
    if (message.Mensagem === newText) {
      setEditMessage(null);
      return;
    }

    const index = listMessages.findIndex(
      (x) => x.IdMensagem === message.IdMensagem
    );
    listMessages[index].Mensagem = newText;

    handleUpdateMessages();
    handleCloseEdit();
    handleEditMessage(message, newText);
  };

  const handleAlert = (message, type) => {
    setAlert({
      isOpen: true,
      type: type ?? 'error',
      message: message ?? 'Ocorreu um erro inesperado!',
    });
  };

  const handleErrorChat = (message) => {
    setIsLoading(false);
    setIsLoadingMore(false);

    setAlert({
      isOpen: true,
      type: 'error',
      message: message ?? 'Ocorreu um erro inesperado!',
    });
  };

  const confirmCloseChat = () => {
    setLoadingClose(true);

    const registerData = {
      idAssuntoMsgLote,
    };

    api
      .put('chat/closeChatBatch', registerData)
      .then((res) => {
        if (!res.data.Sucesso) {
          handleAlert(res.data.Mensagem, 'error');
          handleResetCloseChat();
        } else {
          setChatAtivo(false);
          handleResetCloseChat();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
        handleResetCloseChat();
      });
  };

  const handleResetCloseChat = () => {
    setLoadingClose(false);
    setOpenClose(false);
  };

  const handleDeleteMessage = (message) => {
    handleCloseMenu();
    setOpenDelete(message);
  };

  const handleCancelMessage = (message) => {
    handleCloseMenu();
    setOpenCancel(message);
  };

  const handleResetDelete = () => {
    setLoadingDelete(false);
    setOpenDelete(null);
  };

  const handleResetCancel = () => {
    setLoadingCancel(false);
    setOpenCancel(null);
  };

  const handleConfirmDeleteMessage = (message) => {
    setLoadingDelete(true);

    const params = {
      idBatch: message.IdLote,
      adminMode,
    };

    api
      .delete('chat/deleteMessageInBatch', {
        params,
      })
      .then((res) => {
        if (!res.data.Sucesso) {
          handleAlert(res.data.Mensagem, 'error');
          handleResetDelete();
        } else {
          const index = listMessages.findIndex(
            (x) => x.IdLote === message.IdLote
          );
          listMessages[index].Excluida = true;
          handleUpdateMessages();
          handleResetDelete();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
        handleResetDelete();
      });
  };
  const handleConfirmCancelMessage = (message) => {
    setLoadingCancel(true);

    const params = {
      idStatusEnvioMsg: message.IdStatusEnvioMsg,
    };

    api
      .put('chat/cancelSendingMessage', params)
      .then((res) => {
        if (res.status != 200) {
          handleAlert(res.data.Mensagem, 'error');
          handleResetCancel();
        } else {
          const index = listMessages.findIndex(
            (x) => x.IdLote === message.IdLote
          );
          listMessages[index].StatusEnvio = 'Interrompido';
          handleResetCancel();
          handleUpdateMessages();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
        handleResetCancel();
      });
  };

  const handleConfirmResendMessage = (message) => {
    handleCloseMenu();

    const params = {
      idStatusEnvioMsg: message.IdStatusEnvioMsg,
      idAssuntoMsgLote,
    };

    api
      .post('chat/resendMessage', params)
      .then((res) => {
        if (res.status !== 200) {
          handleAlert(res.data.Mensagem, 'error');
        } else {
          const index = listMessages.findIndex(
            (x) => x.IdLote === message.IdLote
          );
          listMessages[index].StatusEnvio = 'Reenviando';

          handleUpdateMessages();
        }
      })
      .catch(() => {
        handleAlert(null, 'error');
      });
  };

  const handleEditMessage = (message, newText) => {
    if (newText?.trim() === '') return;
    const registerData = {
      idBatchMessage: message.IdLote,
      message: newText,
    };

    api
      .put('chat/editMessageBatch', registerData)
      .then((res) => {
        if (!res.data.Sucesso) {
          handleAlert(res.data.Mensagem, 'error');
        } else {
          const index = listMessages.findIndex(
            (x) => x.IdMensagem === message.IdMensagem
          );
          listMessages[index].Editado = true;
          listMessages[index].DataEnvio = moment();
          handleUpdateMessages();
        }
      })
      .catch(() => {
        handleAlert(
          'Ocorreu um erro inesperado ao tentar editar mensagem.',
          'error'
        );
      });
  };

  const sendMessage = (message, currentBase64, currentfile) => {
    if ((message?.trim() === '' && currentBase64 == null) || !chatAtivo) return;

    const fileType = currentfile !== null ? currentfile.type : null;
    const newMessage = {
      Excluida: false,
      IdMensagem: null,
      Mensagem: message,
      DataEnvio: Date.now(),
      ProprietarioMensagem: true,
      Visualizada: false,
      Arquivo: currentfile,
      TipoArquivo: fileType,
      StatusEnvio: 'Enviando',
      IdStatusEnvioMsg: 0,
    };

    const index = listMessages.push(newMessage) - 1;
    updateScroll = true;
    handleUpdateMessages();

    setSketch(idAssuntoMsgLote, '');
    setText('');
    textRef.current.value = '';

    const registerData = {
      idAssuntoMsgLote,
      message: message?.trim() === '' ? ' ' : message,
      fileName: currentfile !== null ? currentfile.name : null,
      fileType,
      base64: currentBase64,
    };

    api
      .post('chat/sendMessageSectorInBatch', registerData)
      .then((res) => {
        if (!res.data.Sucesso) {
          listMessages.splice(index, 1);
          handleErrorChat();
        } else {
          listMessages[index].IdStatusEnvioMsg =
            res.data.Dados.IdStatusEnvioMsg;
          listMessages[index].IdLote = res.data.Dados.IdLote;
        }
        handleUpdateMessages();
      })
      .catch(() => {
        listMessages.splice(index, 1);
        handleUpdateMessages();
      });
  };

  const handleLoadMoreMessages = () => {
    updateScroll = false;
    setIsLoadingMore(true);
    handleLoadMessage(page + 1, { before: firstMessage });
  };

  useEffect(() => {
    listMessages = [];
    setIsLoading(true);
    handleLoadMessage();
    requestStatistic();
  }, []);

  useEffect(() => {
    if (updateScroll) messagesScrollToBottom();
  }, [messages]);

  const handleLoadMessage = (pag = 1, extraParams = null) => {
    const params = {
      idAssuntoMsgLote,
      page: pag,
      limit: 25,
      ...extraParams,
      adminMode,
    };

    api
      .get('chat/getChatSectorInBatch', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) handleErrorChat(response.data.Mensagem);
        else {
          listMessages.unshift(...response.data.Dados.Data);
          handleUpdateMessages();
          setAssunto(response.data.Dados.Data[0].Assunto);
          setLastPage(response.data.Dados.LastPage);
          setPage(pag);
          setIsLoading(false);
          setIsLoadingMore(false);

          if (pag === 1) {
            if (listMessages.length > 0) {
              setFirstMessage(
                listMessages[listMessages.length - 1].IdMensagem + 1
              );
            }

            messagesScrollToBottom();
          }
        }
      })
      .catch(() => handleErrorChat(null));
  };

  const requestStatistic = async () => {
    const params = {
      idAssuntoMsgLote,
    };

    api
      .get('chat/getStatisticBatch', {
        params,
      })
      .then((response) => {
        setRecipientNumber(response.data.QtdDestinatarios);
        setAllowAnswer(response.data.PermitirRespostas);
        setChatAtivo(response.data.ChatLoteAtivo);
        setFinishSendBatch(response.data.ChatsLoteEnviados);
      })
      .catch(() => handleErrorChat(null));
  };

  const handleAllowAnswer = (statusAllowAnswer) => {
    setAllowAnswer(statusAllowAnswer);
    handleChengeAllowAnswer(statusAllowAnswer);
  };

  const handleChengeAllowAnswer = async (statusAllowAnswer) => {
    const params = {
      permiteResposta: statusAllowAnswer,
      idAssuntoMsgLote,
    };

    api
      .put('chat/updateAllowAnswerBatch', params)
      .then(() => {})
      .catch(() => handleAlert(null));
  };

  const RenderInfoMessage = (message) => {
    if (message.Excluida) return null;

    return (
      <>
        {moment(message.DataEnvio).format('HH:mm')}
        {message.Editado && <span style={{ marginLeft: '4px' }}>Editada</span>}
        {(message.ProprietarioMensagem || message.TipoUsuarioRemetente === 2) &&
          RenderIconSend(message)}
      </>
    );
  };

  const RenderViewMessage = (message) => {
    return (
      <>
        {message.Excluida ? (
          <span className={classes.deleteMessageCustom}>
            <Block fontSize="small" style={{ marginRight: '5px' }} /> Mensagem
            excluída
          </span>
        ) : (
          <div style={{ whiteSpace: 'pre-wrap' }}>{message.Mensagem}</div>
        )}
      </>
    );
  };

  const RenderIconSend = (message) => {
    const styleTemp = { fontSize: 15, marginLeft: '4px' };
    const fontStyle = { fontSize: 12, margin: '0px 0px 0px 4px' };

    if (message.VisualizadaPorTodos)
      return <DoneAll style={{ ...styleTemp, color: '#00B940' }} />;

    if (message.StatusEnvio === 'Enviando')
      return <p style={fontStyle}>- Enviando...</p>;
    if (message.StatusEnvio === 'Erro')
      return <p style={fontStyle}>- Envio incompleto</p>;
    if (message.StatusEnvio === 'Interrompido')
      return <p style={fontStyle}>- Envio interrompido</p>;
    if (message.StatusEnvio === 'Reenviando')
      return <p style={fontStyle}>- Reenviando...</p>;

    return <Check style={styleTemp} />;
  };

  const handleSetDataDetails = (data, start) => {
    const receivedDetailsTemp = start ? [] : [...receivedDetails.data];
    const visualizedDetailsTemp = start ? [] : [...visualizedDetails.data];

    if (start)
      setDataDetails({ DataEnvio: data.DataEnvio, IdMessage: data.IdMensagem });

    if (data.UsuarioRecebido) {
      const receivedTemp = data.UsuarioRecebido.Data.filter(
        (x) => !x.Proprietario
      );
      setReceivedDetails({
        lastPage: data.UsuarioRecebido.LastPage,
        data: [...receivedDetailsTemp, ...receivedTemp],
      });
    }

    if (data.UsuarioVisualizado) {
      const visualizedTemp = data.UsuarioVisualizado.Data.filter(
        (x) => !x.Proprietario
      );
      setVisualizedDetails({
        lastPage: data.UsuarioVisualizado.LastPage,
        data: [...visualizedDetailsTemp, ...visualizedTemp],
      });
    }
  };

  const handleLoadMoreVisualized = () => {
    if (pageVisualized >= visualizedDetails.lastPage) return;

    setLoadingVisualized(true);
    const pageTemp = pageVisualized + 1;
    setPageReceived(pageTemp);

    handleGetInfoMessage(dataDetails.IdMessage, {
      page: pageTemp,
      getReceived: false,
    });
  };

  const handleLoadMoreReceived = () => {
    if (pageReceived >= receivedDetails.lastPage) return;

    setLoadingReceived(true);
    const pageTemp = pageReceived + 1;
    setPageReceived(pageTemp);

    handleGetInfoMessage(dataDetails.IdMessage, {
      page: pageTemp,
      getVisualized: false,
    });
  };

  const resetDetails = () => {
    setLoadingDetails(false);
    setLoadingReceived(false);
    setLoadingVisualized(false);
  };

  const handleGetInfoMessage = (idMessage, addParams, start = false) => {
    if (start) {
      setPageReceived(1);
      setPageVisualized(1);
      setLoadingDetails(true);
      setOpenDetails(true);
      handleCloseMenu();
    }

    const params = {
      idMessage,
      limit: 15,
      ...addParams,
    };

    api
      .get('chat/getMessageInfoSector', {
        params,
      })
      .then((response) => {
        if (!response.data.Sucesso) {
          resetDetails();
          handleAlert(response.data.Mensagem, 'error');
        } else {
          handleSetDataDetails(response.data.Dados, start);
          resetDetails();
        }
      })
      .catch(() => {
        resetDetails();
        handleAlert(null, null);
      });
  };

  const handleScroll = (e) => {
    const diff = e.target.scrollHeight - e.target.clientHeight;
    const onBottom = diff <= e.target.scrollTop + 15;

    if (onBottom) updateScroll = true;
    else updateScroll = false;
  };

  const handleForceCloseChat = () => {
    if (chatAtivo) setOpenClose(true);
    else
      setAlert({
        isOpen: true,
        type: 'warning',
        message: 'Esta conversa já foi encerrada.',
      });
  };

  const handleOpenSendDetails = (id, message) => {
    setIdLoteMsg(message.IdLote);
    setOpenDetailsByMessage(true);
  };

  const sendFile = () => {
    sendMessage(text, base64, file);
  };

  const downloadFile = (e) => {
    e.stopPropagation();
  };

  const handleGroupMessages = (listMessages) => {
    const group = [];
    let currentData = null;
    listMessages.forEach((m) => {
      const newDate = moment(m.DataEnvio).format('DD/MM/YYYY');
      if (currentData != newDate) {
        currentData = newDate;
        group.push({
          data: currentData,
          mensagens: [m],
        });
      } else {
        group.find((e) => e.data == currentData).mensagens.push(m);
      }
    });
    return group;
  };

  const handleUpdateStatusMessages = () => {
    const params = {
      idAssuntoMsgLote,
    };

    api
      .get('chat/getUpdateStatusMessages', {
        params,
      })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.dados;
          data.forEach((msg) => {
            const index = listMessages.findIndex(
              (x) => x.IdStatusEnvioMsg === msg.idStatusEnvioMsg
            );
            if (index !== -1) listMessages[index].StatusEnvio = msg.statusEnvio;
          });
          handleUpdateMessages();
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => handleUpdateStatusMessages(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <DefaultPage title={`Mensagens dos setores | ${assunto}`}>
      <div className={classes.containerCustom}>
        <div style={{ marginTop: '2em' }}>
          <div hidden={!adminMode} className={classes.divModoAdmTag}>
            <Alert severity="warning" style={{ fontSize: '12px' }}>
              Modo Administrador - Permite apenas a edição de mensagens já
              enviadas por qualquer setor. Não é possível enviar novas mensagens
              ou realizar outras ações.
            </Alert>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={9} lg={8} xl={8}>
              <Grid direction="row" alignItems="center" container spacing={3}>
                <Grid item xs={7}>
                  <ListItem style={{ paddingLeft: '0' }}>
                    <div className={classes.recipientsNumber}>
                      <span className={classes.formatNumberRecip}>
                        {recipientNumber}
                      </span>
                    </div>
                    <div
                      className={classes.allRecipientsText}
                      onClick={() => setOpenRecipientsModal(true)}
                    >
                      <span>Todos os destinatários</span>
                    </div>
                  </ListItem>
                </Grid>
                <Grid style={{ textAlign: 'right' }} item xs={5}>
                  <RoundedButton
                    className={classes.customButtonDefault}
                    style={
                      adminMode
                        ? { backgroundColor: '#C3C3C3' }
                        : { backgroundColor: '#B80101' }
                    }
                    color="primary"
                    variant="contained"
                    onClick={handleForceCloseChat}
                    disabled={adminMode}
                  >
                    Encerrar conversa
                  </RoundedButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ paddingLeft: '12px' }}>
            <div className={classes.viewOnly}>
              <ErrorOutline className={classes.errorIcon} />
              <span>
                As mensagens enviadas nesse chat serão entregues a todos os
                destinatários.
              </span>
            </div>
          </Grid>
          {isLoading ? (
            <Grid style={{ marginTop: '2em' }} container spacing={3}>
              <Grid item xs={12} md={9} lg={8}>
                <Skeleton variant="rect" height={500} />
              </Grid>
              <Grid item xs={12} md={3} lg={4}>
                <Skeleton variant="rect" height={200} />
              </Grid>
            </Grid>
          ) : (
            <Grid style={{ marginTop: '2em' }} container spacing={3}>
              <Grid item xs={12} sm={12} md={9} lg={8}>
                <Grid style={{ marginBottom: '1em' }} container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">Nova Mensagem</Typography>
                  </Grid>
                </Grid>
                <Grid spacing={3} container className={classes.chatSection}>
                  <Grid item xs={12}>
                    <Card>
                      <List
                        ref={messagesEndRef}
                        onScroll={(e) => handleScroll(e)}
                        className={classes.messageArea}
                      >
                        {page < lastPage && (
                          <div
                            style={{
                              textAlign: 'center',
                              marginBottom: '15px',
                            }}
                          >
                            {isLoadingMore ? (
                              <CircularProgress size={30} color="primary" />
                            ) : (
                              <Button
                                color="primary"
                                onClick={handleLoadMoreMessages}
                              >
                                Carregar mais
                              </Button>
                            )}
                          </div>
                        )}
                        {messages?.map((messageData, idx) => (
                          <>
                            <div key={idx} className={classes.divDataSeparator}>
                              <span className={classes.dateMessageSeparator}>
                                {messageData.data}
                              </span>
                            </div>
                            {messageData.mensagens?.map((message, index) => (
                              <ListItem key={index}>
                                <Grid container>
                                  <Grid
                                    align={
                                      message.ProprietarioMensagem ||
                                      message.TipoUsuarioRemetente === 2
                                        ? 'right'
                                        : 'left'
                                    }
                                    item
                                    xs={12}
                                  >
                                    {editMessage === message.IdMensagem ? (
                                      <Paper style={{ maxWidth: '80%' }}>
                                        <RoundedInput
                                          style={{ height: '100%' }}
                                          defaultValue={message.Mensagem}
                                          multiline
                                          size="small"
                                          variant="outlined"
                                          fullWidth
                                          onBlur={(e) =>
                                            setNewText(e.target.value)
                                          }
                                          onKeyPress={(e) =>
                                            handleEditMessageEnterPress(
                                              message,
                                              e.target.value,
                                              e
                                            )
                                          }
                                        />
                                        <IconButton onClick={handleCloseEdit}>
                                          <Close />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            requestEditMessage(message, newText)
                                          }
                                        >
                                          <Check />
                                        </IconButton>
                                      </Paper>
                                    ) : (
                                      <>
                                        <Paper
                                          className={
                                            message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente === 2
                                              ? classes.messageProp
                                              : classes.messageRem
                                          }
                                          aria-controls={`simple-menu${index}`}
                                          aria-haspopup="true"
                                          onClick={(e) =>
                                            handleOpenMenu(e, message)
                                          }
                                        >
                                          {message.TipoArquivo !== null &&
                                          message.TipoArquivo.includes(
                                            'image'
                                          ) ? (
                                            <div>
                                              {!message.Excluida ? (
                                                <div>
                                                  <a
                                                    download={
                                                      message.NomeArquivo
                                                    }
                                                    className={
                                                      classes.downloadFile
                                                    }
                                                    onClick={(e) =>
                                                      downloadFile(e)
                                                    }
                                                    href={`data:${
                                                      message.TipoArquivo
                                                    };base64,${
                                                      message.GuidArquivo ??
                                                      message.Mensagem
                                                    }`}
                                                  >
                                                    <img
                                                      src={`data:${message.TipoArquivo};base64,${message.GuidArquivo}`}
                                                      className={classes.img}
                                                      alt=""
                                                      onClick={(e) =>
                                                        downloadFile(e)
                                                      }
                                                    />
                                                  </a>
                                                  <ListItemText
                                                    primary={message.Mensagem}
                                                    secondary={RenderInfoMessage(
                                                      message
                                                    )}
                                                  />
                                                </div>
                                              ) : (
                                                <ListItemText
                                                  primary={RenderViewMessage(
                                                    message
                                                  )}
                                                  secondary={RenderInfoMessage(
                                                    message
                                                  )}
                                                />
                                              )}
                                            </div>
                                          ) : message.TipoArquivo !== null &&
                                            message.TipoArquivo.includes(
                                              'application'
                                            ) ? (
                                            <div>
                                              {!message.Excluida ? (
                                                <div>
                                                  <a
                                                    download={
                                                      message.NomeArquivo
                                                    }
                                                    className={
                                                      classes.downloadFile
                                                    }
                                                    onClick={(e) =>
                                                      downloadFile(e)
                                                    }
                                                    href={`data:${
                                                      message.TipoArquivo
                                                    };base64,${
                                                      message.GuidArquivo ??
                                                      message.Mensagem
                                                    }`}
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.infoFile
                                                      }
                                                    >
                                                      <DescriptionOutlinedIcon />
                                                      {message.NomeArquivo}
                                                    </Typography>
                                                  </a>
                                                  <ListItemText
                                                    primary={message.Mensagem}
                                                    secondary={RenderInfoMessage(
                                                      message
                                                    )}
                                                  />
                                                </div>
                                              ) : (
                                                <ListItemText
                                                  primary={RenderViewMessage(
                                                    message
                                                  )}
                                                  secondary={RenderInfoMessage(
                                                    message
                                                  )}
                                                />
                                              )}
                                            </div>
                                          ) : (
                                            <ListItemText
                                              primary={RenderViewMessage(
                                                message
                                              )}
                                              secondary={RenderInfoMessage(
                                                message
                                              )}
                                            />
                                          )}
                                        </Paper>
                                        <Menu
                                          id={`simple-menu${index}`}
                                          anchorEl={anchorEl}
                                          open={
                                            (message.IdMensagem != null &&
                                              message.IdMensagem ===
                                                menuOpenId &&
                                              editMessage === 0) ||
                                            (message.IdStatusEnvioMsg != null &&
                                              message.IdStatusEnvioMsg ===
                                                menuOpenId)
                                          }
                                          onClose={handleCloseMenu}
                                        >
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={
                                                message.StatusEnvio ===
                                                  'Enviando' ||
                                                message.StatusEnvio ===
                                                  'Reenviando'
                                              }
                                              onClick={() =>
                                                handleOpenSendDetails(
                                                  index,
                                                  message
                                                )
                                              }
                                            >
                                              Detalhamento do envio
                                            </MenuItem>
                                          )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={
                                                message.StatusEnvio ===
                                                  'Enviando' ||
                                                message.StatusEnvio ===
                                                  'Reenviando'
                                              }
                                              onClick={() =>
                                                handleOpenEditMessage(
                                                  message.IdMensagem,
                                                  message
                                                )
                                              }
                                            >
                                              Editar
                                            </MenuItem>
                                          )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={
                                                message.StatusEnvio ===
                                                  'Enviando' ||
                                                message.StatusEnvio ===
                                                  'Concluido' ||
                                                message.StatusEnvio ===
                                                  'Reenviando' ||
                                                apiVersion <= '4.3.026' ||
                                                message.StatusEnvio === null
                                              }
                                              onClick={() =>
                                                handleConfirmResendMessage(
                                                  message
                                                )
                                              }
                                            >
                                              Retomar envio
                                            </MenuItem>
                                          )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={
                                                idx === 0 && index === 0
                                              }
                                              onClick={() =>
                                                handleDeleteMessage(message)
                                              }
                                              style={{ color: '#B80101' }}
                                            >
                                              Excluir
                                            </MenuItem>
                                          )}
                                          {(message.ProprietarioMensagem ||
                                            message.TipoUsuarioRemetente ===
                                              2) && (
                                            <MenuItem
                                              disabled={
                                                message.StatusEnvio ===
                                                  'Concluido' ||
                                                message.StatusEnvio ===
                                                  'Interrompido' ||
                                                message.StatusEnvio ===
                                                  'Erro' ||
                                                apiVersion <= '4.3.026' ||
                                                message.StatusEnvio === null
                                              }
                                              onClick={() =>
                                                handleCancelMessage(message)
                                              }
                                              style={{ color: '#B80101' }}
                                            >
                                              Parar envio
                                            </MenuItem>
                                          )}
                                        </Menu>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                              </ListItem>
                            ))}
                          </>
                        ))}
                      </List>
                      <Divider />
                      <Grid container style={{ padding: '5px 15px' }}>
                        <Grid item xs={10}>
                          <textarea
                            className={classes.input}
                            disabled={
                              !chatAtivo || !finishSendBatch || adminMode
                            }
                            size="small"
                            variant="outlined"
                            ref={textRef}
                            fullWidth
                            onChange={(e) => handleChangeText(e.target.value)}
                            onKeyPress={(e) =>
                              handleSendEnterPress(e.target.value, e)
                            }
                            inputProps={{ maxLength: '5000' }}
                            placeholder="Mensagem de até 5000 caracteres"
                            defaultValue={text}
                          />
                        </Grid>
                        <Grid item xs={1} align="right">
                          <EnviarArquivo
                            disabledButton={!finishSendBatch || adminMode}
                            setFile={setFile}
                            setBase64={setBase64}
                            onConfirmFile={sendFile}
                            isSend
                          />
                        </Grid>
                        <Grid item xs={1} align="right">
                          <IconButton
                            disabled={!finishSendBatch || adminMode}
                            onClick={() => sendMessage(text, null, null)}
                            color="primary"
                          >
                            <Send fontSize="large" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                <Typography
                  style={{ marginBottom: '1.5em' }}
                  color="primary"
                  variant="subtitle2"
                >
                  Configurações
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={!chatAtivo || adminMode}
                      checked={allowAnswer}
                      onChange={() => handleAllowAnswer(!allowAnswer)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.85em' }}>
                      Permitir respostas
                    </span>
                  }
                />
                <div className={classes.viewOnly}>
                  <ErrorOutline className={classes.errorIcon} />
                  <span>
                    O recebimento das mensagens em lote por todos os
                    destinatários pode levar alguns minutos.
                  </span>
                </div>
              </Grid>
            </Grid>
          )}
          <ConfirmCloseModal
            handleClose={() => setOpenClose(false)}
            open={openClose}
            loading={loadingClose}
            confirm={confirmCloseChat}
            title="Encerrar conversa"
            message="Tem certeza? Essa ação não pode ser revertida"
          />
          <ConfirmCloseModal
            handleClose={() => setOpenDelete(null)}
            open={openDelete != null}
            loading={loadingDelete}
            confirm={() => handleConfirmDeleteMessage(openDelete)}
            title="Excluir mensagem"
            message="Tem certeza? Essa ação não pode ser revertida"
          />
          <ConfirmCloseModal
            handleClose={() => setOpenCancel(null)}
            open={openCancel != null}
            loading={loadingCancel}
            confirm={() => handleConfirmCancelMessage(openCancel)}
            title="Parar envio da mensagem"
            message="Tem certeza? Essa ação não pode ser revertida"
          />
          <DetailsModal
            handleClose={() => setOpenDetails(false)}
            open={openDetails}
            loading={loadingDetails}
            data={dataDetails}
            received={receivedDetails}
            visualized={visualizedDetails}
            loadingReceived={loadingReceived}
            loadingVisualized={loadingVisualized}
            loadMoreReceived={
              loadingDetails || pageReceived >= receivedDetails?.lastPage
                ? null
                : handleLoadMoreReceived
            }
            loadMoreVisualized={
              loadingDetails || pageVisualized >= visualizedDetails?.lastPage
                ? null
                : handleLoadMoreVisualized
            }
          />
          <RecipientsBatchModal
            open={openRecipientsModal}
            handleClose={() => setOpenRecipientsModal(false)}
            idAssuntoMsgLote={idAssuntoMsgLote}
          />
          <DetailsByMessage
            open={openDetailsByMessage}
            handleClose={() => setOpenDetailsByMessage(false)}
            idLote={idLoteMsg}
            idAssuntoMsgLote={idAssuntoMsgLote}
          />
        </div>
      </div>
    </DefaultPage>
  );
};
