/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ConfigurationMessagesForm from './ConfigurationMessagesForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { configurationMessageId } = useParams();
  const [configurationMessageInfo, setConfigurationMessageInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get(`configuration-message/info/${configurationMessageId}`)
      .then(async (response) => {
        setConfigurationMessageInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, []);

  return (
    <DefaultPage title="Edição de configurações">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <ConfigurationMessagesForm
              isEdit
              configurationMessageInfo={configurationMessageInfo}
              configurationMessageId={configurationMessageId}
            />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
