import React from 'react';
import { List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { MenuSection } from '..';

export default () => {
  const sections = useSelector((state) => state.profile?.featureSections);

  return (
    <List>
      {sections?.map((s) => (
        <MenuSection key={s.title} title={s.title} items={s.items} />
      ))}
    </List>
  );
};
