import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import { CachedRounded } from '@material-ui/icons';
import { ApplicationsDialog } from '..';

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    margin: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    fontSize: '14px',
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginLeft: '3px',
  },
  icon: {
    color: theme.palette.primary.dark,
  },
}));

export default () => {
  const classes = useStyles();

  const application = useSelector((state) => state.profile?.application);
  const applications = useSelector((state) => state.profile?.applications);

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const hasMoreThanOneApp = applications.length > 1;

  const handleClick = () => {
    if (hasMoreThanOneApp) setDialogIsOpen(true);
  };

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  return (
    <div hidden={!application} className={classes.root}>
      <ListItem button onClick={handleClick}>
        <ListItemAvatar>
          <Avatar className={classes.avatar}>{application?.initials}</Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography className={classes.text}>{application?.name}</Typography>
        </ListItemText>
        {hasMoreThanOneApp ? <CachedRounded className={classes.icon} /> : null}
      </ListItem>
      <ApplicationsDialog onClose={handleClose} open={dialogIsOpen} />
    </div>
  );
};
