import React from 'react';
import SnackBarAlert from './SnackBarAlert';
import Profile from './Profile';
import Notification from './Notification';
import Configuration from './Configuration';

export default () => (
  <>
    <Notification />
    <Configuration />
    <SnackBarAlert />
    <Profile />
  </>
);
