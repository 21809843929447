import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Chip } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Container, RoundedInput, LoadingButton } from '../../components';
import useAlert from '../../hooks/useAlert';

import api from '../../services/api';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexFlow: 'column',
  },
  formInputs: {
    display: 'flex',
    flexFlow: 'column',
    margin: '0em 2em',
  },
  title: {
    display: 'flex',
    fontWeight: 'bold',
    paddingTop: '1em',
  },
  chip: {
    marginLeft: '1em',
  },
  input: {
    margin: '0.8em 0em',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 1em',
  },
  button: {
    marginLeft: '1em',
  },
}));

export default () => {
  const classes = useStyles();
  const { applicationId } = useParams();
  const setAlert = useAlert();
  const role = useSelector((state) => state.profile?.role);

  const [isRegister, setIsRegister] = useState(false);
  const [apiId, setApiId] = useState(null);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiVersion, setApiVersion] = useState('');
  const [connectionError, setConnectionError] = useState(false);

  const roleIsAdminOrConsulting = role === 'admin' || role === 'consulting';

  const setApiInfo = useCallback(
    (data) => {
      setName(data.name);
      setUrl(data.api_url);
      setApiKey(data.api_key);
      setApiSecret(data.api_secret);
      setApiId(data.id);
      api
        .get(`apis/version/${applicationId}`)
        .then((response) => {
          setApiVersion(response.data);
          setConnectionError(false);
        })
        .catch(() => {
          setApiVersion('');
          setConnectionError(true);
        });
    },
    [applicationId]
  );

  const handleEditError = (err) => {
    setIsLoading(false);

    const dataIsArray = Array.isArray(err.response?.data);
    const errorMessage = dataIsArray
      ? err?.response?.data?.find((e) => e.message)?.message
      : null;

    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível editar a aplicação!',
    });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const editData = {
      name,
      url,
      apiKey,
      apiSecret,
    };

    api
      .put(`apis/${apiId}`, editData)
      .then((res) => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Aplicação atualizada com sucesso!',
        });
        setApiInfo(res.data);
      })
      .catch((err) => {
        handleEditError(err);
      });
  };

  const handleRegisterError = (err) => {
    setIsLoading(false);

    const dataIsArray = Array.isArray(err.response?.data);
    const errorMessage = dataIsArray
      ? err?.response?.data?.find((e) => e.message)?.message
      : null;

    setAlert({
      isOpen: true,
      type: 'error',
      message: errorMessage ?? 'Não foi possível cadastrar a API!',
    });
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const registerData = {
      name,
      url,
      appId: applicationId,
    };

    api
      .post('apis', registerData)
      .then((res) => {
        setIsLoading(false);
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Api cadastrada com sucesso!',
        });
        setApiInfo(res.data);
        setIsRegister(false);
      })
      .catch((err) => {
        handleRegisterError(err);
      });
  };

  useEffect(() => {
    api.get(`apis/current-app/${applicationId}`).then((res) => {
      if (res.data) {
        setApiInfo(res.data);
      } else {
        setIsRegister(true);
      }
    });
  }, [applicationId, setApiInfo]);

  return (
    <Container>
      <form
        className={classes.form}
        onSubmit={isRegister ? handleSubmitRegister : handleSubmitEdit}
      >
        <div className={classes.formInputs}>
          <Typography className={classes.title} variant="h5" color="primary">
            Configuração de API
            {apiVersion && (
              <Chip
                className={classes.chip}
                label={`Versão: ${apiVersion}`}
                color="primary"
              />
            )}
            {connectionError && (
              <Chip
                className={classes.chip}
                label="Problema de conexão!"
                color="secondary"
              />
            )}
          </Typography>
          <RoundedInput
            className={classes.input}
            label="Nome"
            size="small"
            value={name}
            inputProps={{ minLength: '4' }}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <RoundedInput
            className={classes.input}
            label="Url"
            size="small"
            value={url}
            type="url"
            onChange={(e) => setUrl(e.target.value)}
            required
          />
          {!isRegister ? (
            <>
              <RoundedInput
                className={classes.input}
                label="API Key"
                size="small"
                value={apiKey}
                inputProps={{ minLength: '32', maxLength: '32' }}
                onChange={(e) => setApiKey(e.target.value)}
                disabled={!roleIsAdminOrConsulting}
              />
              <RoundedInput
                className={classes.input}
                label="API Secret"
                size="small"
                value={apiSecret}
                inputProps={{ minLength: '64', maxLength: '64' }}
                onChange={(e) => setApiSecret(e.target.value)}
                disabled={!roleIsAdminOrConsulting}
              />
            </>
          ) : null}
        </div>
        <div className={classes.buttons}>
          <LoadingButton
            className={classes.button}
            type="submit"
            variant="contained"
            color="primary"
            isLoading={isLoading}
          >
            {isRegister ? 'Cadastrar' : 'Salvar'}
          </LoadingButton>
        </div>
      </form>
    </Container>
  );
};
