import React from 'react';
import {
  Dialog,
  DialogActions,
  IconButton,
  DialogContent,
  Avatar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Close } from '@material-ui/icons';
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import {
  RoundedButton,
  RoundedInput,
  MultipleValueInput,
} from '../../components';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  customDialogContent: {
    marginLeft: '2em',
    marginRight: '2em',
    marginBottom: '1em',
  },
  buttons: {
    marginTop: '1.5em',
  },
  customButton: {
    borderRadius: '5px !important',
  },
  loadingButton: {
    marginLeft: '1em',
    borderRadius: '5px !important',
  },
  input: {
    borderRadius: '5px !important',
    '& input + fieldset': {
      borderRadius: '5px !important',
    },
    '& input.Mui-disabled': {
      color: '#585858',
    },
    '& textArea.Mui-disabled': {
      color: '#585858',
    },
  },
  formBody: {
    display: 'flex',
    flexFlow: 'column',
    flexGrow: '1',
    margin: '1em 0em',
    '& p': {
      fontWeight: 500,
      marginBottom: '1em',
    },
  },
  notFound: {
    color: '#848484',
    fontWeight: '300 !important',
  },
  customDialogActions: {
    padding: '0',
  },
  title: {
    marginTop: '1em',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default ({
  handleClose,
  open,
  dialogSector,
  usersPreview,
  targetsPreview,
  limitPreview,
  isLoading,
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogActions>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.customDialogContent}>
        <div className={classes.formBody}>
          <Typography color="primary">Nome do Setor</Typography>
          <RoundedInput
            className={classes.input}
            disabled
            size="small"
            value={dialogSector ? dialogSector.Nome : ''}
            variant="outlined"
          />
          <Typography className={classes.title} color="primary">
            Mensagem Padrão
          </Typography>
          <RoundedInput
            className={classes.input}
            disabled
            multiline
            rows={4}
            size="small"
            value={
              dialogSector && dialogSector.MensagemAutomatica
                ? dialogSector.MensagemAutomatica
                : ' '
            }
            variant="outlined"
          />
          <Typography className={classes.title} color="primary">
            Público-alvo
          </Typography>
          <div className={classes.divTargetAudience}>
            {targetsPreview?.length === 0 ? (
              <Typography className={classes.notFound} variant="body2">
                Nenhum público alvo está cadastrado a este setor.
              </Typography>
            ) : (
              <MultipleValueInput
                customClasses={classes.fieldTargetAudience}
                variant="outlined"
                disabled
                labelProperty="descricao"
                value={targetsPreview}
                options={targetsPreview}
              />
            )}
          </div>

          <Typography className={classes.title} color="primary">
            Usuários ativos
          </Typography>
          {isLoading ? (
            <Skeleton />
          ) : usersPreview?.length === 0 ? (
            <Typography className={classes.notFound} variant="body2">
              Nenhum usuário está cadastrado a este setor.
            </Typography>
          ) : (
            <AvatarGroup max={limitPreview} spacing={-4}>
              {usersPreview?.map((u, index) => (
                <Tooltip key={index} title={u || ''}>
                  <Avatar className={classes.avatar}>
                    {u ? u[0].toUpperCase() : null}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )}
        </div>
        <DialogActions className={classes.customDialogActions}>
          <RoundedButton
            variant="contained"
            color="primary"
            className={classes.customButton}
            onClick={() =>
              history.push(`/sector/editSector/${dialogSector.Id}`)
            }
          >
            Editar
          </RoundedButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
