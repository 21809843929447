import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, FileCopy } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import DefaultPage from '../../templates/DefaultPage';
import {
  SearchInput,
  RoundedButton,
  Container,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import ModalMural from '../../components/ModalMural';

const useStyles = makeStyles(() => ({
  container: {
    padding: 'auto 20px',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10%',
    marginRight: '20%',
    fontFamily: 'Segoe UI',
    width: '70%',
  },
  tableCell: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  title: {
    flexGrow: '1',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkAcesso: {
    padding: '8px',
    alignSelf: 'center',
    fontSize: '22px',
  },
}));

export default () => {
  const classes = useStyles();
  const debounce = useDebounce();
  const history = useHistory();
  const setAlert = useAlert();

  const perPage = 10;

  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [news, setNews] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogCloneOpen, setIsDialogCloneOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [idNewsToDelete, setIdNewsToDelete] = useState(0);
  const [idNoticiaCloneNews, setIdNoticiaCloneNews] = useState(0);
  const [isDialogCloneLoading, setIsDialogCloneLoading] = useState(false);

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setNews([]);
    setPage(1);
    setTotal(0);
  };

  const handleOpenRegisterPage = () => history.push(`/news/register`);

  const handleOpenEditPage = (idNoticia) => history.push(`/news/${idNoticia}`);

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  useEffect(
    () => {
      getConfiguration();
    },
    [page, queryText],
    []
  );

  const loadPage = () => {
    const params = {
      page,
      limit: perPage,
    };
    if (queryText) {
      params.query = queryText;
    }

    setIsLoading(true);
    api
      .get('news/getNews', { params })
      .then((response) => {
        setTotal(response.data.total);
        setNews(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
    // eslint-disable-next-line
  };

  const getConfiguration = () => {
    api.get('configuration/getConfigurations').then((res) => {
      if (!res.data.usaMural) {
        loadPage();
      } else {
        window.open(
          `https://${res.data.subDominio}.painel.gvdasa.com.br/servico-mural/mural`,
          '_blank'
        );
        window.location.href = '/';
      }
    });
  };

  const handleDeleteError = () => {
    setIsDialogLoading(false);
    setIsDialogOpen(false);
    setIdNewsToDelete(0);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível excluir a notícia!',
    });
  };

  const deleteNews = (idNoticia) => {
    setIdNewsToDelete(idNoticia);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setIdNewsToDelete(0);
  };

  const handleDelete = () => {
    setIsDialogLoading(true);

    const params = {
      idNewsToDelete,
    };

    api
      .delete(`news/deleteNews`, { params })
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Notícia excluída com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogOpen(false);
        setIdNewsToDelete(0);
        window.location.reload();
      })
      .catch((err) => {
        handleDeleteError(err);
      });
  };

  const handleCloneError = () => {
    setIsDialogCloneLoading(false);
    setIsDialogCloneOpen(false);
    setIdNoticiaCloneNews(0);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível clonar a notícia!',
    });
  };

  const cloneNews = (idNoticia) => {
    // TODO: Alterar forma de clonagem para apenas abrir uma página com valores preenchidos
    setIdNoticiaCloneNews(idNoticia);
    setIsDialogCloneOpen(true);
  };

  const closeCloneDialog = () => {
    setIsDialogCloneOpen(false);
    setIdNoticiaCloneNews(0);
  };

  const handleCloneNews = () => {
    setIsDialogCloneLoading(true);

    const params = {
      idNoticiaCloneNews,
    };

    api
      .post(`news/cloneNews`, params)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Notícia clonada com sucesso!',
        });
        setIsDialogLoading(false);
        setIsDialogCloneOpen(false);
        setIdNoticiaCloneNews(0);
        window.location.reload();
      })
      .catch((err) => {
        handleCloneError(err);
      });
  };

  return (
    <DefaultPage title="Notícias">
      <Container>
        <ModalMural />

        <>
          <ConfirmationDialog
            title="Excluir esta notícia?"
            open={isDialogOpen}
            isConfirmLoading={isDialogLoading}
            handleConfirm={handleDelete}
            handleClose={() => closeDialog()}
          />
          <ConfirmationDialog
            title="Clonar esta notícia?"
            open={isDialogCloneOpen}
            isConfirmLoading={isDialogCloneLoading}
            handleConfirm={handleCloneNews}
            handleClose={() => closeCloneDialog()}
          />
          <Toolbar>
            <SearchInput onChange={debounce(handleChangeQueryText)} />
            <RoundedButton
              className={classes.registerButton}
              color="primary"
              variant="contained"
              startIcon={<Add />}
              onClick={handleOpenRegisterPage}
            >
              Cadastrar
            </RoundedButton>
          </Toolbar>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Titulo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading
                  ? new Array(10)
                      .fill()
                      .map((e, i) => i)
                      .map((e) => (
                        <TableRowFocusable key={e} onClick={() => {}} hover>
                          <TableCell>
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRowFocusable>
                      ))
                  : news?.map((ne) => (
                      <TableRowFocusable
                        key={ne.idNoticia}
                        onClick={() => {}}
                        hover
                      >
                        <TableCell className={classes.tableCell}>
                          <div className={classes.title}>{ne.titulo}</div>
                          <div className={classes.icons}>
                            <Edit
                              color="primary"
                              onClick={() => handleOpenEditPage(ne.idNoticia)}
                            />
                            <FileCopy
                              color="primary"
                              onClick={() => cloneNews(ne.idNoticia)}
                            />
                            <DeleteForever
                              color="primary"
                              onClick={() => deleteNews(ne.idNoticia)}
                            />
                          </div>
                        </TableCell>
                      </TableRowFocusable>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[perPage]}
            component="div"
            count={total}
            rowsPerPage={perPage}
            page={page - 1}
            onChangePage={handleChangePage}
          />
        </>
      </Container>
    </DefaultPage>
  );
};
