import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { SectionOfFeatureCards } from '../../components';
import DefaultPage from '../../templates/DefaultPage';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    padding: '1.5em',
  },
}));

export default () => {
  const classes = useStyles();
  const sections = useSelector((state) => state.profile?.featureSections);

  return (
    <DefaultPage title="Home">
      <div className={classes.root}>
        {sections?.map((section) => (
          <SectionOfFeatureCards key={section.title} section={section} />
        ))}
      </div>
    </DefaultPage>
  );
};
