import React, { useState, useEffect, useCallback } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Toolbar,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, DeleteForever, Edit, FileCopy, Search } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import DefaultPage from '../../templates/DefaultPage';
import {
  SearchInput,
  RoundedButton,
  Container,
  TableRowFocusable,
  ConfirmationDialog,
} from '../../components';
import useDebounce from '../../hooks/useDebounce';
import useAlert from '../../hooks/useAlert';
import api from '../../services/api';
import MenuResume from './MenuResume';

const useStyles = makeStyles(() => ({
  tableCellName: {
    alignItems: 'center',
    wordBreak: 'break-word',
    minWidth: '60px',
  },
  tableCell: {
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  tableCellButtons: {
    width: '10%',
  },
  registerButton: {
    padding: 'auto 20px',
    marginLeft: '1em',
  },
  title: {
    flexGrow: '1',
  },
  icons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default () => {
  const classes = useStyles();
  const debounce = useDebounce();
  const setAlert = useAlert();
  const history = useHistory();
  const perPage = 20;

  const [isLoading, setIsLoading] = useState(false);
  const [queryText, setQueryText] = useState('');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [menu, setMenu] = useState([]);
  const [dataInicial, setDataInicial] = useState(moment().format('YYYY-MM-DD'));
  const [dataFinal, setDataFinal] = useState(moment().format('YYYY-MM-DD'));
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [idMenuToDelete, setIdMenuToDelete] = useState(0);
  const [openView, setOpenView] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [dialogViewMenu, setDialogViewMenu] = useState(null);

  const handleQueryError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Ocorreu um problema inesperado!',
    });
    setMenu([]);
    setPage(1);
    setTotal(0);
  };

  const handleOpenEditPage = (menuId) => history.push(`/menu/${menuId}`);

  const handleOpenClonePage = (menuId) => history.push(`/menu/clone/${menuId}`);

  const search = (pageP, queryTextP) => {
    const params = {
      page: pageP,
      limit: perPage,
      appRole: ['coordination', 'admin'],
    };

    if (queryText) {
      params.query = queryTextP.concat(';', dataInicial, ';', dataFinal);
    } else {
      params.query = queryTextP.concat(';', dataInicial, ';', dataFinal);
    }

    setIsLoading(true);
    api
      .get('menu', {
        params,
      })
      .then((response) => {
        setTotal(response.data.total);
        setMenu(response.data.data);
        setIsLoading(false);
      })
      .catch((err) => handleQueryError(err));
  };

  const doSearch = useCallback(() => {
    search(page, queryText, dataInicial, dataFinal);
    // eslint-disable-next-line
  }, [page, queryText, dataInicial, dataFinal]);

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setIdMenuToDelete(0);
    setIsDeleteLoading(false);
  };

  const handleOpenDeleteDialog = (idMenu) => {
    setIdMenuToDelete(idMenu);
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    setIsDeleteLoading(true);

    api
      .delete(`menu/${idMenuToDelete}`)
      .then(() => {
        setAlert({
          isOpen: true,
          type: 'success',
          message: 'Cardápio excluído com sucesso!',
        });
        handleCloseDeleteDialog();
        doSearch();
      })
      .catch(() => {
        handleCloseDeleteDialog();
        setAlert({
          isOpen: true,
          type: 'error',
          message: 'Não foi possível excluir o cardápio!',
        });
      });
  };

  const handleOpenRegisterPage = () => history.push(`/menu/register`);

  const handleChangeQueryText = (inputValue) => {
    setQueryText(inputValue.trim());
    setPage(1);
  };

  const handleChangePage = (event, nextPage) => {
    setPage(nextPage + 1);
  };

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  return (
    <DefaultPage title="Cardápio">
      <Container>
        <ConfirmationDialog
          title="Excluir este cardápio?"
          open={isDeleteDialogOpen}
          isConfirmLoading={isDeleteLoading}
          handleConfirm={handleDelete}
          handleClose={() => handleCloseDeleteDialog()}
        />
        <Toolbar>
          <SearchInput onChange={debounce(handleChangeQueryText)} />
          <RoundedButton
            className={classes.registerButton}
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={handleOpenRegisterPage}
          >
            Cadastrar
          </RoundedButton>
        </Toolbar>{' '}
        <Toolbar>
          <TextField
            id="start-date"
            label="Data Inicial"
            type="date"
            style={{ marginRight: '8px' }}
            defaultValue={dataInicial}
            onChange={(e) => setDataInicial(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Data Final"
            type="date"
            style={{ marginRight: '8px' }}
            defaultValue={dataFinal}
            onChange={(e) => setDataFinal(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Toolbar>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCellName}>Nome</TableCell>
                <TableCell className={classes.tableCell}>
                  Público alvo
                </TableCell>
                <TableCell className={classes.tableCell}>Data</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                new Array(20)
                  .fill()
                  .map((e, i) => i)
                  .map((e) => (
                    <TableRowFocusable key={e} onClick={() => {}} hover>
                      <TableCell>
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRowFocusable>
                  ))
              ) : menu?.length === 0 ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" color="textSecondary">
                      Nenhum registro encontrado.
                    </Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              ) : (
                menu?.map((m) => (
                  <TableRowFocusable key={m.id} onClick={() => {}} hover>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.title}>{m.descricao}</div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.title}>
                        {m.publicosAlvo.length == 0
                          ? 'Todos'
                          : m.publicosAlvo.map((target) => target).join(', ')}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <div className={classes.title}>
                        {new Date(m.dataExibicao).toLocaleDateString()}
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCellButtons}>
                      <div className={classes.icons}>
                        <Edit
                          color="primary"
                          onClick={() => handleOpenEditPage(m.id)}
                        />
                        <FileCopy
                          color="primary"
                          onClick={() => handleOpenClonePage(m.id)}
                        />
                        <DeleteForever
                          color="primary"
                          onClick={() => handleOpenDeleteDialog(m.id)}
                        />
                      </div>
                    </TableCell>
                  </TableRowFocusable>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[perPage]}
          component="div"
          count={total}
          rowsPerPage={perPage}
          page={page - 1}
          onChangePage={handleChangePage}
        />
      </Container>
    </DefaultPage>
  );
};
