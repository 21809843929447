import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Typography,
  TextField,
  Checkbox,
  Modal,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info';
import { Autocomplete } from '@material-ui/lab';
import {
  LoadingButton,
  MultipleValueInput,
  RoundedButton,
  ModalContent,
} from '../../components';
import { Creators as AlertActions } from '../../store/ducks/alert';
import api from '../../services/api';
import CropImageModal from './CropImageModal';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2em',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Segoe UI',
  },
  title: {
    fontWeight: 'bold',
  },
  subTitle: {
    fontWeight: 'bold',
    marginTop: '1em',
  },
  titleText: {
    marginTop: '1em',
  },
  divPeriodFields: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  divPeriod: {
    display: 'flex',
    alignItems: 'center',
    color: '#414141',
    fontSize: '17px',
    marginRight: '10px',
  },
  dataTextStart: {
    margin: '0px',
    marginRight: '5px',
    width: '5em',
  },
  dataTextEnd: {
    margin: '0px',
    marginRight: '5px',
    width: '4em',
  },
  divRemovePreVisualizacao: {
    display: 'flex',
    width: '40%',
    alignItems: 'center',
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  preVisualizacao: {
    margin: '0px',
    marginRight: '5px',
    width: '12em',
    color: '#414141',
    fontSize: '17px',
  },
  imagemPreVisualizacao: {
    borderRadius: '10px',
  },
  dateField: {
    width: '12em',
  },
  dropzone: {
    marginTop: '1em',
    minHeight: '4em',
    width: '100%',
    color: '#C4C3C3',
  },
  dropzoneIcon: {
    color: '#C4C3C3',
    marginBottom: '1em',
  },
  dropzoneText: {
    fontSize: '15px',
    marginBottom: '0em',
    marginTop: '2em',
  },
  containerPreview: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  rowImg: {
    display: 'flex',
  },
  imgPreview: {
    marginTop: '0.5em',
    position: 'relative',
  },
  img: {
    maxWidth: '300px',
  },
  removeImg: {
    display: 'flex',
    width: '2em',
    height: '3em',
    marginLeft: '1em',
    marginTop: '1em',
  },
  divUseLink: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: '1em',
  },
  checkUseLinkProp: {
    paddingLeft: '0em',
  },
  pUseLink: {
    color: '#414141',
    fontSize: '17px',
    margin: '0em',
    marginRight: '0.5em',
    minWidth: '4em',
  },
  urlLinkText: {
    width: '100%',
  },
  descriptionText: {
    marginTop: '1em',
  },
  rootDescription: {
    height: '5em',
  },
  divTargetAudience: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1.5em',
    marginBottom: '0.5em',
  },
  fieldTargetAudience: {
    flexGrow: 1,
  },
  divPublish: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1em',
  },
  textPublish: {
    color: theme.palette.primary.main,
    fontSize: '17px',
    fontWeight: 'bold',
  },
  createButton: {
    marginLeft: 'auto',
  },
  hoveredStyle: {
    cursor: 'initial',
  },
  textColor: {
    color: theme.palette.primary.main,
  },
}));

export default ({ isEdit = false, newsInfo = {}, newsId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [title, setTitle] = useState(newsInfo?.titulo ?? '');
  const [startPeriod, setStartPeriod] = useState(
    newsInfo?.dataInicioEvento ?? ''
  );
  const [endPeriod, setEndPeriod] = useState(newsInfo?.dataTerminoEvento ?? '');
  const [checkEndPeriod, setCheckEndPeriod] = useState(
    (newsInfo?.dataTerminoEvento ?? null) !== null
  );
  const [checkUseLink, setCheckUseLink] = useState(
    (newsInfo?.urlLink ?? null) !== null
  );
  const [urlLink, setUrlLink] = useState(newsInfo?.urlLink ?? '');
  const [urlImage, setUrlImage] = useState(newsInfo?.urlImagem ?? '');
  const [description, setDescription] = useState(newsInfo?.descricao ?? '');
  const [startValidityPeriod, setStartValidityPeriod] = useState(
    newsInfo?.dataInicioValidade ?? ''
  );
  const [endValidityPeriod, setEndValidityPeriod] = useState(
    newsInfo?.dataFimValidade ?? ''
  );
  const [checkEndValidityPeriod, setCheckEndValidityPeriod] = useState(
    (newsInfo?.dataFimValidade ?? null) !== null
  );
  const [checkPublish, setCheckPublish] = useState(
    newsInfo?.publicado ?? false
  );
  const [checkSendNotification, setCheckSendNotification] = useState(
    newsInfo?.enviaNotificacao ?? false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fileObject, setFileObject] = useState([]);
  const [targetAudiences, setTargetAudiences] = useState([]);
  const [selectedTargetAudiences, setSelectedTargetAudiences] = useState(
    newsInfo?.publicoAlvo ?? []
  );
  const [cropModalIsOpen, setCropModalIsOpen] = useState(false);
  const [imageBase64, setImageBase64] = useState([]);
  const [cropActive, setCropActive] = useState(true);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(
    newsInfo?.idSetor ?? null
  );
  const [checkSendEmail, setCheckSendEmail] = useState(
    newsInfo?.enviaEmail ?? false
  );
  const tip =
    'Ao selecionar um setor os destinatários da notícia poderão enviar mensagem para o setor vinculado.';

  const setAlert = (alert) => {
    dispatch(AlertActions.setAlert(alert));
  };

  const handleRegisterError = () => {
    setIsLoading(false);
    setAlert({
      isOpen: true,
      type: 'error',
      message: 'Não foi possível salvar a notícia!',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const paramsNews = {
      idNoticia: newsId,
      checkPublish,
      sendNotification: checkSendNotification,
      title,
      startPeriod,
      endPeriod: checkEndPeriod ? endPeriod : null,
      urlImagem: urlImage,
      urlLink: checkUseLink ? urlLink : null,
      description,
      startValidityPeriod,
      idsPublicoAlvo: selectedTargetAudiences.map((ta) => ta.id),
      sendEmail: checkSendEmail,
      idSector: selectedSector?.Id,
      endValidityPeriod: checkEndValidityPeriod ? endValidityPeriod : null,
    };

    if (isEdit) {
      api
        .put(`news/editNews`, paramsNews)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Notícia alterada com sucesso!',
          });
          history.push('/news');
        })
        .catch((err) => {
          handleRegisterError(err);
        });
    } else {
      api
        .post('news/createNews', paramsNews)
        .then(() => {
          setIsLoading(false);
          setAlert({
            isOpen: true,
            type: 'success',
            message: 'Notícia cadastrada com sucesso!',
          });
          history.push('/news');
        })
        .catch((err) => {
          handleRegisterError(err);
        });
    }
  };

  useEffect(() => {
    api
      .get('target-audience', {
        params: {
          limit: 1000,
          appRole: ['communication', 'admin', 'comunicationPublish'],
        },
      })
      .then((response) => {
        setTargetAudiences(response.data.data);
      });

    api
      .get('/sector/getSectorsByUser')
      .then((response) => {
        if (!response.data.Sucesso)
          setAlert({
            isOpen: true,
            type: 'warning',
            message: response.data.Mensagem,
          });
        else {
          setSectors((prevState) => [...prevState, ...response.data.Dados]);
          setSelectedSector(
            response.data.Dados.find((e) => e.Id === selectedSector)
          );
        }
      })
      .catch(() => {
        setAlert({
          isOpen: true,
          type: 'error',
          message:
            'Ocorreu um problema inesperado ao buscar setores de usuário!',
        });
      });
  }, []);

  const deleteImage = () => {
    setUrlImage('');
    setCropActive(true);
    setFileObject([]);
  };

  const handleCloseModal = () => {
    setCropModalIsOpen(false);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  useEffect(() => {
    if (fileObject.length > 0 && cropActive) {
      toBase64(fileObject[0]).then((base64) => {
        setImageBase64(base64);
      });
    } else if (fileObject.length > 0) {
      toBase64(fileObject[0]).then((base64) => {
        setUrlImage(base64);
      });
    }
    if (fileObject.length === 0) {
      setImageBase64('');
      setCropActive(true);
    }
  }, [fileObject, cropActive]);

  useEffect(() => {
    if (imageBase64.length > 0) {
      setCropModalIsOpen(true);
    }
  }, [imageBase64]);

  const handleSelectSetor = (setor) => {
    setSelectedSector(setor);
  };

  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      <Typography className={classes.title} variant="h5" color="primary">
        {isEdit ? 'Edição' : 'Cadastro'}
      </Typography>
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Título
      </Typography>
      <TextField
        label="Título"
        value={title}
        className={classes.titleText}
        variant="outlined"
        onChange={(e) => setTitle(e.target.value)}
        required
        inputProps={{ maxLength: 300 }}
      />
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Período do Evento
      </Typography>
      <div className={classes.divPeriodFields}>
        <div className={classes.divPeriod}>
          <p className={classes.dataTextStart}>Data início</p>
          <TextField
            className={classes.dateField}
            type="datetime-local"
            onChange={(e) => setStartPeriod(e.target.value)}
            required={checkPublish}
            value={startPeriod}
          />
        </div>
        <div className={classes.divPeriod}>
          <Checkbox
            checked={checkEndPeriod}
            onChange={() => setCheckEndPeriod(!checkEndPeriod)}
            color="primary"
          />
          <p className={classes.dataTextEnd}>Término</p>
          <TextField
            disabled={!checkEndPeriod}
            className={classes.dateField}
            type="datetime-local"
            onChange={(e) => setEndPeriod(e.target.value)}
            required={checkPublish}
            value={endPeriod}
          />
        </div>
      </div>
      {urlImage !== '' ? (
        <div className={classes.containerPreview}>
          <Typography
            className={classes.subTitle}
            variant="subtitle1"
            color="primary"
          >
            Pré-visualização:
          </Typography>
          <div className={classes.rowImg}>
            <div className={classes.imgPreview}>
              <img
                src={urlImage}
                className={classes.img}
                alt="Imagem notícia"
              />
            </div>
            <div className={classes.removeImg}>
              <RoundedButton
                variant="contained"
                color="primary"
                onClick={() => deleteImage()}
              >
                <DeleteIcon />
              </RoundedButton>
            </div>
          </div>
        </div>
      ) : (
        <>
          <DropzoneArea
            classes={{
              root: classes.dropzone,
              icon: classes.dropzoneIcon,
              text: classes.dropzoneText,
            }}
            acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
            onChange={(newFileObjs) => {
              setFileObject(newFileObjs);
            }}
            dropzoneText="Anexe sua imagem"
            showAlerts={false}
            filesLimit={1}
            showPreviews={false}
            showPreviewsInDropzone={false}
          />
          <div>
            <p className={classes.textColor}>
              * Para não ser necessário recortar a imagem, a sua proporção deve
              ser de 4:3. (Formato retangular)
            </p>
          </div>
        </>
      )}
      {urlImage === 'ERROR' ? (
        <Typography
          className={classes.subTitle}
          variant="subtitle1"
          color="error"
        >
          Há um problema com o serviço de arquivos. Não será possível criar ou
          editar noticias com imagens.
        </Typography>
      ) : null}
      <Modal open={cropModalIsOpen}>
        <ModalContent>
          <CropImageModal
            handleClose={handleCloseModal}
            imageBase64={imageBase64}
            file={fileObject}
            setFileObject={setFileObject}
            setCropActive={setCropActive}
          />
        </ModalContent>
      </Modal>
      <div className={classes.divUseLink}>
        <Checkbox
          className={classes.checkUseLinkProp}
          checked={checkUseLink}
          onChange={() => setCheckUseLink(!checkUseLink)}
          color="primary"
        />
        <p className={classes.pUseLink}>Usar link</p>
        <TextField
          label="Http://"
          variant="outlined"
          className={classes.urlLinkText}
          onChange={(e) => setUrlLink(e.target.value)}
          disabled={!checkUseLink}
          value={urlLink}
          inputProps={{ maxLength: 400 }}
        />
      </div>
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Descrição
      </Typography>
      <TextField
        label="Até 2000 caracteres"
        variant="outlined"
        className={classes.descriptionText}
        multiline
        required={checkPublish}
        rows={4}
        inputProps={{
          maxLength: 2000,
        }}
        onChange={(e) => setDescription(e.target.value)}
        value={description}
      />
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Setor
        <Tooltip title={tip}>
          <InfoIcon color="primary" />
        </Tooltip>
      </Typography>
      <Autocomplete
        options={sectors}
        getOptionLabel={(option) => option.Nome}
        onChange={(event, value) => handleSelectSetor(value)}
        value={selectedSector}
        renderInput={(params) => (
          <TextField {...params} label="Procurar..." variant="outlined" />
        )}
      />
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Público-alvo
      </Typography>
      <div className={classes.divTargetAudience}>
        <MultipleValueInput
          customClasses={classes.fieldTargetAudience}
          label="Procurar..."
          variant="outlined"
          labelProperty="descricao"
          value={selectedTargetAudiences}
          options={targetAudiences}
          onChange={(event, value) => setSelectedTargetAudiences(value)}
        />
      </div>
      <Typography
        className={classes.subTitle}
        variant="subtitle1"
        color="primary"
      >
        Período de Validade
      </Typography>
      <div className={classes.divPeriodFields}>
        <div className={classes.divPeriod}>
          <p className={classes.dataTextStart}>Data início</p>
          <TextField
            className={classes.dateField}
            type="datetime-local"
            onChange={(e) => setStartValidityPeriod(e.target.value)}
            required={checkPublish}
            value={startValidityPeriod}
          />
        </div>
        <div className={classes.divPeriod}>
          <Checkbox
            checked={checkEndValidityPeriod}
            onChange={() => setCheckEndValidityPeriod(!checkEndValidityPeriod)}
            color="primary"
          />
          <p className={classes.dataTextEnd}>Término</p>
          <TextField
            disabled={!checkEndValidityPeriod}
            className={classes.dateField}
            type="datetime-local"
            onChange={(e) => setEndValidityPeriod(e.target.value)}
            required={checkPublish}
            value={endValidityPeriod}
          />
        </div>
      </div>
      <div className={classes.divPublish}>
        <div className={classes.checkbox}>
          <Checkbox
            className={classes.checkUseLinkProp}
            checked={checkPublish}
            onChange={() => {
              if (checkPublish) setCheckSendNotification(false);
              setCheckPublish(!checkPublish);
            }}
            color="primary"
          />
          <p className={classes.textPublish}>Publicar</p>
        </div>
        <div className={classes.checkbox}>
          <Checkbox
            className={classes.checkUseLinkProp}
            checked={checkSendNotification}
            disabled={!checkPublish}
            onChange={() => setCheckSendNotification(!checkSendNotification)}
            color="primary"
          />
          <p className={classes.textPublish}>Envia notificação</p>
        </div>
        <div className={classes.checkbox}>
          <Checkbox
            className={classes.checkUseLinkProp}
            checked={checkSendEmail}
            disabled={!checkPublish}
            onChange={() => setCheckSendEmail(!checkSendEmail)}
            color="primary"
          />
          <p className={classes.textPublish}>Envia e-mail</p>
        </div>
        <LoadingButton
          className={classes.createButton}
          type="submit"
          variant="contained"
          color="primary"
          isLoading={isLoading}
        >
          {isEdit ? 'Salvar' : 'Cadastrar'}
        </LoadingButton>
      </div>
    </form>
  );
};
