import React from 'react';

import { Divider } from '@material-ui/core';

import { ProfileSection, ApplicationSection, MenuSections } from '..';

export default () => {
  return (
    <>
      <ProfileSection />
      <Divider />
      <ApplicationSection />
      <Divider />
      <MenuSections />
    </>
  );
};
