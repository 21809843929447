import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    width: '140px',
    height: '140px',
    margin: '10px',
    padding: '10px',
    textAlign: 'center',
    textDecoration: 'none',
    transition: 'all 0.5s ease-out',
    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: theme.palette.primary.dark,
    },
  },
  icon: {
    fontSize: '3em',
  },
  subSection: {
    color: '#A9A9A9',
  },
}));

export default ({
  item: { name, subSection, path, icon = <AccountCircleOutlinedIcon /> },
}) => {
  const classes = useStyles();
  const FeatureIcon = React.cloneElement(icon, { className: classes.icon });
  return (
    <Card className={classes.root} component={Link} to={path}>
      {FeatureIcon}
      <Typography className={classes.subSection}>{subSection? subSection + "/" : ""}</Typography>
      <Typography>{name}</Typography>
    </Card>
  );
};
