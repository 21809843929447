import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ItemsForm from './itemsForm';
import { LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const { id } = useParams();

  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    api
      .get(`/getItemById/${id}`)
      .then(async (response) => {
        setItem(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [id]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : (
        <>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <ItemsForm isEdit={true} headerTitle="Edição de item" item={item}></ItemsForm>
          )}
        </>
      )}
    </>
  );
};
