import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import { Drafts, Mail } from '@material-ui/icons';
import api from '../../../services/api';

export default ({ infoChat, handleUpdate = () => {} }) => {
  if (!infoChat) return null;

  const handleRegisterRead = async (e) => {
    if (e.detail > 1) return;

    const body = {
      idChat: infoChat.IdChatSetor,
      read: Boolean(infoChat.QtdMensagensNaoLidas),
    };

    handleUpdate({
      ...infoChat,
      QtdMensagensNaoLidas: body.read ? 0 : 1,
    });

    api.post('chat/registerReadMessageSector', body).catch(() => {});
  };

  return (
    <IconButton onClick={handleRegisterRead}>
      {infoChat.QtdMensagensNaoLidas ? (
        <Tooltip title="Marcar como lido">
          <Drafts color="primary" />
        </Tooltip>
      ) : (
        <Tooltip title="Marcar como não lido">
          <Mail color="primary" />
        </Tooltip>
      )}
    </IconButton>
  );
};
