import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

export default withStyles((theme) => ({
  root: {
    borderRadius: '30px',
    '& input + fieldset': {
      borderRadius: '30px',
    },
    '& input:focus + fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}))(TextField);
