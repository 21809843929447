/* eslint-disable radix */
import React, { useState, useEffect } from 'react';

import MenuForm from './MenuForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container, LoadingForm, NotFound } from '../../components';

import api from '../../services/api';

export default () => {
  const [menuInfo, setmenuInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const params = { appRole: ['coordination', 'admin'] };
    api
      .get(`target-audience/info`, { params })
      .then(async (response) => {
        setmenuInfo(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setNotFound(true);
      });
  }, []);

  return (
    <DefaultPage title="Cardápio">
      {notFound ? (
        <NotFound />
      ) : (
        <Container>
          {isLoading ? (
            <LoadingForm />
          ) : (
            <MenuForm targetAudienceInfo={menuInfo} />
          )}
        </Container>
      )}
    </DefaultPage>
  );
};
