/* eslint-disable radix */
import React from 'react';

import ConfigurationMessagesForm from './ConfigurationMessagesForm';
import DefaultPage from '../../templates/DefaultPage';
import { Container } from '../../components';

export default () => {
  return (
    <DefaultPage title="Cadastro de configurações">
        <Container>
            <ConfigurationMessagesForm />
        </Container>
    </DefaultPage>
  );
};
