import React from 'react';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import InfoDialog from '../../components/InfoDialog/index';

const useStyles = makeStyles(() => ({
  divItemType: {
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    marginTop: '1px',
  },
  cellDialog: {
    padding: '0.5em',
    textAlign: 'center',
    width: '25%',
  },
  divOptionsConfigs: {
    marginLeft: '1em',
  },
  divConfigs: {
    margin: '16px 0px',
  }
}));

export default ({item, onClose, open}) => {
  const classes = useStyles();

  return (
    <InfoDialog
      onClose={onClose}
      open={open}
      title="Detalhes do cadastro"
    >
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          Descrição do Item:&nbsp;
        </Typography>
        <div className={classes.body}>
          {item?.identificador + " - " + item?.descricao}
        </div>
      </div>
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          Tipo de Item:&nbsp;
        </Typography>
        <div>
          {item?.tipoDescricao}
        </div>
      </div>
      <div className={classes.divItemType}>
        <Typography
          variant="h4"
          style={{ fontSize: '1em', margin: '0.5em 0em' }}
          color="primary"
        >
          Estabelecimento:&nbsp;
        </Typography>
        <div>
          {item?.descricaoEstabelecimento}
        </div>
      </div>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.cellDialog}>
                  <Typography
                    variant="h4"
                    style={{ fontSize: '1em', margin: '0.5em 0em' }}
                    color="primary"
                  >
                    Opção:
                  </Typography>
                </TableCell>
                <TableCell className={classes.cellDialog}>
                  <Typography
                    variant="h4"
                    style={{ fontSize: '1em', margin: '0.5em 0em' }}
                    color="primary"
                  >
                    Usa complemento:
                  </Typography>
                </TableCell>
                <TableCell className={classes.cellDialog}>
                  <Typography
                    variant="h4"
                    style={{ fontSize: '1em', margin: '0.5em 0em' }}
                    color="primary"
                  >
                    Tipo complemento:
                  </Typography>
                </TableCell>
                <TableCell className={classes.cellDialog}>
                  <Typography
                    variant="h4"
                    style={{ fontSize: '1em', margin: '0.5em 0em' }}
                    color="primary"
                  >
                    Descrição complemento:
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item?.opcoes.map((opt) => (
                <TableRow key={opt.id} className={classes.rowDialog}>
                  <TableCell className={classes.cellDialog}>{opt.descricao}</TableCell>
                  <TableCell className={classes.cellDialog}>{opt.usaComplemento ? "Sim" : "Não"}</TableCell>
                  <TableCell className={classes.cellDialog}>{opt.complementoTipoDescricao}</TableCell>
                  <TableCell className={classes.cellDialog}>{opt.complementoDescricao}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
        <div className={classes.divConfigs}>
          <Typography
            variant="h4"
            style={{ fontSize: '1em', margin: '0.5em 0em' }}
            color="primary"
          >
          Configurações vinculadas:&nbsp;
          </Typography>
          {item?.checkLists.length > 0 ? <>
            {item?.checkLists.map((ch) => (
              <div key={ch.id} className={classes.divOptionsConfigs}>
                {" - " + ch.descricao}
              </div>
            ))}
            </> : <>Item não possui configuração vinculada.</>} 
        </div>
    </InfoDialog>
  )
}